@charset "UTF-8";
/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/* ----------------------------- Phoenix-buttons ---------------------------- */
/*-----------------------------------------------
|   Phoenix buttons
-----------------------------------------------*/
/* -------------------------------------------------------------------------- */
/*                                Phoenix button                              */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                  Utilities                                 */
/* -------------------------------------------------------------------------- */
:root,
:root.light,
:root .light {
  --phoenix-gray-soft: #f5f7fa;
  --phoenix-gray-100: #eff2f6;
  --phoenix-gray-200: #e3e6ed;
  --phoenix-gray-300: #cbd0dd;
  --phoenix-gray-400: #9fa6bc;
  --phoenix-gray-500: #8a94ad;
  --phoenix-gray-600: #6e7891;
  --phoenix-gray-700: #525b75;
  --phoenix-gray-800: #3e465b;
  --phoenix-gray-900: #31374a;
  --phoenix-gray-1000: #222834;
  --phoenix-gray-1100: #141824;
  --phoenix-gray-soft-rgb: 245, 247, 250;
  --phoenix-gray-100-rgb: 239, 242, 246;
  --phoenix-gray-200-rgb: 227, 230, 237;
  --phoenix-gray-300-rgb: 203, 208, 221;
  --phoenix-gray-400-rgb: 159, 166, 188;
  --phoenix-gray-500-rgb: 138, 148, 173;
  --phoenix-gray-600-rgb: 110, 120, 145;
  --phoenix-gray-700-rgb: 82, 91, 117;
  --phoenix-gray-800-rgb: 62, 70, 91;
  --phoenix-gray-900-rgb: 49, 55, 74;
  --phoenix-gray-1000-rgb: 34, 40, 52;
  --phoenix-gray-1100-rgb: 20, 24, 36;
  --phoenix-soft: #f5f7fa;
  --phoenix-100: #eff2f6;
  --phoenix-200: #e3e6ed;
  --phoenix-300: #cbd0dd;
  --phoenix-400: #9fa6bc;
  --phoenix-500: #8a94ad;
  --phoenix-600: #6e7891;
  --phoenix-700: #525b75;
  --phoenix-800: #3e465b;
  --phoenix-900: #31374a;
  --phoenix-1000: #222834;
  --phoenix-1100: #141824;
  --phoenix-facebook: #3c5a99;
  --phoenix-google-plus: #dd4b39;
  --phoenix-twitter: #1da1f2;
  --phoenix-linkedin: #0077b5;
  --phoenix-youtube: #ff0001;
  --phoenix-github: #333333;
  --phoenix-bootstrap: #6f45a9;
  --phoenix-css3: #203ccf;
  --phoenix-html5: #bf4b2c;
  --phoenix-sass: #c55589;
  --phoenix-gulp: #d54049;
  --phoenix-w3c: #255997;
  --phoenix-primary: #3874ff;
  --phoenix-secondary: #31374a;
  --phoenix-success: #25b003;
  --phoenix-info: #0097eb;
  --phoenix-warning: #e5780b;
  --phoenix-danger: #ed2000;
  --phoenix-light: #eff2f6;
  --phoenix-dark: #141824;
  --phoenix-primary-soft: #f5f8ff;
  --phoenix-primary-100: #e5edff;
  --phoenix-primary-200: #adc5ff;
  --phoenix-primary-300: #85a9ff;
  --phoenix-primary-500: #004dff;
  --phoenix-primary-600: #003cc7;
  --phoenix-warning-soft: #fff6e0;
  --phoenix-warning-100: #ffefca;
  --phoenix-warning-200: #ffe6ad;
  --phoenix-warning-300: #ffcc85;
  --phoenix-warning-500: #d6700a;
  --phoenix-warning-600: #bc3803;
  --phoenix-danger-300: #f48270;
  --phoenix-danger-100: #ffe0db;
  --phoenix-danger-200: #fabcb3;
  --phoenix-danger-500: #cc1b00;
  --phoenix-danger-600: #b81800;
  --phoenix-success-100: #d9fbd0;
  --phoenix-success-200: #bee8b4;
  --phoenix-success-300: #90d67f;
  --phoenix-success-500: #23890b;
  --phoenix-success-600: #1c6c09;
  --phoenix-info-100: #c7ebff;
  --phoenix-info-200: #96d9ff;
  --phoenix-info-300: #60c6ff;
  --phoenix-info-500: #0080c7;
  --phoenix-info-600: #005585;
  --phoenix-white: #fff;
  --phoenix-black: #000000;
  --phoenix-soft-rgb: 245, 247, 250;
  --phoenix-100-rgb: 239, 242, 246;
  --phoenix-200-rgb: 227, 230, 237;
  --phoenix-300-rgb: 203, 208, 221;
  --phoenix-400-rgb: 159, 166, 188;
  --phoenix-500-rgb: 138, 148, 173;
  --phoenix-600-rgb: 110, 120, 145;
  --phoenix-700-rgb: 82, 91, 117;
  --phoenix-800-rgb: 62, 70, 91;
  --phoenix-900-rgb: 49, 55, 74;
  --phoenix-1000-rgb: 34, 40, 52;
  --phoenix-1100-rgb: 20, 24, 36;
  --phoenix-facebook-rgb: 60, 90, 153;
  --phoenix-google-plus-rgb: 221, 75, 57;
  --phoenix-twitter-rgb: 29, 161, 242;
  --phoenix-linkedin-rgb: 0, 119, 181;
  --phoenix-youtube-rgb: 255, 0, 1;
  --phoenix-github-rgb: 51, 51, 51;
  --phoenix-bootstrap-rgb: 111, 69, 169;
  --phoenix-css3-rgb: 32, 60, 207;
  --phoenix-html5-rgb: 191, 75, 44;
  --phoenix-sass-rgb: 197, 85, 137;
  --phoenix-gulp-rgb: 213, 64, 73;
  --phoenix-w3c-rgb: 37, 89, 151;
  --phoenix-primary-rgb: 56, 116, 255;
  --phoenix-secondary-rgb: 49, 55, 74;
  --phoenix-success-rgb: 37, 176, 3;
  --phoenix-info-rgb: 0, 151, 235;
  --phoenix-warning-rgb: 229, 120, 11;
  --phoenix-danger-rgb: 237, 32, 0;
  --phoenix-light-rgb: 239, 242, 246;
  --phoenix-dark-rgb: 20, 24, 36;
  --phoenix-primary-soft-rgb: 245, 248, 255;
  --phoenix-primary-100-rgb: 229, 237, 255;
  --phoenix-primary-200-rgb: 173, 197, 255;
  --phoenix-primary-300-rgb: 133, 169, 255;
  --phoenix-primary-500-rgb: 0, 77, 255;
  --phoenix-primary-600-rgb: 0, 60, 199;
  --phoenix-warning-soft-rgb: 255, 246, 224;
  --phoenix-warning-100-rgb: 255, 239, 202;
  --phoenix-warning-200-rgb: 255, 230, 173;
  --phoenix-warning-300-rgb: 255, 204, 133;
  --phoenix-warning-500-rgb: 214, 112, 10;
  --phoenix-warning-600-rgb: 188, 56, 3;
  --phoenix-danger-300-rgb: 244, 130, 112;
  --phoenix-danger-100-rgb: 255, 224, 219;
  --phoenix-danger-200-rgb: 250, 188, 179;
  --phoenix-danger-500-rgb: 204, 27, 0;
  --phoenix-danger-600-rgb: 184, 24, 0;
  --phoenix-success-100-rgb: 217, 251, 208;
  --phoenix-success-200-rgb: 190, 232, 180;
  --phoenix-success-300-rgb: 144, 214, 127;
  --phoenix-success-500-rgb: 35, 137, 11;
  --phoenix-success-600-rgb: 28, 108, 9;
  --phoenix-info-100-rgb: 199, 235, 255;
  --phoenix-info-200-rgb: 150, 217, 255;
  --phoenix-info-300-rgb: 96, 198, 255;
  --phoenix-info-500-rgb: 0, 128, 199;
  --phoenix-info-600-rgb: 0, 85, 133;
  --phoenix-white-rgb: 255, 255, 255;
  --phoenix-black-rgb: 0, 0, 0;
  --phoenix-white: #fff;
  --phoenix-black: #000000;
  --phoenix-white-rgb: 255, 255, 255;
  --phoenix-black-rgb: 0, 0, 0;
  --phoenix-body-color-rgb: 49, 55, 74;
  --phoenix-body-bg-rgb: 245, 247, 250;
  --phoenix-font-sans-serif: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --phoenix-font-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --phoenix-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --phoenix-scroll-margin-top: 2.5rem;
  --phoenix-body-font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --phoenix-body-font-size: 1rem;
  --phoenix-body-font-weight: 400;
  --phoenix-body-line-height: 1.49;
  --phoenix-body-color: #31374a;
  --phoenix-body-bg: #f5f7fa;
  --phoenix-border-width: 1px;
  --phoenix-border-style: solid;
  --phoenix-border-color: var(--phoenix-gray-200);
  --phoenix-border-color-translucent: rgba(0, 0, 0, 0.175);
  --phoenix-border-radius: 0.375rem;
  --phoenix-border-radius-sm: 0.25rem;
  --phoenix-border-radius-lg: 0.5rem;
  --phoenix-border-radius-xl: 1rem;
  --phoenix-border-radius-2xl: 2rem;
  --phoenix-border-radius-pill: 50rem;
  --phoenix-heading-color: var(--phoenix-1100);
  --phoenix-code-color: #d63384;
  --phoenix-highlight-bg: #fff3cd;
  --phoenix-input-bg: #fff;
  --phoenix-input-border-color: #cbd0dd;
  --phoenix-input-group-addon-bg: #eff2f6;
  /* -------------------------------------------------------------------------- */
  /*                                Scrollbar                                   */
  /* -------------------------------------------------------------------------- */
  --phoenix-scrollbar-bg: rgba(159, 166, 188, 0.55);
  /* -------------------------------------------------------------------------- */
  /*                                Navbar                                      */
  /* -------------------------------------------------------------------------- */
  --phoenix-navbar-top-height: 4rem;
  /* ------------------------------- navbar-top  ------------------------------- */
  --phoenix-navbar-top-link-color: var(--phoenix-gray-900);
  --phoenix-navbar-top-menu-bg-color: #fff;
  --phoenix-navbar-top-border-color: #cbd0dd;
  --phoenix-navbar-top-link-hover-color: #222834;
  --phoenix-navbar-top-link-hover-bg: #eff2f6;
  --phoenix-navbar-top-link-active-color: var(--phoenix-primary);
  --phoenix-navbar-top-dropdown-color: var(--phoenix-gray-900);
  --phoenix-navbar-top-dropdown-hover-bg: #eff2f6;
  --phoenix-navbar-top-menu-margin: 1.5rem;
  /* ------------------------------- dual-nav ------------------------------- */
  --phoenix-dual-nav-border-color: #e3e6ed;
  --phoenix-dual-nav-first-layer-bg: #eff2f6;
  /* ------------------------------- navbar-vertical light ------------------------------- */
  --phoenix-navbar-vertical-border-color: #cbd0dd;
  --phoenix-navbar-vertical-bg-color: #fff;
  --phoenix-navbar-vertical-label-color: #6e7891;
  --phoenix-navbar-vertical-link-color: #525b75;
  --phoenix-navbar-vertical-link-hover-color: #3e465b;
  --phoenix-navbar-vertical-link-hover-bg: #eff2f6;
  --phoenix-navbar-vertical-link-active-color: #3874ff;
  --phoenix-navbar-vertical-link-disable-color: #8a94ad;
  --phoenix-navbar-vertical-width: 15.875rem;
  --phoenix-navbar-vertical-link-icon-color: #3e465b;
  --phoenix-navbar-vertical-dropdown-indicator-color: #8a94ad;
  --phoenix-navbar-footer-border-color: #cbd0dd;
  --phoenix-navbar-footer-border-color: #cbd0dd;
  --phoenix-navbar-vertical-hr-color: #e3e6ed;
  --phoenix-navbar-vertical-collapsed-menu-bg: #fff;
  --phoenix-navbar-vertical-collapsed-menu-color: #222834;
  --phoenix-hamburger-menu-color: #6e7891;
  /* ------------------------------- navbar-top ------------------------------- */
  --phoenix-navbar-top-bg-color: #fff;
  /* --------------------------------- buttons -------------------------------- */
  --phoenix-btn-hover-border-color: transparent;
  --phoenix-btn-active-border-color: transparent;
  /* ------------------------------- List Group ------------------------------- */
  --phoenix-list-group-bg: #fff;
  --phoenix-list-group-action-hover-color: #525b75;
  --phoenix-list-group-hover-bg: #eff2f6;
  --phoenix-body-bg: #f5f7fa;
  --phoenix-body-color: #31374a;
  --phoenix-headings-color: #31374a;
  --phoenix-link-hover-color: #004dff;
  /* ------------------------------- link-color ------------------------------- */
  --phoenix-link-color: #3874ff;
  --phoenix-colored-link-100-hover-color: map-get(grays, 200);
  /* --------------------------------- choices -------------------------------- */
  --phoenix-choices-inner-min-height: auto;
  --phoenix-choices-item-has-no-choices-bg: #fff;
  /* -------------------------------- Flatpickr ------------------------------- */
  --phoenix-flatpickr-calendar-input-bg: var(--phoenix-input-bg);
  --phoenix-flatpickr-calendar-bg: #e3e6ed;
  --phoenix-flatpickr-current-month-color: #31374a;
  --phoenix-flatpickr-weekday-color: #31374a;
  --phoenix-flatpickr-calendar-day-hover-bg: rgba(203, 208, 221, 0.5);
  --phoenix-flatpickr-weekend-days-color: #d6700a;
  --phoenix-flatpickr-today-color: #fff;
  --phoenix-flatpickr-calendar-day-selected-bg: rgba(56, 116, 255, 0.2);
  /* -------------------------------- Fullcalendar ------------------------------- */
  --phoenix-calendar-bg: #e3e6ed;
  --phoenix-calendar-border-color: #f5f7fa;
  /* --------------------------------- wizard --------------------------------- */
  --phoenix-theme-wizard-nav-item-circle-bg: #eff2f6;
  --phoenix-theme-wizard-complete-color: #25b003;
  --phoenix-theme-wizard-active-color: #3874ff;
  --phoenix-border-opacity: 1;
  /* --------------------------------- tinymce -------------------------------- */
  --phoenix-tinymce-bg: var(--phoenix-input-bg);
  --phoenix-tinymce-tox-tbtn-enabled-icon-color: var(--phoenix-gray-800);
  /* -------------------------------- choices -------------------------------- */
  --phoenix-choices-item-bg: #e5edff;
  --phoenix-choices-item-color: #222834;
  /* ---------------------------------- docs ---------------------------------- */
  --phoenix-docs-language-bg: #222834;
  /* ------------------------------- form select ------------------------------ */
  --phoenix-form-select-indicator: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwIiBoZWlnaHQ9IjE1MCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03NS4zNDggMTI3LjE5MkM3Mi40MzgxIDEyNy4xOTIgNjkuODUxNCAxMjYuMjIyIDY3LjkxMTUgMTI0LjI4Mkw1LjgzMjE1IDYyLjIwMjNDMS42Mjg4NyA1OC4zMjIzIDEuNjI4ODcgNTEuNTMyNCA1LjgzMjE1IDQ3LjY1MjVDOS43MTIxMSA0My40NDkyIDE2LjUwMiA0My40NDkyIDIwLjM4MiA0Ny42NTI1TDc1LjM0OCAxMDIuMjk1TDEyOS45OTEgNDcuNjUyNUMxMzMuODcxIDQzLjQ0OTIgMTQwLjY2MSA0My40NDkyIDE0NC41NDEgNDcuNjUyNUMxNDguNzQ0IDUxLjUzMjQgMTQ4Ljc0NCA1OC4zMjIzIDE0NC41NDEgNjIuMjAyM0w4Mi40NjEzIDEyNC4yODJDODAuNTIxMyAxMjYuMjIyIDc3LjkzNDcgMTI3LjE5MiA3NS4zNDggMTI3LjE5MloiIGZpbGw9IiMzMTM3NEEiLz4KPC9zdmc+Cg==");
  /* --------------------------------- Avatar --------------------------------- */
  --phoenix-avatar-status-border-color: #fff;
  --phoenix-avatar-name-color: #004dff;
  /* ---------------------------------- link ---------------------------------- */
  --phoenix-colored-link-100-hover-color: map-get(grays, "200");
  /* ---------------------------------- logo ---------------------------------- */
  --phoenix-logo-text: #6e7891;
  /* -------------------------- theme-control-toggle -------------------------- */
  --phoenix-theme-control-toggle-bg: rgba(255, 204, 133, 0.24);
  --phoenix-theme-control-toggle-hover-bg: #e5780b;
  --phoenix-theme-control-toggle-color: #e5780b;
  --phoenix-theme-control-toggle-hover-color: #fff6e0;
  /* ---------------------------------- Badge --------------------------------- */
  /* ------------------------------- navbar-vertical light dark ------------------------------- */
  /* -------------------------------- Kanban ------------------------------- */
  --phoenix-kanban-bg: #e3e6ed;
  --phoenix-kanban-column-bg: #f5f7fa;
  --phoenix-ghost-bg: var(--phoenix-gray-100);
  --phoenix-drag-bg: var(--phoenix-white);
  --phoenix-kanban-column-hover-bg: var(--phoenix-white);
}
:root .nav-slim,
:root.light .nav-slim,
:root .light .nav-slim {
  --phoenix-navbar-top-height: 1.7rem;
}
:root .dual-nav,
:root.light .dual-nav,
:root .light .dual-nav {
  --phoenix-navbar-top-height: 7.25rem;
}
:root .navbar-slim,
:root.light .navbar-slim,
:root .light .navbar-slim {
  --phoenix-navbar-top-menu-margin: .8rem;
}
:root.dual-nav,
:root.light.dual-nav,
:root .light.dual-nav {
  --phoenix-navbar-top-menu-margin: 1.3rem;
}
:root:not(.dark) .navbar-darker,
:root.light:not(.dark) .navbar-darker,
:root .light:not(.dark) .navbar-darker {
  --phoenix-navbar-top-dropdown-hover-bg: #31374a;
  --phoenix-navbar-top-dropdown-color: #9fa6bc;
}
:root:not(.dark) .navbar-darker:not(.dark) .navbar-top-collapse.show,
:root.light:not(.dark) .navbar-darker:not(.dark) .navbar-top-collapse.show,
:root .light:not(.dark) .navbar-darker:not(.dark) .navbar-top-collapse.show {
  --phoenix-navbar-top-link-color: #9fa6bc;
  --phoenix-navbar-top-menu-bg-color: #141824;
  --phoenix-navbar-top-border-color: #373e53;
  --phoenix-navbar-top-link-hover-color: #cbd0dd;
  --phoenix-navbar-top-link-hover-bg: #222834;
  --phoenix-navbar-top-link-active-color: #cbd0dd;
}
:root.dual-nav:not(.dark) .navbar-darker,
:root.light.dual-nav:not(.dark) .navbar-darker,
:root .light.dual-nav:not(.dark) .navbar-darker {
  --phoenix-dual-nav-border-color: #31374a;
  --phoenix-dual-nav-first-layer-bg: #0f111a;
}
:root.dual-nav:not(.dark) .navbar-darker .search-box,
:root.light.dual-nav:not(.dark) .navbar-darker .search-box,
:root .light.dual-nav:not(.dark) .navbar-darker .search-box {
  --phoenix-input-bg: #141824;
  --phoenix-input-border-color: #373e53;
}
:root.dual-nav:not(.dark) .navbar-darker .search-box.search-box-icon,
:root.light.dual-nav:not(.dark) .navbar-darker .search-box.search-box-icon,
:root .light.dual-nav:not(.dark) .navbar-darker .search-box.search-box-icon {
  color: #525b75;
}
:root.navbar-vertical-collapsed,
:root.light.navbar-vertical-collapsed,
:root .light.navbar-vertical-collapsed {
  --phoenix-navbar-vertical-width: 4rem;
}
:root .badge-phoenix-primary,
:root.light .badge-phoenix-primary,
:root .light .badge-phoenix-primary {
  --phoenix-badge-bg: #e5edff;
  --phoenix-badge-color: #003cc7;
  --phoenix-badge-border-color: #adc5ff;
}
:root .badge-phoenix-secondary,
:root.light .badge-phoenix-secondary,
:root .light .badge-phoenix-secondary {
  --phoenix-badge-bg: #e3e6ed;
  --phoenix-badge-color: #141824;
  --phoenix-badge-border-color: #cbd0dd;
}
:root .badge-phoenix-success,
:root.light .badge-phoenix-success,
:root .light .badge-phoenix-success {
  --phoenix-badge-bg: #d9fbd0;
  --phoenix-badge-color: #1c6c09;
  --phoenix-badge-border-color: #bee8b4;
}
:root .badge-phoenix-info,
:root.light .badge-phoenix-info,
:root .light .badge-phoenix-info {
  --phoenix-badge-bg: #c7ebff;
  --phoenix-badge-color: #005585;
  --phoenix-badge-border-color: #96d9ff;
}
:root .badge-phoenix-warning,
:root.light .badge-phoenix-warning,
:root .light .badge-phoenix-warning {
  --phoenix-badge-bg: #ffefca;
  --phoenix-badge-color: #bc3803;
  --phoenix-badge-border-color: #ffcc85;
}
:root .badge-phoenix-danger,
:root.light .badge-phoenix-danger,
:root .light .badge-phoenix-danger {
  --phoenix-badge-bg: #ffe0db;
  --phoenix-badge-color: #b81800;
  --phoenix-badge-border-color: #fabcb3;
}
:root:not(.dark) .navbar-darker,
:root.light:not(.dark) .navbar-darker,
:root .light:not(.dark) .navbar-darker {
  --phoenix-navbar-vertical-border-color: #31374a;
  --phoenix-navbar-vertical-bg-color: #222834;
  --phoenix-navbar-vertical-label-color: #6e7891;
  --phoenix-navbar-vertical-link-color: #9fa6bc;
  --phoenix-navbar-vertical-link-hover-color: #eff2f6;
  --phoenix-navbar-vertical-link-hover-bg: #31374a;
  --phoenix-navbar-vertical-link-active-color: #fff;
  --phoenix-navbar-vertical-link-disable-color: #8a94ad;
  --phoenix-navbar-vertical-link-icon-color: #9fa6bc;
  --phoenix-navbar-vertical-hr-color: #3e465b;
  --phoenix-navbar-vertical-collapsed-menu-bg: #222834;
  --phoenix-navbar-vertical-collapsed-menu-color: #9fa6bc;
  --phoenix-logo-text: #eff2f6;
  --phoenix-navbar-footer-border-color: #31374a;
  --phoenix-navbar-top-bg-color: #141824;
  --phoenix-nav-link-color: #8a94ad;
  --phoenix-theme-control-toggle-bg: rgba(255, 204, 133, 0.18);
  --phoenix-theme-control-toggle-hover-bg: rgba(229, 120, 11, 0.7);
  --phoenix-theme-control-toggle-color: #ffcc85;
  --phoenix-theme-control-toggle-hover-color: #fff6e0;
}
:root:not(.dark) .navbar-darker .form-control,
:root.light:not(.dark) .navbar-darker .form-control,
:root .light:not(.dark) .navbar-darker .form-control {
  color: #6e7891;
}
:root:not(.dark) .navbar-darker .search-box,
:root.light:not(.dark) .navbar-darker .search-box,
:root .light:not(.dark) .navbar-darker .search-box {
  --phoenix-input-bg: #222834;
  --phoenix-input-border-color: #373e53;
}
:root:not(.dark) .navbar-darker .search-box.search-box-icon,
:root.light:not(.dark) .navbar-darker .search-box.search-box-icon,
:root .light:not(.dark) .navbar-darker .search-box.search-box-icon {
  color: #525b75;
}
:root:not(.dark) .navbar-darker.navbar-slim .navbar-brand span,
:root.light:not(.dark) .navbar-darker.navbar-slim .navbar-brand span,
:root .light:not(.dark) .navbar-darker.navbar-slim .navbar-brand span {
  color: #cbd0dd !important;
}

.dark, .dark .picmo__popupContainer.picmo__light {
  --phoenix-gray-soft: #0f111a;
  --phoenix-gray-100: #222834;
  --phoenix-gray-200: #31374a;
  --phoenix-gray-300: #373e53;
  --phoenix-gray-400: #3e465b;
  --phoenix-gray-500: #525b75;
  --phoenix-gray-600: #8a94ad;
  --phoenix-gray-700: #8a94ad;
  --phoenix-gray-800: #949db5;
  --phoenix-gray-900: #9fa6bc;
  --phoenix-gray-1000: #cbd0dd;
  --phoenix-gray-1100: #e3e6ed;
  --phoenix-gray-soft-rgb: 15, 17, 26;
  --phoenix-gray-100-rgb: 34, 40, 52;
  --phoenix-gray-200-rgb: 49, 55, 74;
  --phoenix-gray-300-rgb: 55, 62, 83;
  --phoenix-gray-400-rgb: 62, 70, 91;
  --phoenix-gray-500-rgb: 82, 91, 117;
  --phoenix-gray-600-rgb: 138, 148, 173;
  --phoenix-gray-700-rgb: 138, 148, 173;
  --phoenix-gray-800-rgb: 148, 157, 181;
  --phoenix-gray-900-rgb: 159, 166, 188;
  --phoenix-gray-1000-rgb: 203, 208, 221;
  --phoenix-gray-1100-rgb: 227, 230, 237;
  --phoenix-soft: #0f111a;
  --phoenix-100: #222834;
  --phoenix-200: #31374a;
  --phoenix-300: #373e53;
  --phoenix-400: #3e465b;
  --phoenix-500: #525b75;
  --phoenix-600: #8a94ad;
  --phoenix-700: #8a94ad;
  --phoenix-800: #949db5;
  --phoenix-900: #9fa6bc;
  --phoenix-1000: #cbd0dd;
  --phoenix-1100: #e3e6ed;
  --phoenix-primary: #85a9ff;
  --phoenix-secondary: #31374a;
  --phoenix-success: #90d67f;
  --phoenix-info: #60c6ff;
  --phoenix-warning: #ffcc85;
  --phoenix-danger: #f48270;
  --phoenix-light: rgba(49, 55, 74, 0.25);
  --phoenix-dark: #eff2f6;
  --phoenix-primary-soft: #f5f8ff;
  --phoenix-primary-100: #1d2332;
  --phoenix-primary-200: #adc5ff;
  --phoenix-primary-300: #85a9ff;
  --phoenix-primary-500: #3874ff;
  --phoenix-primary-600: #003cc7;
  --phoenix-warning-soft: #fff6e0;
  --phoenix-warning-100: #272528;
  --phoenix-warning-200: #ffe6ad;
  --phoenix-warning-300: #ffcc85;
  --phoenix-warning-500: #e5780b;
  --phoenix-warning-600: #bc3803;
  --phoenix-danger-300: #f48270;
  --phoenix-danger-200: #fabcb3;
  --phoenix-danger-100: #262026;
  --phoenix-danger-500: #cc1b00;
  --phoenix-danger-600: #b81800;
  --phoenix-success-100: #1e2628;
  --phoenix-success-200: #bee8b4;
  --phoenix-success-300: #90d67f;
  --phoenix-success-500: #25b003;
  --phoenix-success-600: #1c6c09;
  --phoenix-info-100: #1a2532;
  --phoenix-info-200: #96d9ff;
  --phoenix-info-300: #60c6ff;
  --phoenix-info-500: #0097eb;
  --phoenix-info-600: #005585;
  --phoenix-white: #141824;
  --phoenix-black: #eff2f6;
  --phoenix-soft-rgb: 15, 17, 26;
  --phoenix-100-rgb: 34, 40, 52;
  --phoenix-200-rgb: 49, 55, 74;
  --phoenix-300-rgb: 55, 62, 83;
  --phoenix-400-rgb: 62, 70, 91;
  --phoenix-500-rgb: 82, 91, 117;
  --phoenix-600-rgb: 138, 148, 173;
  --phoenix-700-rgb: 138, 148, 173;
  --phoenix-800-rgb: 148, 157, 181;
  --phoenix-900-rgb: 159, 166, 188;
  --phoenix-1000-rgb: 203, 208, 221;
  --phoenix-1100-rgb: 227, 230, 237;
  --phoenix-primary-rgb: 133, 169, 255;
  --phoenix-secondary-rgb: 49, 55, 74;
  --phoenix-success-rgb: 144, 214, 127;
  --phoenix-info-rgb: 96, 198, 255;
  --phoenix-warning-rgb: 255, 204, 133;
  --phoenix-danger-rgb: 244, 130, 112;
  --phoenix-light-rgb: 49, 55, 74;
  --phoenix-dark-rgb: 239, 242, 246;
  --phoenix-primary-soft-rgb: 245, 248, 255;
  --phoenix-primary-100-rgb: 29, 35, 50;
  --phoenix-primary-200-rgb: 173, 197, 255;
  --phoenix-primary-300-rgb: 133, 169, 255;
  --phoenix-primary-500-rgb: 56, 116, 255;
  --phoenix-primary-600-rgb: 0, 60, 199;
  --phoenix-warning-soft-rgb: 255, 246, 224;
  --phoenix-warning-100-rgb: 39, 37, 40;
  --phoenix-warning-200-rgb: 255, 230, 173;
  --phoenix-warning-300-rgb: 255, 204, 133;
  --phoenix-warning-500-rgb: 229, 120, 11;
  --phoenix-warning-600-rgb: 188, 56, 3;
  --phoenix-danger-300-rgb: 244, 130, 112;
  --phoenix-danger-200-rgb: 250, 188, 179;
  --phoenix-danger-100-rgb: 38, 32, 38;
  --phoenix-danger-500-rgb: 204, 27, 0;
  --phoenix-danger-600-rgb: 184, 24, 0;
  --phoenix-success-100-rgb: 30, 38, 40;
  --phoenix-success-200-rgb: 190, 232, 180;
  --phoenix-success-300-rgb: 144, 214, 127;
  --phoenix-success-500-rgb: 37, 176, 3;
  --phoenix-success-600-rgb: 28, 108, 9;
  --phoenix-info-100-rgb: 26, 37, 50;
  --phoenix-info-200-rgb: 150, 217, 255;
  --phoenix-info-300-rgb: 96, 198, 255;
  --phoenix-info-500-rgb: 0, 151, 235;
  --phoenix-info-600-rgb: 0, 85, 133;
  --phoenix-white-rgb: 20, 24, 36;
  --phoenix-black-rgb: 239, 242, 246;
  --phoenix-white: #141824;
  --phoenix-black: #eff2f6;
  --phoenix-white-rgb: 20, 24, 36;
  --phoenix-black-rgb: 239, 242, 246;
  --phoenix-body-color: #9fa6bc;
  --phoenix-body-bg: #0f111a;
  /* ---------------------------------- Card ---------------------------------- */
  /* --------------------------------- table --------------------------------- */
  /* ---------------------------------- input --------------------------------- */
  --phoenix-input-bg: #141824;
  --phoenix-input-border-color: #373e53;
  --phoenix-input-group-addon-bg: #31374a;
  /* ------------------------------- navbar-vertical ------------------------------- */
  --phoenix-navbar-vertical-border-color: #31374a;
  --phoenix-navbar-footer-border-color: #31374a;
  /* ------------------------------- navbar-top ------------------------------- */
  --phoenix-navbar-top-bg-color: #141824;
  /* ---------------------------------- links --------------------------------- */
  --phoenix-link-color: #85a9ff;
  --phoenix-link-hover-color: #3874ff;
  /* ------------------------------ Badge ----------------------------- */
  /* --------------------------------- Avatar --------------------------------- */
  --phoenix-avatar-status-border-color: #141824;
  --phoenix-avatar-name-color: #85a9ff;
  /* ------------------------------ phoenix badge ----------------------------- */
  /* --------------------------------- buttons -------------------------------- */
  /* ----------------------------- phoenix-buttons ---------------------------- */
  --phoenix-btn-active-bg: $primary;
  /* ----------------------------- outline buttons ---------------------------- */
  /* --------------------------------- list js -------------------------------- */
  --phoenix-list-sort-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMS4xMjkzIDQuOTkwMjNDMC42OTEwMTYgNC45OTAyMyAwLjQ3MTg3NSA1LjUwODIgMC43OTA2MjUgNS44MDcwM0wzLjE2MTMzIDguMTc3NzNDMy4zNDA2MiA4LjM3Njk1IDMuNjM5NDUgOC4zNzY5NSAzLjgzODY3IDguMTc3NzNMNi4yMDkzNyA1LjgwNzAzQzYuNTA4MiA1LjUwODIgNi4yODkwNiA0Ljk5MDIzIDUuODcwNyA0Ljk5MDIzSDEuMTI5M1pNNi4yMDkzNyAyLjg5ODQ0TDMuODM4NjcgMC41Mjc3MzVDMy42Mzk0NSAwLjM0ODQzOCAzLjM0MDYyIDAuMzQ4NDM4IDMuMTYxMzMgMC41Mjc3MzVMMC43OTA2MjUgMi44OTg0NEMwLjQ3MTg3NSAzLjIxNzE5IDAuNjkxMDE2IDMuNzE1MjMgMS4xMjkzIDMuNzE1MjNINS44NzA3QzYuMjg5MDYgMy43MTUyMyA2LjUwODIgMy4yMTcxOSA2LjIwOTM3IDIuODk4NDRaIiBmaWxsPSIjM0U0NjVCIi8+Cjwvc3ZnPgo=");
  /* --------------------------------- tinymce -------------------------------- */
  --phoenix-tinymce-tox-tbtn-enabled-icon-color: var(--phoenix-gray-1000);
  /* -------------------------------- choices -------------------------------- */
  --phoenix-choices-item-bg: rgba(133, 169, 255, 0.25);
  --phoenix-choices-item-color: var(--phoenix-gray-1000);
  --phoenix-choices-item-has-no-choices-bg: #141824;
  /* -------------------------------- dropdown -------------------------------- */
  /* ---------------------------------- modal --------------------------------- */
  /* -------------------------------- accordion ------------------------------- */
  /* ---------------------------------- docs ---------------------------------- */
  --phoenix-docs-language-bg: #222834;
  /* ------------------------------- form-select ------------------------------ */
  --phoenix-form-select-indicator: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUwIiBoZWlnaHQ9IjE1MCIgdmlld0JveD0iMCAwIDE1MCAxNTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03NS4zNDggMTI3LjE5MkM3Mi40MzgxIDEyNy4xOTIgNjkuODUxNCAxMjYuMjIyIDY3LjkxMTUgMTI0LjI4Mkw1LjgzMjE1IDYyLjIwMjNDMS42Mjg4NyA1OC4zMjIzIDEuNjI4ODcgNTEuNTMyNCA1LjgzMjE1IDQ3LjY1MjVDOS43MTIxMSA0My40NDkyIDE2LjUwMiA0My40NDkyIDIwLjM4MiA0Ny42NTI1TDc1LjM0OCAxMDIuMjk1TDEyOS45OTEgNDcuNjUyNUMxMzMuODcxIDQzLjQ0OTIgMTQwLjY2MSA0My40NDkyIDE0NC41NDEgNDcuNjUyNUMxNDguNzQ0IDUxLjUzMjQgMTQ4Ljc0NCA1OC4zMjIzIDE0NC41NDEgNjIuMjAyM0w4Mi40NjEzIDEyNC4yODJDODAuNTIxMyAxMjYuMjIyIDc3LjkzNDcgMTI3LjE5MiA3NS4zNDggMTI3LjE5MloiIGZpbGw9IiM5RkE2QkMiLz4KPC9zdmc+Cg==");
  /* --------------------------------- popover -------------------------------- */
  /* ---------------------------------- toast --------------------------------- */
  /* --------------------------------- wizard --------------------------------- */
  --phoenix-theme-wizard-nav-item-circle-bg: #222834;
  /* ---------------------------------- logo ---------------------------------- */
  --phoenix-logo-text: #eff2f6;
  /* ------------------------------- date-picker ------------------------------ */
  --phoenix-flatpickr-calendar-input-bg: var(--phoenix-input-bg);
  --phoenix-flatpickr-calendar-bg: #141824;
  --phoenix-flatpickr-current-month-color: #cbd0dd;
  --phoenix-flatpickr-weekday-color: #cbd0dd;
  --phoenix-flatpickr-calendar-day-hover-bg: #222834;
  --phoenix-flatpickr-weekend-days-color: #e5780b;
  --phoenix-flatpickr-today-color: #31374a;
  --phoenix-flatpickr-calendar-day-selected-bg: rgba(56, 116, 255, 0.2);
  /* -------------------------------- Fullcalendar ------------------------------- */
  --phoenix-calendar-bg: #141824;
  --phoenix-calendar-border-color: #0f111a;
  /* -------------------------------- offcanvas ------------------------------- */
  /* ------------------------------- list-group ------------------------------- */
  --phoenix-list-group-bg: #141824;
  /* -------------------------------- progress -------------------------------- */
  /* ------------------------------ dark classes ------------------------------ */
  /* --------------------------------- global scss --------------------------------- */
  /* ------------------------------- navbar-top  ------------------------------- */
  --phoenix-navbar-top-link-color: #9fa6bc;
  --phoenix-navbar-top-menu-bg-color: #141824;
  --phoenix-navbar-top-border-color: #31374a;
  --phoenix-navbar-top-link-hover-color: #cbd0dd;
  --phoenix-navbar-top-link-hover-bg: #222834;
  --phoenix-navbar-top-dropdown-hover-bg: #222834;
  --phoenix-navbar-top-dropdown-color: #8a94ad;
  /* ------------------------------- navbar-double top ------------------------------- */
  --phoenix-dual-nav-border-color: #31374a;
  --phoenix-dual-nav-first-layer-bg: #0f111a;
  /* ------------------------------- navbar-vertical inverted ------------------------------- */
  --phoenix-navbar-vertical-bg-color: #141824;
  --phoenix-navbar-vertical-label-color: #6e7891;
  --phoenix-navbar-vertical-link-color: #9fa6bc;
  --phoenix-navbar-vertical-link-hover-color: #eff2f6;
  --phoenix-navbar-vertical-link-hover-bg: #31374a;
  --phoenix-navbar-vertical-link-active-color: #fff;
  --phoenix-navbar-vertical-link-disable-color: #8a94ad;
  --phoenix-navbar-vertical-link-icon-color: #9fa6bc;
  --phoenix-hamburger-menu-color: #525b75;
  --phoenix-navbar-vertical-hr-color: #3e465b;
  --phoenix-navbar-vertical-collapsed-menu-bg: #222834;
  --phoenix-navbar-vertical-collapsed-menu-color: #9fa6bc;
  /* -------------------------- theme-control-toggle -------------------------- */
  --phoenix-theme-control-toggle-bg: rgba(56, 116, 255, 0.24);
  --phoenix-theme-control-toggle-hover-bg: rgba(56, 116, 255, 0.7);
  --phoenix-theme-control-toggle-color: #85a9ff;
  --phoenix-theme-control-toggle-hover-color: #f5f8ff;
  /* -------------------------------- Scrollbar ------------------------------- */
  --bs-scrollbar-bg: rgba(49, 55, 74, 0.6);
  /* -------------------------------- Kanban ------------------------------- */
  --phoenix-kanban-bg: #0f111a;
  --phoenix-kanban-column-bg: #141824;
  --phoenix-ghost-bg: #0f111a;
  --phoenix-drag-bg: #1C222C;
  --phoenix-kanban-column-hover-bg: #1C222C;
  --phoenix-leaflet-bar-bg: #373e53;
  --phoenix-leaflet-popup-content-wrapper-bg: #141824;
}
.dark .card, .dark .picmo__popupContainer.picmo__light .card {
  --phoenix-card-bg: var(--phoenix-white);
}
.dark .table, .dark .picmo__popupContainer.picmo__light .table {
  --phoenix-table-border-color: #31374a;
  --phoenix-table-active-bg: rgba(var(--phoenix-1100-rgb), 0.07);
  --phoenix-table-hover-color: #004dff;
}
.dark .badge:not(.light) {
  --phoenix-badge-color: var(--phoenix-white);
}
.dark .badge-phoenix-primary:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-primary-rgb), 0.08);
  --phoenix-badge-color: #85a9ff;
  --phoenix-badge-border-color: rgba(
    var(--phoenix-primary-rgb),
    0.2
  );
}
.dark .badge-phoenix-primary:not(.light)[href]:hover, .dark .badge-phoenix-primary:not(.light)[href]:focus {
  color: #003cc7;
}
.dark .badge-phoenix-success:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-success-rgb), 0.08);
  --phoenix-badge-color: #90d67f;
  --phoenix-badge-border-color: rgba(
    var(--phoenix-success-rgb),
    0.2
  );
}
.dark .badge-phoenix-success:not(.light)[href]:hover, .dark .badge-phoenix-success:not(.light)[href]:focus {
  color: #1c6c09;
}
.dark .badge-phoenix-info:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-info-rgb), 0.08);
  --phoenix-badge-color: #60c6ff;
  --phoenix-badge-border-color: rgba(
    var(--phoenix-info-rgb),
    0.2
  );
}
.dark .badge-phoenix-info:not(.light)[href]:hover, .dark .badge-phoenix-info:not(.light)[href]:focus {
  color: #005585;
}
.dark .badge-phoenix-warning:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-warning-rgb), 0.08);
  --phoenix-badge-color: #ffcc85;
  --phoenix-badge-border-color: rgba(
    var(--phoenix-warning-rgb),
    0.2
  );
}
.dark .badge-phoenix-warning:not(.light)[href]:hover, .dark .badge-phoenix-warning:not(.light)[href]:focus {
  color: #bc3803;
}
.dark .badge-phoenix-danger:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-danger-rgb), 0.08);
  --phoenix-badge-color: #f48270;
  --phoenix-badge-border-color: rgba(
    var(--phoenix-danger-rgb),
    0.2
  );
}
.dark .badge-phoenix-danger:not(.light)[href]:hover, .dark .badge-phoenix-danger:not(.light)[href]:focus {
  color: #b81800;
}
.dark .badge-phoenix-secondary:not(.light) {
  --phoenix-badge-bg: rgba(var(--phoenix-200-rgb), 0.08);
  --phoenix-badge-color: #cbd0dd;
  --phoenix-badge-border-color: rgba(var(--phoenix-1000-rgb), 0.2);
}
.dark .btn-reveal, .dark .picmo__popupContainer.picmo__light .btn-reveal {
  --phoenix-btn-color: #9fa6bc;
}
.dark .btn.active, .dark .google-map-control-btn .active.zoomIn, .google-map-control-btn .dark .active.zoomIn,
.dark .google-map-control-btn .active.zoomOut,
.google-map-control-btn .dark .active.zoomOut, .dark .tox .tox-dialog__footer .active.tox-button--secondary, .tox .tox-dialog__footer .dark .active.tox-button--secondary, .dark .tox .active.tox-button-secondary, .tox .dark .active.tox-button-secondary, .dark .tox .active.tox-button, .tox .dark .active.tox-button, .dark [data-list] .active.page, [data-list] .dark .active.page, .dark .table-list .active.page, .table-list .dark .active.page {
  --phoenix-btn-active-bg: #373e53;
  --phoenix-btn-active-color: red;
}
.dark .btn-phoenix-primary, .dark .picmo__popupContainer.picmo__light .btn-phoenix-primary {
  --phoenix-btn-color: #85a9ff;
  --phoenix-btn-hover-color: #85a9ff;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .btn-phoenix-secondary, .dark .picmo__popupContainer.picmo__light .btn-phoenix-secondary, .dark .google-map-control-btn .zoomIn, .google-map-control-btn .dark .zoomIn,
.dark .google-map-control-btn .zoomOut,
.google-map-control-btn .dark .zoomOut, .dark .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer .dark .tox-button--secondary, .dark .tox .tox-button-secondary, .tox .dark .tox-button-secondary {
  --phoenix-btn-color: #9fa6bc;
  --phoenix-btn-active-color: #9fa6bc;
  --phoenix-btn-hover-color: #cbd0dd;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .btn-phoenix-success, .dark .picmo__popupContainer.picmo__light .btn-phoenix-success {
  --phoenix-btn-color: #90d67f;
  --phoenix-btn-hover-color: #90d67f;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .btn-phoenix-info, .dark .picmo__popupContainer.picmo__light .btn-phoenix-info {
  --phoenix-btn-color: #60c6ff;
  --phoenix-btn-hover-color: #60c6ff;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .btn-phoenix-warning, .dark .picmo__popupContainer.picmo__light .btn-phoenix-warning {
  --phoenix-btn-color: #ffcc85;
  --phoenix-btn-hover-color: #ffcc85;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .btn-phoenix-danger, .dark .picmo__popupContainer.picmo__light .btn-phoenix-danger {
  --phoenix-btn-color: #f48270;
  --phoenix-btn-hover-color: #f48270;
  --phoenix-btn-bg: #0f111a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-active-bg: #222834;
  --phoenix-btn-hover-border-color: #31374a;
}
.dark .settings-panel .btn, .dark .settings-panel .google-map-control-btn .zoomIn, .google-map-control-btn .dark .settings-panel .zoomIn, .dark .google-map-control-btn .picmo__popupContainer.picmo__light .settings-panel .zoomIn,
.dark .settings-panel .google-map-control-btn .zoomOut,
.google-map-control-btn .dark .settings-panel .zoomOut,
.dark .google-map-control-btn .picmo__popupContainer.picmo__light .settings-panel .zoomOut, .dark .settings-panel .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer .dark .settings-panel .tox-button--secondary, .dark .tox .tox-dialog__footer .picmo__popupContainer.picmo__light .settings-panel .tox-button--secondary, .dark .settings-panel .tox .tox-button-secondary, .tox .dark .settings-panel .tox-button-secondary, .dark .tox .picmo__popupContainer.picmo__light .settings-panel .tox-button-secondary, .dark .settings-panel .tox .tox-button, .tox .dark .settings-panel .tox-button, .dark .tox .picmo__popupContainer.picmo__light .settings-panel .tox-button, .dark .settings-panel [data-list] .page, [data-list] .dark .settings-panel .page, .dark [data-list] .picmo__popupContainer.picmo__light .settings-panel .page, .dark .settings-panel .table-list .page, .table-list .dark .settings-panel .page, .dark .table-list .picmo__popupContainer.picmo__light .settings-panel .page {
  --phoenix-btn-color: #8a94ad;
}
.dark .btn-soft-primary, .dark .picmo__popupContainer.picmo__light .btn-soft-primary {
  --phoenix-btn-color: #85a9ff;
  --phoenix-btn-bg: rgba(56, 116, 255, 0.14);
  --phoenix-btn-border-color: rgba(56, 116, 255, 0.14);
  --phoenix-btn-hover-color: #85a9ff;
  --phoenix-btn-hover-bg: rgba(56, 116, 255, 0.2);
  --phoenix-btn-hover-border-color: rgba(175, 199, 255, 0.226);
  --phoenix-btn-focus-shadow-rgb: 110, 153, 255;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: rgba(209, 223, 255, 0.312);
  --phoenix-btn-active-border-color: rgba(175, 199, 255, 0.226);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(56, 116, 255, 0.14);
  --phoenix-btn-disabled-border-color: rgba(56, 116, 255, 0.14);
}
.dark .btn-soft-secondary, .dark .picmo__popupContainer.picmo__light .btn-soft-secondary {
  --phoenix-btn-color: #cbd0dd;
  --phoenix-btn-bg: rgba(49, 55, 74, 0.5);
  --phoenix-btn-border-color: rgba(49, 55, 74, 0.5);
  --phoenix-btn-hover-color: #cbd0dd;
  --phoenix-btn-hover-bg: rgba(49, 55, 74, 0.8);
  --phoenix-btn-hover-border-color: rgba(101, 105, 119, 0.55);
  --phoenix-btn-focus-shadow-rgb: 102, 108, 125;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: rgba(137, 141, 152, 0.6);
  --phoenix-btn-active-border-color: rgba(101, 105, 119, 0.55);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(49, 55, 74, 0.5);
  --phoenix-btn-disabled-border-color: rgba(49, 55, 74, 0.5);
}
.dark .btn-soft-success, .dark .picmo__popupContainer.picmo__light .btn-soft-success {
  --phoenix-btn-color: #90d67f;
  --phoenix-btn-bg: rgba(37, 176, 3, 0.14);
  --phoenix-btn-border-color: rgba(37, 176, 3, 0.14);
  --phoenix-btn-hover-color: #90d67f;
  --phoenix-btn-hover-bg: rgba(37, 176, 3, 0.2);
  --phoenix-btn-hover-border-color: rgba(167, 223, 153, 0.226);
  --phoenix-btn-focus-shadow-rgb: 112, 203, 90;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: rgba(205, 237, 197, 0.312);
  --phoenix-btn-active-border-color: rgba(167, 223, 153, 0.226);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(37, 176, 3, 0.14);
  --phoenix-btn-disabled-border-color: rgba(37, 176, 3, 0.14);
}
.dark .btn-soft-info, .dark .picmo__popupContainer.picmo__light .btn-soft-info {
  --phoenix-btn-color: #60c6ff;
  --phoenix-btn-bg: rgba(0, 151, 235, 0.14);
  --phoenix-btn-border-color: rgba(0, 151, 235, 0.14);
  --phoenix-btn-hover-color: #60c6ff;
  --phoenix-btn-hover-bg: rgba(0, 151, 235, 0.2);
  --phoenix-btn-hover-border-color: rgba(152, 213, 247, 0.226);
  --phoenix-btn-focus-shadow-rgb: 67, 184, 249;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: rgba(196, 231, 250, 0.312);
  --phoenix-btn-active-border-color: rgba(152, 213, 247, 0.226);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(0, 151, 235, 0.14);
  --phoenix-btn-disabled-border-color: rgba(0, 151, 235, 0.14);
}
.dark .btn-soft-warning, .dark .picmo__popupContainer.picmo__light .btn-soft-warning {
  --phoenix-btn-color: #ffcc85;
  --phoenix-btn-bg: rgba(229, 120, 11, 0.14);
  --phoenix-btn-border-color: rgba(229, 120, 11, 0.14);
  --phoenix-btn-hover-color: #ffcc85;
  --phoenix-btn-hover-bg: rgba(229, 120, 11, 0.2);
  --phoenix-btn-hover-border-color: rgba(245, 200, 156, 0.226);
  --phoenix-btn-focus-shadow-rgb: 247, 179, 97;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: rgba(249, 224, 199, 0.312);
  --phoenix-btn-active-border-color: rgba(245, 200, 156, 0.226);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(229, 120, 11, 0.14);
  --phoenix-btn-disabled-border-color: rgba(229, 120, 11, 0.14);
}
.dark .btn-soft-danger, .dark .picmo__popupContainer.picmo__light .btn-soft-danger {
  --phoenix-btn-color: #f48270;
  --phoenix-btn-bg: rgba(237, 32, 0, 0.14);
  --phoenix-btn-border-color: rgba(237, 32, 0, 0.14);
  --phoenix-btn-hover-color: #f48270;
  --phoenix-btn-hover-bg: rgba(237, 32, 0, 0.2);
  --phoenix-btn-hover-border-color: rgba(248, 165, 152, 0.226);
  --phoenix-btn-focus-shadow-rgb: 242, 101, 79;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: rgba(251, 203, 196, 0.312);
  --phoenix-btn-active-border-color: rgba(248, 165, 152, 0.226);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: rgba(237, 32, 0, 0.14);
  --phoenix-btn-disabled-border-color: rgba(237, 32, 0, 0.14);
}
.dark .btn-outline-primary, .dark .picmo__popupContainer.picmo__light .btn-outline-primary {
  --phoenix-btn-color: #85a9ff;
  --phoenix-btn-border-color: #85a9ff;
}
.dark .btn-outline-secondary, .dark .picmo__popupContainer.picmo__light .btn-outline-secondary {
  --phoenix-btn-color: #cbd0dd;
  --phoenix-btn-border-color: #cbd0dd;
}
.dark .btn-outline-success, .dark .picmo__popupContainer.picmo__light .btn-outline-success {
  --phoenix-btn-color: #90d67f;
  --phoenix-btn-border-color: #90d67f;
}
.dark .btn-outline-info, .dark .picmo__popupContainer.picmo__light .btn-outline-info {
  --phoenix-btn-color: #60c6ff;
  --phoenix-btn-border-color: #60c6ff;
}
.dark .btn-outline-warning, .dark .picmo__popupContainer.picmo__light .btn-outline-warning {
  --phoenix-btn-color: #ffcc85;
  --phoenix-btn-border-color: #ffcc85;
}
.dark .btn-outline-danger, .dark .picmo__popupContainer.picmo__light .btn-outline-danger {
  --phoenix-btn-color: #f48270;
  --phoenix-btn-border-color: #f48270;
}
.dark .dropdown-menu, .dark .picmo__popupContainer.picmo__light .dropdown-menu {
  --phoenix-dropdown-bg: #141824;
  --phoenix-dropdown-border-color: #373e53;
  --phoenix-dropdown-divider-bg: #373e53;
  --phoenix-dropdown-link-hover-bg: #222834;
  --phoenix-dropdown-link-color: #cbd0dd;
  --phoenix-dropdown-link-hover-color: #eff2f6;
}
.dark .modal-content, .dark .picmo__popupContainer.picmo__light .modal-content {
  --phoenix-modal-bg: #141824;
}
.dark .accordion, .dark .picmo__popupContainer.picmo__light .accordion {
  --phoenix-accordion-color: #cbd0dd;
}
.dark .accordion-button, .dark .picmo__popupContainer.picmo__light .accordion-button {
  --phoenix-accordion-active-color: #e3e6ed;
}
.dark .popover, .dark .picmo__popupContainer.picmo__light .popover {
  --phoenix-popover-bg: #141824;
  --phoenix-popover-border-color: #373e53;
  --phoenix-popover-body-color: #cbd0dd;
  --phoenix-popover-header-bg: #0f111a;
}
.dark .toast, .dark .picmo__popupContainer.picmo__light .toast {
  --phoenix-toast-bg: rgba(var(--phoenix-white-rgb), 0.85);
  --phoenix-toast-header-color: #949db5;
  --phoenix-toast-header-border-color: #525b75;
  --phoenix-toast-border-color: rgba(var(--phoenix-black-rgb), 0.175);
}
.dark .offcanvas, .dark .picmo__popupContainer.picmo__light .offcanvas {
  --phoenix-offcanvas-bg: #141824;
}
.dark .progress, .dark .picmo__popupContainer.picmo__light .progress {
  --phoenix-progress-bg: #373e53;
}
.dark .dark__border-primary, .dark .picmo__popupContainer.picmo__light .dark__border-primary {
  --phoenix-border-color: #3874ff !important;
}
.dark .navbar-darker, .dark .picmo__popupContainer.picmo__light .navbar-darker {
  --phoenix-navbar-top-dropdown-hover-bg: #31374a;
}
.dark .navbar-darker, .dark .picmo__popupContainer.picmo__light .navbar-darker {
  --phoenix-navbar-vertical-bg-color: #222834;
  --phoenix-navbar-vertical-label-color: #6e7891;
  --phoenix-navbar-vertical-link-color: #9fa6bc;
  --phoenix-navbar-vertical-link-hover-color: #eff2f6;
  --phoenix-navbar-vertical-link-hover-bg: #31374a;
  --phoenix-navbar-vertical-link-active-color: #fff;
  --phoenix-navbar-vertical-link-disable-color: #8a94ad;
  --phoenix-navbar-vertical-link-icon-color: #9fa6bc;
  --phoenix-navbar-vertical-collapsed-menu-bg: #222834;
  --phoenix-navbar-vertical-collapsed-menu-color: #9fa6bc;
  --phoenix-navbar-top-bg-color: #222834;
  --phoenix-nav-link-color: #8a94ad;
  --phoenix-input-bg: #222834;
  --phoenix-input-border-color: #373e53;
}
.dark .navbar-darker .form-control {
  color: #6e7891;
}
.dark .navbar-darker .search-box .search-box-icon {
  color: #525b75;
}

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--phoenix-body-font-family);
  font-size: var(--phoenix-body-font-size);
  font-weight: var(--phoenix-body-font-weight);
  line-height: var(--phoenix-body-line-height);
  color: var(--phoenix-body-color);
  text-align: var(--phoenix-body-text-align);
  background-color: var(--phoenix-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: var(--phoenix-gray-200);
  border: 0;
  border-top: 1px solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  line-height: 1;
  color: var(--phoenix-1100);
}

h1, .h1 {
  font-size: calc(1.369140625rem + 1.4296875vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.44140625rem;
  }
}

h2, .h2 {
  font-size: calc(1.3203125rem + 0.84375vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.953125rem;
  }
}

h3, .h3 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5625rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.8rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small, .small {
  font-size: 75%;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--phoenix-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--phoenix-link-color);
  text-decoration: none;
}
a:hover {
  color: var(--phoenix-link-hover-color);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--phoenix-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 75%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 75%;
  color: var(--phoenix-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 75%;
  color: var(--phoenix-body-bg);
  background-color: var(--phoenix-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #8a94ad;
  text-align: left;
}

th {
  font-weight: 800;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 600;
}

.display-1 {
  font-size: calc(1.6018371582rem + 4.2220458984vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 4.768371582rem;
  }
}

.display-2 {
  font-size: calc(1.5064697266rem + 3.0776367188vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 3.8146972656rem;
  }
}

.display-3 {
  font-size: calc(1.4301757813rem + 2.162109375vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 3.0517578125rem;
  }
}

.display-4 {
  font-size: calc(1.369140625rem + 1.4296875vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 2.44140625rem;
  }
}

.display-5 {
  font-size: calc(1.3203125rem + 0.84375vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 1.953125rem;
  }
}

.display-6 {
  font-size: calc(1.28125rem + 0.375vw);
  font-weight: 400;
  line-height: 1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 1.5625rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 75%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 75%;
  color: #6e7891;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--phoenix-thumbnail-bg);
  border: 3px solid var(--phoenix-thumbnail-bg);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 75%;
  color: #6e7891;
}

.container,
.container-fluid,
.container-small-xl,
.container-small-lg,
.container-small-md,
.container-small-sm,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --phoenix-gutter-x: 2rem;
  --phoenix-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--phoenix-gutter-x) * 0.5);
  padding-left: calc(var(--phoenix-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1184px;
  }
}
@media (min-width: 1540px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1678px;
  }
}
.row {
  --phoenix-gutter-x: 2rem;
  --phoenix-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--phoenix-gutter-y));
  margin-right: calc(-0.5 * var(--phoenix-gutter-x));
  margin-left: calc(-0.5 * var(--phoenix-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--phoenix-gutter-x) * 0.5);
  padding-left: calc(var(--phoenix-gutter-x) * 0.5);
  margin-top: var(--phoenix-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --phoenix-gutter-x: 0;
}

.g-0,
.gy-0 {
  --phoenix-gutter-y: 0;
}

.g-1,
.gx-1 {
  --phoenix-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --phoenix-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --phoenix-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --phoenix-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --phoenix-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --phoenix-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --phoenix-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --phoenix-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --phoenix-gutter-x: 2rem;
}

.g-5,
.gy-5 {
  --phoenix-gutter-y: 2rem;
}

.g-6,
.gx-6 {
  --phoenix-gutter-x: 2.5rem;
}

.g-6,
.gy-6 {
  --phoenix-gutter-y: 2.5rem;
}

.g-7,
.gx-7 {
  --phoenix-gutter-x: 3rem;
}

.g-7,
.gy-7 {
  --phoenix-gutter-y: 3rem;
}

.g-8,
.gx-8 {
  --phoenix-gutter-x: 3.5rem;
}

.g-8,
.gy-8 {
  --phoenix-gutter-y: 3.5rem;
}

.g-9,
.gx-9 {
  --phoenix-gutter-x: 4rem;
}

.g-9,
.gy-9 {
  --phoenix-gutter-y: 4rem;
}

.g-10,
.gx-10 {
  --phoenix-gutter-x: 4.5rem;
}

.g-10,
.gy-10 {
  --phoenix-gutter-y: 4.5rem;
}

.g-11,
.gx-11 {
  --phoenix-gutter-x: 5rem;
}

.g-11,
.gy-11 {
  --phoenix-gutter-y: 5rem;
}

.g-12,
.gx-12 {
  --phoenix-gutter-x: 6rem;
}

.g-12,
.gy-12 {
  --phoenix-gutter-y: 6rem;
}

.g-13,
.gx-13 {
  --phoenix-gutter-x: 6.5rem;
}

.g-13,
.gy-13 {
  --phoenix-gutter-y: 6.5rem;
}

.g-14,
.gx-14 {
  --phoenix-gutter-x: 7rem;
}

.g-14,
.gy-14 {
  --phoenix-gutter-y: 7rem;
}

.g-15,
.gx-15 {
  --phoenix-gutter-x: 7.5rem;
}

.g-15,
.gy-15 {
  --phoenix-gutter-y: 7.5rem;
}

.g-card,
.gx-card {
  --phoenix-gutter-x: 3rem;
}

.g-card,
.gy-card {
  --phoenix-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --phoenix-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --phoenix-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --phoenix-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --phoenix-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --phoenix-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --phoenix-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --phoenix-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --phoenix-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --phoenix-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --phoenix-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --phoenix-gutter-x: 2rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --phoenix-gutter-y: 2rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --phoenix-gutter-x: 2.5rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --phoenix-gutter-y: 2.5rem;
  }
  .g-sm-7,
.gx-sm-7 {
    --phoenix-gutter-x: 3rem;
  }
  .g-sm-7,
.gy-sm-7 {
    --phoenix-gutter-y: 3rem;
  }
  .g-sm-8,
.gx-sm-8 {
    --phoenix-gutter-x: 3.5rem;
  }
  .g-sm-8,
.gy-sm-8 {
    --phoenix-gutter-y: 3.5rem;
  }
  .g-sm-9,
.gx-sm-9 {
    --phoenix-gutter-x: 4rem;
  }
  .g-sm-9,
.gy-sm-9 {
    --phoenix-gutter-y: 4rem;
  }
  .g-sm-10,
.gx-sm-10 {
    --phoenix-gutter-x: 4.5rem;
  }
  .g-sm-10,
.gy-sm-10 {
    --phoenix-gutter-y: 4.5rem;
  }
  .g-sm-11,
.gx-sm-11 {
    --phoenix-gutter-x: 5rem;
  }
  .g-sm-11,
.gy-sm-11 {
    --phoenix-gutter-y: 5rem;
  }
  .g-sm-12,
.gx-sm-12 {
    --phoenix-gutter-x: 6rem;
  }
  .g-sm-12,
.gy-sm-12 {
    --phoenix-gutter-y: 6rem;
  }
  .g-sm-13,
.gx-sm-13 {
    --phoenix-gutter-x: 6.5rem;
  }
  .g-sm-13,
.gy-sm-13 {
    --phoenix-gutter-y: 6.5rem;
  }
  .g-sm-14,
.gx-sm-14 {
    --phoenix-gutter-x: 7rem;
  }
  .g-sm-14,
.gy-sm-14 {
    --phoenix-gutter-y: 7rem;
  }
  .g-sm-15,
.gx-sm-15 {
    --phoenix-gutter-x: 7.5rem;
  }
  .g-sm-15,
.gy-sm-15 {
    --phoenix-gutter-y: 7.5rem;
  }
  .g-sm-card,
.gx-sm-card {
    --phoenix-gutter-x: 3rem;
  }
  .g-sm-card,
.gy-sm-card {
    --phoenix-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --phoenix-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --phoenix-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --phoenix-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --phoenix-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --phoenix-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --phoenix-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --phoenix-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --phoenix-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --phoenix-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --phoenix-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --phoenix-gutter-x: 2rem;
  }
  .g-md-5,
.gy-md-5 {
    --phoenix-gutter-y: 2rem;
  }
  .g-md-6,
.gx-md-6 {
    --phoenix-gutter-x: 2.5rem;
  }
  .g-md-6,
.gy-md-6 {
    --phoenix-gutter-y: 2.5rem;
  }
  .g-md-7,
.gx-md-7 {
    --phoenix-gutter-x: 3rem;
  }
  .g-md-7,
.gy-md-7 {
    --phoenix-gutter-y: 3rem;
  }
  .g-md-8,
.gx-md-8 {
    --phoenix-gutter-x: 3.5rem;
  }
  .g-md-8,
.gy-md-8 {
    --phoenix-gutter-y: 3.5rem;
  }
  .g-md-9,
.gx-md-9 {
    --phoenix-gutter-x: 4rem;
  }
  .g-md-9,
.gy-md-9 {
    --phoenix-gutter-y: 4rem;
  }
  .g-md-10,
.gx-md-10 {
    --phoenix-gutter-x: 4.5rem;
  }
  .g-md-10,
.gy-md-10 {
    --phoenix-gutter-y: 4.5rem;
  }
  .g-md-11,
.gx-md-11 {
    --phoenix-gutter-x: 5rem;
  }
  .g-md-11,
.gy-md-11 {
    --phoenix-gutter-y: 5rem;
  }
  .g-md-12,
.gx-md-12 {
    --phoenix-gutter-x: 6rem;
  }
  .g-md-12,
.gy-md-12 {
    --phoenix-gutter-y: 6rem;
  }
  .g-md-13,
.gx-md-13 {
    --phoenix-gutter-x: 6.5rem;
  }
  .g-md-13,
.gy-md-13 {
    --phoenix-gutter-y: 6.5rem;
  }
  .g-md-14,
.gx-md-14 {
    --phoenix-gutter-x: 7rem;
  }
  .g-md-14,
.gy-md-14 {
    --phoenix-gutter-y: 7rem;
  }
  .g-md-15,
.gx-md-15 {
    --phoenix-gutter-x: 7.5rem;
  }
  .g-md-15,
.gy-md-15 {
    --phoenix-gutter-y: 7.5rem;
  }
  .g-md-card,
.gx-md-card {
    --phoenix-gutter-x: 3rem;
  }
  .g-md-card,
.gy-md-card {
    --phoenix-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --phoenix-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --phoenix-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --phoenix-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --phoenix-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --phoenix-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --phoenix-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --phoenix-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --phoenix-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --phoenix-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --phoenix-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --phoenix-gutter-x: 2rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --phoenix-gutter-y: 2rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --phoenix-gutter-x: 2.5rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --phoenix-gutter-y: 2.5rem;
  }
  .g-lg-7,
.gx-lg-7 {
    --phoenix-gutter-x: 3rem;
  }
  .g-lg-7,
.gy-lg-7 {
    --phoenix-gutter-y: 3rem;
  }
  .g-lg-8,
.gx-lg-8 {
    --phoenix-gutter-x: 3.5rem;
  }
  .g-lg-8,
.gy-lg-8 {
    --phoenix-gutter-y: 3.5rem;
  }
  .g-lg-9,
.gx-lg-9 {
    --phoenix-gutter-x: 4rem;
  }
  .g-lg-9,
.gy-lg-9 {
    --phoenix-gutter-y: 4rem;
  }
  .g-lg-10,
.gx-lg-10 {
    --phoenix-gutter-x: 4.5rem;
  }
  .g-lg-10,
.gy-lg-10 {
    --phoenix-gutter-y: 4.5rem;
  }
  .g-lg-11,
.gx-lg-11 {
    --phoenix-gutter-x: 5rem;
  }
  .g-lg-11,
.gy-lg-11 {
    --phoenix-gutter-y: 5rem;
  }
  .g-lg-12,
.gx-lg-12 {
    --phoenix-gutter-x: 6rem;
  }
  .g-lg-12,
.gy-lg-12 {
    --phoenix-gutter-y: 6rem;
  }
  .g-lg-13,
.gx-lg-13 {
    --phoenix-gutter-x: 6.5rem;
  }
  .g-lg-13,
.gy-lg-13 {
    --phoenix-gutter-y: 6.5rem;
  }
  .g-lg-14,
.gx-lg-14 {
    --phoenix-gutter-x: 7rem;
  }
  .g-lg-14,
.gy-lg-14 {
    --phoenix-gutter-y: 7rem;
  }
  .g-lg-15,
.gx-lg-15 {
    --phoenix-gutter-x: 7.5rem;
  }
  .g-lg-15,
.gy-lg-15 {
    --phoenix-gutter-y: 7.5rem;
  }
  .g-lg-card,
.gx-lg-card {
    --phoenix-gutter-x: 3rem;
  }
  .g-lg-card,
.gy-lg-card {
    --phoenix-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --phoenix-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --phoenix-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --phoenix-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --phoenix-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --phoenix-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --phoenix-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --phoenix-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --phoenix-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --phoenix-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --phoenix-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --phoenix-gutter-x: 2rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --phoenix-gutter-y: 2rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --phoenix-gutter-x: 2.5rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --phoenix-gutter-y: 2.5rem;
  }
  .g-xl-7,
.gx-xl-7 {
    --phoenix-gutter-x: 3rem;
  }
  .g-xl-7,
.gy-xl-7 {
    --phoenix-gutter-y: 3rem;
  }
  .g-xl-8,
.gx-xl-8 {
    --phoenix-gutter-x: 3.5rem;
  }
  .g-xl-8,
.gy-xl-8 {
    --phoenix-gutter-y: 3.5rem;
  }
  .g-xl-9,
.gx-xl-9 {
    --phoenix-gutter-x: 4rem;
  }
  .g-xl-9,
.gy-xl-9 {
    --phoenix-gutter-y: 4rem;
  }
  .g-xl-10,
.gx-xl-10 {
    --phoenix-gutter-x: 4.5rem;
  }
  .g-xl-10,
.gy-xl-10 {
    --phoenix-gutter-y: 4.5rem;
  }
  .g-xl-11,
.gx-xl-11 {
    --phoenix-gutter-x: 5rem;
  }
  .g-xl-11,
.gy-xl-11 {
    --phoenix-gutter-y: 5rem;
  }
  .g-xl-12,
.gx-xl-12 {
    --phoenix-gutter-x: 6rem;
  }
  .g-xl-12,
.gy-xl-12 {
    --phoenix-gutter-y: 6rem;
  }
  .g-xl-13,
.gx-xl-13 {
    --phoenix-gutter-x: 6.5rem;
  }
  .g-xl-13,
.gy-xl-13 {
    --phoenix-gutter-y: 6.5rem;
  }
  .g-xl-14,
.gx-xl-14 {
    --phoenix-gutter-x: 7rem;
  }
  .g-xl-14,
.gy-xl-14 {
    --phoenix-gutter-y: 7rem;
  }
  .g-xl-15,
.gx-xl-15 {
    --phoenix-gutter-x: 7.5rem;
  }
  .g-xl-15,
.gy-xl-15 {
    --phoenix-gutter-y: 7.5rem;
  }
  .g-xl-card,
.gx-xl-card {
    --phoenix-gutter-x: 3rem;
  }
  .g-xl-card,
.gy-xl-card {
    --phoenix-gutter-y: 3rem;
  }
}
@media (min-width: 1540px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --phoenix-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --phoenix-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --phoenix-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --phoenix-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --phoenix-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --phoenix-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --phoenix-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --phoenix-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --phoenix-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --phoenix-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --phoenix-gutter-x: 2rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --phoenix-gutter-y: 2rem;
  }
  .g-xxl-6,
.gx-xxl-6 {
    --phoenix-gutter-x: 2.5rem;
  }
  .g-xxl-6,
.gy-xxl-6 {
    --phoenix-gutter-y: 2.5rem;
  }
  .g-xxl-7,
.gx-xxl-7 {
    --phoenix-gutter-x: 3rem;
  }
  .g-xxl-7,
.gy-xxl-7 {
    --phoenix-gutter-y: 3rem;
  }
  .g-xxl-8,
.gx-xxl-8 {
    --phoenix-gutter-x: 3.5rem;
  }
  .g-xxl-8,
.gy-xxl-8 {
    --phoenix-gutter-y: 3.5rem;
  }
  .g-xxl-9,
.gx-xxl-9 {
    --phoenix-gutter-x: 4rem;
  }
  .g-xxl-9,
.gy-xxl-9 {
    --phoenix-gutter-y: 4rem;
  }
  .g-xxl-10,
.gx-xxl-10 {
    --phoenix-gutter-x: 4.5rem;
  }
  .g-xxl-10,
.gy-xxl-10 {
    --phoenix-gutter-y: 4.5rem;
  }
  .g-xxl-11,
.gx-xxl-11 {
    --phoenix-gutter-x: 5rem;
  }
  .g-xxl-11,
.gy-xxl-11 {
    --phoenix-gutter-y: 5rem;
  }
  .g-xxl-12,
.gx-xxl-12 {
    --phoenix-gutter-x: 6rem;
  }
  .g-xxl-12,
.gy-xxl-12 {
    --phoenix-gutter-y: 6rem;
  }
  .g-xxl-13,
.gx-xxl-13 {
    --phoenix-gutter-x: 6.5rem;
  }
  .g-xxl-13,
.gy-xxl-13 {
    --phoenix-gutter-y: 6.5rem;
  }
  .g-xxl-14,
.gx-xxl-14 {
    --phoenix-gutter-x: 7rem;
  }
  .g-xxl-14,
.gy-xxl-14 {
    --phoenix-gutter-y: 7rem;
  }
  .g-xxl-15,
.gx-xxl-15 {
    --phoenix-gutter-x: 7.5rem;
  }
  .g-xxl-15,
.gy-xxl-15 {
    --phoenix-gutter-y: 7.5rem;
  }
  .g-xxl-card,
.gx-xxl-card {
    --phoenix-gutter-x: 3rem;
  }
  .g-xxl-card,
.gy-xxl-card {
    --phoenix-gutter-y: 3rem;
  }
}
.table {
  --phoenix-table-color: var(--phoenix-gray-900);
  --phoenix-table-bg: transparent;
  --phoenix-table-border-color: #e3e6ed;
  --phoenix-table-accent-bg: transparent;
  --phoenix-table-striped-color: var(--phoenix-gray-1000);
  --phoenix-table-striped-bg: rgba(var(--phoenix-1100-rgb), 0.07);
  --phoenix-table-active-color: var(--phoenix-gray-900);
  --phoenix-table-active-bg: rgba(0, 0, 0, 0.1);
  --phoenix-table-hover-color: var(--phoenix-black);
  --phoenix-table-hover-bg: rgba(var(--phoenix-primary-600-rgb), 0.07);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--phoenix-table-color);
  vertical-align: top;
  border-color: var(--phoenix-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 1rem 0.5rem;
  background-color: var(--phoenix-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--phoenix-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid inherit;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --phoenix-table-accent-bg: var(--phoenix-table-striped-bg);
  color: var(--phoenix-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --phoenix-table-accent-bg: var(--phoenix-table-striped-bg);
  color: var(--phoenix-table-striped-color);
}

.table-active {
  --phoenix-table-accent-bg: var(--phoenix-table-active-bg);
  color: var(--phoenix-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --phoenix-table-accent-bg: var(--phoenix-table-hover-bg);
  color: var(--phoenix-table-hover-color);
}

.table-primary {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #d7e3ff;
  --phoenix-table-border-color: #c2cce6;
  --phoenix-table-striped-bg: #ccd8f2;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #c2cce6;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #c7d2ec;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-secondary {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #d6d7db;
  --phoenix-table-border-color: #c1c2c5;
  --phoenix-table-striped-bg: #cbccd0;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #c1c2c5;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #c6c7cb;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-success {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #d3efcd;
  --phoenix-table-border-color: #bed7b9;
  --phoenix-table-striped-bg: #c8e3c3;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #bed7b9;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #c3ddbe;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-info {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #cceafb;
  --phoenix-table-border-color: #b8d3e2;
  --phoenix-table-striped-bg: #c2deee;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #b8d3e2;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #bdd8e8;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-warning {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #fae4ce;
  --phoenix-table-border-color: #e1cdb9;
  --phoenix-table-striped-bg: #eed9c4;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #e1cdb9;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #e7d3bf;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-danger {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #fbd2cc;
  --phoenix-table-border-color: #e2bdb8;
  --phoenix-table-striped-bg: #eec8c2;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #e2bdb8;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #e8c2bd;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-light {
  --phoenix-table-color: #000000;
  --phoenix-table-bg: #eff2f6;
  --phoenix-table-border-color: #d7dadd;
  --phoenix-table-striped-bg: #e3e6ea;
  --phoenix-table-striped-color: #000000;
  --phoenix-table-active-bg: #d7dadd;
  --phoenix-table-active-color: #000000;
  --phoenix-table-hover-bg: #dde0e4;
  --phoenix-table-hover-color: #000000;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-dark {
  --phoenix-table-color: #fff;
  --phoenix-table-bg: #141824;
  --phoenix-table-border-color: #2c2f3a;
  --phoenix-table-striped-bg: #20242f;
  --phoenix-table-striped-color: #fff;
  --phoenix-table-active-bg: #2c2f3a;
  --phoenix-table-active-color: #fff;
  --phoenix-table-hover-bg: #262934;
  --phoenix-table-hover-color: #fff;
  color: var(--phoenix-table-color);
  border-color: var(--phoenix-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1539.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.64rem;
  font-weight: 700;
  color: var(--phoenix-gray-700);
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.49;
  color: var(--phoenix-gray-700);
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  font-size: 0.8rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 75%;
  color: #8a94ad;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.49;
  color: var(--phoenix-gray-900);
  background-color: var(--phoenix-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--phoenix-input-border-color);
  appearance: none;
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 2px transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--phoenix-gray-900);
  background-color: var(--phoenix-input-bg);
  border-color: #3874ff;
  outline: 0;
  box-shadow: inset 0 1px 2px transparent, 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.49em;
}
.form-control::placeholder {
  color: #9fa6bc;
  opacity: 1;
}
.form-control:disabled {
  color: var(--phoenix-gray-500);
  background-color: rgba(var(--phoenix-gray-100-rgb), 0.7);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  color: var(--phoenix-gray-900);
  background-color: #eff2f6;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e3e6ea;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.49;
  color: #31374a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.49em + 0.75rem + 2px);
  padding: 0.375rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.375rem;
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 1rem;
  margin: -0.375rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg {
  min-height: calc(1.49em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.49em + 1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.49em + 0.75rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.49em + 1.5rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.49em + 1rem + 2px);
  padding: 0.5rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.49em + 0.75rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.49em + 1.5rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.49;
  color: var(--phoenix-900);
  background-color: var(--phoenix-input-bg);
  background-image: var(--phoenix-form-select-indicator);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 9px 12px;
  border: 1px solid var(--phoenix-input-border-color);
  border-radius: 0.375rem;
  box-shadow: inset 0 1px 2px transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #3874ff;
  outline: 0;
  box-shadow: inset 0 1px 2px transparent, 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--phoenix-200);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--phoenix-900);
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  font-size: 0.8rem;
  border-radius: 0.375rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}

.form-check {
  display: block;
  min-height: 1.49rem;
  padding-left: 1.5em;
  margin-bottom: 0.34375rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.245em;
  vertical-align: top;
  background-color: var(--phoenix-input-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--phoenix-400);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #3874ff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
}
.form-check-input:checked {
  background-color: #3874ff;
  border-color: #3874ff;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #3874ff;
  border-color: #3874ff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-check-label {
  color: var(--phoenix-gray-700);
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .google-map-control-btn .btn-check[disabled] + .zoomIn,
.google-map-control-btn .btn-check[disabled] + .zoomOut, .tox .tox-dialog__footer .btn-check[disabled] + .tox-button:last-child, .tox .tox-dialog__footer .btn-check[disabled] + .tox-button--secondary, .tox .btn-check[disabled] + .tox-button-secondary, .tox .btn-check[disabled] + .tox-button, [data-list] .btn-check[disabled] + .page, .table-list .btn-check[disabled] + .page, .btn-check:disabled + .btn, .google-map-control-btn .btn-check:disabled + .zoomIn,
.google-map-control-btn .btn-check:disabled + .zoomOut, .tox .tox-dialog__footer .btn-check:disabled + .tox-button:last-child, .tox .tox-dialog__footer .btn-check:disabled + .tox-button--secondary, .tox .btn-check:disabled + .tox-button-secondary, .tox .btn-check:disabled + .tox-button, [data-list] .btn-check:disabled + .page, .table-list .btn-check:disabled + .page {
  pointer-events: none;
  filter: none;
  opacity: 0.3;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3874ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #c3d5ff;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--phoenix-gray-300);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3874ff;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #c3d5ff;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--phoenix-gray-300);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #8a94ad;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #8a94ad;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(2.875rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 22.5px;
  padding-bottom: 9.5px;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 22.5px;
  padding-bottom: 9.5px;
}
.form-floating > .form-select {
  padding-top: 22.5px;
  padding-bottom: 9.5px;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.4rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.4rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn, .input-group .google-map-control-btn .zoomIn, .google-map-control-btn .input-group .zoomIn,
.input-group .google-map-control-btn .zoomOut,
.google-map-control-btn .input-group .zoomOut, .input-group .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-dialog__footer .input-group .tox-button:last-child, .input-group .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer .input-group .tox-button--secondary, .input-group .tox .tox-button-secondary, .tox .input-group .tox-button-secondary, .input-group .tox .tox-button, .tox .input-group .tox-button, .input-group [data-list] .page, [data-list] .input-group .page, .input-group .table-list .page, .table-list .input-group .page {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .google-map-control-btn .zoomIn:focus, .google-map-control-btn .input-group .zoomIn:focus,
.input-group .google-map-control-btn .zoomOut:focus,
.google-map-control-btn .input-group .zoomOut:focus, .input-group .tox .tox-dialog__footer .tox-button--secondary:focus, .tox .tox-dialog__footer .input-group .tox-button--secondary:focus, .input-group .tox .tox-button-secondary:focus, .tox .input-group .tox-button-secondary:focus, .input-group .tox .tox-button:focus, .tox .input-group .tox-button:focus, .input-group [data-list] .page:focus, [data-list] .input-group .page:focus, .input-group .table-list .page:focus, .table-list .input-group .page:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.49;
  color: var(--phoenix-gray-900);
  text-align: center;
  white-space: nowrap;
  background-color: #eff2f6;
  border: 1px solid var(--phoenix-input-border-color);
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.google-map-control-btn .input-group-lg > .zoomIn,
.google-map-control-btn .input-group-lg > .zoomOut,
.tox .tox-dialog__footer .input-group-lg > .tox-button:last-child,
.tox .tox-dialog__footer .input-group-lg > .tox-button--secondary,
.tox .input-group-lg > .tox-button-secondary,
.tox .input-group-lg > .tox-button,
[data-list] .input-group-lg > .page,
.table-list .input-group-lg > .page {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.375rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.google-map-control-btn .input-group-sm > .zoomIn,
.google-map-control-btn .input-group-sm > .zoomOut,
.tox .tox-dialog__footer .input-group-sm > .tox-button:last-child,
.tox .tox-dialog__footer .input-group-sm > .tox-button--secondary,
.tox .input-group-sm > .tox-button-secondary,
.tox .input-group-sm > .tox-button,
[data-list] .input-group-sm > .page,
.table-list .input-group-sm > .page {
  padding: 0.375rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.375rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #25b003;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(37, 176, 3, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #25b003;
  padding-right: calc(1.49em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2325b003' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3725em + 0.25rem) center;
  background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #25b003;
  box-shadow: 0 0 0 0.25rem rgba(37, 176, 3, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.49em + 1rem);
  background-position: top calc(0.3725em + 0.25rem) right calc(0.3725em + 0.25rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #25b003;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5rem;
  background-image: var(--phoenix-form-select-indicator), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2325b003' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2.5rem;
  background-size: 9px 12px, calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #25b003;
  box-shadow: 0 0 0 0.25rem rgba(37, 176, 3, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.49em + 1rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #25b003;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #25b003;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(37, 176, 3, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #25b003;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #ed2000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  color: #fff;
  background-color: rgba(237, 32, 0, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ed2000;
  padding-right: calc(1.49em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3725em + 0.25rem) center;
  background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ed2000;
  box-shadow: 0 0 0 0.25rem rgba(237, 32, 0, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.49em + 1rem);
  background-position: top calc(0.3725em + 0.25rem) right calc(0.3725em + 0.25rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ed2000;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5rem;
  background-image: var(--phoenix-form-select-indicator), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 2.5rem;
  background-size: 9px 12px, calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ed2000;
  box-shadow: 0 0 0 0.25rem rgba(237, 32, 0, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.49em + 1rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ed2000;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ed2000;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 32, 0, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ed2000;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --phoenix-dropdown-zindex: 1000;
  --phoenix-dropdown-min-width: 10rem;
  --phoenix-dropdown-padding-x: 0;
  --phoenix-dropdown-padding-y: 1rem;
  --phoenix-dropdown-spacer: 0.125rem;
  --phoenix-dropdown-font-size: 0.8rem;
  --phoenix-dropdown-color: #525b75;
  --phoenix-dropdown-bg: #fff;
  --phoenix-dropdown-border-color: #cbd0dd;
  --phoenix-dropdown-border-radius: 0.375rem;
  --phoenix-dropdown-border-width: 1px;
  --phoenix-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --phoenix-dropdown-divider-bg: #cbd0dd;
  --phoenix-dropdown-divider-margin-y: 0.5rem;
  --phoenix-dropdown-box-shadow: 0px 2px 4px -2px rgba(36, 40, 46, 0.08);
  --phoenix-dropdown-link-color: #525b75;
  --phoenix-dropdown-link-hover-color: #292e3b;
  --phoenix-dropdown-link-hover-bg: #e3e6ed;
  --phoenix-dropdown-link-active-color: #fff;
  --phoenix-dropdown-link-active-bg: #3874ff;
  --phoenix-dropdown-link-disabled-color: #eff2f6;
  --phoenix-dropdown-item-padding-x: 1rem;
  --phoenix-dropdown-item-padding-y: 0.5rem;
  --phoenix-dropdown-header-color: #6e7891;
  --phoenix-dropdown-header-padding-x: 1rem;
  --phoenix-dropdown-header-padding-y: 1rem;
  position: absolute;
  z-index: var(--phoenix-dropdown-zindex);
  display: none;
  min-width: var(--phoenix-dropdown-min-width);
  padding: var(--phoenix-dropdown-padding-y) var(--phoenix-dropdown-padding-x);
  margin: 0;
  font-size: var(--phoenix-dropdown-font-size);
  color: var(--phoenix-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--phoenix-dropdown-bg);
  background-clip: padding-box;
  border: var(--phoenix-dropdown-border-width) solid var(--phoenix-dropdown-border-color);
  border-radius: var(--phoenix-dropdown-border-radius);
  box-shadow: var(--phoenix-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--phoenix-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1540px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--phoenix-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--phoenix-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--phoenix-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--phoenix-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--phoenix-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--phoenix-dropdown-item-padding-y) var(--phoenix-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--phoenix-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--phoenix-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--phoenix-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--phoenix-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--phoenix-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--phoenix-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--phoenix-dropdown-header-padding-y) var(--phoenix-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--phoenix-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--phoenix-dropdown-item-padding-y) var(--phoenix-dropdown-item-padding-x);
  color: var(--phoenix-dropdown-link-color);
}

.dropdown-menu-dark {
  --phoenix-dropdown-color: #cbd0dd;
  --phoenix-dropdown-bg: #3e465b;
  --phoenix-dropdown-border-color: #cbd0dd;
  --phoenix-dropdown-box-shadow: ;
  --phoenix-dropdown-link-color: #cbd0dd;
  --phoenix-dropdown-link-hover-color: #fff;
  --phoenix-dropdown-divider-bg: #cbd0dd;
  --phoenix-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --phoenix-dropdown-link-active-color: #fff;
  --phoenix-dropdown-link-active-bg: #3874ff;
  --phoenix-dropdown-link-disabled-color: #8a94ad;
  --phoenix-dropdown-header-color: #8a94ad;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .google-map-control-btn .btn-group > .zoomIn,
.google-map-control-btn .btn-group > .zoomOut, .tox .tox-dialog__footer .btn-group > .tox-button:last-child, .tox .tox-dialog__footer .btn-group > .tox-button--secondary, .tox .btn-group > .tox-button-secondary, .tox .btn-group > .tox-button, [data-list] .btn-group > .page, .table-list .btn-group > .page,
.btn-group-vertical > .btn,
.google-map-control-btn .btn-group-vertical > .zoomIn,
.google-map-control-btn .btn-group-vertical > .zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button:last-child,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary,
.tox .btn-group-vertical > .tox-button-secondary,
.tox .btn-group-vertical > .tox-button,
[data-list] .btn-group-vertical > .page,
.table-list .btn-group-vertical > .page {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .google-map-control-btn .btn-group > .btn-check:checked + .zoomIn,
.google-map-control-btn .btn-group > .btn-check:checked + .zoomOut, .tox .tox-dialog__footer .btn-group > .btn-check:checked + .tox-button--secondary, .tox .btn-group > .btn-check:checked + .tox-button-secondary, .tox .btn-group > .btn-check:checked + .tox-button, [data-list] .btn-group > .btn-check:checked + .page, .table-list .btn-group > .btn-check:checked + .page,
.btn-group > .btn-check:focus + .btn,
.google-map-control-btn .btn-group > .btn-check:focus + .zoomIn,
.google-map-control-btn .btn-group > .btn-check:focus + .zoomOut,
.tox .tox-dialog__footer .btn-group > .btn-check:focus + .tox-button--secondary,
.tox .btn-group > .btn-check:focus + .tox-button-secondary,
.tox .btn-group > .btn-check:focus + .tox-button,
[data-list] .btn-group > .btn-check:focus + .page,
.table-list .btn-group > .btn-check:focus + .page,
.btn-group > .btn:hover,
.google-map-control-btn .btn-group > .zoomIn:hover,
.google-map-control-btn .btn-group > .zoomOut:hover,
.tox .tox-dialog__footer .btn-group > .tox-button:hover:last-child,
.tox .tox-dialog__footer .btn-group > .tox-button--secondary:hover,
.tox .btn-group > .tox-button-secondary:hover,
.tox .btn-group > .tox-button:hover,
[data-list] .btn-group > .page:hover,
.table-list .btn-group > .page:hover,
.btn-group > .btn:focus,
.google-map-control-btn .btn-group > .zoomIn:focus,
.google-map-control-btn .btn-group > .zoomOut:focus,
.tox .tox-dialog__footer .btn-group > .tox-button:focus:last-child,
.tox .tox-dialog__footer .btn-group > .tox-button--secondary:focus,
.tox .btn-group > .tox-button-secondary:focus,
.tox .btn-group > .tox-button:focus,
[data-list] .btn-group > .page:focus,
.table-list .btn-group > .page:focus,
.btn-group > .btn:active,
.google-map-control-btn .btn-group > .zoomIn:active,
.google-map-control-btn .btn-group > .zoomOut:active,
.tox .tox-dialog__footer .btn-group > .tox-button:active:last-child,
.tox .tox-dialog__footer .btn-group > .tox-button--secondary:active,
.tox .btn-group > .tox-button-secondary:active,
.tox .btn-group > .tox-button:active,
[data-list] .btn-group > .page:active,
.table-list .btn-group > .page:active,
.btn-group > .btn.active,
.google-map-control-btn .btn-group > .active.zoomIn,
.google-map-control-btn .btn-group > .active.zoomOut,
.tox .tox-dialog__footer .btn-group > .active.tox-button:last-child,
.tox .tox-dialog__footer .btn-group > .active.tox-button--secondary,
.tox .btn-group > .active.tox-button-secondary,
.tox .btn-group > .active.tox-button,
[data-list] .btn-group > .active.page,
.table-list .btn-group > .active.page,
.btn-group-vertical > .btn-check:checked + .btn,
.google-map-control-btn .btn-group-vertical > .btn-check:checked + .zoomIn,
.google-map-control-btn .btn-group-vertical > .btn-check:checked + .zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .btn-check:checked + .tox-button--secondary,
.tox .btn-group-vertical > .btn-check:checked + .tox-button-secondary,
.tox .btn-group-vertical > .btn-check:checked + .tox-button,
[data-list] .btn-group-vertical > .btn-check:checked + .page,
.table-list .btn-group-vertical > .btn-check:checked + .page,
.btn-group-vertical > .btn-check:focus + .btn,
.google-map-control-btn .btn-group-vertical > .btn-check:focus + .zoomIn,
.google-map-control-btn .btn-group-vertical > .btn-check:focus + .zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .btn-check:focus + .tox-button--secondary,
.tox .btn-group-vertical > .btn-check:focus + .tox-button-secondary,
.tox .btn-group-vertical > .btn-check:focus + .tox-button,
[data-list] .btn-group-vertical > .btn-check:focus + .page,
.table-list .btn-group-vertical > .btn-check:focus + .page,
.btn-group-vertical > .btn:hover,
.google-map-control-btn .btn-group-vertical > .zoomIn:hover,
.google-map-control-btn .btn-group-vertical > .zoomOut:hover,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button:hover:last-child,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary:hover,
.tox .btn-group-vertical > .tox-button-secondary:hover,
.tox .btn-group-vertical > .tox-button:hover,
[data-list] .btn-group-vertical > .page:hover,
.table-list .btn-group-vertical > .page:hover,
.btn-group-vertical > .btn:focus,
.google-map-control-btn .btn-group-vertical > .zoomIn:focus,
.google-map-control-btn .btn-group-vertical > .zoomOut:focus,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button:focus:last-child,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary:focus,
.tox .btn-group-vertical > .tox-button-secondary:focus,
.tox .btn-group-vertical > .tox-button:focus,
[data-list] .btn-group-vertical > .page:focus,
.table-list .btn-group-vertical > .page:focus,
.btn-group-vertical > .btn:active,
.google-map-control-btn .btn-group-vertical > .zoomIn:active,
.google-map-control-btn .btn-group-vertical > .zoomOut:active,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button:active:last-child,
.tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary:active,
.tox .btn-group-vertical > .tox-button-secondary:active,
.tox .btn-group-vertical > .tox-button:active,
[data-list] .btn-group-vertical > .page:active,
.table-list .btn-group-vertical > .page:active,
.btn-group-vertical > .btn.active,
.google-map-control-btn .btn-group-vertical > .active.zoomIn,
.google-map-control-btn .btn-group-vertical > .active.zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .active.tox-button:last-child,
.tox .tox-dialog__footer .btn-group-vertical > .active.tox-button--secondary,
.tox .btn-group-vertical > .active.tox-button-secondary,
.tox .btn-group-vertical > .active.tox-button,
[data-list] .btn-group-vertical > .active.page,
.table-list .btn-group-vertical > .active.page {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn, .google-map-control-btn .btn-group > :not(.btn-check:first-child) + .zoomIn,
.google-map-control-btn .btn-group > :not(.btn-check:first-child) + .zoomOut, .tox .tox-dialog__footer .btn-group > :not(.btn-check:first-child) + .tox-button--secondary, .tox .btn-group > :not(.btn-check:first-child) + .tox-button-secondary, .tox .btn-group > :not(.btn-check:first-child) + .tox-button, [data-list] .btn-group > :not(.btn-check:first-child) + .page, .table-list .btn-group > :not(.btn-check:first-child) + .page,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .google-map-control-btn .btn-group > .zoomIn:not(:last-child):not(.dropdown-toggle),
.google-map-control-btn .btn-group > .zoomOut:not(:last-child):not(.dropdown-toggle), .tox .tox-dialog__footer .btn-group > .tox-button:not(:last-child):not(.dropdown-toggle):last-child, .tox .tox-dialog__footer .btn-group > .tox-button--secondary:not(:last-child):not(.dropdown-toggle), .tox .btn-group > .tox-button-secondary:not(:last-child):not(.dropdown-toggle), .tox .btn-group > .tox-button:not(:last-child):not(.dropdown-toggle), [data-list] .btn-group > .page:not(:last-child):not(.dropdown-toggle), .table-list .btn-group > .page:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.google-map-control-btn .btn-group > .dropdown-toggle-split.zoomIn:first-child,
.google-map-control-btn .btn-group > .dropdown-toggle-split.zoomOut:first-child,
.tox .tox-dialog__footer .btn-group > .dropdown-toggle-split.tox-button:first-child:last-child,
.tox .tox-dialog__footer .btn-group > .dropdown-toggle-split.tox-button--secondary:first-child,
.tox .btn-group > .dropdown-toggle-split.tox-button-secondary:first-child,
.tox .btn-group > .dropdown-toggle-split.tox-button:first-child,
[data-list] .btn-group > .dropdown-toggle-split.page:first-child,
.table-list .btn-group > .dropdown-toggle-split.page:first-child,
.btn-group > .btn-group:not(:last-child) > .btn,
.google-map-control-btn .btn-group > .btn-group:not(:last-child) > .zoomIn,
.google-map-control-btn .btn-group > .btn-group:not(:last-child) > .zoomOut,
.tox .tox-dialog__footer .btn-group > .btn-group:not(:last-child) > .tox-button--secondary,
.tox .btn-group > .btn-group:not(:last-child) > .tox-button-secondary,
.tox .btn-group > .btn-group:not(:last-child) > .tox-button,
[data-list] .btn-group > .btn-group:not(:last-child) > .page,
.table-list .btn-group > .btn-group:not(:last-child) > .page {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .google-map-control-btn .btn-group > .zoomIn:nth-child(n+3),
.google-map-control-btn .btn-group > .zoomOut:nth-child(n+3), .tox .tox-dialog__footer .btn-group > .tox-button:nth-child(n+3):last-child, .tox .tox-dialog__footer .btn-group > .tox-button--secondary:nth-child(n+3), .tox .btn-group > .tox-button-secondary:nth-child(n+3), .tox .btn-group > .tox-button:nth-child(n+3), [data-list] .btn-group > .page:nth-child(n+3), .table-list .btn-group > .page:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.google-map-control-btn .btn-group > :not(.btn-check) + .zoomIn,
.google-map-control-btn .btn-group > :not(.btn-check) + .zoomOut,
.tox .tox-dialog__footer .btn-group > :not(.btn-check) + .tox-button--secondary,
.tox .btn-group > :not(.btn-check) + .tox-button-secondary,
.tox .btn-group > :not(.btn-check) + .tox-button,
[data-list] .btn-group > :not(.btn-check) + .page,
.table-list .btn-group > :not(.btn-check) + .page,
.btn-group > .btn-group:not(:first-child) > .btn,
.google-map-control-btn .btn-group > .btn-group:not(:first-child) > .zoomIn,
.google-map-control-btn .btn-group > .btn-group:not(:first-child) > .zoomOut,
.tox .tox-dialog__footer .btn-group > .btn-group:not(:first-child) > .tox-button--secondary,
.tox .btn-group > .btn-group:not(:first-child) > .tox-button-secondary,
.tox .btn-group > .btn-group:not(:first-child) > .tox-button,
[data-list] .btn-group > .btn-group:not(:first-child) > .page,
.table-list .btn-group > .btn-group:not(:first-child) > .page {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .google-map-control-btn .btn-group-sm > .zoomIn + .dropdown-toggle-split,
.google-map-control-btn .btn-group-sm > .zoomOut + .dropdown-toggle-split, .tox .tox-dialog__footer .btn-group-sm > .tox-button--secondary + .dropdown-toggle-split, .tox .btn-group-sm > .tox-button-secondary + .dropdown-toggle-split, .tox .btn-group-sm > .tox-button + .dropdown-toggle-split, [data-list] .btn-group-sm > .page + .dropdown-toggle-split, .table-list .btn-group-sm > .page + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .google-map-control-btn .btn-group-lg > .zoomIn + .dropdown-toggle-split,
.google-map-control-btn .btn-group-lg > .zoomOut + .dropdown-toggle-split, .tox .tox-dialog__footer .btn-group-lg > .tox-button--secondary + .dropdown-toggle-split, .tox .btn-group-lg > .tox-button-secondary + .dropdown-toggle-split, .tox .btn-group-lg > .tox-button + .dropdown-toggle-split, [data-list] .btn-group-lg > .page + .dropdown-toggle-split, .table-list .btn-group-lg > .page + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .google-map-control-btn .btn-group-vertical > .zoomIn,
.google-map-control-btn .btn-group-vertical > .zoomOut, .tox .tox-dialog__footer .btn-group-vertical > .tox-button:last-child, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary, .tox .btn-group-vertical > .tox-button-secondary, .tox .btn-group-vertical > .tox-button, [data-list] .btn-group-vertical > .page, .table-list .btn-group-vertical > .page,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .google-map-control-btn .btn-group-vertical > .zoomIn:not(:first-child),
.google-map-control-btn .btn-group-vertical > .zoomOut:not(:first-child), .tox .tox-dialog__footer .btn-group-vertical > .tox-button:not(:first-child):last-child, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary:not(:first-child), .tox .btn-group-vertical > .tox-button-secondary:not(:first-child), .tox .btn-group-vertical > .tox-button:not(:first-child), [data-list] .btn-group-vertical > .page:not(:first-child), .table-list .btn-group-vertical > .page:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .google-map-control-btn .btn-group-vertical > .zoomIn:not(:last-child):not(.dropdown-toggle),
.google-map-control-btn .btn-group-vertical > .zoomOut:not(:last-child):not(.dropdown-toggle), .tox .tox-dialog__footer .btn-group-vertical > .tox-button:not(:last-child):not(.dropdown-toggle):last-child, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary:not(:last-child):not(.dropdown-toggle), .tox .btn-group-vertical > .tox-button-secondary:not(:last-child):not(.dropdown-toggle), .tox .btn-group-vertical > .tox-button:not(:last-child):not(.dropdown-toggle), [data-list] .btn-group-vertical > .page:not(:last-child):not(.dropdown-toggle), .table-list .btn-group-vertical > .page:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.google-map-control-btn .btn-group-vertical > .btn-group:not(:last-child) > .zoomIn,
.google-map-control-btn .btn-group-vertical > .btn-group:not(:last-child) > .zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .btn-group:not(:last-child) > .tox-button--secondary,
.tox .btn-group-vertical > .btn-group:not(:last-child) > .tox-button-secondary,
.tox .btn-group-vertical > .btn-group:not(:last-child) > .tox-button,
[data-list] .btn-group-vertical > .btn-group:not(:last-child) > .page,
.table-list .btn-group-vertical > .btn-group:not(:last-child) > .page {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .google-map-control-btn .btn-group-vertical > .zoomIn ~ .btn,
.google-map-control-btn .btn-group-vertical > .zoomOut ~ .btn, .google-map-control-btn .btn-group-vertical > .btn ~ .zoomIn, .google-map-control-btn .btn-group-vertical > .zoomIn ~ .zoomIn,
.google-map-control-btn .btn-group-vertical > .zoomOut ~ .zoomIn,
.google-map-control-btn .btn-group-vertical > .btn ~ .zoomOut,
.google-map-control-btn .btn-group-vertical > .zoomIn ~ .zoomOut,
.google-map-control-btn .btn-group-vertical > .zoomOut ~ .zoomOut, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .btn, .tox .tox-dialog__footer .google-map-control-btn .btn-group-vertical > .tox-button--secondary ~ .zoomIn, .google-map-control-btn .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .zoomIn,
.tox .tox-dialog__footer .google-map-control-btn .btn-group-vertical > .tox-button--secondary ~ .zoomOut,
.google-map-control-btn .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .zoomOut, .tox .tox-dialog__footer .btn-group-vertical > .btn ~ .tox-button--secondary, .tox .tox-dialog__footer .google-map-control-btn .btn-group-vertical > .zoomIn ~ .tox-button--secondary, .google-map-control-btn .tox .tox-dialog__footer .btn-group-vertical > .zoomIn ~ .tox-button--secondary,
.tox .tox-dialog__footer .google-map-control-btn .btn-group-vertical > .zoomOut ~ .tox-button--secondary,
.google-map-control-btn .tox .tox-dialog__footer .btn-group-vertical > .zoomOut ~ .tox-button--secondary, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .tox-button--secondary, .tox .btn-group-vertical > .tox-button-secondary ~ .btn, .tox .google-map-control-btn .btn-group-vertical > .tox-button-secondary ~ .zoomIn, .google-map-control-btn .tox .btn-group-vertical > .tox-button-secondary ~ .zoomIn,
.tox .google-map-control-btn .btn-group-vertical > .tox-button-secondary ~ .zoomOut,
.google-map-control-btn .tox .btn-group-vertical > .tox-button-secondary ~ .zoomOut, .tox .tox-dialog__footer .btn-group-vertical > .tox-button-secondary ~ .tox-button--secondary, .tox .btn-group-vertical > .btn ~ .tox-button-secondary, .tox .google-map-control-btn .btn-group-vertical > .zoomIn ~ .tox-button-secondary, .google-map-control-btn .tox .btn-group-vertical > .zoomIn ~ .tox-button-secondary,
.tox .google-map-control-btn .btn-group-vertical > .zoomOut ~ .tox-button-secondary,
.google-map-control-btn .tox .btn-group-vertical > .zoomOut ~ .tox-button-secondary, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .tox-button-secondary, .tox .btn-group-vertical > .tox-button-secondary ~ .tox-button-secondary, .tox .btn-group-vertical > .tox-button ~ .btn, .tox .google-map-control-btn .btn-group-vertical > .tox-button ~ .zoomIn, .google-map-control-btn .tox .btn-group-vertical > .tox-button ~ .zoomIn,
.tox .google-map-control-btn .btn-group-vertical > .tox-button ~ .zoomOut,
.google-map-control-btn .tox .btn-group-vertical > .tox-button ~ .zoomOut, .tox .tox-dialog__footer .btn-group-vertical > .tox-button ~ .tox-button--secondary, .tox .btn-group-vertical > .tox-button ~ .tox-button-secondary, .tox .btn-group-vertical > .btn ~ .tox-button, .tox .google-map-control-btn .btn-group-vertical > .zoomIn ~ .tox-button, .google-map-control-btn .tox .btn-group-vertical > .zoomIn ~ .tox-button,
.tox .google-map-control-btn .btn-group-vertical > .zoomOut ~ .tox-button,
.google-map-control-btn .tox .btn-group-vertical > .zoomOut ~ .tox-button, .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .tox-button, .tox .btn-group-vertical > .tox-button-secondary ~ .tox-button, .tox .btn-group-vertical > .tox-button ~ .tox-button, [data-list] .btn-group-vertical > .page ~ .btn, [data-list] .google-map-control-btn .btn-group-vertical > .page ~ .zoomIn, .google-map-control-btn [data-list] .btn-group-vertical > .page ~ .zoomIn,
[data-list] .google-map-control-btn .btn-group-vertical > .page ~ .zoomOut,
.google-map-control-btn [data-list] .btn-group-vertical > .page ~ .zoomOut, [data-list] .tox .tox-dialog__footer .btn-group-vertical > .page ~ .tox-button--secondary, .tox .tox-dialog__footer [data-list] .btn-group-vertical > .page ~ .tox-button--secondary, [data-list] .tox .btn-group-vertical > .page ~ .tox-button-secondary, .tox [data-list] .btn-group-vertical > .page ~ .tox-button-secondary, [data-list] .tox .btn-group-vertical > .page ~ .tox-button, .tox [data-list] .btn-group-vertical > .page ~ .tox-button, .table-list .btn-group-vertical > .page ~ .btn, .table-list .google-map-control-btn .btn-group-vertical > .page ~ .zoomIn, .google-map-control-btn .table-list .btn-group-vertical > .page ~ .zoomIn,
.table-list .google-map-control-btn .btn-group-vertical > .page ~ .zoomOut,
.google-map-control-btn .table-list .btn-group-vertical > .page ~ .zoomOut, .table-list .tox .tox-dialog__footer .btn-group-vertical > .page ~ .tox-button--secondary, .tox .tox-dialog__footer .table-list .btn-group-vertical > .page ~ .tox-button--secondary, .table-list .tox .btn-group-vertical > .page ~ .tox-button-secondary, .tox .table-list .btn-group-vertical > .page ~ .tox-button-secondary, .table-list .tox .btn-group-vertical > .page ~ .tox-button, .tox .table-list .btn-group-vertical > .page ~ .tox-button, [data-list] .btn-group-vertical > .btn ~ .page, [data-list] .google-map-control-btn .btn-group-vertical > .zoomIn ~ .page, .google-map-control-btn [data-list] .btn-group-vertical > .zoomIn ~ .page,
[data-list] .google-map-control-btn .btn-group-vertical > .zoomOut ~ .page,
.google-map-control-btn [data-list] .btn-group-vertical > .zoomOut ~ .page, [data-list] .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .page, .tox .tox-dialog__footer [data-list] .btn-group-vertical > .tox-button--secondary ~ .page, [data-list] .tox .btn-group-vertical > .tox-button-secondary ~ .page, .tox [data-list] .btn-group-vertical > .tox-button-secondary ~ .page, [data-list] .tox .btn-group-vertical > .tox-button ~ .page, .tox [data-list] .btn-group-vertical > .tox-button ~ .page, [data-list] .btn-group-vertical > .page ~ .page, .table-list .btn-group-vertical > .btn ~ .page, .table-list .google-map-control-btn .btn-group-vertical > .zoomIn ~ .page, .google-map-control-btn .table-list .btn-group-vertical > .zoomIn ~ .page,
.table-list .google-map-control-btn .btn-group-vertical > .zoomOut ~ .page,
.google-map-control-btn .table-list .btn-group-vertical > .zoomOut ~ .page, .table-list .tox .tox-dialog__footer .btn-group-vertical > .tox-button--secondary ~ .page, .tox .tox-dialog__footer .table-list .btn-group-vertical > .tox-button--secondary ~ .page, .table-list .tox .btn-group-vertical > .tox-button-secondary ~ .page, .tox .table-list .btn-group-vertical > .tox-button-secondary ~ .page, .table-list .tox .btn-group-vertical > .tox-button ~ .page, .tox .table-list .btn-group-vertical > .tox-button ~ .page, .table-list .btn-group-vertical > .page ~ .page,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.google-map-control-btn .btn-group-vertical > .btn-group:not(:first-child) > .zoomIn,
.google-map-control-btn .btn-group-vertical > .btn-group:not(:first-child) > .zoomOut,
.tox .tox-dialog__footer .btn-group-vertical > .btn-group:not(:first-child) > .tox-button--secondary,
.tox .btn-group-vertical > .btn-group:not(:first-child) > .tox-button-secondary,
.tox .btn-group-vertical > .btn-group:not(:first-child) > .tox-button,
[data-list] .btn-group-vertical > .btn-group:not(:first-child) > .page,
.table-list .btn-group-vertical > .btn-group:not(:first-child) > .page {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --phoenix-nav-link-padding-x: 1rem;
  --phoenix-nav-link-padding-y: 0.5rem;
  --phoenix-nav-link-font-weight: ;
  --phoenix-nav-link-color: var(--phoenix-link-color);
  --phoenix-nav-link-hover-color: var(--phoenix-link-hover-color);
  --phoenix-nav-link-disabled-color: #6e7891;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--phoenix-nav-link-padding-y) var(--phoenix-nav-link-padding-x);
  font-size: var(--phoenix-nav-link-font-size);
  font-weight: var(--phoenix-nav-link-font-weight);
  color: var(--phoenix-nav-link-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--phoenix-nav-link-hover-color);
  text-decoration: none;
}
.nav-link.disabled {
  color: var(--phoenix-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --phoenix-nav-tabs-border-width: 2px;
  --phoenix-nav-tabs-border-color: #cbd0dd;
  --phoenix-nav-tabs-border-radius: 0.375rem;
  --phoenix-nav-tabs-link-hover-border-color: #e3e6ed #e3e6ed #cbd0dd;
  --phoenix-nav-tabs-link-active-color: #525b75;
  --phoenix-nav-tabs-link-active-bg: #f5f7fa;
  --phoenix-nav-tabs-link-active-border-color: #cbd0dd #cbd0dd #f5f7fa;
  border-bottom: var(--phoenix-nav-tabs-border-width) solid var(--phoenix-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--phoenix-nav-tabs-border-width));
  background: none;
  border: var(--phoenix-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--phoenix-nav-tabs-border-radius);
  border-top-right-radius: var(--phoenix-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--phoenix-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--phoenix-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--phoenix-nav-tabs-link-active-color);
  background-color: var(--phoenix-nav-tabs-link-active-bg);
  border-color: var(--phoenix-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--phoenix-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --phoenix-nav-pills-border-radius: 0.375rem;
  --phoenix-nav-pills-link-active-color: #fff;
  --phoenix-nav-pills-link-active-bg: #3874ff;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--phoenix-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--phoenix-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--phoenix-nav-pills-link-active-color);
  background-color: var(--phoenix-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --phoenix-navbar-padding-x: 1.5rem;
  --phoenix-navbar-padding-y: 0.5rem;
  --phoenix-navbar-color: var(--phoenix-navbar-light-color);
  --phoenix-navbar-hover-color: var(--phoenix-navbar-light-hover-color);
  --phoenix-navbar-disabled-color: var(--phoenix-navbar-light-disabled-color);
  --phoenix-navbar-active-color: var(--phoenix-navbar-light-active-color);
  --phoenix-navbar-brand-padding-y: -0.210078125rem;
  --phoenix-navbar-brand-margin-end: 1rem;
  --phoenix-navbar-brand-font-size: 1.953125rem;
  --phoenix-navbar-brand-color: var(--phoenix-navbar-light-brand-color);
  --phoenix-navbar-brand-hover-color: var(--phoenix-navbar-light-brand-color);
  --phoenix-navbar-nav-link-padding-x: 0.5rem;
  --phoenix-navbar-toggler-padding-y: 0.25rem;
  --phoenix-navbar-toggler-padding-x: 0.75rem;
  --phoenix-navbar-toggler-font-size: 1.2rem;
  --phoenix-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%239da9bb' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E");
  --phoenix-navbar-toggler-border-color: var(--phoenix-navbar-light-toggler-border-color);
  --phoenix-navbar-toggler-border-radius: 0.375rem;
  --phoenix-navbar-toggler-focus-width: 0;
  --phoenix-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--phoenix-navbar-padding-y) var(--phoenix-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-small-xl,
.navbar > .container-small-lg,
.navbar > .container-small-md,
.navbar > .container-small-sm,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--phoenix-navbar-brand-padding-y);
  padding-bottom: var(--phoenix-navbar-brand-padding-y);
  margin-right: var(--phoenix-navbar-brand-margin-end);
  font-size: var(--phoenix-navbar-brand-font-size);
  color: var(--phoenix-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--phoenix-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --phoenix-nav-link-padding-x: 0;
  --phoenix-nav-link-padding-y: 0.5rem;
  --phoenix-nav-link-font-weight: ;
  --phoenix-nav-link-color: var(--phoenix-navbar-color);
  --phoenix-nav-link-hover-color: var(--phoenix-navbar-hover-color);
  --phoenix-nav-link-disabled-color: var(--phoenix-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--phoenix-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--phoenix-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--phoenix-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--phoenix-navbar-toggler-padding-y) var(--phoenix-navbar-toggler-padding-x);
  font-size: var(--phoenix-navbar-toggler-font-size);
  line-height: 1;
  color: var(--phoenix-navbar-color);
  background-color: transparent;
  border: var(--phoenix-border-width) solid var(--phoenix-navbar-toggler-border-color);
  border-radius: var(--phoenix-navbar-toggler-border-radius);
  transition: var(--phoenix-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--phoenix-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--phoenix-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--phoenix-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--phoenix-navbar-nav-link-padding-x);
    padding-left: var(--phoenix-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--phoenix-navbar-nav-link-padding-x);
    padding-left: var(--phoenix-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--phoenix-navbar-nav-link-padding-x);
    padding-left: var(--phoenix-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--phoenix-navbar-nav-link-padding-x);
    padding-left: var(--phoenix-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1540px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--phoenix-navbar-nav-link-padding-x);
    padding-left: var(--phoenix-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--phoenix-navbar-nav-link-padding-x);
  padding-left: var(--phoenix-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --phoenix-navbar-color: var(--phoenix-navbar-dark-color);
  --phoenix-navbar-hover-color: var(--phoenix-navbar-dark-hover-color);
  --phoenix-navbar-disabled-color: var(--phoenix-navbar-dark-disabled-color);
  --phoenix-navbar-active-color: var(--phoenix-navbar-dark-active-color);
  --phoenix-navbar-brand-color: var(--phoenix-navbar-dark-brand-color);
  --phoenix-navbar-brand-hover-color: var(--phoenix-navbar-dark-brand-color);
  --phoenix-navbar-toggler-border-color: var(--phoenix-navbar-dark-toggler-border-color);
  --phoenix-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%239da9bb' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E");
}

.card {
  --phoenix-card-spacer-y: 1.5rem;
  --phoenix-card-spacer-x: 1.5rem;
  --phoenix-card-title-spacer-y: 1rem;
  --phoenix-card-border-width: 1px;
  --phoenix-card-border-color: var(--phoenix-gray-200);
  --phoenix-card-border-radius: 0.5rem;
  --phoenix-card-box-shadow: ;
  --phoenix-card-inner-border-radius: calc(0.5rem - 1px);
  --phoenix-card-cap-padding-y: 1.5rem;
  --phoenix-card-cap-padding-x: 1.5rem;
  --phoenix-card-cap-bg: var(--phoenix-card-cap-bg);
  --phoenix-card-cap-color: ;
  --phoenix-card-height: ;
  --phoenix-card-color: ;
  --phoenix-card-bg: #fff;
  --phoenix-card-img-overlay-padding: 1rem;
  --phoenix-card-group-margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--phoenix-card-height);
  word-wrap: break-word;
  background-color: var(--phoenix-card-bg);
  background-clip: border-box;
  border: var(--phoenix-card-border-width) solid var(--phoenix-card-border-color);
  border-radius: var(--phoenix-card-border-radius);
  box-shadow: var(--phoenix-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--phoenix-card-inner-border-radius);
  border-top-right-radius: var(--phoenix-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--phoenix-card-inner-border-radius);
  border-bottom-left-radius: var(--phoenix-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--phoenix-card-spacer-y) var(--phoenix-card-spacer-x);
  color: var(--phoenix-card-color);
}

.card-title {
  margin-bottom: var(--phoenix-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--phoenix-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--phoenix-card-spacer-x);
}

.card-header {
  padding: var(--phoenix-card-cap-padding-y) var(--phoenix-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--phoenix-card-cap-color);
  background-color: var(--phoenix-card-cap-bg);
  border-bottom: var(--phoenix-card-border-width) solid var(--phoenix-card-border-color);
}
.card-header:first-child {
  border-radius: var(--phoenix-card-inner-border-radius) var(--phoenix-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--phoenix-card-cap-padding-y) var(--phoenix-card-cap-padding-x);
  color: var(--phoenix-card-cap-color);
  background-color: var(--phoenix-card-cap-bg);
  border-top: var(--phoenix-card-border-width) solid var(--phoenix-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--phoenix-card-inner-border-radius) var(--phoenix-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--phoenix-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--phoenix-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--phoenix-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--phoenix-card-bg);
  border-bottom-color: var(--phoenix-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--phoenix-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--phoenix-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--phoenix-card-img-overlay-padding);
  border-radius: var(--phoenix-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--phoenix-card-inner-border-radius);
  border-top-right-radius: var(--phoenix-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--phoenix-card-inner-border-radius);
  border-bottom-left-radius: var(--phoenix-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--phoenix-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --phoenix-accordion-color: #222834;
  --phoenix-accordion-bg: transparent;
  --phoenix-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --phoenix-accordion-border-color: var(--phoenix-border-color);
  --phoenix-accordion-border-width: 0;
  --phoenix-accordion-border-radius: 0;
  --phoenix-accordion-inner-border-radius: 0;
  --phoenix-accordion-btn-padding-x: 0;
  --phoenix-accordion-btn-padding-y: 0.5rem;
  --phoenix-accordion-btn-color: #3874ff;
  --phoenix-accordion-btn-bg: var(--phoenix-accordion-bg);
  --phoenix-accordion-btn-icon: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874ff' d='M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E");
  --phoenix-accordion-btn-icon-width: 1.25rem;
  --phoenix-accordion-btn-icon-transform: rotate(-180deg);
  --phoenix-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --phoenix-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874ff' d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E");
  --phoenix-accordion-btn-focus-border-color: #3874ff;
  --phoenix-accordion-btn-focus-box-shadow: 0 0 0 0.25rem transparent;
  --phoenix-accordion-body-padding-x: 0;
  --phoenix-accordion-body-padding-y: 0.5rem;
  --phoenix-accordion-active-color: #141824;
  --phoenix-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--phoenix-accordion-btn-padding-y) var(--phoenix-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--phoenix-accordion-btn-color);
  text-align: left;
  background-color: var(--phoenix-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--phoenix-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--phoenix-accordion-active-color);
  background-color: var(--phoenix-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--phoenix-accordion-border-width)) 0 var(--phoenix-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--phoenix-accordion-btn-active-icon);
  transform: var(--phoenix-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--phoenix-accordion-btn-icon-width);
  height: var(--phoenix-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--phoenix-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--phoenix-accordion-btn-icon-width);
  transition: var(--phoenix-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--phoenix-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--phoenix-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--phoenix-accordion-color);
  background-color: var(--phoenix-accordion-bg);
  border: var(--phoenix-accordion-border-width) solid var(--phoenix-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--phoenix-accordion-border-radius);
  border-top-right-radius: var(--phoenix-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--phoenix-accordion-inner-border-radius);
  border-top-right-radius: var(--phoenix-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--phoenix-accordion-border-radius);
  border-bottom-left-radius: var(--phoenix-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--phoenix-accordion-inner-border-radius);
  border-bottom-left-radius: var(--phoenix-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--phoenix-accordion-border-radius);
  border-bottom-left-radius: var(--phoenix-accordion-border-radius);
}

.accordion-body {
  padding: var(--phoenix-accordion-body-padding-y) var(--phoenix-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --phoenix-breadcrumb-padding-x: 0;
  --phoenix-breadcrumb-padding-y: 0;
  --phoenix-breadcrumb-margin-bottom: 1rem;
  --phoenix-breadcrumb-font-size: 0.8rem;
  --phoenix-breadcrumb-bg: ;
  --phoenix-breadcrumb-border-radius: ;
  --phoenix-breadcrumb-divider-color: #6e7891;
  --phoenix-breadcrumb-item-padding-x: 0.5rem;
  --phoenix-breadcrumb-item-active-color: var(--phoenix-gray-900);
  display: flex;
  flex-wrap: wrap;
  padding: var(--phoenix-breadcrumb-padding-y) var(--phoenix-breadcrumb-padding-x);
  margin-bottom: var(--phoenix-breadcrumb-margin-bottom);
  font-size: var(--phoenix-breadcrumb-font-size);
  list-style: none;
  background-color: var(--phoenix-breadcrumb-bg);
  border-radius: var(--phoenix-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--phoenix-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--phoenix-breadcrumb-item-padding-x);
  color: var(--phoenix-breadcrumb-divider-color);
  content: var(--phoenix-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' fill='%236e7891' viewBox='0 0 256 480'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/svg%3E")) /* rtl: var(--phoenix-breadcrumb-divider, url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='10' width='6' fill='%236e7891' viewBox='0 0 256 480'%3E%3Cpath d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/%3E%3C/svg%3E")) */;
}
.breadcrumb-item.active {
  color: var(--phoenix-breadcrumb-item-active-color);
}

.pagination {
  --phoenix-pagination-padding-x: 0.75rem;
  --phoenix-pagination-padding-y: 0.5rem;
  --phoenix-pagination-font-size: 0.8rem;
  --phoenix-pagination-color: var(--phoenix-gray-800);
  --phoenix-pagination-bg: transparent;
  --phoenix-pagination-border-width: 0;
  --phoenix-pagination-border-color: #cbd0dd;
  --phoenix-pagination-border-radius: 0.375rem;
  --phoenix-pagination-hover-color: #3874ff;
  --phoenix-pagination-hover-bg: #e3e6ed;
  --phoenix-pagination-hover-border-color: #cbd0dd;
  --phoenix-pagination-focus-color: var(--phoenix-link-hover-color);
  --phoenix-pagination-focus-bg: #e3e6ed;
  --phoenix-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
  --phoenix-pagination-active-color: #fff;
  --phoenix-pagination-active-bg: #3874ff;
  --phoenix-pagination-active-border-color: #3874ff;
  --phoenix-pagination-disabled-color: #6e7891;
  --phoenix-pagination-disabled-bg: #fff;
  --phoenix-pagination-disabled-border-color: #cbd0dd;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--phoenix-pagination-padding-y) var(--phoenix-pagination-padding-x);
  font-size: var(--phoenix-pagination-font-size);
  color: var(--phoenix-pagination-color);
  background-color: var(--phoenix-pagination-bg);
  border: var(--phoenix-pagination-border-width) solid var(--phoenix-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--phoenix-pagination-hover-color);
  text-decoration: none;
  background-color: var(--phoenix-pagination-hover-bg);
  border-color: var(--phoenix-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--phoenix-pagination-focus-color);
  background-color: var(--phoenix-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--phoenix-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--phoenix-pagination-active-color);
  background-color: var(--phoenix-pagination-active-bg);
  border-color: var(--phoenix-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--phoenix-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--phoenix-pagination-disabled-bg);
  border-color: var(--phoenix-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--phoenix-pagination-border-radius);
  border-bottom-left-radius: var(--phoenix-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--phoenix-pagination-border-radius);
  border-bottom-right-radius: var(--phoenix-pagination-border-radius);
}

.pagination-lg {
  --phoenix-pagination-padding-x: 1.5rem;
  --phoenix-pagination-padding-y: 0.75rem;
  --phoenix-pagination-font-size: 1.2rem;
  --phoenix-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --phoenix-pagination-padding-x: 0.5rem;
  --phoenix-pagination-padding-y: 0.25rem;
  --phoenix-pagination-font-size: 0.875rem;
  --phoenix-pagination-border-radius: 0.25rem;
}

.badge {
  --phoenix-badge-padding-x: 0.711111em;
  --phoenix-badge-padding-y: 0.355555em;
  --phoenix-badge-font-size: 0.75em;
  --phoenix-badge-font-weight: 700;
  --phoenix-badge-color: #fff;
  --phoenix-badge-border-radius: 0.25rem;
  display: inline-block;
  padding: var(--phoenix-badge-padding-y) var(--phoenix-badge-padding-x);
  font-size: var(--phoenix-badge-font-size);
  font-weight: var(--phoenix-badge-font-weight);
  line-height: 1;
  color: var(--phoenix-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--phoenix-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge, .google-map-control-btn .zoomIn .badge,
.google-map-control-btn .zoomOut .badge, .tox .tox-dialog__footer .tox-button:last-child .badge, .tox .tox-dialog__footer .tox-button--secondary .badge, .tox .tox-button-secondary .badge, .tox .tox-button .badge, [data-list] .page .badge, .table-list .page .badge {
  position: relative;
  top: -1px;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.313rem;
  }
}
.progress {
  --phoenix-progress-height: 0.313rem;
  --phoenix-progress-font-size: 0.75rem;
  --phoenix-progress-bg: #e3e6ed;
  --phoenix-progress-border-radius: 0.5rem;
  --phoenix-progress-box-shadow: 0 0 0 transparent;
  --phoenix-progress-bar-color: #fff;
  --phoenix-progress-bar-bg: #3874ff;
  --phoenix-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--phoenix-progress-height);
  overflow: hidden;
  font-size: var(--phoenix-progress-font-size);
  background-color: var(--phoenix-progress-bg);
  border-radius: var(--phoenix-progress-border-radius);
  box-shadow: var(--phoenix-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--phoenix-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--phoenix-progress-bar-bg);
  transition: var(--phoenix-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--phoenix-progress-height) var(--phoenix-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(56, 116, 255, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --phoenix-toast-zindex: 1090;
  --phoenix-toast-padding-x: 0;
  --phoenix-toast-padding-y: 1rem;
  --phoenix-toast-spacing: 2rem;
  --phoenix-toast-max-width: 350px;
  --phoenix-toast-font-size: 1rem;
  --phoenix-toast-color: ;
  --phoenix-toast-bg: rgba(255, 255, 255, 0.85);
  --phoenix-toast-border-width: 1px;
  --phoenix-toast-border-color: var(--phoenix-border-color-translucent);
  --phoenix-toast-border-radius: 0.375rem;
  --phoenix-toast-box-shadow: 0px 4px 8px -6px rgba(36, 40, 46, 0.03), 0px 16px 8px -4px rgba(36, 40, 46, 0.03);
  --phoenix-toast-header-color: #3e465b;
  --phoenix-toast-header-bg: #fff;
  --phoenix-toast-header-border-color: #cbd0dd;
  width: var(--phoenix-toast-max-width);
  max-width: 100%;
  font-size: var(--phoenix-toast-font-size);
  color: var(--phoenix-toast-color);
  pointer-events: auto;
  background-color: var(--phoenix-toast-bg);
  background-clip: padding-box;
  border: var(--phoenix-toast-border-width) solid var(--phoenix-toast-border-color);
  box-shadow: var(--phoenix-toast-box-shadow);
  border-radius: var(--phoenix-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --phoenix-toast-zindex: 1090;
  position: absolute;
  z-index: var(--phoenix-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--phoenix-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--phoenix-toast-padding-y) var(--phoenix-toast-padding-x);
  color: var(--phoenix-toast-header-color);
  background-color: var(--phoenix-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--phoenix-toast-border-width) solid var(--phoenix-toast-header-border-color);
  border-top-left-radius: calc(var(--phoenix-toast-border-radius) - var(--phoenix-toast-border-width));
  border-top-right-radius: calc(var(--phoenix-toast-border-radius) - var(--phoenix-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--phoenix-toast-padding-x));
  margin-left: var(--phoenix-toast-padding-x);
}

.toast-body {
  padding: var(--phoenix-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --phoenix-modal-zindex: 1055;
  --phoenix-modal-width: 500px;
  --phoenix-modal-padding: 1rem;
  --phoenix-modal-margin: 0.5rem;
  --phoenix-modal-color: ;
  --phoenix-modal-bg: #fff;
  --phoenix-modal-border-color: transparent;
  --phoenix-modal-border-width: 1px;
  --phoenix-modal-border-radius: 0.375rem;
  --phoenix-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --phoenix-modal-inner-border-radius: calc(0.375rem - 1px);
  --phoenix-modal-header-padding-x: 1rem;
  --phoenix-modal-header-padding-y: 1rem;
  --phoenix-modal-header-padding: 1rem 1rem;
  --phoenix-modal-header-border-color: var(--phoenix-border-color);
  --phoenix-modal-header-border-width: 1px;
  --phoenix-modal-title-line-height: 1.49;
  --phoenix-modal-footer-gap: 0.5rem;
  --phoenix-modal-footer-bg: ;
  --phoenix-modal-footer-border-color: var(--phoenix-border-color);
  --phoenix-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--phoenix-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--phoenix-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--phoenix-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--phoenix-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--phoenix-modal-color);
  pointer-events: auto;
  background-color: var(--phoenix-modal-bg);
  background-clip: padding-box;
  border: var(--phoenix-modal-border-width) solid var(--phoenix-modal-border-color);
  border-radius: var(--phoenix-modal-border-radius);
  box-shadow: var(--phoenix-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --phoenix-backdrop-zindex: 1050;
  --phoenix-backdrop-bg: #000000;
  --phoenix-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--phoenix-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--phoenix-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--phoenix-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--phoenix-modal-header-padding);
  border-bottom: var(--phoenix-modal-header-border-width) solid var(--phoenix-modal-header-border-color);
  border-top-left-radius: var(--phoenix-modal-inner-border-radius);
  border-top-right-radius: var(--phoenix-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--phoenix-modal-header-padding-y) * 0.5) calc(var(--phoenix-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--phoenix-modal-header-padding-y)) calc(-0.5 * var(--phoenix-modal-header-padding-x)) calc(-0.5 * var(--phoenix-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--phoenix-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--phoenix-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--phoenix-modal-padding) - var(--phoenix-modal-footer-gap) * 0.5);
  background-color: var(--phoenix-modal-footer-bg);
  border-top: var(--phoenix-modal-footer-border-width) solid var(--phoenix-modal-footer-border-color);
  border-bottom-right-radius: var(--phoenix-modal-inner-border-radius);
  border-bottom-left-radius: var(--phoenix-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--phoenix-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --phoenix-modal-margin: 1.75rem;
    --phoenix-modal-box-shadow: 0px 2px 4px -2px rgba(36, 40, 46, 0.08);
  }
  .modal-dialog {
    max-width: var(--phoenix-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --phoenix-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --phoenix-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --phoenix-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1539.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --phoenix-tooltip-zindex: 1080;
  --phoenix-tooltip-max-width: 200px;
  --phoenix-tooltip-padding-x: 0.5rem;
  --phoenix-tooltip-padding-y: 0.5rem;
  --phoenix-tooltip-margin: ;
  --phoenix-tooltip-font-size: 0.8rem;
  --phoenix-tooltip-color: #fff;
  --phoenix-tooltip-bg: #222834;
  --phoenix-tooltip-border-radius: 0.25rem;
  --phoenix-tooltip-opacity: 0.9;
  --phoenix-tooltip-arrow-width: 0.8rem;
  --phoenix-tooltip-arrow-height: 0.4rem;
  z-index: var(--phoenix-tooltip-zindex);
  display: block;
  padding: var(--phoenix-tooltip-arrow-height);
  margin: var(--phoenix-tooltip-margin);
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.49;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--phoenix-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--phoenix-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--phoenix-tooltip-arrow-width);
  height: var(--phoenix-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--phoenix-tooltip-arrow-height) calc(var(--phoenix-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--phoenix-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--phoenix-tooltip-arrow-height);
  height: var(--phoenix-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--phoenix-tooltip-arrow-width) * 0.5) var(--phoenix-tooltip-arrow-height) calc(var(--phoenix-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--phoenix-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--phoenix-tooltip-arrow-width) * 0.5) var(--phoenix-tooltip-arrow-height);
  border-bottom-color: var(--phoenix-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--phoenix-tooltip-arrow-height);
  height: var(--phoenix-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--phoenix-tooltip-arrow-width) * 0.5) 0 calc(var(--phoenix-tooltip-arrow-width) * 0.5) var(--phoenix-tooltip-arrow-height);
  border-left-color: var(--phoenix-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--phoenix-tooltip-max-width);
  padding: var(--phoenix-tooltip-padding-y) var(--phoenix-tooltip-padding-x);
  color: var(--phoenix-tooltip-color);
  text-align: center;
  background-color: var(--phoenix-tooltip-bg);
  border-radius: var(--phoenix-tooltip-border-radius);
}

.popover {
  --phoenix-popover-zindex: 1070;
  --phoenix-popover-max-width: 276px;
  --phoenix-popover-font-size: 0.875rem;
  --phoenix-popover-bg: #fff;
  --phoenix-popover-border-width: 1px;
  --phoenix-popover-border-color: #e3e6ed;
  --phoenix-popover-border-radius: 0.5rem;
  --phoenix-popover-inner-border-radius: calc(0.5rem - 1px);
  --phoenix-popover-box-shadow: 0px 2px 4px -2px rgba(36, 40, 46, 0.08);
  --phoenix-popover-header-padding-x: 1rem;
  --phoenix-popover-header-padding-y: 0.5rem;
  --phoenix-popover-header-font-size: 1rem;
  --phoenix-popover-header-color: var(--phoenix-1100);
  --phoenix-popover-header-bg: #eff2f6;
  --phoenix-popover-body-padding-x: 1rem;
  --phoenix-popover-body-padding-y: 1rem;
  --phoenix-popover-body-color: #31374a;
  --phoenix-popover-arrow-width: 1rem;
  --phoenix-popover-arrow-height: 0.5rem;
  --phoenix-popover-arrow-border: var(--phoenix-popover-border-color);
  z-index: var(--phoenix-popover-zindex);
  display: block;
  max-width: var(--phoenix-popover-max-width);
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.49;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--phoenix-popover-font-size);
  word-wrap: break-word;
  background-color: var(--phoenix-popover-bg);
  background-clip: padding-box;
  border: var(--phoenix-popover-border-width) solid var(--phoenix-popover-border-color);
  border-radius: var(--phoenix-popover-border-radius);
  box-shadow: var(--phoenix-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--phoenix-popover-arrow-width);
  height: var(--phoenix-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--phoenix-popover-arrow-height)) - var(--phoenix-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--phoenix-popover-arrow-height) calc(var(--phoenix-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--phoenix-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--phoenix-popover-border-width);
  border-top-color: var(--phoenix-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--phoenix-popover-arrow-height)) - var(--phoenix-popover-border-width));
  width: var(--phoenix-popover-arrow-height);
  height: var(--phoenix-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--phoenix-popover-arrow-width) * 0.5) var(--phoenix-popover-arrow-height) calc(var(--phoenix-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--phoenix-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--phoenix-popover-border-width);
  border-right-color: var(--phoenix-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--phoenix-popover-arrow-height)) - var(--phoenix-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--phoenix-popover-arrow-width) * 0.5) var(--phoenix-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--phoenix-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--phoenix-popover-border-width);
  border-bottom-color: var(--phoenix-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--phoenix-popover-arrow-width);
  margin-left: calc(-0.5 * var(--phoenix-popover-arrow-width));
  content: "";
  border-bottom: var(--phoenix-popover-border-width) solid var(--phoenix-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--phoenix-popover-arrow-height)) - var(--phoenix-popover-border-width));
  width: var(--phoenix-popover-arrow-height);
  height: var(--phoenix-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--phoenix-popover-arrow-width) * 0.5) 0 calc(var(--phoenix-popover-arrow-width) * 0.5) var(--phoenix-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--phoenix-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--phoenix-popover-border-width);
  border-left-color: var(--phoenix-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--phoenix-popover-header-padding-y) var(--phoenix-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--phoenix-popover-header-font-size);
  color: var(--phoenix-popover-header-color);
  background-color: var(--phoenix-popover-header-bg);
  border-bottom: var(--phoenix-popover-border-width) solid var(--phoenix-popover-border-color);
  border-top-left-radius: var(--phoenix-popover-inner-border-radius);
  border-top-right-radius: var(--phoenix-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--phoenix-popover-body-padding-y) var(--phoenix-popover-body-padding-x);
  color: var(--phoenix-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.8s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.8s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #3874ff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--phoenix-spinner-width);
  height: var(--phoenix-spinner-height);
  vertical-align: var(--phoenix-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--phoenix-spinner-animation-speed) linear infinite var(--phoenix-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --phoenix-spinner-width: 2rem;
  --phoenix-spinner-height: 2rem;
  --phoenix-spinner-vertical-align: -0.125em;
  --phoenix-spinner-border-width: 0.25em;
  --phoenix-spinner-animation-speed: 0.75s;
  --phoenix-spinner-animation-name: spinner-border;
  border: var(--phoenix-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --phoenix-spinner-width: 1.35rem;
  --phoenix-spinner-height: 1.35rem;
  --phoenix-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --phoenix-spinner-width: 2rem;
  --phoenix-spinner-height: 2rem;
  --phoenix-spinner-vertical-align: -0.125em;
  --phoenix-spinner-animation-speed: 0.75s;
  --phoenix-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --phoenix-spinner-width: 1.35rem;
  --phoenix-spinner-height: 1.35rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --phoenix-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --phoenix-offcanvas-zindex: 1045;
  --phoenix-offcanvas-width: 458px;
  --phoenix-offcanvas-height: 30vh;
  --phoenix-offcanvas-padding-x: 1rem;
  --phoenix-offcanvas-padding-y: 1rem;
  --phoenix-offcanvas-color: ;
  --phoenix-offcanvas-bg: var(--phoenix-white);
  --phoenix-offcanvas-border-width: 1px;
  --phoenix-offcanvas-border-color: transparent;
  --phoenix-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--phoenix-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--phoenix-offcanvas-color);
    visibility: hidden;
    background-color: var(--phoenix-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--phoenix-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--phoenix-offcanvas-width);
    border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--phoenix-offcanvas-width);
    border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --phoenix-offcanvas-height: auto;
    --phoenix-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--phoenix-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--phoenix-offcanvas-color);
    visibility: hidden;
    background-color: var(--phoenix-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--phoenix-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--phoenix-offcanvas-width);
    border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--phoenix-offcanvas-width);
    border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --phoenix-offcanvas-height: auto;
    --phoenix-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--phoenix-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--phoenix-offcanvas-color);
    visibility: hidden;
    background-color: var(--phoenix-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--phoenix-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--phoenix-offcanvas-width);
    border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--phoenix-offcanvas-width);
    border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --phoenix-offcanvas-height: auto;
    --phoenix-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--phoenix-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--phoenix-offcanvas-color);
    visibility: hidden;
    background-color: var(--phoenix-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--phoenix-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--phoenix-offcanvas-width);
    border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--phoenix-offcanvas-width);
    border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --phoenix-offcanvas-height: auto;
    --phoenix-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1539.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--phoenix-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--phoenix-offcanvas-color);
    visibility: hidden;
    background-color: var(--phoenix-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--phoenix-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1539.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--phoenix-offcanvas-width);
    border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--phoenix-offcanvas-width);
    border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--phoenix-offcanvas-height);
    max-height: 100%;
    border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1539.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1540px) {
  .offcanvas-xxl {
    --phoenix-offcanvas-height: auto;
    --phoenix-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--phoenix-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--phoenix-offcanvas-color);
  visibility: hidden;
  background-color: var(--phoenix-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--phoenix-offcanvas-box-shadow);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--phoenix-offcanvas-width);
  border-right: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--phoenix-offcanvas-width);
  border-left: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--phoenix-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--phoenix-offcanvas-height);
  max-height: 100%;
  border-top: var(--phoenix-offcanvas-border-width) solid var(--phoenix-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--phoenix-offcanvas-padding-y) var(--phoenix-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--phoenix-offcanvas-padding-y) * 0.5) calc(var(--phoenix-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--phoenix-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--phoenix-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--phoenix-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.49;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--phoenix-offcanvas-padding-y) var(--phoenix-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .google-map-control-btn .placeholder.zoomIn::before,
.google-map-control-btn .placeholder.zoomOut::before, .tox .tox-dialog__footer .placeholder.tox-button:last-child::before, .tox .tox-dialog__footer .placeholder.tox-button--secondary::before, .tox .placeholder.tox-button-secondary::before, .tox .placeholder.tox-button::before, [data-list] .placeholder.page::before, .table-list .placeholder.page::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--phoenix-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --phoenix-aspect-ratio: 100%;
}

.ratio-4x3 {
  --phoenix-aspect-ratio: 75%;
}

.ratio-16x9 {
  --phoenix-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --phoenix-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1540px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(56, 116, 255, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(49, 55, 74, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(37, 176, 3, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(0, 151, 235, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(229, 120, 11, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(237, 32, 0, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(239, 242, 246, var(--phoenix-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(20, 24, 36, var(--phoenix-bg-opacity, 1)) !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-85 {
  opacity: 0.85 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0px 2px 4px -2px rgba(36, 40, 46, 0.08) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translateX(-50%) translateY(-50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-soft {
  --phoenix-border-color: rgba(var(--phoenix-soft-rgb), var(--phoenix-border-opacity));
}

.border-100 {
  --phoenix-border-color: rgba(var(--phoenix-100-rgb), var(--phoenix-border-opacity));
}

.border-200 {
  --phoenix-border-color: rgba(var(--phoenix-200-rgb), var(--phoenix-border-opacity));
}

.border-300 {
  --phoenix-border-color: rgba(var(--phoenix-300-rgb), var(--phoenix-border-opacity));
}

.border-400 {
  --phoenix-border-color: rgba(var(--phoenix-400-rgb), var(--phoenix-border-opacity));
}

.border-500 {
  --phoenix-border-color: rgba(var(--phoenix-500-rgb), var(--phoenix-border-opacity));
}

.border-600 {
  --phoenix-border-color: rgba(var(--phoenix-600-rgb), var(--phoenix-border-opacity));
}

.border-700 {
  --phoenix-border-color: rgba(var(--phoenix-700-rgb), var(--phoenix-border-opacity));
}

.border-800 {
  --phoenix-border-color: rgba(var(--phoenix-800-rgb), var(--phoenix-border-opacity));
}

.border-900 {
  --phoenix-border-color: rgba(var(--phoenix-900-rgb), var(--phoenix-border-opacity));
}

.border-1000 {
  --phoenix-border-color: rgba(var(--phoenix-1000-rgb), var(--phoenix-border-opacity));
}

.border-1100 {
  --phoenix-border-color: rgba(var(--phoenix-1100-rgb), var(--phoenix-border-opacity));
}

.border-facebook {
  --phoenix-border-color: rgba(var(--phoenix-facebook-rgb), var(--phoenix-border-opacity));
}

.border-google-plus {
  --phoenix-border-color: rgba(var(--phoenix-google-plus-rgb), var(--phoenix-border-opacity));
}

.border-twitter {
  --phoenix-border-color: rgba(var(--phoenix-twitter-rgb), var(--phoenix-border-opacity));
}

.border-linkedin {
  --phoenix-border-color: rgba(var(--phoenix-linkedin-rgb), var(--phoenix-border-opacity));
}

.border-youtube {
  --phoenix-border-color: rgba(var(--phoenix-youtube-rgb), var(--phoenix-border-opacity));
}

.border-github {
  --phoenix-border-color: rgba(var(--phoenix-github-rgb), var(--phoenix-border-opacity));
}

.border-bootstrap {
  --phoenix-border-color: rgba(var(--phoenix-bootstrap-rgb), var(--phoenix-border-opacity));
}

.border-css3 {
  --phoenix-border-color: rgba(var(--phoenix-css3-rgb), var(--phoenix-border-opacity));
}

.border-html5 {
  --phoenix-border-color: rgba(var(--phoenix-html5-rgb), var(--phoenix-border-opacity));
}

.border-sass {
  --phoenix-border-color: rgba(var(--phoenix-sass-rgb), var(--phoenix-border-opacity));
}

.border-gulp {
  --phoenix-border-color: rgba(var(--phoenix-gulp-rgb), var(--phoenix-border-opacity));
}

.border-w3c {
  --phoenix-border-color: rgba(var(--phoenix-w3c-rgb), var(--phoenix-border-opacity));
}

.border-primary {
  --phoenix-border-color: rgba(var(--phoenix-primary-rgb), var(--phoenix-border-opacity));
}

.border-secondary {
  --phoenix-border-color: rgba(var(--phoenix-secondary-rgb), var(--phoenix-border-opacity));
}

.border-success {
  --phoenix-border-color: rgba(var(--phoenix-success-rgb), var(--phoenix-border-opacity));
}

.border-info {
  --phoenix-border-color: rgba(var(--phoenix-info-rgb), var(--phoenix-border-opacity));
}

.border-warning {
  --phoenix-border-color: rgba(var(--phoenix-warning-rgb), var(--phoenix-border-opacity));
}

.border-danger {
  --phoenix-border-color: rgba(var(--phoenix-danger-rgb), var(--phoenix-border-opacity));
}

.border-light {
  --phoenix-border-color: rgba(var(--phoenix-light-rgb), var(--phoenix-border-opacity));
}

.border-dark {
  --phoenix-border-color: rgba(var(--phoenix-dark-rgb), var(--phoenix-border-opacity));
}

.border-primary-soft {
  --phoenix-border-color: rgba(var(--phoenix-primary-soft-rgb), var(--phoenix-border-opacity));
}

.border-primary-100 {
  --phoenix-border-color: rgba(var(--phoenix-primary-100-rgb), var(--phoenix-border-opacity));
}

.border-primary-200 {
  --phoenix-border-color: rgba(var(--phoenix-primary-200-rgb), var(--phoenix-border-opacity));
}

.border-primary-300 {
  --phoenix-border-color: rgba(var(--phoenix-primary-300-rgb), var(--phoenix-border-opacity));
}

.border-primary-500 {
  --phoenix-border-color: rgba(var(--phoenix-primary-500-rgb), var(--phoenix-border-opacity));
}

.border-primary-600 {
  --phoenix-border-color: rgba(var(--phoenix-primary-600-rgb), var(--phoenix-border-opacity));
}

.border-warning-soft {
  --phoenix-border-color: rgba(var(--phoenix-warning-soft-rgb), var(--phoenix-border-opacity));
}

.border-warning-100 {
  --phoenix-border-color: rgba(var(--phoenix-warning-100-rgb), var(--phoenix-border-opacity));
}

.border-warning-200 {
  --phoenix-border-color: rgba(var(--phoenix-warning-200-rgb), var(--phoenix-border-opacity));
}

.border-warning-300 {
  --phoenix-border-color: rgba(var(--phoenix-warning-300-rgb), var(--phoenix-border-opacity));
}

.border-warning-500 {
  --phoenix-border-color: rgba(var(--phoenix-warning-500-rgb), var(--phoenix-border-opacity));
}

.border-warning-600 {
  --phoenix-border-color: rgba(var(--phoenix-warning-600-rgb), var(--phoenix-border-opacity));
}

.border-danger-300 {
  --phoenix-border-color: rgba(var(--phoenix-danger-300-rgb), var(--phoenix-border-opacity));
}

.border-danger-100 {
  --phoenix-border-color: rgba(var(--phoenix-danger-100-rgb), var(--phoenix-border-opacity));
}

.border-danger-200 {
  --phoenix-border-color: rgba(var(--phoenix-danger-200-rgb), var(--phoenix-border-opacity));
}

.border-danger-500 {
  --phoenix-border-color: rgba(var(--phoenix-danger-500-rgb), var(--phoenix-border-opacity));
}

.border-danger-600 {
  --phoenix-border-color: rgba(var(--phoenix-danger-600-rgb), var(--phoenix-border-opacity));
}

.border-success-100 {
  --phoenix-border-color: rgba(var(--phoenix-success-100-rgb), var(--phoenix-border-opacity));
}

.border-success-200 {
  --phoenix-border-color: rgba(var(--phoenix-success-200-rgb), var(--phoenix-border-opacity));
}

.border-success-300 {
  --phoenix-border-color: rgba(var(--phoenix-success-300-rgb), var(--phoenix-border-opacity));
}

.border-success-500 {
  --phoenix-border-color: rgba(var(--phoenix-success-500-rgb), var(--phoenix-border-opacity));
}

.border-success-600 {
  --phoenix-border-color: rgba(var(--phoenix-success-600-rgb), var(--phoenix-border-opacity));
}

.border-info-100 {
  --phoenix-border-color: rgba(var(--phoenix-info-100-rgb), var(--phoenix-border-opacity));
}

.border-info-200 {
  --phoenix-border-color: rgba(var(--phoenix-info-200-rgb), var(--phoenix-border-opacity));
}

.border-info-300 {
  --phoenix-border-color: rgba(var(--phoenix-info-300-rgb), var(--phoenix-border-opacity));
}

.border-info-500 {
  --phoenix-border-color: rgba(var(--phoenix-info-500-rgb), var(--phoenix-border-opacity));
}

.border-info-600 {
  --phoenix-border-color: rgba(var(--phoenix-info-600-rgb), var(--phoenix-border-opacity));
}

.border-white {
  --phoenix-border-color: rgba(var(--phoenix-white-rgb), var(--phoenix-border-opacity));
}

.border-black {
  --phoenix-border-color: rgba(var(--phoenix-black-rgb), var(--phoenix-border-opacity));
}

.border-1 {
  --phoenix-border-width: 1px;
}

.border-2 {
  --phoenix-border-width: 2px;
}

.border-3 {
  --phoenix-border-width: 3px;
}

.border-4 {
  --phoenix-border-width: 4px;
}

.border-5 {
  --phoenix-border-width: 5px;
}

.border-opacity-10 {
  --phoenix-border-opacity: 0.1;
}

.border-opacity-25 {
  --phoenix-border-opacity: 0.25;
}

.border-opacity-50 {
  --phoenix-border-opacity: 0.5;
}

.border-opacity-75 {
  --phoenix-border-opacity: 0.75;
}

.border-opacity-100 {
  --phoenix-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max-content {
  width: max-content !important;
}

.w-min-content {
  width: min-content !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-25 {
  width: 25vw !important;
}

.vw-50 {
  width: 50vw !important;
}

.vw-75 {
  width: 75vw !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-max-content {
  height: max-content !important;
}

.h-min-content {
  height: min-content !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-25 {
  height: 25vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.m-12 {
  margin: 6rem !important;
}

.m-13 {
  margin: 6.5rem !important;
}

.m-14 {
  margin: 7rem !important;
}

.m-15 {
  margin: 7.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-10 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-11 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-12 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-13 {
  margin-right: 6.5rem !important;
  margin-left: 6.5rem !important;
}

.mx-14 {
  margin-right: 7rem !important;
  margin-left: 7rem !important;
}

.mx-15 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-10 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-11 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-12 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-13 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.my-14 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.my-15 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-7 {
  margin-top: 3rem !important;
}

.mt-8 {
  margin-top: 3.5rem !important;
}

.mt-9 {
  margin-top: 4rem !important;
}

.mt-10 {
  margin-top: 4.5rem !important;
}

.mt-11 {
  margin-top: 5rem !important;
}

.mt-12 {
  margin-top: 6rem !important;
}

.mt-13 {
  margin-top: 6.5rem !important;
}

.mt-14 {
  margin-top: 7rem !important;
}

.mt-15 {
  margin-top: 7.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2rem !important;
}

.me-6 {
  margin-right: 2.5rem !important;
}

.me-7 {
  margin-right: 3rem !important;
}

.me-8 {
  margin-right: 3.5rem !important;
}

.me-9 {
  margin-right: 4rem !important;
}

.me-10 {
  margin-right: 4.5rem !important;
}

.me-11 {
  margin-right: 5rem !important;
}

.me-12 {
  margin-right: 6rem !important;
}

.me-13 {
  margin-right: 6.5rem !important;
}

.me-14 {
  margin-right: 7rem !important;
}

.me-15 {
  margin-right: 7.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 2.5rem !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.mb-8 {
  margin-bottom: 3.5rem !important;
}

.mb-9 {
  margin-bottom: 4rem !important;
}

.mb-10 {
  margin-bottom: 4.5rem !important;
}

.mb-11 {
  margin-bottom: 5rem !important;
}

.mb-12 {
  margin-bottom: 6rem !important;
}

.mb-13 {
  margin-bottom: 6.5rem !important;
}

.mb-14 {
  margin-bottom: 7rem !important;
}

.mb-15 {
  margin-bottom: 7.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2rem !important;
}

.ms-6 {
  margin-left: 2.5rem !important;
}

.ms-7 {
  margin-left: 3rem !important;
}

.ms-8 {
  margin-left: 3.5rem !important;
}

.ms-9 {
  margin-left: 4rem !important;
}

.ms-10 {
  margin-left: 4.5rem !important;
}

.ms-11 {
  margin-left: 5rem !important;
}

.ms-12 {
  margin-left: 6rem !important;
}

.ms-13 {
  margin-left: 6.5rem !important;
}

.ms-14 {
  margin-left: 7rem !important;
}

.ms-15 {
  margin-left: 7.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.m-n12 {
  margin: -6rem !important;
}

.m-n13 {
  margin: -6.5rem !important;
}

.m-n14 {
  margin: -7rem !important;
}

.m-n15 {
  margin: -7.5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n10 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n11 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n12 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n13 {
  margin-right: -6.5rem !important;
  margin-left: -6.5rem !important;
}

.mx-n14 {
  margin-right: -7rem !important;
  margin-left: -7rem !important;
}

.mx-n15 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n10 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n11 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n12 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n13 {
  margin-top: -6.5rem !important;
  margin-bottom: -6.5rem !important;
}

.my-n14 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.my-n15 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -2rem !important;
}

.mt-n6 {
  margin-top: -2.5rem !important;
}

.mt-n7 {
  margin-top: -3rem !important;
}

.mt-n8 {
  margin-top: -3.5rem !important;
}

.mt-n9 {
  margin-top: -4rem !important;
}

.mt-n10 {
  margin-top: -4.5rem !important;
}

.mt-n11 {
  margin-top: -5rem !important;
}

.mt-n12 {
  margin-top: -6rem !important;
}

.mt-n13 {
  margin-top: -6.5rem !important;
}

.mt-n14 {
  margin-top: -7rem !important;
}

.mt-n15 {
  margin-top: -7.5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -2rem !important;
}

.me-n6 {
  margin-right: -2.5rem !important;
}

.me-n7 {
  margin-right: -3rem !important;
}

.me-n8 {
  margin-right: -3.5rem !important;
}

.me-n9 {
  margin-right: -4rem !important;
}

.me-n10 {
  margin-right: -4.5rem !important;
}

.me-n11 {
  margin-right: -5rem !important;
}

.me-n12 {
  margin-right: -6rem !important;
}

.me-n13 {
  margin-right: -6.5rem !important;
}

.me-n14 {
  margin-right: -7rem !important;
}

.me-n15 {
  margin-right: -7.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -2rem !important;
}

.mb-n6 {
  margin-bottom: -2.5rem !important;
}

.mb-n7 {
  margin-bottom: -3rem !important;
}

.mb-n8 {
  margin-bottom: -3.5rem !important;
}

.mb-n9 {
  margin-bottom: -4rem !important;
}

.mb-n10 {
  margin-bottom: -4.5rem !important;
}

.mb-n11 {
  margin-bottom: -5rem !important;
}

.mb-n12 {
  margin-bottom: -6rem !important;
}

.mb-n13 {
  margin-bottom: -6.5rem !important;
}

.mb-n14 {
  margin-bottom: -7rem !important;
}

.mb-n15 {
  margin-bottom: -7.5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -2rem !important;
}

.ms-n6 {
  margin-left: -2.5rem !important;
}

.ms-n7 {
  margin-left: -3rem !important;
}

.ms-n8 {
  margin-left: -3.5rem !important;
}

.ms-n9 {
  margin-left: -4rem !important;
}

.ms-n10 {
  margin-left: -4.5rem !important;
}

.ms-n11 {
  margin-left: -5rem !important;
}

.ms-n12 {
  margin-left: -6rem !important;
}

.ms-n13 {
  margin-left: -6.5rem !important;
}

.ms-n14 {
  margin-left: -7rem !important;
}

.ms-n15 {
  margin-left: -7.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.p-12 {
  padding: 6rem !important;
}

.p-13 {
  padding: 6.5rem !important;
}

.p-14 {
  padding: 7rem !important;
}

.p-15 {
  padding: 7.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-10 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-11 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-12 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-13 {
  padding-right: 6.5rem !important;
  padding-left: 6.5rem !important;
}

.px-14 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.px-15 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-10 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-11 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-12 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-13 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.py-14 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-15 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 2.5rem !important;
}

.pt-7 {
  padding-top: 3rem !important;
}

.pt-8 {
  padding-top: 3.5rem !important;
}

.pt-9 {
  padding-top: 4rem !important;
}

.pt-10 {
  padding-top: 4.5rem !important;
}

.pt-11 {
  padding-top: 5rem !important;
}

.pt-12 {
  padding-top: 6rem !important;
}

.pt-13 {
  padding-top: 6.5rem !important;
}

.pt-14 {
  padding-top: 7rem !important;
}

.pt-15 {
  padding-top: 7.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2rem !important;
}

.pe-6 {
  padding-right: 2.5rem !important;
}

.pe-7 {
  padding-right: 3rem !important;
}

.pe-8 {
  padding-right: 3.5rem !important;
}

.pe-9 {
  padding-right: 4rem !important;
}

.pe-10 {
  padding-right: 4.5rem !important;
}

.pe-11 {
  padding-right: 5rem !important;
}

.pe-12 {
  padding-right: 6rem !important;
}

.pe-13 {
  padding-right: 6.5rem !important;
}

.pe-14 {
  padding-right: 7rem !important;
}

.pe-15 {
  padding-right: 7.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 2.5rem !important;
}

.pb-7 {
  padding-bottom: 3rem !important;
}

.pb-8 {
  padding-bottom: 3.5rem !important;
}

.pb-9 {
  padding-bottom: 4rem !important;
}

.pb-10 {
  padding-bottom: 4.5rem !important;
}

.pb-11 {
  padding-bottom: 5rem !important;
}

.pb-12 {
  padding-bottom: 6rem !important;
}

.pb-13 {
  padding-bottom: 6.5rem !important;
}

.pb-14 {
  padding-bottom: 7rem !important;
}

.pb-15 {
  padding-bottom: 7.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2rem !important;
}

.ps-6 {
  padding-left: 2.5rem !important;
}

.ps-7 {
  padding-left: 3rem !important;
}

.ps-8 {
  padding-left: 3.5rem !important;
}

.ps-9 {
  padding-left: 4rem !important;
}

.ps-10 {
  padding-left: 4.5rem !important;
}

.ps-11 {
  padding-left: 5rem !important;
}

.ps-12 {
  padding-left: 6rem !important;
}

.ps-13 {
  padding-left: 6.5rem !important;
}

.ps-14 {
  padding-left: 7rem !important;
}

.ps-15 {
  padding-left: 7.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2rem !important;
}

.gap-6 {
  gap: 2.5rem !important;
}

.gap-7 {
  gap: 3rem !important;
}

.gap-8 {
  gap: 3.5rem !important;
}

.gap-9 {
  gap: 4rem !important;
}

.gap-10 {
  gap: 4.5rem !important;
}

.gap-11 {
  gap: 5rem !important;
}

.gap-12 {
  gap: 6rem !important;
}

.gap-13 {
  gap: 6.5rem !important;
}

.gap-14 {
  gap: 7rem !important;
}

.gap-15 {
  gap: 7.5rem !important;
}

.font-monospace {
  font-family: var(--phoenix-font-monospace) !important;
}

.fs--3 {
  font-size: 0.512rem !important;
}

.fs--2 {
  font-size: 0.64rem !important;
}

.fs--1 {
  font-size: 0.8rem !important;
}

.fs-0 {
  font-size: 1rem !important;
}

.fs-1 {
  font-size: 1.25rem !important;
}

.fs-2 {
  font-size: 1.5625rem !important;
}

.fs-3 {
  font-size: 1.953125rem !important;
}

.fs-4 {
  font-size: 2.44140625rem !important;
}

.fs-5 {
  font-size: 3.0517578125rem !important;
}

.fs-6 {
  font-size: 3.8146972656rem !important;
}

.fs-7 {
  font-size: 4.768371582rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-thin {
  font-weight: 100 !important;
}

.fw-lighter {
  font-weight: 200 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.2 !important;
}

.lh-base {
  line-height: 1.49 !important;
}

.lh-lg {
  line-height: 1.4 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-none {
  text-transform: none !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-soft {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-soft-rgb), var(--phoenix-text-opacity)) !important;
}

.text-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-400 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-400-rgb), var(--phoenix-text-opacity)) !important;
}

.text-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-700 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-700-rgb), var(--phoenix-text-opacity)) !important;
}

.text-800 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-800-rgb), var(--phoenix-text-opacity)) !important;
}

.text-900 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-900-rgb), var(--phoenix-text-opacity)) !important;
}

.text-1000 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-1000-rgb), var(--phoenix-text-opacity)) !important;
}

.text-1100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-1100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-facebook {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-facebook-rgb), var(--phoenix-text-opacity)) !important;
}

.text-google-plus {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-google-plus-rgb), var(--phoenix-text-opacity)) !important;
}

.text-twitter {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-twitter-rgb), var(--phoenix-text-opacity)) !important;
}

.text-linkedin {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-linkedin-rgb), var(--phoenix-text-opacity)) !important;
}

.text-youtube {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-youtube-rgb), var(--phoenix-text-opacity)) !important;
}

.text-github {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-github-rgb), var(--phoenix-text-opacity)) !important;
}

.text-bootstrap {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-bootstrap-rgb), var(--phoenix-text-opacity)) !important;
}

.text-css3 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-css3-rgb), var(--phoenix-text-opacity)) !important;
}

.text-html5 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-html5-rgb), var(--phoenix-text-opacity)) !important;
}

.text-sass {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-sass-rgb), var(--phoenix-text-opacity)) !important;
}

.text-gulp {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-gulp-rgb), var(--phoenix-text-opacity)) !important;
}

.text-w3c {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-w3c-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-rgb), var(--phoenix-text-opacity)) !important;
}

.text-secondary {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-secondary-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-rgb), var(--phoenix-text-opacity)) !important;
}

.text-light {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-light-rgb), var(--phoenix-text-opacity)) !important;
}

.text-dark {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-dark-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-soft {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-soft-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-primary-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-primary-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-soft {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-soft-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-warning-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-warning-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-danger-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-danger-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-success-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-success-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info-100 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-100-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info-200 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-200-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info-300 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-300-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info-500 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-500-rgb), var(--phoenix-text-opacity)) !important;
}

.text-info-600 {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-info-600-rgb), var(--phoenix-text-opacity)) !important;
}

.text-white {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-white-rgb), var(--phoenix-text-opacity)) !important;
}

.text-black {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-black-rgb), var(--phoenix-text-opacity)) !important;
}

.text-body {
  --phoenix-text-opacity: 1;
  color: rgba(var(--phoenix-body-color-rgb), var(--phoenix-text-opacity)) !important;
}

.text-muted {
  --phoenix-text-opacity: 1;
  color: #8a94ad !important;
}

.text-black-50 {
  --phoenix-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --phoenix-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --phoenix-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --phoenix-text-opacity: 0.25;
}

.text-opacity-50 {
  --phoenix-text-opacity: 0.5;
}

.text-opacity-75 {
  --phoenix-text-opacity: 0.75;
}

.text-opacity-100 {
  --phoenix-text-opacity: 1;
}

.bg-soft {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-soft-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-400 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-400-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-700 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-700-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-800 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-800-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-900 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-900-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-1000 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-1000-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-1100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-1100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-facebook {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-facebook-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-google-plus {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-google-plus-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-twitter {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-twitter-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-linkedin {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-linkedin-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-youtube {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-youtube-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-github {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-github-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-bootstrap {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-bootstrap-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-css3 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-css3-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-html5 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-html5-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-sass {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-sass-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-gulp {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-gulp-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-w3c {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-w3c-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-secondary {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-secondary-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-light {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-light-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-dark {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-dark-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-soft {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-soft-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-primary-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-primary-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-soft {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-soft-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-warning-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-warning-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-danger-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-danger-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-success-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-success-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info-100 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-100-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info-200 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-200-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info-300 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-300-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info-500 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-500-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-info-600 {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-info-600-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-white {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-white-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-black {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-black-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-body {
  --phoenix-bg-opacity: 1;
  background-color: rgba(var(--phoenix-body-bg-rgb), var(--phoenix-bg-opacity)) !important;
}

.bg-transparent {
  --phoenix-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --phoenix-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --phoenix-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --phoenix-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --phoenix-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --phoenix-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--phoenix-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--phoenix-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--phoenix-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--phoenix-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--phoenix-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--phoenix-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--phoenix-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--phoenix-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-end-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-bottom-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0.375rem !important;
  border-top-left-radius: 0.375rem !important;
}

.rounded-start-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.border-x {
  border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important;
}

.border-y {
  border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
}

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.bg-soft-primary {
  background-color: #f5f8ff !important;
}

.bg-soft-secondary {
  background-color: #f5f7fa !important;
}

.bg-soft-success {
  background-color: #f0fdec !important;
}

.bg-soft-info {
  background-color: #f0faff !important;
}

.bg-soft-warning {
  background-color: #fff6e0 !important;
}

.bg-soft-danger {
  background-color: #ffedeb !important;
}

.bg-light-primary {
  background-color: #e5edff !important;
}

.bg-light-secondary {
  background-color: #e3e6ed !important;
}

.bg-light-success {
  background-color: #d9fbd0 !important;
}

.bg-light-info {
  background-color: #c7ebff !important;
}

.bg-light-warning {
  background-color: #ffefca !important;
}

.bg-light-danger {
  background-color: #ffe0db !important;
}

.ls-1 {
  letter-spacing: 1 !important;
}

.ls-2 {
  letter-spacing: 0.165em !important;
}

.ls-3 {
  letter-spacing: 0.25em !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.z-index--1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.z-index-4 {
  z-index: 4 !important;
}

.z-index-5 {
  z-index: 5 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .opacity-sm-0 {
    opacity: 0 !important;
  }
  .opacity-sm-25 {
    opacity: 0.25 !important;
  }
  .opacity-sm-50 {
    opacity: 0.5 !important;
  }
  .opacity-sm-75 {
    opacity: 0.75 !important;
  }
  .opacity-sm-85 {
    opacity: 0.85 !important;
  }
  .opacity-sm-100 {
    opacity: 1 !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
  .translate-sm-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-sm-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-sm-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-sm {
    border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-max-content {
    width: max-content !important;
  }
  .w-sm-min-content {
    width: min-content !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .vw-sm-25 {
    width: 25vw !important;
  }
  .vw-sm-50 {
    width: 50vw !important;
  }
  .vw-sm-75 {
    width: 75vw !important;
  }
  .vw-sm-100 {
    width: 100vw !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-30 {
    height: 30% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-60 {
    height: 60% !important;
  }
  .h-sm-70 {
    height: 70% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-max-content {
    height: max-content !important;
  }
  .h-sm-min-content {
    height: min-content !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .vh-sm-25 {
    height: 25vh !important;
  }
  .vh-sm-50 {
    height: 50vh !important;
  }
  .vh-sm-75 {
    height: 75vh !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
  .min-vh-sm-25 {
    min-height: 25vh !important;
  }
  .min-vh-sm-50 {
    min-height: 50vh !important;
  }
  .min-vh-sm-75 {
    min-height: 75vh !important;
  }
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .m-sm-12 {
    margin: 6rem !important;
  }
  .m-sm-13 {
    margin: 6.5rem !important;
  }
  .m-sm-14 {
    margin: 7rem !important;
  }
  .m-sm-15 {
    margin: 7.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-sm-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-13 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-sm-14 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-sm-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-13 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-sm-14 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-sm-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 3rem !important;
  }
  .mt-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 4rem !important;
  }
  .mt-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-11 {
    margin-top: 5rem !important;
  }
  .mt-sm-12 {
    margin-top: 6rem !important;
  }
  .mt-sm-13 {
    margin-top: 6.5rem !important;
  }
  .mt-sm-14 {
    margin-top: 7rem !important;
  }
  .mt-sm-15 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2rem !important;
  }
  .me-sm-6 {
    margin-right: 2.5rem !important;
  }
  .me-sm-7 {
    margin-right: 3rem !important;
  }
  .me-sm-8 {
    margin-right: 3.5rem !important;
  }
  .me-sm-9 {
    margin-right: 4rem !important;
  }
  .me-sm-10 {
    margin-right: 4.5rem !important;
  }
  .me-sm-11 {
    margin-right: 5rem !important;
  }
  .me-sm-12 {
    margin-right: 6rem !important;
  }
  .me-sm-13 {
    margin-right: 6.5rem !important;
  }
  .me-sm-14 {
    margin-right: 7rem !important;
  }
  .me-sm-15 {
    margin-right: 7.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 6.5rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 3rem !important;
  }
  .ms-sm-8 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 4rem !important;
  }
  .ms-sm-10 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-11 {
    margin-left: 5rem !important;
  }
  .ms-sm-12 {
    margin-left: 6rem !important;
  }
  .ms-sm-13 {
    margin-left: 6.5rem !important;
  }
  .ms-sm-14 {
    margin-left: 7rem !important;
  }
  .ms-sm-15 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .m-sm-n12 {
    margin: -6rem !important;
  }
  .m-sm-n13 {
    margin: -6.5rem !important;
  }
  .m-sm-n14 {
    margin: -7rem !important;
  }
  .m-sm-n15 {
    margin: -7.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n13 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-sm-n14 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-sm-n15 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-sm-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n13 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-sm-n14 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-sm-n15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -2rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -3rem !important;
  }
  .mt-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -4rem !important;
  }
  .mt-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n11 {
    margin-top: -5rem !important;
  }
  .mt-sm-n12 {
    margin-top: -6rem !important;
  }
  .mt-sm-n13 {
    margin-top: -6.5rem !important;
  }
  .mt-sm-n14 {
    margin-top: -7rem !important;
  }
  .mt-sm-n15 {
    margin-top: -7.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -2rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -3rem !important;
  }
  .me-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -4rem !important;
  }
  .me-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n11 {
    margin-right: -5rem !important;
  }
  .me-sm-n12 {
    margin-right: -6rem !important;
  }
  .me-sm-n13 {
    margin-right: -6.5rem !important;
  }
  .me-sm-n14 {
    margin-right: -7rem !important;
  }
  .me-sm-n15 {
    margin-right: -7.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -6.5rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -7rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -7.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -2rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -3rem !important;
  }
  .ms-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -4rem !important;
  }
  .ms-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n11 {
    margin-left: -5rem !important;
  }
  .ms-sm-n12 {
    margin-left: -6rem !important;
  }
  .ms-sm-n13 {
    margin-left: -6.5rem !important;
  }
  .ms-sm-n14 {
    margin-left: -7rem !important;
  }
  .ms-sm-n15 {
    margin-left: -7.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .p-sm-12 {
    padding: 6rem !important;
  }
  .p-sm-13 {
    padding: 6.5rem !important;
  }
  .p-sm-14 {
    padding: 7rem !important;
  }
  .p-sm-15 {
    padding: 7.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-sm-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-13 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-sm-14 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-sm-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-13 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-sm-14 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-sm-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 3rem !important;
  }
  .pt-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 4rem !important;
  }
  .pt-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-11 {
    padding-top: 5rem !important;
  }
  .pt-sm-12 {
    padding-top: 6rem !important;
  }
  .pt-sm-13 {
    padding-top: 6.5rem !important;
  }
  .pt-sm-14 {
    padding-top: 7rem !important;
  }
  .pt-sm-15 {
    padding-top: 7.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 3rem !important;
  }
  .pe-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 4rem !important;
  }
  .pe-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-11 {
    padding-right: 5rem !important;
  }
  .pe-sm-12 {
    padding-right: 6rem !important;
  }
  .pe-sm-13 {
    padding-right: 6.5rem !important;
  }
  .pe-sm-14 {
    padding-right: 7rem !important;
  }
  .pe-sm-15 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 6.5rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 7.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 3rem !important;
  }
  .ps-sm-8 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 4rem !important;
  }
  .ps-sm-10 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-11 {
    padding-left: 5rem !important;
  }
  .ps-sm-12 {
    padding-left: 6rem !important;
  }
  .ps-sm-13 {
    padding-left: 6.5rem !important;
  }
  .ps-sm-14 {
    padding-left: 7rem !important;
  }
  .ps-sm-15 {
    padding-left: 7.5rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2rem !important;
  }
  .gap-sm-6 {
    gap: 2.5rem !important;
  }
  .gap-sm-7 {
    gap: 3rem !important;
  }
  .gap-sm-8 {
    gap: 3.5rem !important;
  }
  .gap-sm-9 {
    gap: 4rem !important;
  }
  .gap-sm-10 {
    gap: 4.5rem !important;
  }
  .gap-sm-11 {
    gap: 5rem !important;
  }
  .gap-sm-12 {
    gap: 6rem !important;
  }
  .gap-sm-13 {
    gap: 6.5rem !important;
  }
  .gap-sm-14 {
    gap: 7rem !important;
  }
  .gap-sm-15 {
    gap: 7.5rem !important;
  }
  .fs-sm--3 {
    font-size: 0.512rem !important;
  }
  .fs-sm--2 {
    font-size: 0.64rem !important;
  }
  .fs-sm--1 {
    font-size: 0.8rem !important;
  }
  .fs-sm-0 {
    font-size: 1rem !important;
  }
  .fs-sm-1 {
    font-size: 1.25rem !important;
  }
  .fs-sm-2 {
    font-size: 1.5625rem !important;
  }
  .fs-sm-3 {
    font-size: 1.953125rem !important;
  }
  .fs-sm-4 {
    font-size: 2.44140625rem !important;
  }
  .fs-sm-5 {
    font-size: 3.0517578125rem !important;
  }
  .fs-sm-6 {
    font-size: 3.8146972656rem !important;
  }
  .fs-sm-7 {
    font-size: 4.768371582rem !important;
  }
  .lh-sm-1 {
    line-height: 1 !important;
  }
  .lh-sm-sm {
    line-height: 1.2 !important;
  }
  .lh-sm-base {
    line-height: 1.49 !important;
  }
  .lh-sm-lg {
    line-height: 1.4 !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .rounded-sm-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-sm-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-sm-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-sm-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-sm-end-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-sm-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-sm-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-sm-bottom-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-sm-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-sm-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-sm-start-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-sm-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .max-vh-sm-25 {
    max-height: 25vh !important;
  }
  .max-vh-sm-50 {
    max-height: 50vh !important;
  }
  .max-vh-sm-75 {
    max-height: 75vh !important;
  }
  .max-vh-sm-100 {
    max-height: 100vh !important;
  }
  .border-x-sm {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-x-sm-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-y-sm {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-y-sm-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .rounded-sm-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .opacity-md-0 {
    opacity: 0 !important;
  }
  .opacity-md-25 {
    opacity: 0.25 !important;
  }
  .opacity-md-50 {
    opacity: 0.5 !important;
  }
  .opacity-md-75 {
    opacity: 0.75 !important;
  }
  .opacity-md-85 {
    opacity: 0.85 !important;
  }
  .opacity-md-100 {
    opacity: 1 !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
  .translate-md-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-md-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-md-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-md {
    border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-max-content {
    width: max-content !important;
  }
  .w-md-min-content {
    width: min-content !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .vw-md-25 {
    width: 25vw !important;
  }
  .vw-md-50 {
    width: 50vw !important;
  }
  .vw-md-75 {
    width: 75vw !important;
  }
  .vw-md-100 {
    width: 100vw !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-30 {
    height: 30% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-60 {
    height: 60% !important;
  }
  .h-md-70 {
    height: 70% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-max-content {
    height: max-content !important;
  }
  .h-md-min-content {
    height: min-content !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .vh-md-25 {
    height: 25vh !important;
  }
  .vh-md-50 {
    height: 50vh !important;
  }
  .vh-md-75 {
    height: 75vh !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
  .min-vh-md-25 {
    min-height: 25vh !important;
  }
  .min-vh-md-50 {
    min-height: 50vh !important;
  }
  .min-vh-md-75 {
    min-height: 75vh !important;
  }
  .min-vh-md-100 {
    min-height: 100vh !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .m-md-12 {
    margin: 6rem !important;
  }
  .m-md-13 {
    margin: 6.5rem !important;
  }
  .m-md-14 {
    margin: 7rem !important;
  }
  .m-md-15 {
    margin: 7.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-md-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-13 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-md-14 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-md-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-13 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-md-14 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-md-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2rem !important;
  }
  .mt-md-6 {
    margin-top: 2.5rem !important;
  }
  .mt-md-7 {
    margin-top: 3rem !important;
  }
  .mt-md-8 {
    margin-top: 3.5rem !important;
  }
  .mt-md-9 {
    margin-top: 4rem !important;
  }
  .mt-md-10 {
    margin-top: 4.5rem !important;
  }
  .mt-md-11 {
    margin-top: 5rem !important;
  }
  .mt-md-12 {
    margin-top: 6rem !important;
  }
  .mt-md-13 {
    margin-top: 6.5rem !important;
  }
  .mt-md-14 {
    margin-top: 7rem !important;
  }
  .mt-md-15 {
    margin-top: 7.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2rem !important;
  }
  .me-md-6 {
    margin-right: 2.5rem !important;
  }
  .me-md-7 {
    margin-right: 3rem !important;
  }
  .me-md-8 {
    margin-right: 3.5rem !important;
  }
  .me-md-9 {
    margin-right: 4rem !important;
  }
  .me-md-10 {
    margin-right: 4.5rem !important;
  }
  .me-md-11 {
    margin-right: 5rem !important;
  }
  .me-md-12 {
    margin-right: 6rem !important;
  }
  .me-md-13 {
    margin-right: 6.5rem !important;
  }
  .me-md-14 {
    margin-right: 7rem !important;
  }
  .me-md-15 {
    margin-right: 7.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3rem !important;
  }
  .mb-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4rem !important;
  }
  .mb-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-11 {
    margin-bottom: 5rem !important;
  }
  .mb-md-12 {
    margin-bottom: 6rem !important;
  }
  .mb-md-13 {
    margin-bottom: 6.5rem !important;
  }
  .mb-md-14 {
    margin-bottom: 7rem !important;
  }
  .mb-md-15 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2rem !important;
  }
  .ms-md-6 {
    margin-left: 2.5rem !important;
  }
  .ms-md-7 {
    margin-left: 3rem !important;
  }
  .ms-md-8 {
    margin-left: 3.5rem !important;
  }
  .ms-md-9 {
    margin-left: 4rem !important;
  }
  .ms-md-10 {
    margin-left: 4.5rem !important;
  }
  .ms-md-11 {
    margin-left: 5rem !important;
  }
  .ms-md-12 {
    margin-left: 6rem !important;
  }
  .ms-md-13 {
    margin-left: 6.5rem !important;
  }
  .ms-md-14 {
    margin-left: 7rem !important;
  }
  .ms-md-15 {
    margin-left: 7.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .m-md-n12 {
    margin: -6rem !important;
  }
  .m-md-n13 {
    margin: -6.5rem !important;
  }
  .m-md-n14 {
    margin: -7rem !important;
  }
  .m-md-n15 {
    margin: -7.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-md-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n13 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-md-n14 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-md-n15 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-md-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n13 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-md-n14 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-md-n15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -2rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -3rem !important;
  }
  .mt-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -4rem !important;
  }
  .mt-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n11 {
    margin-top: -5rem !important;
  }
  .mt-md-n12 {
    margin-top: -6rem !important;
  }
  .mt-md-n13 {
    margin-top: -6.5rem !important;
  }
  .mt-md-n14 {
    margin-top: -7rem !important;
  }
  .mt-md-n15 {
    margin-top: -7.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -2rem !important;
  }
  .me-md-n6 {
    margin-right: -2.5rem !important;
  }
  .me-md-n7 {
    margin-right: -3rem !important;
  }
  .me-md-n8 {
    margin-right: -3.5rem !important;
  }
  .me-md-n9 {
    margin-right: -4rem !important;
  }
  .me-md-n10 {
    margin-right: -4.5rem !important;
  }
  .me-md-n11 {
    margin-right: -5rem !important;
  }
  .me-md-n12 {
    margin-right: -6rem !important;
  }
  .me-md-n13 {
    margin-right: -6.5rem !important;
  }
  .me-md-n14 {
    margin-right: -7rem !important;
  }
  .me-md-n15 {
    margin-right: -7.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -6.5rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -7rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -7.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -2rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -3rem !important;
  }
  .ms-md-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -4rem !important;
  }
  .ms-md-n10 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n11 {
    margin-left: -5rem !important;
  }
  .ms-md-n12 {
    margin-left: -6rem !important;
  }
  .ms-md-n13 {
    margin-left: -6.5rem !important;
  }
  .ms-md-n14 {
    margin-left: -7rem !important;
  }
  .ms-md-n15 {
    margin-left: -7.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .p-md-12 {
    padding: 6rem !important;
  }
  .p-md-13 {
    padding: 6.5rem !important;
  }
  .p-md-14 {
    padding: 7rem !important;
  }
  .p-md-15 {
    padding: 7.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-md-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-13 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-md-14 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-md-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-13 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-md-14 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-md-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2rem !important;
  }
  .pt-md-6 {
    padding-top: 2.5rem !important;
  }
  .pt-md-7 {
    padding-top: 3rem !important;
  }
  .pt-md-8 {
    padding-top: 3.5rem !important;
  }
  .pt-md-9 {
    padding-top: 4rem !important;
  }
  .pt-md-10 {
    padding-top: 4.5rem !important;
  }
  .pt-md-11 {
    padding-top: 5rem !important;
  }
  .pt-md-12 {
    padding-top: 6rem !important;
  }
  .pt-md-13 {
    padding-top: 6.5rem !important;
  }
  .pt-md-14 {
    padding-top: 7rem !important;
  }
  .pt-md-15 {
    padding-top: 7.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2rem !important;
  }
  .pe-md-6 {
    padding-right: 2.5rem !important;
  }
  .pe-md-7 {
    padding-right: 3rem !important;
  }
  .pe-md-8 {
    padding-right: 3.5rem !important;
  }
  .pe-md-9 {
    padding-right: 4rem !important;
  }
  .pe-md-10 {
    padding-right: 4.5rem !important;
  }
  .pe-md-11 {
    padding-right: 5rem !important;
  }
  .pe-md-12 {
    padding-right: 6rem !important;
  }
  .pe-md-13 {
    padding-right: 6.5rem !important;
  }
  .pe-md-14 {
    padding-right: 7rem !important;
  }
  .pe-md-15 {
    padding-right: 7.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3rem !important;
  }
  .pb-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4rem !important;
  }
  .pb-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-11 {
    padding-bottom: 5rem !important;
  }
  .pb-md-12 {
    padding-bottom: 6rem !important;
  }
  .pb-md-13 {
    padding-bottom: 6.5rem !important;
  }
  .pb-md-14 {
    padding-bottom: 7rem !important;
  }
  .pb-md-15 {
    padding-bottom: 7.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2rem !important;
  }
  .ps-md-6 {
    padding-left: 2.5rem !important;
  }
  .ps-md-7 {
    padding-left: 3rem !important;
  }
  .ps-md-8 {
    padding-left: 3.5rem !important;
  }
  .ps-md-9 {
    padding-left: 4rem !important;
  }
  .ps-md-10 {
    padding-left: 4.5rem !important;
  }
  .ps-md-11 {
    padding-left: 5rem !important;
  }
  .ps-md-12 {
    padding-left: 6rem !important;
  }
  .ps-md-13 {
    padding-left: 6.5rem !important;
  }
  .ps-md-14 {
    padding-left: 7rem !important;
  }
  .ps-md-15 {
    padding-left: 7.5rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2rem !important;
  }
  .gap-md-6 {
    gap: 2.5rem !important;
  }
  .gap-md-7 {
    gap: 3rem !important;
  }
  .gap-md-8 {
    gap: 3.5rem !important;
  }
  .gap-md-9 {
    gap: 4rem !important;
  }
  .gap-md-10 {
    gap: 4.5rem !important;
  }
  .gap-md-11 {
    gap: 5rem !important;
  }
  .gap-md-12 {
    gap: 6rem !important;
  }
  .gap-md-13 {
    gap: 6.5rem !important;
  }
  .gap-md-14 {
    gap: 7rem !important;
  }
  .gap-md-15 {
    gap: 7.5rem !important;
  }
  .fs-md--3 {
    font-size: 0.512rem !important;
  }
  .fs-md--2 {
    font-size: 0.64rem !important;
  }
  .fs-md--1 {
    font-size: 0.8rem !important;
  }
  .fs-md-0 {
    font-size: 1rem !important;
  }
  .fs-md-1 {
    font-size: 1.25rem !important;
  }
  .fs-md-2 {
    font-size: 1.5625rem !important;
  }
  .fs-md-3 {
    font-size: 1.953125rem !important;
  }
  .fs-md-4 {
    font-size: 2.44140625rem !important;
  }
  .fs-md-5 {
    font-size: 3.0517578125rem !important;
  }
  .fs-md-6 {
    font-size: 3.8146972656rem !important;
  }
  .fs-md-7 {
    font-size: 4.768371582rem !important;
  }
  .lh-md-1 {
    line-height: 1 !important;
  }
  .lh-md-sm {
    line-height: 1.2 !important;
  }
  .lh-md-base {
    line-height: 1.49 !important;
  }
  .lh-md-lg {
    line-height: 1.4 !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .rounded-md-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-md-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-md-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-md-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-md-end-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-md-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-md-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-md-bottom-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-md-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-md-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-md-start-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-md-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .max-vh-md-25 {
    max-height: 25vh !important;
  }
  .max-vh-md-50 {
    max-height: 50vh !important;
  }
  .max-vh-md-75 {
    max-height: 75vh !important;
  }
  .max-vh-md-100 {
    max-height: 100vh !important;
  }
  .border-x-md {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-x-md-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-y-md {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-y-md-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .rounded-md-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .opacity-lg-0 {
    opacity: 0 !important;
  }
  .opacity-lg-25 {
    opacity: 0.25 !important;
  }
  .opacity-lg-50 {
    opacity: 0.5 !important;
  }
  .opacity-lg-75 {
    opacity: 0.75 !important;
  }
  .opacity-lg-85 {
    opacity: 0.85 !important;
  }
  .opacity-lg-100 {
    opacity: 1 !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .translate-lg-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-lg-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-lg-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-lg {
    border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-max-content {
    width: max-content !important;
  }
  .w-lg-min-content {
    width: min-content !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .vw-lg-25 {
    width: 25vw !important;
  }
  .vw-lg-50 {
    width: 50vw !important;
  }
  .vw-lg-75 {
    width: 75vw !important;
  }
  .vw-lg-100 {
    width: 100vw !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-30 {
    height: 30% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-60 {
    height: 60% !important;
  }
  .h-lg-70 {
    height: 70% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-max-content {
    height: max-content !important;
  }
  .h-lg-min-content {
    height: min-content !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .vh-lg-25 {
    height: 25vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-75 {
    height: 75vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .min-vh-lg-25 {
    min-height: 25vh !important;
  }
  .min-vh-lg-50 {
    min-height: 50vh !important;
  }
  .min-vh-lg-75 {
    min-height: 75vh !important;
  }
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .m-lg-12 {
    margin: 6rem !important;
  }
  .m-lg-13 {
    margin: 6.5rem !important;
  }
  .m-lg-14 {
    margin: 7rem !important;
  }
  .m-lg-15 {
    margin: 7.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-lg-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-13 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-lg-14 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-lg-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-13 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-lg-14 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-lg-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 3rem !important;
  }
  .mt-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 4rem !important;
  }
  .mt-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-11 {
    margin-top: 5rem !important;
  }
  .mt-lg-12 {
    margin-top: 6rem !important;
  }
  .mt-lg-13 {
    margin-top: 6.5rem !important;
  }
  .mt-lg-14 {
    margin-top: 7rem !important;
  }
  .mt-lg-15 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2rem !important;
  }
  .me-lg-6 {
    margin-right: 2.5rem !important;
  }
  .me-lg-7 {
    margin-right: 3rem !important;
  }
  .me-lg-8 {
    margin-right: 3.5rem !important;
  }
  .me-lg-9 {
    margin-right: 4rem !important;
  }
  .me-lg-10 {
    margin-right: 4.5rem !important;
  }
  .me-lg-11 {
    margin-right: 5rem !important;
  }
  .me-lg-12 {
    margin-right: 6rem !important;
  }
  .me-lg-13 {
    margin-right: 6.5rem !important;
  }
  .me-lg-14 {
    margin-right: 7rem !important;
  }
  .me-lg-15 {
    margin-right: 7.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 6.5rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 3rem !important;
  }
  .ms-lg-8 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 4rem !important;
  }
  .ms-lg-10 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-11 {
    margin-left: 5rem !important;
  }
  .ms-lg-12 {
    margin-left: 6rem !important;
  }
  .ms-lg-13 {
    margin-left: 6.5rem !important;
  }
  .ms-lg-14 {
    margin-left: 7rem !important;
  }
  .ms-lg-15 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .m-lg-n12 {
    margin: -6rem !important;
  }
  .m-lg-n13 {
    margin: -6.5rem !important;
  }
  .m-lg-n14 {
    margin: -7rem !important;
  }
  .m-lg-n15 {
    margin: -7.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n13 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-lg-n14 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-lg-n15 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-lg-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n13 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-lg-n14 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-lg-n15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -2rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -3rem !important;
  }
  .mt-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -4rem !important;
  }
  .mt-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n11 {
    margin-top: -5rem !important;
  }
  .mt-lg-n12 {
    margin-top: -6rem !important;
  }
  .mt-lg-n13 {
    margin-top: -6.5rem !important;
  }
  .mt-lg-n14 {
    margin-top: -7rem !important;
  }
  .mt-lg-n15 {
    margin-top: -7.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -2rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -3rem !important;
  }
  .me-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -4rem !important;
  }
  .me-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n11 {
    margin-right: -5rem !important;
  }
  .me-lg-n12 {
    margin-right: -6rem !important;
  }
  .me-lg-n13 {
    margin-right: -6.5rem !important;
  }
  .me-lg-n14 {
    margin-right: -7rem !important;
  }
  .me-lg-n15 {
    margin-right: -7.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -6.5rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -7rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -7.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -2rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -3rem !important;
  }
  .ms-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -4rem !important;
  }
  .ms-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n11 {
    margin-left: -5rem !important;
  }
  .ms-lg-n12 {
    margin-left: -6rem !important;
  }
  .ms-lg-n13 {
    margin-left: -6.5rem !important;
  }
  .ms-lg-n14 {
    margin-left: -7rem !important;
  }
  .ms-lg-n15 {
    margin-left: -7.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .p-lg-12 {
    padding: 6rem !important;
  }
  .p-lg-13 {
    padding: 6.5rem !important;
  }
  .p-lg-14 {
    padding: 7rem !important;
  }
  .p-lg-15 {
    padding: 7.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-lg-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-13 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-lg-14 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-lg-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-13 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-lg-14 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-lg-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 3rem !important;
  }
  .pt-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 4rem !important;
  }
  .pt-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-11 {
    padding-top: 5rem !important;
  }
  .pt-lg-12 {
    padding-top: 6rem !important;
  }
  .pt-lg-13 {
    padding-top: 6.5rem !important;
  }
  .pt-lg-14 {
    padding-top: 7rem !important;
  }
  .pt-lg-15 {
    padding-top: 7.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 3rem !important;
  }
  .pe-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 4rem !important;
  }
  .pe-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-11 {
    padding-right: 5rem !important;
  }
  .pe-lg-12 {
    padding-right: 6rem !important;
  }
  .pe-lg-13 {
    padding-right: 6.5rem !important;
  }
  .pe-lg-14 {
    padding-right: 7rem !important;
  }
  .pe-lg-15 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 6.5rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 7.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 3rem !important;
  }
  .ps-lg-8 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 4rem !important;
  }
  .ps-lg-10 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-11 {
    padding-left: 5rem !important;
  }
  .ps-lg-12 {
    padding-left: 6rem !important;
  }
  .ps-lg-13 {
    padding-left: 6.5rem !important;
  }
  .ps-lg-14 {
    padding-left: 7rem !important;
  }
  .ps-lg-15 {
    padding-left: 7.5rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2rem !important;
  }
  .gap-lg-6 {
    gap: 2.5rem !important;
  }
  .gap-lg-7 {
    gap: 3rem !important;
  }
  .gap-lg-8 {
    gap: 3.5rem !important;
  }
  .gap-lg-9 {
    gap: 4rem !important;
  }
  .gap-lg-10 {
    gap: 4.5rem !important;
  }
  .gap-lg-11 {
    gap: 5rem !important;
  }
  .gap-lg-12 {
    gap: 6rem !important;
  }
  .gap-lg-13 {
    gap: 6.5rem !important;
  }
  .gap-lg-14 {
    gap: 7rem !important;
  }
  .gap-lg-15 {
    gap: 7.5rem !important;
  }
  .fs-lg--3 {
    font-size: 0.512rem !important;
  }
  .fs-lg--2 {
    font-size: 0.64rem !important;
  }
  .fs-lg--1 {
    font-size: 0.8rem !important;
  }
  .fs-lg-0 {
    font-size: 1rem !important;
  }
  .fs-lg-1 {
    font-size: 1.25rem !important;
  }
  .fs-lg-2 {
    font-size: 1.5625rem !important;
  }
  .fs-lg-3 {
    font-size: 1.953125rem !important;
  }
  .fs-lg-4 {
    font-size: 2.44140625rem !important;
  }
  .fs-lg-5 {
    font-size: 3.0517578125rem !important;
  }
  .fs-lg-6 {
    font-size: 3.8146972656rem !important;
  }
  .fs-lg-7 {
    font-size: 4.768371582rem !important;
  }
  .lh-lg-1 {
    line-height: 1 !important;
  }
  .lh-lg-sm {
    line-height: 1.2 !important;
  }
  .lh-lg-base {
    line-height: 1.49 !important;
  }
  .lh-lg-lg {
    line-height: 1.4 !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .rounded-lg-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-lg-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-lg-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-lg-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-lg-end-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-lg-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-lg-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-lg-bottom-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-lg-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-lg-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-lg-start-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-lg-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .max-vh-lg-25 {
    max-height: 25vh !important;
  }
  .max-vh-lg-50 {
    max-height: 50vh !important;
  }
  .max-vh-lg-75 {
    max-height: 75vh !important;
  }
  .max-vh-lg-100 {
    max-height: 100vh !important;
  }
  .border-x-lg {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-x-lg-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-y-lg {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-y-lg-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .rounded-lg-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .opacity-xl-0 {
    opacity: 0 !important;
  }
  .opacity-xl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xl-85 {
    opacity: 0.85 !important;
  }
  .opacity-xl-100 {
    opacity: 1 !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .translate-xl-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-xl-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-xl-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-xl {
    border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-max-content {
    width: max-content !important;
  }
  .w-xl-min-content {
    width: min-content !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .vw-xl-25 {
    width: 25vw !important;
  }
  .vw-xl-50 {
    width: 50vw !important;
  }
  .vw-xl-75 {
    width: 75vw !important;
  }
  .vw-xl-100 {
    width: 100vw !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-30 {
    height: 30% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-60 {
    height: 60% !important;
  }
  .h-xl-70 {
    height: 70% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-max-content {
    height: max-content !important;
  }
  .h-xl-min-content {
    height: min-content !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .vh-xl-25 {
    height: 25vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-75 {
    height: 75vh !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .min-vh-xl-25 {
    min-height: 25vh !important;
  }
  .min-vh-xl-50 {
    min-height: 50vh !important;
  }
  .min-vh-xl-75 {
    min-height: 75vh !important;
  }
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .m-xl-12 {
    margin: 6rem !important;
  }
  .m-xl-13 {
    margin: 6.5rem !important;
  }
  .m-xl-14 {
    margin: 7rem !important;
  }
  .m-xl-15 {
    margin: 7.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-13 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xl-14 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xl-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-13 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xl-14 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xl-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 3rem !important;
  }
  .mt-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 4rem !important;
  }
  .mt-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-11 {
    margin-top: 5rem !important;
  }
  .mt-xl-12 {
    margin-top: 6rem !important;
  }
  .mt-xl-13 {
    margin-top: 6.5rem !important;
  }
  .mt-xl-14 {
    margin-top: 7rem !important;
  }
  .mt-xl-15 {
    margin-top: 7.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2rem !important;
  }
  .me-xl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xl-7 {
    margin-right: 3rem !important;
  }
  .me-xl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xl-9 {
    margin-right: 4rem !important;
  }
  .me-xl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xl-11 {
    margin-right: 5rem !important;
  }
  .me-xl-12 {
    margin-right: 6rem !important;
  }
  .me-xl-13 {
    margin-right: 6.5rem !important;
  }
  .me-xl-14 {
    margin-right: 7rem !important;
  }
  .me-xl-15 {
    margin-right: 7.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 3rem !important;
  }
  .ms-xl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 4rem !important;
  }
  .ms-xl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-11 {
    margin-left: 5rem !important;
  }
  .ms-xl-12 {
    margin-left: 6rem !important;
  }
  .ms-xl-13 {
    margin-left: 6.5rem !important;
  }
  .ms-xl-14 {
    margin-left: 7rem !important;
  }
  .ms-xl-15 {
    margin-left: 7.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .m-xl-n12 {
    margin: -6rem !important;
  }
  .m-xl-n13 {
    margin: -6.5rem !important;
  }
  .m-xl-n14 {
    margin: -7rem !important;
  }
  .m-xl-n15 {
    margin: -7.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xl-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n13 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-xl-n14 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xl-n15 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xl-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n13 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-xl-n14 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xl-n15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n11 {
    margin-top: -5rem !important;
  }
  .mt-xl-n12 {
    margin-top: -6rem !important;
  }
  .mt-xl-n13 {
    margin-top: -6.5rem !important;
  }
  .mt-xl-n14 {
    margin-top: -7rem !important;
  }
  .mt-xl-n15 {
    margin-top: -7.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -2rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -3rem !important;
  }
  .me-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -4rem !important;
  }
  .me-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n11 {
    margin-right: -5rem !important;
  }
  .me-xl-n12 {
    margin-right: -6rem !important;
  }
  .me-xl-n13 {
    margin-right: -6.5rem !important;
  }
  .me-xl-n14 {
    margin-right: -7rem !important;
  }
  .me-xl-n15 {
    margin-right: -7.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -6.5rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -7rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -7.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n11 {
    margin-left: -5rem !important;
  }
  .ms-xl-n12 {
    margin-left: -6rem !important;
  }
  .ms-xl-n13 {
    margin-left: -6.5rem !important;
  }
  .ms-xl-n14 {
    margin-left: -7rem !important;
  }
  .ms-xl-n15 {
    margin-left: -7.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .p-xl-12 {
    padding: 6rem !important;
  }
  .p-xl-13 {
    padding: 6.5rem !important;
  }
  .p-xl-14 {
    padding: 7rem !important;
  }
  .p-xl-15 {
    padding: 7.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-13 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xl-14 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xl-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-13 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xl-14 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xl-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 3rem !important;
  }
  .pt-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 4rem !important;
  }
  .pt-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-11 {
    padding-top: 5rem !important;
  }
  .pt-xl-12 {
    padding-top: 6rem !important;
  }
  .pt-xl-13 {
    padding-top: 6.5rem !important;
  }
  .pt-xl-14 {
    padding-top: 7rem !important;
  }
  .pt-xl-15 {
    padding-top: 7.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 3rem !important;
  }
  .pe-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 4rem !important;
  }
  .pe-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-11 {
    padding-right: 5rem !important;
  }
  .pe-xl-12 {
    padding-right: 6rem !important;
  }
  .pe-xl-13 {
    padding-right: 6.5rem !important;
  }
  .pe-xl-14 {
    padding-right: 7rem !important;
  }
  .pe-xl-15 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 7.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 3rem !important;
  }
  .ps-xl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 4rem !important;
  }
  .ps-xl-10 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-11 {
    padding-left: 5rem !important;
  }
  .ps-xl-12 {
    padding-left: 6rem !important;
  }
  .ps-xl-13 {
    padding-left: 6.5rem !important;
  }
  .ps-xl-14 {
    padding-left: 7rem !important;
  }
  .ps-xl-15 {
    padding-left: 7.5rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2rem !important;
  }
  .gap-xl-6 {
    gap: 2.5rem !important;
  }
  .gap-xl-7 {
    gap: 3rem !important;
  }
  .gap-xl-8 {
    gap: 3.5rem !important;
  }
  .gap-xl-9 {
    gap: 4rem !important;
  }
  .gap-xl-10 {
    gap: 4.5rem !important;
  }
  .gap-xl-11 {
    gap: 5rem !important;
  }
  .gap-xl-12 {
    gap: 6rem !important;
  }
  .gap-xl-13 {
    gap: 6.5rem !important;
  }
  .gap-xl-14 {
    gap: 7rem !important;
  }
  .gap-xl-15 {
    gap: 7.5rem !important;
  }
  .fs-xl--3 {
    font-size: 0.512rem !important;
  }
  .fs-xl--2 {
    font-size: 0.64rem !important;
  }
  .fs-xl--1 {
    font-size: 0.8rem !important;
  }
  .fs-xl-0 {
    font-size: 1rem !important;
  }
  .fs-xl-1 {
    font-size: 1.25rem !important;
  }
  .fs-xl-2 {
    font-size: 1.5625rem !important;
  }
  .fs-xl-3 {
    font-size: 1.953125rem !important;
  }
  .fs-xl-4 {
    font-size: 2.44140625rem !important;
  }
  .fs-xl-5 {
    font-size: 3.0517578125rem !important;
  }
  .fs-xl-6 {
    font-size: 3.8146972656rem !important;
  }
  .fs-xl-7 {
    font-size: 4.768371582rem !important;
  }
  .lh-xl-1 {
    line-height: 1 !important;
  }
  .lh-xl-sm {
    line-height: 1.2 !important;
  }
  .lh-xl-base {
    line-height: 1.49 !important;
  }
  .lh-xl-lg {
    line-height: 1.4 !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .rounded-xl-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-xl-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-xl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xl-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-xl-end-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-xl-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-xl-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-xl-bottom-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-xl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xl-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-xl-start-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-xl-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .max-vh-xl-25 {
    max-height: 25vh !important;
  }
  .max-vh-xl-50 {
    max-height: 50vh !important;
  }
  .max-vh-xl-75 {
    max-height: 75vh !important;
  }
  .max-vh-xl-100 {
    max-height: 100vh !important;
  }
  .border-x-xl {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-x-xl-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-y-xl {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-y-xl-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .rounded-xl-0 {
    border-radius: 0 !important;
  }
}
@media (min-width: 1540px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .opacity-xxl-0 {
    opacity: 0 !important;
  }
  .opacity-xxl-25 {
    opacity: 0.25 !important;
  }
  .opacity-xxl-50 {
    opacity: 0.5 !important;
  }
  .opacity-xxl-75 {
    opacity: 0.75 !important;
  }
  .opacity-xxl-85 {
    opacity: 0.85 !important;
  }
  .opacity-xxl-100 {
    opacity: 1 !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .translate-xxl-middle {
    transform: translateX(-50%) translateY(-50%) !important;
  }
  .translate-xxl-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-xxl-middle-y {
    transform: translateY(-50%) !important;
  }
  .border-xxl {
    border: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-max-content {
    width: max-content !important;
  }
  .w-xxl-min-content {
    width: min-content !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .vw-xxl-25 {
    width: 25vw !important;
  }
  .vw-xxl-50 {
    width: 50vw !important;
  }
  .vw-xxl-75 {
    width: 75vw !important;
  }
  .vw-xxl-100 {
    width: 100vw !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-30 {
    height: 30% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-60 {
    height: 60% !important;
  }
  .h-xxl-70 {
    height: 70% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-max-content {
    height: max-content !important;
  }
  .h-xxl-min-content {
    height: min-content !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .vh-xxl-25 {
    height: 25vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-75 {
    height: 75vh !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .min-vh-xxl-25 {
    min-height: 25vh !important;
  }
  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }
  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2rem !important;
  }
  .m-xxl-6 {
    margin: 2.5rem !important;
  }
  .m-xxl-7 {
    margin: 3rem !important;
  }
  .m-xxl-8 {
    margin: 3.5rem !important;
  }
  .m-xxl-9 {
    margin: 4rem !important;
  }
  .m-xxl-10 {
    margin: 4.5rem !important;
  }
  .m-xxl-11 {
    margin: 5rem !important;
  }
  .m-xxl-12 {
    margin: 6rem !important;
  }
  .m-xxl-13 {
    margin: 6.5rem !important;
  }
  .m-xxl-14 {
    margin: 7rem !important;
  }
  .m-xxl-15 {
    margin: 7.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .mx-xxl-10 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-11 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-12 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-13 {
    margin-right: 6.5rem !important;
    margin-left: 6.5rem !important;
  }
  .mx-xxl-14 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .mx-xxl-15 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-10 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-11 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-12 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-13 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }
  .my-xxl-14 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .my-xxl-15 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2rem !important;
  }
  .mt-xxl-6 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3rem !important;
  }
  .mt-xxl-8 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4rem !important;
  }
  .mt-xxl-10 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-11 {
    margin-top: 5rem !important;
  }
  .mt-xxl-12 {
    margin-top: 6rem !important;
  }
  .mt-xxl-13 {
    margin-top: 6.5rem !important;
  }
  .mt-xxl-14 {
    margin-top: 7rem !important;
  }
  .mt-xxl-15 {
    margin-top: 7.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 2rem !important;
  }
  .me-xxl-6 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 3rem !important;
  }
  .me-xxl-8 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 4rem !important;
  }
  .me-xxl-10 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-11 {
    margin-right: 5rem !important;
  }
  .me-xxl-12 {
    margin-right: 6rem !important;
  }
  .me-xxl-13 {
    margin-right: 6.5rem !important;
  }
  .me-xxl-14 {
    margin-right: 7rem !important;
  }
  .me-xxl-15 {
    margin-right: 7.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 6.5rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 7.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 2rem !important;
  }
  .ms-xxl-6 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 3rem !important;
  }
  .ms-xxl-8 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 4rem !important;
  }
  .ms-xxl-10 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-11 {
    margin-left: 5rem !important;
  }
  .ms-xxl-12 {
    margin-left: 6rem !important;
  }
  .ms-xxl-13 {
    margin-left: 6.5rem !important;
  }
  .ms-xxl-14 {
    margin-left: 7rem !important;
  }
  .ms-xxl-15 {
    margin-left: 7.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2rem !important;
  }
  .m-xxl-n6 {
    margin: -2.5rem !important;
  }
  .m-xxl-n7 {
    margin: -3rem !important;
  }
  .m-xxl-n8 {
    margin: -3.5rem !important;
  }
  .m-xxl-n9 {
    margin: -4rem !important;
  }
  .m-xxl-n10 {
    margin: -4.5rem !important;
  }
  .m-xxl-n11 {
    margin: -5rem !important;
  }
  .m-xxl-n12 {
    margin: -6rem !important;
  }
  .m-xxl-n13 {
    margin: -6.5rem !important;
  }
  .m-xxl-n14 {
    margin: -7rem !important;
  }
  .m-xxl-n15 {
    margin: -7.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-xxl-n12 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n13 {
    margin-right: -6.5rem !important;
    margin-left: -6.5rem !important;
  }
  .mx-xxl-n14 {
    margin-right: -7rem !important;
    margin-left: -7rem !important;
  }
  .mx-xxl-n15 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }
  .my-xxl-n10 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n11 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-xxl-n12 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n13 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }
  .my-xxl-n14 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }
  .my-xxl-n15 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -6.5rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -7rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -7.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -2rem !important;
  }
  .me-xxl-n6 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -3rem !important;
  }
  .me-xxl-n8 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -4rem !important;
  }
  .me-xxl-n10 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n11 {
    margin-right: -5rem !important;
  }
  .me-xxl-n12 {
    margin-right: -6rem !important;
  }
  .me-xxl-n13 {
    margin-right: -6.5rem !important;
  }
  .me-xxl-n14 {
    margin-right: -7rem !important;
  }
  .me-xxl-n15 {
    margin-right: -7.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -6.5rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -7rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -7.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -6.5rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -7rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -7.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2rem !important;
  }
  .p-xxl-6 {
    padding: 2.5rem !important;
  }
  .p-xxl-7 {
    padding: 3rem !important;
  }
  .p-xxl-8 {
    padding: 3.5rem !important;
  }
  .p-xxl-9 {
    padding: 4rem !important;
  }
  .p-xxl-10 {
    padding: 4.5rem !important;
  }
  .p-xxl-11 {
    padding: 5rem !important;
  }
  .p-xxl-12 {
    padding: 6rem !important;
  }
  .p-xxl-13 {
    padding: 6.5rem !important;
  }
  .p-xxl-14 {
    padding: 7rem !important;
  }
  .p-xxl-15 {
    padding: 7.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .px-xxl-10 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-11 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-12 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-13 {
    padding-right: 6.5rem !important;
    padding-left: 6.5rem !important;
  }
  .px-xxl-14 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .px-xxl-15 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-10 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-11 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-12 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-13 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }
  .py-xxl-14 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .py-xxl-15 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2rem !important;
  }
  .pt-xxl-6 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3rem !important;
  }
  .pt-xxl-8 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4rem !important;
  }
  .pt-xxl-10 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-11 {
    padding-top: 5rem !important;
  }
  .pt-xxl-12 {
    padding-top: 6rem !important;
  }
  .pt-xxl-13 {
    padding-top: 6.5rem !important;
  }
  .pt-xxl-14 {
    padding-top: 7rem !important;
  }
  .pt-xxl-15 {
    padding-top: 7.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 2rem !important;
  }
  .pe-xxl-6 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 3rem !important;
  }
  .pe-xxl-8 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 4rem !important;
  }
  .pe-xxl-10 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-11 {
    padding-right: 5rem !important;
  }
  .pe-xxl-12 {
    padding-right: 6rem !important;
  }
  .pe-xxl-13 {
    padding-right: 6.5rem !important;
  }
  .pe-xxl-14 {
    padding-right: 7rem !important;
  }
  .pe-xxl-15 {
    padding-right: 7.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 6.5rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 7.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 2rem !important;
  }
  .ps-xxl-6 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 3rem !important;
  }
  .ps-xxl-8 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 4rem !important;
  }
  .ps-xxl-10 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-11 {
    padding-left: 5rem !important;
  }
  .ps-xxl-12 {
    padding-left: 6rem !important;
  }
  .ps-xxl-13 {
    padding-left: 6.5rem !important;
  }
  .ps-xxl-14 {
    padding-left: 7rem !important;
  }
  .ps-xxl-15 {
    padding-left: 7.5rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 2rem !important;
  }
  .gap-xxl-6 {
    gap: 2.5rem !important;
  }
  .gap-xxl-7 {
    gap: 3rem !important;
  }
  .gap-xxl-8 {
    gap: 3.5rem !important;
  }
  .gap-xxl-9 {
    gap: 4rem !important;
  }
  .gap-xxl-10 {
    gap: 4.5rem !important;
  }
  .gap-xxl-11 {
    gap: 5rem !important;
  }
  .gap-xxl-12 {
    gap: 6rem !important;
  }
  .gap-xxl-13 {
    gap: 6.5rem !important;
  }
  .gap-xxl-14 {
    gap: 7rem !important;
  }
  .gap-xxl-15 {
    gap: 7.5rem !important;
  }
  .fs-xxl--3 {
    font-size: 0.512rem !important;
  }
  .fs-xxl--2 {
    font-size: 0.64rem !important;
  }
  .fs-xxl--1 {
    font-size: 0.8rem !important;
  }
  .fs-xxl-0 {
    font-size: 1rem !important;
  }
  .fs-xxl-1 {
    font-size: 1.25rem !important;
  }
  .fs-xxl-2 {
    font-size: 1.5625rem !important;
  }
  .fs-xxl-3 {
    font-size: 1.953125rem !important;
  }
  .fs-xxl-4 {
    font-size: 2.44140625rem !important;
  }
  .fs-xxl-5 {
    font-size: 3.0517578125rem !important;
  }
  .fs-xxl-6 {
    font-size: 3.8146972656rem !important;
  }
  .fs-xxl-7 {
    font-size: 4.768371582rem !important;
  }
  .lh-xxl-1 {
    line-height: 1 !important;
  }
  .lh-xxl-sm {
    line-height: 1.2 !important;
  }
  .lh-xxl-base {
    line-height: 1.49 !important;
  }
  .lh-xxl-lg {
    line-height: 1.4 !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .rounded-xxl-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .rounded-xxl-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .rounded-xxl-top-0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .rounded-xxl-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .rounded-xxl-end-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .rounded-xxl-end-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .rounded-xxl-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .rounded-xxl-bottom-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .rounded-xxl-bottom-0 {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .rounded-xxl-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
  }
  .rounded-xxl-start-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
  .rounded-xxl-start-0 {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  .max-vh-xxl-25 {
    max-height: 25vh !important;
  }
  .max-vh-xxl-50 {
    max-height: 50vh !important;
  }
  .max-vh-xxl-75 {
    max-height: 75vh !important;
  }
  .max-vh-xxl-100 {
    max-height: 100vh !important;
  }
  .border-x-xxl {
    border-left: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-right: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-x-xxl-0 {
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .border-y-xxl {
    border-top: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
    border-bottom: var(--phoenix-border-width) var(--phoenix-border-style) var(--phoenix-border-color) !important;
  }
  .border-y-xxl-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
  .rounded-xxl-0 {
    border-radius: 0 !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::placeholder {
  opacity: 1;
  color: var(--phoenix-gray-400);
}

::selection {
  background-color: var(--phoenix-primary);
  color: #fff;
}

[id] {
  scroll-margin-top: calc(var(--phoenix-navbar-top-height) + var(--phoenix-scroll-margin-top));
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 1 !important;
}

pre,
code {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

section {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media (min-width: 992px) {
  section {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}

input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut,
.tox .tox-dialog__footer .tox-button:last-child,
.tox .tox-dialog__footer .tox-button--secondary,
.tox .tox-button-secondary,
.tox .tox-button,
[data-list] .page,
.table-list .page,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

pre {
  margin: 0;
  transform: translate3d(0, 0, 0);
}

strong {
  font-weight: 700;
}

label {
  font-weight: 500;
}

pre,
code,
kbd,
samp {
  font-size: 0.875rem;
}

[type=search] {
  -webkit-appearance: none;
}

.min-h-auto {
  min-height: auto;
}

.content-min-h {
  min-height: calc(100vh - 10rem);
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.stack-icon-item {
  margin-top: 1px;
}

.top-1 {
  top: 1px;
}

.dark .d-dark-none, .dark .picmo__popupContainer.picmo__light .d-dark-none {
  display: none;
}

html:not(.dark) .d-light-none {
  display: none;
}

/*-----------------------------------------------
|   Button Reveal
-----------------------------------------------*/
.btn-reveal-trigger:hover .btn-reveal, .btn-reveal-trigger:focus .btn-reveal {
  background: var(--phoenix-gray-soft);
  border: 1px solid var(--phoenix-gray-200);
  color: var(--phoenix-gray-900);
}
.btn-reveal-trigger:hover .btn-reveal:hover, .btn-reveal-trigger:hover .btn-reveal:focus, .btn-reveal-trigger:hover .btn-reveal:active, .btn-reveal-trigger:focus .btn-reveal:hover, .btn-reveal-trigger:focus .btn-reveal:focus, .btn-reveal-trigger:focus .btn-reveal:active {
  background: var(--phoenix-gray-200);
  color: var(--phoenix-gray-1000) !important;
}
.btn-reveal-trigger .dropdown-toggle:after, .btn-reveal-trigger .dropdown-toggle:before {
  display: none;
}

@media (min-width: 576px) {
  .btn-reveal-trigger-sm:hover .btn-reveal, .btn-reveal-trigger-sm:focus .btn-reveal {
    background: #f5f7fa;
    border: 1px solid #e3e6ed;
  }
  .btn-reveal-trigger-sm:hover .btn-reveal:hover, .btn-reveal-trigger-sm:hover .btn-reveal:focus, .btn-reveal-trigger-sm:hover .btn-reveal:active, .btn-reveal-trigger-sm:focus .btn-reveal:hover, .btn-reveal-trigger-sm:focus .btn-reveal:focus, .btn-reveal-trigger-sm:focus .btn-reveal:active {
    color: var(--phoenix-btn-reveal-color) !important;
  }
  .btn-reveal-trigger-sm .dropdown-toggle:after, .btn-reveal-trigger-sm .dropdown-toggle:before {
    display: none;
  }
}

.btn, .google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut, .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-button-secondary, .tox .tox-button, [data-list] .page, .table-list .page {
  --phoenix-btn-padding-x: 1.5rem;
  --phoenix-btn-padding-y: 0.625rem;
  --phoenix-btn-font-family: ;
  --phoenix-btn-font-size: 0.8rem;
  --phoenix-btn-font-weight: 700;
  --phoenix-btn-line-height: 1.2;
  --phoenix-btn-color: var(--phoenix-body-color);
  --phoenix-btn-bg: transparent;
  --phoenix-btn-border-width: 1px;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-border-radius: 0.375rem;
  --phoenix-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0), 0 1px 1px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-opacity: 0.3;
  --phoenix-btn-focus-box-shadow: 0 0 0 0 rgba(var(--phoenix-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--phoenix-btn-padding-y) var(--phoenix-btn-padding-x);
  font-family: var(--phoenix-btn-font-family);
  font-size: var(--phoenix-btn-font-size);
  font-weight: var(--phoenix-btn-font-weight);
  line-height: var(--phoenix-btn-line-height);
  color: var(--phoenix-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--phoenix-btn-border-width) solid var(--phoenix-btn-border-color);
  border-radius: var(--phoenix-btn-border-radius);
  background-color: var(--phoenix-btn-bg);
  box-shadow: var(--phoenix-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut, .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-button-secondary, .tox .tox-button, [data-list] .page, .table-list .page {
    transition: none;
  }
}
.btn:hover, .google-map-control-btn .zoomIn:hover,
.google-map-control-btn .zoomOut:hover, .tox .tox-dialog__footer .tox-button:hover:last-child, .tox .tox-dialog__footer .tox-button--secondary:hover, .tox .tox-button-secondary:hover, .tox .tox-button:hover, [data-list] .page:hover, .table-list .page:hover {
  color: var(--phoenix-btn-hover-color);
  text-decoration: none;
  background-color: var(--phoenix-btn-hover-bg);
  border-color: var(--phoenix-btn-hover-border-color);
}
.btn-check:focus + .btn, .google-map-control-btn .btn-check:focus + .zoomIn,
.google-map-control-btn .btn-check:focus + .zoomOut, .tox .tox-dialog__footer .btn-check:focus + .tox-button:last-child, .tox .tox-dialog__footer .btn-check:focus + .tox-button--secondary, .tox .btn-check:focus + .tox-button-secondary, .tox .btn-check:focus + .tox-button, [data-list] .btn-check:focus + .page, .table-list .btn-check:focus + .page, .btn:focus, .google-map-control-btn .zoomIn:focus,
.google-map-control-btn .zoomOut:focus, .tox .tox-dialog__footer .tox-button:focus:last-child, .tox .tox-dialog__footer .tox-button--secondary:focus, .tox .tox-button-secondary:focus, .tox .tox-button:focus, [data-list] .page:focus, .table-list .page:focus {
  color: var(--phoenix-btn-hover-color);
  background-color: var(--phoenix-btn-hover-bg);
  border-color: var(--phoenix-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--phoenix-btn-box-shadow), var(--phoenix-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .google-map-control-btn .btn-check:checked + .zoomIn,
.google-map-control-btn .btn-check:checked + .zoomOut, .tox .tox-dialog__footer .btn-check:checked + .tox-button:last-child, .tox .tox-dialog__footer .btn-check:checked + .tox-button--secondary, .tox .btn-check:checked + .tox-button-secondary, .tox .btn-check:checked + .tox-button, [data-list] .btn-check:checked + .page, .table-list .btn-check:checked + .page, .btn-check:active + .btn, .google-map-control-btn .btn-check:active + .zoomIn,
.google-map-control-btn .btn-check:active + .zoomOut, .tox .tox-dialog__footer .btn-check:active + .tox-button:last-child, .tox .tox-dialog__footer .btn-check:active + .tox-button--secondary, .tox .btn-check:active + .tox-button-secondary, .tox .btn-check:active + .tox-button, [data-list] .btn-check:active + .page, .table-list .btn-check:active + .page, .btn:active, .google-map-control-btn .zoomIn:active,
.google-map-control-btn .zoomOut:active, .tox .tox-dialog__footer .tox-button:active:last-child, .tox .tox-dialog__footer .tox-button--secondary:active, .tox .tox-button-secondary:active, .tox .tox-button:active, [data-list] .page:active, .table-list .page:active, .btn.active, .google-map-control-btn .active.zoomIn,
.google-map-control-btn .active.zoomOut, .tox .tox-dialog__footer .active.tox-button:last-child, .tox .tox-dialog__footer .active.tox-button--secondary, .tox .active.tox-button-secondary, .tox .active.tox-button, [data-list] .active.page, .table-list .active.page, .btn.show, .google-map-control-btn .show.zoomIn,
.google-map-control-btn .show.zoomOut, .tox .tox-dialog__footer .show.tox-button:last-child, .tox .tox-dialog__footer .show.tox-button--secondary, .tox .show.tox-button-secondary, .tox .show.tox-button, [data-list] .show.page, .table-list .show.page {
  color: var(--phoenix-btn-active-color);
  background-color: var(--phoenix-btn-active-bg);
  border-color: var(--phoenix-btn-active-border-color);
  box-shadow: var(--phoenix-btn-active-shadow);
}
.btn-check:checked + .btn:focus, .google-map-control-btn .btn-check:checked + .zoomIn:focus,
.google-map-control-btn .btn-check:checked + .zoomOut:focus, .tox .tox-dialog__footer .btn-check:checked + .tox-button:focus:last-child, .tox .tox-dialog__footer .btn-check:checked + .tox-button--secondary:focus, .tox .btn-check:checked + .tox-button-secondary:focus, .tox .btn-check:checked + .tox-button:focus, [data-list] .btn-check:checked + .page:focus, .table-list .btn-check:checked + .page:focus, .btn-check:active + .btn:focus, .google-map-control-btn .btn-check:active + .zoomIn:focus,
.google-map-control-btn .btn-check:active + .zoomOut:focus, .tox .tox-dialog__footer .btn-check:active + .tox-button:focus:last-child, .tox .tox-dialog__footer .btn-check:active + .tox-button--secondary:focus, .tox .btn-check:active + .tox-button-secondary:focus, .tox .btn-check:active + .tox-button:focus, [data-list] .btn-check:active + .page:focus, .table-list .btn-check:active + .page:focus, .btn:active:focus, .google-map-control-btn .zoomIn:active:focus,
.google-map-control-btn .zoomOut:active:focus, .tox .tox-dialog__footer .tox-button--secondary:active:focus, .tox .tox-button-secondary:active:focus, .tox .tox-button:active:focus, [data-list] .page:active:focus, .table-list .page:active:focus, .btn.active:focus, .google-map-control-btn .active.zoomIn:focus,
.google-map-control-btn .active.zoomOut:focus, .tox .tox-dialog__footer .active.tox-button--secondary:focus, .tox .active.tox-button-secondary:focus, .tox .active.tox-button:focus, [data-list] .active.page:focus, .table-list .active.page:focus, .btn.show:focus, .google-map-control-btn .show.zoomIn:focus,
.google-map-control-btn .show.zoomOut:focus, .tox .tox-dialog__footer .show.tox-button--secondary:focus, .tox .show.tox-button-secondary:focus, .tox .show.tox-button:focus, [data-list] .show.page:focus, .table-list .show.page:focus {
  box-shadow: var(--phoenix-btn-active-shadow), var(--phoenix-btn-focus-box-shadow);
}
.btn:disabled, .google-map-control-btn .zoomIn:disabled,
.google-map-control-btn .zoomOut:disabled, .tox .tox-dialog__footer .tox-button:disabled:last-child, .tox .tox-dialog__footer .tox-button--secondary:disabled, .tox .tox-button-secondary:disabled, .tox .tox-button:disabled, [data-list] .page:disabled, .table-list .page:disabled, .btn.disabled, .google-map-control-btn .disabled.zoomIn,
.google-map-control-btn .disabled.zoomOut, .tox .tox-dialog__footer .disabled.tox-button:last-child, .tox .tox-dialog__footer .disabled.tox-button--secondary, .tox .disabled.tox-button-secondary, .tox .disabled.tox-button, [data-list] .disabled.page, .table-list .disabled.page, fieldset:disabled .btn, fieldset:disabled .google-map-control-btn .zoomIn, .google-map-control-btn fieldset:disabled .zoomIn,
fieldset:disabled .google-map-control-btn .zoomOut,
.google-map-control-btn fieldset:disabled .zoomOut, fieldset:disabled .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-dialog__footer fieldset:disabled .tox-button:last-child, fieldset:disabled .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer fieldset:disabled .tox-button--secondary, fieldset:disabled .tox .tox-button-secondary, .tox fieldset:disabled .tox-button-secondary, fieldset:disabled .tox .tox-button, .tox fieldset:disabled .tox-button, fieldset:disabled [data-list] .page, [data-list] fieldset:disabled .page, fieldset:disabled .table-list .page, .table-list fieldset:disabled .page {
  color: var(--phoenix-btn-disabled-color);
  pointer-events: none;
  background-color: var(--phoenix-btn-disabled-bg);
  border-color: var(--phoenix-btn-disabled-border-color);
  opacity: var(--phoenix-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary, .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-button {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #3874ff;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #004dff;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #2d5dcc;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #3874ff;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-secondary {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #31374a;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #222834;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #272c3b;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #31374a;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-success {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #25b003;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #23890b;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #1e8d02;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #25b003;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-info {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #0097eb;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #0080c7;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #0079bc;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #0097eb;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-warning {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #e5780b;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #d6700a;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #b76009;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #e5780b;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-danger {
  --phoenix-btn-color: #fff;
  --phoenix-btn-bg: #ed2000;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #cc1b00;
  --phoenix-btn-hover-border-color: rgba(0, 0, 0, 0.2);
  --phoenix-btn-focus-shadow-rgb: 255, 255, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #be1a00;
  --phoenix-btn-active-border-color: rgba(0, 0, 0, 0.25);
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #fff;
  --phoenix-btn-disabled-bg: #ed2000;
  --phoenix-btn-disabled-border-color: transparent;
}

.btn-outline-primary {
  --phoenix-btn-color: #3874ff;
  --phoenix-btn-border-color: #3874ff;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #3874ff;
  --phoenix-btn-hover-border-color: #004dff;
  --phoenix-btn-focus-shadow-rgb: 56, 116, 255;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #3874ff;
  --phoenix-btn-active-border-color: #004dff;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #3874ff;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #3874ff;
  --phoenix-gradient: none;
}

.btn-outline-secondary {
  --phoenix-btn-color: #31374a;
  --phoenix-btn-border-color: #31374a;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #31374a;
  --phoenix-btn-hover-border-color: #222834;
  --phoenix-btn-focus-shadow-rgb: 49, 55, 74;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #31374a;
  --phoenix-btn-active-border-color: #222834;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #31374a;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #31374a;
  --phoenix-gradient: none;
}

.btn-outline-success {
  --phoenix-btn-color: #25b003;
  --phoenix-btn-border-color: #25b003;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #25b003;
  --phoenix-btn-hover-border-color: #23890b;
  --phoenix-btn-focus-shadow-rgb: 37, 176, 3;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #25b003;
  --phoenix-btn-active-border-color: #23890b;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #25b003;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #25b003;
  --phoenix-gradient: none;
}

.btn-outline-info {
  --phoenix-btn-color: #0097eb;
  --phoenix-btn-border-color: #0097eb;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #0097eb;
  --phoenix-btn-hover-border-color: #0080c7;
  --phoenix-btn-focus-shadow-rgb: 0, 151, 235;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #0097eb;
  --phoenix-btn-active-border-color: #0080c7;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #0097eb;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #0097eb;
  --phoenix-gradient: none;
}

.btn-outline-warning {
  --phoenix-btn-color: #e5780b;
  --phoenix-btn-border-color: #e5780b;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e5780b;
  --phoenix-btn-hover-border-color: #d6700a;
  --phoenix-btn-focus-shadow-rgb: 229, 120, 11;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #e5780b;
  --phoenix-btn-active-border-color: #d6700a;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #e5780b;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #e5780b;
  --phoenix-gradient: none;
}

.btn-outline-danger {
  --phoenix-btn-color: #ed2000;
  --phoenix-btn-border-color: #ed2000;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #ed2000;
  --phoenix-btn-hover-border-color: #cc1b00;
  --phoenix-btn-focus-shadow-rgb: 237, 32, 0;
  --phoenix-btn-active-color: #fff;
  --phoenix-btn-active-bg: #ed2000;
  --phoenix-btn-active-border-color: #cc1b00;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #ed2000;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-btn-disabled-border-color: #ed2000;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-primary {
  --phoenix-btn-color: #3874ff;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #004dff;
  --phoenix-btn-focus-shadow-rgb: 56, 116, 255;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #004dff;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #3874ff;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-secondary {
  --phoenix-btn-color: #31374a;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #222834;
  --phoenix-btn-focus-shadow-rgb: 49, 55, 74;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #222834;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #31374a;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-success {
  --phoenix-btn-color: #25b003;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #23890b;
  --phoenix-btn-focus-shadow-rgb: 37, 176, 3;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #23890b;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #25b003;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-info {
  --phoenix-btn-color: #0097eb;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #0080c7;
  --phoenix-btn-focus-shadow-rgb: 0, 151, 235;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #0080c7;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #0097eb;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-warning {
  --phoenix-btn-color: #e5780b;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #d6700a;
  --phoenix-btn-focus-shadow-rgb: 229, 120, 11;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #d6700a;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #e5780b;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-outline-phoenix-danger {
  --phoenix-btn-color: #ed2000;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #fff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #cc1b00;
  --phoenix-btn-focus-shadow-rgb: 237, 32, 0;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #cc1b00;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #ed2000;
  --phoenix-btn-disabled-bg: transparent;
  --phoenix-gradient: none;
}

.btn-soft-primary {
  --phoenix-btn-color: #003cc7;
  --phoenix-btn-bg: #f5f8ff;
  --phoenix-btn-border-color: #f5f8ff;
  --phoenix-btn-hover-color: #003cc7;
  --phoenix-btn-hover-bg: #e5edff;
  --phoenix-btn-hover-border-color: #f6f9ff;
  --phoenix-btn-focus-shadow-rgb: 208, 220, 247;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #f7f9ff;
  --phoenix-btn-active-border-color: #f6f9ff;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f8ff;
  --phoenix-btn-disabled-border-color: #f5f8ff;
}

.btn-soft-secondary {
  --phoenix-btn-color: #141824;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #f5f7fa;
  --phoenix-btn-hover-color: #141824;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #f6f8fb;
  --phoenix-btn-focus-shadow-rgb: 211, 214, 218;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #f7f9fb;
  --phoenix-btn-active-border-color: #f6f8fb;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #f5f7fa;
}

.btn-soft-success {
  --phoenix-btn-color: #1c6c09;
  --phoenix-btn-bg: #f0fdec;
  --phoenix-btn-border-color: #f0fdec;
  --phoenix-btn-hover-color: #1c6c09;
  --phoenix-btn-hover-bg: #d9fbd0;
  --phoenix-btn-hover-border-color: #f2fdee;
  --phoenix-btn-focus-shadow-rgb: 208, 231, 202;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #f3fdf0;
  --phoenix-btn-active-border-color: #f2fdee;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f0fdec;
  --phoenix-btn-disabled-border-color: #f0fdec;
}

.btn-soft-info {
  --phoenix-btn-color: #005585;
  --phoenix-btn-bg: #f0faff;
  --phoenix-btn-border-color: #f0faff;
  --phoenix-btn-hover-color: #005585;
  --phoenix-btn-hover-bg: #c7ebff;
  --phoenix-btn-hover-border-color: #f2fbff;
  --phoenix-btn-focus-shadow-rgb: 204, 225, 237;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #f3fbff;
  --phoenix-btn-active-border-color: #f2fbff;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f0faff;
  --phoenix-btn-disabled-border-color: #f0faff;
}

.btn-soft-warning {
  --phoenix-btn-color: #bc3803;
  --phoenix-btn-bg: #fff6e0;
  --phoenix-btn-border-color: #fff6e0;
  --phoenix-btn-hover-color: #bc3803;
  --phoenix-btn-hover-bg: #ffefca;
  --phoenix-btn-hover-border-color: #fff7e3;
  --phoenix-btn-focus-shadow-rgb: 245, 218, 191;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #fff8e6;
  --phoenix-btn-active-border-color: #fff7e3;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #fff6e0;
  --phoenix-btn-disabled-border-color: #fff6e0;
}

.btn-soft-danger {
  --phoenix-btn-color: #b81800;
  --phoenix-btn-bg: #ffedeb;
  --phoenix-btn-border-color: #ffedeb;
  --phoenix-btn-hover-color: #b81800;
  --phoenix-btn-hover-bg: #ffe0db;
  --phoenix-btn-hover-border-color: #ffefed;
  --phoenix-btn-focus-shadow-rgb: 244, 205, 200;
  --phoenix-btn-active-color: #000000;
  --phoenix-btn-active-bg: #fff1ef;
  --phoenix-btn-active-border-color: #ffefed;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #ffedeb;
  --phoenix-btn-disabled-border-color: #ffedeb;
}

.btn-phoenix-primary {
  --phoenix-btn-color: #3874ff;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #004dff;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 201, 213, 240;
  --phoenix-btn-active-color: #3874ff;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-phoenix-secondary, .google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut, .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-button-secondary {
  --phoenix-btn-color: #31374a;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #222834;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 200, 204, 213;
  --phoenix-btn-active-color: #31374a;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-phoenix-success {
  --phoenix-btn-color: #25b003;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #23890b;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 199, 222, 202;
  --phoenix-btn-active-color: #25b003;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-phoenix-info {
  --phoenix-btn-color: #0097eb;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #0080c7;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 193, 218, 237;
  --phoenix-btn-active-color: #0097eb;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-phoenix-warning {
  --phoenix-btn-color: #e5780b;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #d6700a;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 227, 214, 203;
  --phoenix-btn-active-color: #e5780b;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-phoenix-danger {
  --phoenix-btn-color: #ed2000;
  --phoenix-btn-bg: #f5f7fa;
  --phoenix-btn-border-color: #e3e6ed;
  --phoenix-btn-hover-color: #cc1b00;
  --phoenix-btn-hover-bg: #e3e6ed;
  --phoenix-btn-hover-border-color: #e6e9ef;
  --phoenix-btn-focus-shadow-rgb: 229, 200, 201;
  --phoenix-btn-active-color: #ed2000;
  --phoenix-btn-active-bg: #e3e6ed;
  --phoenix-btn-active-border-color: #e6e9ef;
  --phoenix-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0);
  --phoenix-btn-disabled-color: #000000;
  --phoenix-btn-disabled-bg: #f5f7fa;
  --phoenix-btn-disabled-border-color: #e3e6ed;
}

.btn-link {
  --phoenix-btn-font-weight: 700;
  --phoenix-btn-color: var(--phoenix-link-color);
  --phoenix-btn-bg: transparent;
  --phoenix-btn-border-color: transparent;
  --phoenix-btn-hover-color: var(--phoenix-link-hover-color);
  --phoenix-btn-hover-border-color: transparent;
  --phoenix-btn-active-border-color: transparent;
  --phoenix-btn-disabled-color: #9fa6bc;
  --phoenix-btn-disabled-border-color: transparent;
  --phoenix-btn-box-shadow: none;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus {
  text-decoration: underline;
}

.btn-lg, .btn-group-lg > .btn, .google-map-control-btn .btn-group-lg > .zoomIn,
.google-map-control-btn .btn-group-lg > .zoomOut, .tox .tox-dialog__footer .btn-group-lg > .tox-button:last-child, .tox .tox-dialog__footer .btn-group-lg > .tox-button--secondary, .tox .btn-group-lg > .tox-button-secondary, .tox .btn-group-lg > .tox-button, [data-list] .btn-group-lg > .page, .table-list .btn-group-lg > .page {
  --phoenix-btn-padding-y: 0.875rem;
  --phoenix-btn-padding-x: 1.5rem;
  --phoenix-btn-font-size: 1rem;
  --phoenix-btn-border-radius: 0.375rem;
}

.btn-sm, .btn-group-sm > .btn, .google-map-control-btn .btn-group-sm > .zoomIn,
.google-map-control-btn .btn-group-sm > .zoomOut, .tox .tox-dialog__footer .btn-group-sm > .tox-button:last-child, .tox .tox-dialog__footer .btn-group-sm > .tox-button--secondary, .tox .btn-group-sm > .tox-button-secondary, .tox .btn-group-sm > .tox-button, [data-list] .btn-group-sm > .page, .table-list .btn-group-sm > .page {
  --phoenix-btn-padding-y: 0.5rem;
  --phoenix-btn-padding-x: 1rem;
  --phoenix-btn-font-size: 0.8rem;
  --phoenix-btn-border-radius: 0.375rem;
}

.btn-circle {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.btn-icon {
  height: 2rem;
  width: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-icon.btn-icon-lg {
  height: 2.375rem;
  width: 2.375rem;
}
.btn-icon.btn-icon-xl {
  height: 3rem;
  width: 3rem;
}

.circle-btn {
  height: 2rem;
  width: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--phoenix-border-radius-pill);
}

/* -------------------------------------------------------------------------- */
/*                               Browser Fixing                               */
/* -------------------------------------------------------------------------- */
.firefox .dropcap:first-letter {
  margin-top: 0.175em;
}
.firefox .card-notification {
  min-height: 19rem;
}

.chrome .btn-close {
  background-clip: unset;
}

/*-----------------------------------------------
|   Object fit and Z-index
-----------------------------------------------*/
.fit-cover {
  object-fit: cover;
}

/*-----------------------------------------------
|   Sidebar
-----------------------------------------------*/
.sticky-sidebar {
  position: sticky;
  z-index: 1020;
  top: var(--phoenix-navbar-top-height)1;
}

/*-----------------------------------------------
|   Custom circle
-----------------------------------------------*/
.circle-dashed {
  border: 1px dashed var(--phoenix-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
}

/*-----------------------------------------------
|   Miscellaneous
-----------------------------------------------*/
.hover-text-decoration-none:hover, .hover-text-decoration-none:focus {
  text-decoration: none;
}

.resize-none {
  resize: none;
}

.collapsed .collapse-icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}

.collapse-icon {
  transition: all 0.2s ease-in-out;
  transform: rotate(90deg);
}

[data-dismiss=dropdown] *,
[data-bs-offset-top] *,
[data-bs-toggle=collapse] *,
[data-bs-toggle=tooltip] *,
[data-bs-toggle=popover] * {
  pointer-events: none;
}

/*-----------------------------------------------
|   Outline
-----------------------------------------------*/
.outline-none {
  outline: none;
  box-shadow: none;
}
.outline-none:hover, .outline-none:focus {
  outline: none;
  box-shadow: none;
}

/*-----------------------------------------------
|   Vertical Line (used in kanban header)
-----------------------------------------------*/
.vertical-line:after {
  position: absolute;
  content: "";
  height: 75%;
  width: 1px;
  background: var(--phoenix-gray-300);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.vertical-line.vertical-line-400:after {
  background-color: var(--phoenix-gray-400);
}

/* -------------------------------------------------------------------------- */
/*                                 Transition                                 */
/* -------------------------------------------------------------------------- */
.transition-base {
  transition: all 0.2s ease-in-out;
}

.transition-none {
  transition: none;
}

.fsp-75 {
  font-size: 75%;
}

/* -------------------------------------------------------------------------- */
/*                                    Width                                   */
/* -------------------------------------------------------------------------- */
.min-w-0 {
  min-width: 0;
}

/* -------------------------------------------------------------------------- */
/*                                Terms sidebar                               */
/* -------------------------------------------------------------------------- */
.terms-sidebar .nav-link {
  color: var(--phoenix-gray-500);
  font-weight: 500;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.terms-sidebar .nav-link.active {
  color: var(--phoenix-primary);
}

.divider-content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: var(--phoenix-body-bg);
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8rem;
  color: var(--phoenix-gray-600);
  white-space: nowrap;
  font-weight: 700;
}
.dark .bg-auth-card .divider-content-center {
  background-color: #0f111a !important;
}

.btn-check[data-theme-control]:checked + label {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*                                    Height                                  */
/* -------------------------------------------------------------------------- */
html {
  position: relative;
}

.content {
  min-height: 100vh;
  padding: calc(var(--phoenix-navbar-top-height) + 2rem) 1.5rem 6.375rem 1.5rem;
  padding-bottom: 6rem;
}
@media (min-width: 576px) {
  .content {
    padding-bottom: 4rem;
  }
}

.footer {
  bottom: 0;
  width: 100%;
  height: 6rem;
  border-top: 1px solid var(--phoenix-navbar-footer-border-color);
  padding: 0 1.5rem;
  left: 0;
}
@media (min-width: 576px) {
  .footer {
    height: 4rem;
  }
}
@media (min-width: 992px) {
  .footer {
    padding: 0 2.5rem;
  }
}

.bullet-item {
  height: 0.5rem;
  width: 1rem;
  border-radius: 2px;
}

/* -------------------------------------------------------------------------- */
/*                                    Logo                                    */
/* -------------------------------------------------------------------------- */
.logo-text {
  font-size: 1.63625rem;
  color: var(--phoenix-logo-text);
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}

.notification-card.unread {
  background-color: var(--phoenix-gray-100);
}
.dark .notification-card.unread {
  background-color: var(--phoenix-white);
}

.notification-card:hover {
  background-color: var(--phoenix-gray-200) !important;
}

.notification-dropdown-toggle:hover {
  border-color: var(--phoenix-gray-300);
  background: transparent;
}

.square-icon-btn, .google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut {
  height: 1.875rem;
  width: 1.875rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-column-title-box {
  height: 105px;
}

.pricing--hover:hover .warning-boxshadow, .pricing--hover:focus .warning-boxshadow {
  box-shadow: 0px 0px 0px 4px rgba(229, 120, 11, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
.pricing--hover:hover .border-warning-300, .pricing--hover:focus .border-warning-300 {
  --phoenix-border-color: #ffcc85;
}

.pricing-plan-radio + label {
  border: 1px solid transparent;
  border-radius: 0.5rem;
}
.pricing-plan-radio:checked + div .card {
  border: 1px solid var(--phoenix-primary-300);
  box-shadow: 0px 0px 0px 4px rgba(56, 116, 255, 0.2);
}
.pricing-plan-radio.pricing-plan-recommended:checked + div .card {
  border: 1px solid var(--phoenix-warning-300);
  box-shadow: 0px 0px 0px 4px rgba(229, 120, 11, 0.2);
}

.dark .footer-bg, .dark .picmo__popupContainer.picmo__light .footer-bg {
  opacity: 0.6;
}

.text-illustration-underline {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
}

.bg-linear-gradient {
  background: linear-gradient(95.11deg, #f24e1e -21.8%, #ff7262 -2.23%, #a259ff 46.16%, #1abcfe 79.11%, #0acf83 116.18%);
}

.hover-bg-transparent:hover, .hover-bg-transparent:focus {
  background-color: transparent !important;
}

/* ------------------------------ font-awesome ------------------------------ */
.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
}
[dir=rtl] .fa-ul {
  --fa-li-margin: -0.5em !important;
}
[dir=rtl] .fa-ul.pricing {
  margin-left: var(--fa-li-margin, 2.5em) !important;
}
.fa-ul .fa-li {
  left: calc(var(--fa-li-width, 2em) * -1) !important;
}

@media (min-width: 1200px) {
  .page-title-sticky-top {
    position: sticky;
    top: calc(var(--phoenix-navbar-top-height) + 3.725rem);
  }
}

.dark .pricing-business-plus, .dark .picmo__popupContainer.picmo__light .pricing-business-plus {
  background-color: rgba(229, 120, 11, 0.08) !important;
  border: 1px solid rgba(229, 120, 11, 0.2);
}

.echart-quartet-scatter-chart-example {
  min-height: 31.25rem;
}
@media (max-width: 767.98px) {
  .echart-quartet-scatter-chart-example {
    min-height: 65.625rem;
  }
}

.echart-radar-customized-chart-example {
  height: 22rem;
}
@media (max-width: 1199.98px) {
  .echart-radar-customized-chart-example {
    height: 45rem;
  }
}

.echart-radar-multiple-chart-example {
  height: 21rem;
}
@media (max-width: 1539.98px) {
  .echart-radar-multiple-chart-example {
    height: 35rem;
  }
}
@media (max-width: 991.98px) {
  .echart-radar-multiple-chart-example {
    height: 50rem;
  }
}

.simplebar-scrollspy .nav {
  min-width: 850px;
}
.simplebar-scrollspy .nav .nav-item .nav-link.active {
  color: var(--phoenix-primary) !important;
  border-bottom: 1px solid var(--phoenix-primary) !important;
}

.star-rating {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjc4MTMgNC4xNzkzTDcuODcyNjYgMy43NjA5NEw2LjU1NzgxIDEuMTExMzNDNi4zMzg2NyAwLjY1MzEyNSA1LjY2MTMzIDAuNjMzMjAzIDUuNDIyMjcgMS4xMTEzM0w0LjEyNzM0IDMuNzYwOTRMMS4xOTg4MyA0LjE3OTNDMC42ODA4NiA0LjI1ODk4IDAuNDgxNjQxIDQuODk2NDggMC44NjAxNTcgNS4yNzVMMi45NTE5NSA3LjMyNjk1TDIuNDUzOTEgMTAuMjE1NkMyLjM3NDIyIDEwLjczMzYgMi45MzIwMyAxMS4xMzIgMy4zOTAyMyAxMC44OTNMNiA5LjUxODM2TDguNTg5ODQgMTAuODkzQzkuMDQ4MDUgMTEuMTMyIDkuNjA1ODYgMTAuNzMzNiA5LjUyNjE3IDEwLjIxNTZMOS4wMjgxMyA3LjMyNjk1TDExLjExOTkgNS4yNzVDMTEuNDk4NCA0Ljg5NjQ4IDExLjI5OTIgNC4yNTg5OCAxMC43ODEzIDQuMTc5M1pNNy45OTIxOSA2Ljk4ODI4TDguNDcwMzEgOS43Mzc1TDYgOC40NDI1OEwzLjUwOTc3IDkuNzM3NUwzLjk4Nzg5IDYuOTg4MjhMMS45NzU3OCA1LjAzNTk0TDQuNzQ0OTIgNC42Mzc1TDYgMi4xMjczNEw3LjIzNTE2IDQuNjM3NUwxMC4wMDQzIDUuMDM1OTRMNy45OTIxOSA2Ljk4ODI4WiIgZmlsbD0iI0U1NzgwQiIvPgo8L3N2Zz4K") !important;
}
.star-rating .star-value {
  background-image: url("../img/icons/star.svg") !important;
}

[data-list] .sort[data-sort], .table-list .sort[data-sort] {
  white-space: nowrap;
  cursor: pointer;
}
[data-list] .sort[data-sort]::after, .table-list .sort[data-sort]::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgMC42NDY3NjhDMy4zNDM2NiAwLjQ1NDg4NSAzLjY1NjM0IDAuNDU0ODg1IDMuODUxIDAuNjQ2NzY4TDYuMTMxNTEgMi44OTQ2OUM2LjQ0OTk4IDMuMjA4NjIgNi4yMjc2OSAzLjc1MDc4IDUuNzgwNSAzLjc1MDc4SDEuMjE5NUMwLjc3MjMwNyAzLjc1MDc4IDAuNTUwMDE4IDMuMjA4NjIgMC44Njg0OTUgMi44OTQ2OUwzLjE0OSAwLjY0Njc2OFoiIGZpbGw9IiM2RTc4OTEiLz4KPHBhdGggZD0iTTMuMTQ5IDguMTU0MDFDMy4zNDM2NiA4LjM0NTkgMy42NTYzNCA4LjM0NTkgMy44NTEgOC4xNTQwMUw2LjEzMTUxIDUuOTA2MDlDNi40NDk5OCA1LjU5MjE2IDYuMjI3NjkgNS4wNSA1Ljc4MDUgNS4wNUgxLjIxOTVDMC43NzIzMDcgNS4wNSAwLjU1MDAxOCA1LjU5MjE2IDAuODY4NDk1IDUuOTA2MDlMMy4xNDkgOC4xNTQwMVoiIGZpbGw9IiM2RTc4OTEiLz4KPC9zdmc+Cg==");
  margin-left: 0.25rem;
}
[data-list] .sort[data-sort].asc::after, .table-list .sort[data-sort].asc::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgOC4xNTQwMUMzLjM0MzY2IDguMzQ1OSAzLjY1NjM0IDguMzQ1OSAzLjg1MSA4LjE1NDAxTDYuMTMxNTEgNS45MDYwOUM2LjQ0OTk4IDUuNTkyMTYgNi4yMjc2OSA1LjA1IDUuNzgwNSA1LjA1SDEuMjE5NUMwLjc3MjMwNyA1LjA1IDAuNTUwMDE4IDUuNTkyMTYgMC44Njg0OTUgNS45MDYwOUwzLjE0OSA4LjE1NDAxWiIgZmlsbD0iIzZFNzg5MSIvPgo8L3N2Zz4K") !important;
  margin-top: -1px !important;
}
[data-list] .sort[data-sort].desc::after, .table-list .sort[data-sort].desc::after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgNyA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMy4xNDkgMC42NDY3NjhDMy4zNDM2NiAwLjQ1NDg4NSAzLjY1NjM0IDAuNDU0ODg1IDMuODUxIDAuNjQ2NzY4TDYuMTMxNTEgMi44OTQ2OUM2LjQ0OTk4IDMuMjA4NjIgNi4yMjc2OSAzLjc1MDc4IDUuNzgwNSAzLjc1MDc4SDEuMjE5NUMwLjc3MjMwNyAzLjc1MDc4IDAuNTUwMDE4IDMuMjA4NjIgMC44Njg0OTUgMi44OTQ2OUwzLjE0OSAwLjY0Njc2OFoiIGZpbGw9IiM2RTc4OTEiLz4KPC9zdmc+Cg==") !important;
}
[data-list] .page, .table-list .page {
  padding: 0.5rem 0.75rem;
  color: var(--phoenix-gray-800);
  font-size: 0.64rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
[data-list] li.active .page, .table-list li.active .page {
  color: #fff;
  background-color: #3874ff;
}

.ios [data-list-pagination].btn-primary[disabled], .ios .tox [data-list-pagination][disabled].tox-button, .tox .ios [data-list-pagination][disabled].tox-button {
  opacity: 1 !important;
  border: 0 !important;
  background-color: var(--phoenix-btn-disabled-custom-background) !important;
  color: var(--phoenix-btn-disabled-custom-color) !important;
}

/* PrismJS 1.27.0
https://prismjs.com/download.html?themes#themes=prism-okaidia&languages=markup+css+clike+javascript */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */
/* @import 'prismjs/themes/prism-okaidia.css'; */
code[class*=language-],
pre[class*=language-] {
  color: #f8f8f2;
  background: none;
  text-shadow: 0 1px rgba(0, 0, 0, 0.3);
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #272822;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #8292a2;
}

.token.punctuation {
  color: #f8f8f2;
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #f92672;
}

.token.boolean,
.token.number {
  color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #a6e22e;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #e6db74;
}

.token.keyword {
  color: #66d9ef;
}

.token.regex,
.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.swiper-theme-container {
  position: relative;
}
.swiper-theme-container .swiper-wrapper .swiper-slide {
  height: auto !important;
}
.swiper-theme-container .swiper-nav {
  margin-top: 0;
  cursor: pointer;
}
.swiper-theme-container .swiper-nav [class*=swiper-] {
  position: absolute;
  top: 50%;
  border: 1px solid;
  border-color: var(--phoenix-gray-200);
  transition: opacity 0.4s ease-in-out;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: var(--phoenix-gray-900);
  background-color: var(--phoenix-gray-soft) !important;
  box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.12);
  z-index: 1;
  margin: 0;
}
.swiper-theme-container .swiper-nav [class*=swiper-]:hover, .swiper-theme-container .swiper-nav [class*=swiper-]:focus {
  background-color: var(--phoenix-white);
  color: var(--phoenix-gray-700);
}
.swiper-theme-container .swiper-nav .swiper-button-prev::after, .swiper-theme-container .swiper-nav .swiper-button-prev::before,
.swiper-theme-container .swiper-nav .swiper-button-next::after,
.swiper-theme-container .swiper-nav .swiper-button-next::before {
  content: "";
}
.swiper-theme-container .swiper-nav .swiper-button-prev.swiper-button-disabled,
.swiper-theme-container .swiper-nav .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}
.swiper-theme-container .swiper-nav .swiper-button-prev {
  left: -1rem;
}
.swiper-theme-container .swiper-nav .swiper-button-next {
  right: -1rem;
}

/* -------------------------------------------------------------------------- */
/*                                  Dropzone                                  */
/* -------------------------------------------------------------------------- */
.dropzone {
  position: relative;
  background-color: var(--phoenix-gray-soft);
  border: 0;
  border-radius: 0.5rem;
  min-height: auto;
}
.dropzone.dropzone-has-default .dz-message {
  border-color: transparent;
  transition: all 0.2s ease-in-out;
}
.dropzone.dropzone-has-default .dz-message:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.dropzone .dz-preview {
  min-height: auto;
  margin: 0;
}
.dropzone .dz-remove {
  position: absolute;
  right: -6px;
  top: -6px;
  color: var(--phoenix-white);
  z-index: 1000;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}
.dropzone .dz-remove * {
  cursor: pointer;
}
.dropzone .dz-message {
  position: relative;
  padding: 5rem 2rem;
  margin: 0;
  border: 2px dashed var(--phoenix-border-color);
  border-radius: 0.5rem;
}
.dropzone.dropzone-single.dz-file-processing .dz-message {
  display: none;
}
.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-message {
  display: block;
}
.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-default-image {
  opacity: 0;
}
.dropzone.dropzone-single .dz-processing .dz-message-text {
  opacity: 0 !important;
}
.dropzone.dropzone-single .dz-progress {
  opacity: 1;
  transition: opacity 0.4s ease-in;
  animation: none !important;
  width: 9.375rem !important;
  height: 0.5rem !important;
  margin: 0 !important;
  transform: translateX(-50%) !important;
  top: auto !important;
  bottom: 1.5rem;
}
.dropzone.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--phoenix-white);
  opacity: 0;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
}
.dropzone.dropzone-single.dz-max-files-reached .dz-remove {
  opacity: 1;
}
.dropzone.dropzone-single.dz-max-files-reached:hover .dz-message, .dropzone.dropzone-single.dz-max-files-reached:focus .dz-message {
  opacity: 1;
}
.dropzone.dropzone-single .dz-processing .dz-progress {
  opacity: 1;
}
.dropzone.dropzone-single .dz-complete .dz-progress {
  opacity: 0;
}
.dropzone .dz-preview-single {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.dropzone .dz-preview-single:hover {
  z-index: auto;
}
.dropzone .dz-preview-single .dz-preview-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0 !important;
  overflow: hidden;
}
.dropzone .dz-preview-single .dz-preview-cover .dz-errormessage {
  position: absolute;
  left: 0;
  bottom: 0;
}
.dropzone .dz-preview-single .dz-preview-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}
.dropzone.dropzone-multiple {
  border: 0;
  background: var(--phoenix-gray-soft);
}
.dropzone.dropzone-multiple .dz-message {
  padding: 3rem 2rem;
  border: 1px dashed var(--phoenix-gray-300);
  border-radius: 0.5rem;
}
.dropzone.dropzone-multiple .btn *, .dropzone.dropzone-multiple .google-map-control-btn .zoomIn *, .google-map-control-btn .dropzone.dropzone-multiple .zoomIn *,
.dropzone.dropzone-multiple .google-map-control-btn .zoomOut *,
.google-map-control-btn .dropzone.dropzone-multiple .zoomOut *, .dropzone.dropzone-multiple .tox .tox-dialog__footer .tox-button--secondary *, .tox .tox-dialog__footer .dropzone.dropzone-multiple .tox-button--secondary *, .dropzone.dropzone-multiple .tox .tox-button-secondary *, .tox .dropzone.dropzone-multiple .tox-button-secondary *, .dropzone.dropzone-multiple .tox .tox-button *, .tox .dropzone.dropzone-multiple .tox-button *, .dropzone.dropzone-multiple [data-list] .page *, [data-list] .dropzone.dropzone-multiple .page *, .dropzone.dropzone-multiple .table-list .page *, .table-list .dropzone.dropzone-multiple .page * {
  cursor: pointer;
}
.dropzone.dropzone-multiple .media:first-child {
  margin-top: 1.5rem;
}
.dropzone.dropzone-multiple .dz-image-container {
  padding: 1rem;
}
.dropzone.dropzone-multiple .dz-image {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 0;
}
.dropzone.dropzone-multiple .dz-progress {
  opacity: 0;
  position: relative !important;
  transition: opacity 0.4s ease-in;
  animation: none !important;
  width: 6.25rem !important;
  height: 0.5rem !important;
  margin-top: 0;
  margin-left: 1rem !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
}
.dropzone.dropzone-multiple .dz-complete .dz-progress {
  transition-delay: 1s;
  opacity: 1;
}
.dropzone.dz-drag-hover .dz-message {
  border-color: var(--phoenix-primary);
}
.dropzone .dz-progress {
  background: var(--phoenix-gray-200) !important;
}
.dropzone .dz-progress .dz-upload {
  background: var(--phoenix-primary) !important;
}

.dropzone-area {
  border: 1px dashed var(--phoenix-gray-400);
  border-radius: 0.375rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* -------------------------------------------------------------------------- */
/*                                   Choices                                  */
/* -------------------------------------------------------------------------- */
.choices {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.choices .choices__inner {
  border-radius: 0.375rem;
  line-height: 1.5rem !important;
  font-size: 1rem;
  background-color: var(--phoenix-input-bg) !important;
  min-height: var(--phoenix-choices-inner-min-height) !important;
  background-size: 9px 12px;
  border: 1px solid var(--phoenix-input-border-color);
  padding: 6px 3.5rem 6px 1rem;
  box-shadow: inset 0 1px 2px transparent;
}
.choices .choices__input--cloned {
  padding: 0;
  font-size: 0.8rem;
  color: var(--phoenix--input-color);
  background-color: var(--phoenix-input-bg) !important;
  vertical-align: top;
  margin-bottom: 0;
}
.choices .choices__list--dropdown {
  border: 1px solid var(--phoenix-gray-200) !important;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: var(--phoenix-white);
}
.choices .choices__list--dropdown .choices__item--selectable {
  padding: 2px 1rem;
  padding-right: 20px;
  font-size: 1rem !important;
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-body-color);
}
.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: var(--phoenix-primary);
  color: var(--phoenix-white);
}
.choices .choices__list {
  margin-top: 0 !important;
}
.choices .choices__list .has-no-choices {
  background-color: var(--phoenix-choices-item-has-no-choices-bg);
}
.choices .choices__list--multiple .choices__item {
  text-transform: uppercase;
  padding: 2.5px 8px !important;
  font-size: 0.64rem !important;
  border: 0;
  background-color: var(--phoenix-choices-item-bg) !important;
  border-radius: 4px;
  margin-bottom: 0 !important;
  color: var(--phoenix-choices-item-color);
  font-weight: 700;
  line-height: 1;
}
.choices .choices__list--single {
  padding: 0 !important;
}
.choices .choices__list--single .choices__button {
  background-color: transparent !important;
}

.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM2ZTc4OTEiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLXgiPjxsaW5lIHgxPSIxOCIgeTE9IjYiIHgyPSI2IiB5Mj0iMTgiPjwvbGluZT48bGluZSB4MT0iNiIgeTE9IjYiIHgyPSIxOCIgeTI9IjE4Ij48L2xpbmU+PC9zdmc+);
  background-color: transparent !important;
  padding: 0px !important;
  background-size: 11px !important;
  margin: 0;
  margin-left: 6px;
  border: none;
  background-position: -2px -1px;
}
.firefox .choices[data-type*=select-multiple] .choices__button,
.firefox .choices[data-type*=text] .choices__button {
  background-position: -2px 0px;
}

.choices[data-type*=select-one] .choices__input {
  padding-left: 1rem;
  padding-right: 1rem;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 5px !important;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-radius: 0.375rem !important;
  border-color: #3874ff !important;
  box-shadow: 0 0 0 0.25rem rgba(56, 116, 255, 0.25) !important;
  outline: 0;
}

.choices-select-container {
  position: relative;
}
.choices-select-container .choices__inner {
  padding-left: 2.5rem;
}
.choices-select-container .choices-icon {
  position: absolute;
  left: 1rem;
}

.choices__item .choices__item--choice .has-no-results {
  background-color: red !important;
}

.was-validated .choices.valid .choices__inner {
  border-color: var(--phoenix-success) !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2325b003' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .choices[data-type=select-one].valid .choices__inner {
  background-position: right calc(0.375em + 1.55rem) center;
}
.was-validated .choices.invalid {
  margin-bottom: 0;
}
.was-validated .choices.invalid .choices__inner {
  border-color: var(--phoenix-danger) !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .choices.invalid + .invalid-feedback {
  display: block;
}
.was-validated .choices[data-type=select-one].invalid .choices__inner {
  background-position: right calc(0.375em + 1.55rem) center;
}
.was-validated .choices[data-type*=select-one] .choices__button {
  margin-right: 60px !important;
}

/*-----------------------------------------------
|   Tinymce
-----------------------------------------------*/
.tox {
  border-radius: 0;
}
.tox.tox-tinymce {
  border: 0;
  border-radius: 0;
}
.tox .tox-edit-area {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border: none !important;
}
.tox .tox-toolbar__group:first-child {
  padding-left: 0 !important;
}
.tox .tox-editor-header {
  margin-bottom: 0.25rem;
  box-shadow: none !important;
  background-color: transparent !important;
}
.tox .tox-editor-header .tox-toolbar-overlord {
  background-color: transparent !important;
}
.tox .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
  background-color: transparent !important;
}
[dir=rtl] .tox .tox-editor-header .tox-toolbar-overlord .tox-toolbar__primary {
  justify-content: flex-end;
}
.tox .tox-sidebar-wrap {
  overflow: hidden;
  border: 1px solid var(--phoenix-input-border-color);
  overflow: hidden;
  border-radius: 0.375rem;
}
.tox .tox-menu,
.tox .tox-dialog {
  border-radius: 0.375rem !important;
  border-color: var(--phoenix-border-color) !important;
  background-color: var(--phoenix-tinymce-bg) !important;
}
.tox .tox-dialog__header,
.tox .tox-dialog__footer {
  background: var(--phoenix-white) !important;
  padding: 8px 16px 32px !important;
}
.tox .tox-collection__item--active,
.tox .tox-collection__item-accessory {
  background-color: var(--phoenix-gray-200) !important;
  color: var(--phoenix-body-color) !important;
}
.tox .tox-dialog__title {
  color: var(--phoenix-body-color) !important;
}
.tox .tox-form__group {
  margin-bottom: 1rem !important;
}
.tox .tox-label {
  color: var(--phoenix-gray-700) !important;
  margin-bottom: 0.25rem !important;
}
.tox .tox-textfield {
  background-color: var(--phoenix-input-bg) !important;
  color: var(--phoenix-body-color) !important;
  border-color: var(--phoenix-input-border-color) !important;
  padding: 0.5rem 1rem !important;
}
.tox .tox-textfield:focus {
  border-color: #3874ff !important;
  box-shadow: 0 0 0 0.25rem rgba(56, 116, 255, 0.25) !important;
}
.tox .tox-listbox {
  background-color: var(--phoenix-input-bg) !important;
  color: var(--phoenix-body-color) !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  border-color: var(--phoenix-border-color) !important;
}
.tox .tox-listbox.tox-listbox--select:focus {
  box-shadow: none !important;
}
.tox .tox-collection--list .tox-collection__item {
  background-color: transparent !important;
  color: var(--phoenix-gray-800) !important;
}
.tox .tox-collection--list .tox-collection__item--active {
  background-color: var(--phoenix-gray-200) !important;
}
.tox .tox-button--naked {
  color: var(--phoenix-body-color) !important;
}
.tox .tox-button--naked:hover:not(:disabled) {
  background-color: var(--phoenix-gray-200) !important;
  border-color: var(--phoenix-border-color) !important;
}
.tox .tox-icon {
  color: var(--phoenix-body-color) !important;
}
.tox .tox-dialog {
  border-radius: 0.375rem !important;
  border-color: var(--phoenix-border-color) !important;
  background-color: var(--phoenix-tinymce-bg) !important;
}
.tox .tox-dialog__header, .tox .tox-dialog__footer {
  background-color: var(--phoenix-tinymce-bg) !important;
  border-color: var(--phoenix-border-color) !important;
}
.tox .tox-toolbar__overflow {
  padding-left: 1rem !important;
  background-color: var(--phoenix-gray-100) !important;
  background-image: none !important;
}

.tox .tox-tbtn {
  height: 24px !important;
  width: 24px !important;
}
.tox .tox-tbtn span svg {
  fill: var(--phoenix-gray-700) !important;
}
.tox .tox-tbtn span svg:hover, .tox .tox-tbtn span svg:focus {
  fill: var(--phoenix-gray-700) !important;
}
.tox .tox-tbtn:hover, .tox .tox-tbtn:focus {
  background: var(--phoenix-gray-200) !important;
}

.tox .tox-tbtn--enabled {
  background: var(--phoenix-gray-300) !important;
}
.tox .tox-tbtn--enabled span svg {
  fill: var(--phoenix-tinymce-tox-tbtn-enabled-icon-color) !important;
}
.tox .tox-tbtn--enabled:hover, .tox .tox-tbtn--enabled:focus {
  background: var(--phoenix-gray-200) !important;
}

.tox .tox-edit-area__iframe {
  background-color: var(--phoenix-tinymce-bg) !important;
}

/* -------------------------------------------------------------------------- */
/*                                  Flatpickr                                 */
/* -------------------------------------------------------------------------- */
.flatpickr-input-container {
  position: relative;
}
.flatpickr-input-container .flatpickr-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-12px);
}
.flatpickr-input-container .form-floating input {
  padding-left: 2.25rem;
}
.flatpickr-input-container.flatpickr-input-sm .flatpickr-icon {
  transform: translateY(-13px);
}

.flatpickr-calendar {
  width: 322px !important;
  overflow: hidden;
  text-align: unset;
  background-color: var(--phoenix-flatpickr-calendar-bg) !important;
  box-shadow: none !important;
  border: 1px solid var(--phoenix-border-color) !important;
}
.flatpickr-calendar.open {
  z-index: 1100 !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--phoenix-flatpickr-calendar-bg) !important;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--phoenix-border-color) !important;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--phoenix-flatpickr-calendar-bg) !important;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--phoenix-border-color) !important;
}

.flatpickr-input:disabled, .flatpickr-input[readonly] {
  background-color: var(--phoenix-flatpickr-calendar-input-bg);
}

.flatpickr-time .flatpickr-am-pm,
.flatpickr-time input {
  color: var(--phoenix-gray-400);
}
.flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time input:hover,
.flatpickr-time input:focus {
  background-color: var(--phoenix-gray-100) !important;
}

.flatpickr-day {
  margin: 4px !important;
  color: var(--phoenix-gray-900);
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 0;
  height: 32px !important;
  width: 32px !important;
  max-width: 32px !important;
  border-radius: 0.25rem;
}
.flatpickr-day.weekend-days {
  color: var(--phoenix-flatpickr-weekend-days-color);
}
.flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay {
  color: var(--phoenix-gray-400);
}
.flatpickr-day.today {
  background: var(--phoenix-primary) !important;
  color: var(--phoenix-flatpickr-today-color);
  border-radius: 50%;
}
.flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background-color: var(--phoenix-flatpickr-calendar-day-hover-bg);
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background: var(--phoenix-primary-600) !important;
  color: #fff;
}
.flatpickr-day.selected {
  background-color: var(--phoenix-gray-300);
  color: var(--phoenix-gray-900);
  border: 0;
}
.flatpickr-day.selected:hover, .flatpickr-day.selected:focus {
  background-color: rgba(56, 116, 255, 0.35);
  color: var(--phoenix-gray-900);
}
.flatpickr-day.inRange {
  background-color: rgba(56, 116, 255, 0.15);
}
.flatpickr-day.selected {
  background-color: rgba(56, 116, 255, 0.25);
}

.flatpickr-time input.flatpickr-hour {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 5px;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 0 !important;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top-color: var(--phoenix-border-color) !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: none;
}

span.flatpickr-weekday,
.flatpickr-weekdaycontainer,
.flatpickr-months .flatpickr-month {
  background-color: var(--phoenix-flatpickr-calendar-bg) !important;
  color: var(--phoenix-body-color) !important;
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: var(--phoenix-gray-700);
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--phoenix-gray-800);
}

.flatpickr-day.inRange {
  -webkit-box-shadow: -5px 0 0 var(--phoenix-flatpickr-calendar-bg), 5px 0 0 var(--phoenix-flatpickr-calendar-bg);
  box-shadow: -5px 0 0 var(--phoenix-flatpickr-calendar-bg), 5px 0 0 var(--phoenix-flatpickr-calendar-bg);
}

.flatpickr-months {
  height: 50px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  top: 20px;
  padding: 0;
}
.flatpickr-months .flatpickr-prev-month {
  left: 28px !important;
}
[dir=rtl] .flatpickr-months .flatpickr-prev-month {
  right: 28px;
}
.flatpickr-months .flatpickr-next-month {
  right: 25px !important;
}
[dir=rtl] .flatpickr-months .flatpickr-next-month {
  left: 25px;
  right: auto !important;
}
.flatpickr-months .flatpickr-month {
  height: 100%;
  padding-top: 1rem;
}
.flatpickr-months .flatpickr-month .flatpickr-current-month {
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  width: auto;
  height: 100%;
  color: var(--phoenix-flatpickr-current-month-color);
  padding: 0 2rem;
  background-color: var(--phoenix-flatpickr-calendar-bg) !important;
  font-size: 1.5625rem;
}
.flatpickr-months .flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
  font-weight: 700;
  color: #141824;
}
.flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month:hover, .flatpickr-months .flatpickr-month .flatpickr-current-month .cur-month:focus,
.flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper:hover,
.flatpickr-months .flatpickr-month .flatpickr-current-month .numInputWrapper:focus {
  background: transparent;
}
.flatpickr-months .flatpickr-month .flatpickr-current-month .cur-year {
  font-weight: 600;
}

.flatpickr-innerContainer {
  padding: 20px 20px 16px 20px;
}
.flatpickr-innerContainer .flatpickr-rContainer {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}
.flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days {
  width: 100%;
}
.flatpickr-innerContainer .flatpickr-rContainer .flatpickr-days .dayContainer {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

/*-----------------------------------------------
|   Isotope
-----------------------------------------------*/
.isotope-nav {
  font-weight: 700;
  font-size: 1rem;
  color: var(--phoenix-gray-800);
  letter-spacing: 0.02em;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
}
.isotope-nav.active {
  color: var(--phoenix-primary);
  border: 1px solid var(--phoenix-primary);
  border-radius: 1.2rem;
}
.isotope-nav:hover {
  text-decoration: none;
}

.isotope-item {
  visibility: hidden;
}

.sortable .menu {
  display: flex;
  flex-wrap: wrap;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 0.13rem;
}
.sortable .menu .isotope-nav {
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  margin-right: 0.25rem;
}
.sortable .menu .isotope-nav:last-child {
  margin-right: 0;
}
.sortable .menu .isotope-nav.active {
  border-color: currentColor;
  color: #000000;
  border-radius: 0.375rem;
}

/* -------------------------------------------------------------------------- */
/*                                 Google Map                                 */
/* -------------------------------------------------------------------------- */
.googlemap .gm-style-iw.gm-style-iw-c {
  background: var(--phoenix-white);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  padding: 1rem !important;
}
.googlemap .gm-style-iw.gm-style-iw-c button[title=Close] {
  margin-top: 1rem !important;
  margin-right: 0.5rem !important;
}
.googlemap .gm-style-iw .gm-style-iw-d {
  overflow: auto !important;
}
.googlemap .gm-style-iw-tc {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  filter: none;
}
.googlemap .gm-style-iw-tc:after {
  background: var(--phoenix-white) !important;
}

html[dir=rtl] .googlemap .gm-style-iw.gm-style-iw-c button[title=Close] {
  left: unset !important;
  right: 0 !important;
}

.googlemap > div {
  background: transparent !important;
}

.google-map-control-btn {
  padding: 1rem;
}
.google-map-control-btn .zoomIn,
.google-map-control-btn .zoomOut {
  border-width: 2px;
}
.google-map-control-btn .zoomIn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.google-map-control-btn .zoomOut {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.picmo__popupContainer {
  z-index: 9999;
}

.picmo__picker.picmo__picker {
  width: 300px;
  --background-color: #f5f7fa;
  --border-color: var(--phoenix-gray-300);
  --category-tab-active-color: var(--phoenix-gray-300);
  --category-tab-highlight-background-color: var(--phoenix-gray-200);
  --category-tab-color: var(--phoenix-gray-900);
  --text-color: var(--phoenix-body-color);
  --emoji-size: 1.8rem !important;
}
.dark .picmo__picker.picmo__picker {
  color-scheme: dark;
  --background-color: #141824;
}
.picmo__picker.picmo__picker .picmo__searchField {
  --search-background-color: var(--phoenix-white);
}
.dark .picmo__picker.picmo__picker .picmo__searchField {
  --search-focus-background-color: #141824;
}
.picmo__picker.picmo__picker section {
  padding-top: 0;
  padding-bottom: 0;
}
.picmo__picker.picmo__picker .picmo__header {
  --secondary-background-color: #f5f7fa;
}
.dark .picmo__picker.picmo__picker .picmo__header {
  --secondary-background-color: #222834;
}
.picmo__picker.picmo__picker .picmo__preview {
  --preview-background-color: #f5f7fa;
}
.dark .picmo__picker.picmo__picker .picmo__preview {
  --preview-background-color: #222834;
}
.picmo__picker.picmo__picker .picmo__emojiCategory .picmo__categoryName {
  --category-name-background-color: var(--phoenix-gray-soft);
  --category-name-text-color: var(--phoenix-gray-600);
  font-size: 0.64rem;
  font-weight: 600;
}
.dark .picmo__picker.picmo__picker .picmo__emojiCategory .picmo__categoryName {
  --category-name-background-color: transparent;
}
.picmo__picker.picmo__picker .picmo__emojiButton:hover {
  --hover-background-color: var(--phoenix-gray-200);
}
.picmo__picker.picmo__picker .picmo__content {
  min-height: unset !important;
  padding: unset !important;
}
.picmo__picker.picmo__picker .picmo__searchContainer .picmo__searchField {
  border: 1px solid var(--phoenix-gray-200);
  border-radius: 0.375rem;
  padding: 1rem;
}
.picmo__picker.picmo__picker .picmo__categoryButtons .picmo__categoryTab button.picmo__categoryButton {
  font-size: 1rem;
}
.picmo__picker.picmo__picker .picmo__categoryButtons .picmo__categoryTab.picmo__categoryTabActive .picmo__categoryButton {
  border: unset;
  background: unset;
  color: #3874ff;
  --category-tab-active-background-color: var(--phoenix-gray-200);
}

/* -------------------------------------------------------------------------- */
/*                                   Leaflet                                  */
/* -------------------------------------------------------------------------- */
.leaflet-bar {
  box-shadow: var(--phoenix-box-shadow);
}
.leaflet-bar a, .leaflet-bar a:hover {
  background-color: var(--phoenix-leaflet-bar-bg);
  color: var(--phoenix-body-color);
  border-bottom-color: var(--phoenix-border-color);
}
.leaflet-bar a.leaflet-disabled {
  background-color: var(--phoenix-gray-200);
}

.marker-cluster-small {
  background-color: rgba(56, 116, 255, 0.2) !important;
}
.marker-cluster-small div {
  margin: 5px;
  background-color: var(--phoenix-primary) !important;
  color: var(--phoenix-white);
}

.marker-cluster-medium {
  background-color: rgba(229, 120, 11, 0.2) !important;
}
.marker-cluster-medium div {
  margin: 5px;
  background-color: var(--phoenix-warning) !important;
  color: var(--phoenix-white);
}

.marker-cluster-large {
  background-color: rgba(37, 176, 3, 0.2) !important;
}
.marker-cluster-large div {
  margin: 5px;
  background-color: var(--phoenix-success) !important;
  color: var(--phoenix-white);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: var(--phoenix-leaflet-popup-content-wrapper-bg);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.leaflet-popup-close-button {
  right: 3px !important;
  top: 3px !important;
}

.leaflet-marker-icon {
  height: auto !important;
  width: auto !important;
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-top,
.leaflet-bottom {
  z-index: 999;
}

.leaflet-left .leaflet-control {
  margin-left: 1.5rem;
}

.leaflet-right .leaflet-control {
  margin-right: 1.5rem;
}

.leaflet-top .leaflet-control {
  margin-top: 1.5rem;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 1.5rem;
}

#appCalendar {
  height: calc(100vh - 12.4rem) !important;
}

.fc {
  --fc-page-bg-color: transparent;
  --fc-event-text-color: var(--phoenix-body-color);
}
.fc .fc-button .fc-icon {
  line-height: 1rem;
  font-size: 1.2em;
}
.fc .fc-scrollgrid {
  border-color: var(--phoenix-calendar-border-color);
}
.fc .fc-timegrid-body {
  background-color: var(--phoenix-calendar-bg);
}
.fc .fc-timegrid-axis-frame {
  background-color: var(--phoenix-calendar-bg);
}
.fc.fc-theme-standard a:not([href]) {
  color: inherit;
}
.fc.fc-theme-standard .fc-list,
.fc.fc-theme-standard td,
.fc.fc-theme-standard th {
  border: 1px solid var(--phoenix-calendar-border-color);
}
.fc.fc-theme-standard .fc-daygrid-day {
  background-color: var(--phoenix-calendar-bg);
}
.fc.fc-theme-standard .fc-daygrid-day.fc-day-other {
  opacity: 0.4;
}
.fc.fc-theme-standard .fc-daygrid-day.fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}
.fc.fc-theme-standard .fc-daygrid-day.fc-day-fri, .fc.fc-theme-standard .fc-daygrid-day.fc-day-sat {
  color: var(--phoenix-warning-500) !important;
}
.fc .fc-col-header {
  background-color: var(--phoenix-gray-100);
}
.fc .fc-col-header th {
  border-bottom-width: 1px;
  font-weight: 600;
  color: var(--phoenix-gray-700);
}
.fc .fc-col-header-cell-cushion {
  text-decoration: none !important;
  padding-top: 12px;
  padding-bottom: 8px;
}
@media (min-width: 992px) {
  .fc .fc-timeGridWeek-view .fc-col-header-cell-cushion {
    width: 70px;
  }
}
.fc .fc-timeGridWeek-view .fc-timegrid-divider {
  display: none;
}
.fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion {
  position: relative;
  color: var(--phoenix-primary);
}
@media (min-width: 1200px) {
  .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion {
    color: var(--phoenix-body-color);
  }
  .fc .fc-timeGridWeek-view .fc-col-header-cell.fc-day-today .fc-col-header-cell-cushion::after {
    content: "";
    position: absolute;
    left: -8px;
    top: 43%;
    width: 0;
    height: 0;
    border: 4px solid var(--phoenix-primary);
    border-radius: 4px;
  }
}
.fc .fc-daygrid-day-frame {
  border: 2px solid transparent;
  padding: 2px !important;
  transition: all 0.2s ease-in-out;
}
.fc .fc-daygrid-day-frame:active {
  background-color: var(--phoenix-gray-300) !important;
}
.fc .fc-daygrid-day-top {
  justify-content: flex-end;
  margin-bottom: 0.25rem;
}
.fc .fc-daygrid-day-number {
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
  line-height: 1.875rem;
  padding: 0 !important;
  transition: all 0.2s ease-in-out;
}
.fc .fc-daygrid-bg-harness {
  top: -2px;
}
.fc .fc-daygrid-event {
  border-radius: 0.375rem !important;
  margin-top: 0;
  margin-bottom: 0.25rem !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.64rem;
  border: 1px solid var(--phoenix-gray-300) !important;
}
.fc .fc-h-event,
.fc .fc-daygrid-event {
  background-color: var(--phoenix-white) !important;
}
.fc .fc-h-event .fc-event-main,
.fc .fc-daygrid-event .fc-event-main {
  padding-left: 15px;
}
.fc .fc-h-event .fc-event-main::after,
.fc .fc-daygrid-event .fc-event-main::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid var(--phoenix-primary);
  border-radius: 4px;
}
.fc .fc-h-event .fc-event-time,
.fc .fc-h-event .fc-event-title,
.fc .fc-daygrid-event .fc-event-time,
.fc .fc-daygrid-event .fc-event-title {
  font-weight: 600 !important;
}
.fc .fc-event:focus {
  box-shadow: none;
}
.fc .fc-event:focus::after {
  border-radius: 0.375rem;
}
.fc .fc-event-title {
  font-weight: normal !important;
}
.fc .fc-daygrid-event-dot {
  border-color: var(--phoenix-gray-300) !important;
}
.fc .fc-daygrid-dot-event {
  align-items: baseline !important;
}
.fc .fc-day-today:not(.fc-popover).fc-timegrid-col {
  background-color: transparent !important;
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number {
  background-color: var(--phoenix-primary) !important;
  color: #fff;
}
.fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:hover, .fc .fc-day-today:not(.fc-popover) .fc-daygrid-day-number:focus {
  background-color: #0550ff !important;
}
.fc.fc-direction-rtl .fc-daygrid-event.fc-event-start, .fc.fc-direction-rtl .fc-daygrid-event.fc-event-end, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-left: 0;
  margin-right: 0;
}
.fc .fc-popover {
  border-color: var(--phoenix-gray-200);
  box-shadow: 0px 2px 4px -2px rgba(36, 40, 46, 0.08);
  background-color: var(--phoenix-white);
  z-index: 1000;
}
.fc .fc-popover .fc-popover-title {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.fc .fc-popover .fc-daygrid-event {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 2px !important;
}
.fc .fc-popover-header {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: var(--phoenix-popover-header-bg);
}
.fc .fc-daygrid-more-link {
  display: block;
  text-align: center;
  color: var(--phoenix-gray-500) !important;
  font-size: 0.64rem;
}
.fc .fc-daygrid-more-link:hover, .fc .fc-daygrid-more-link:focus {
  text-decoration: none;
  color: var(--phoenix-gray-600) !important;
}
.fc .fc-daygrid-dot-event {
  color: #8a94ad !important;
}
.fc .fc-daygrid-dot-event:hover, .fc .fc-daygrid-dot-event:focus {
  background-color: var(--phoenix-gray-100) !important;
}
.fc .fc-day:not(.fc-popover) .fc-daygrid-dot-event {
  display: flex;
  justify-content: center;
}
.fc .fc-list-event:hover td {
  background-color: unset;
}
.fc .fc-dayGridMonth-view .fc-event-time {
  display: none;
}
.fc .fc-timeGridDay-view .fc-scrollgrid-sync-inner {
  text-align: left;
}
.fc .fc-timeGridDay-view .fc-daygrid-day-events,
.fc .fc-timeGridWeek-view .fc-daygrid-day-events {
  margin-bottom: 0;
}
.fc .fc-timeGridDay-view .fc-v-event .fc-event-main,
.fc .fc-timeGridWeek-view .fc-v-event .fc-event-main {
  padding-left: 1rem;
  color: var(--phoenix-gray-700);
}
.fc .fc-timeGridDay-view .fc-v-event .fc-event-main:after,
.fc .fc-timeGridWeek-view .fc-v-event .fc-event-main:after {
  content: "";
  position: absolute;
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background-color: var(--phoenix-gray-400);
  top: 0.3125rem;
  left: 0;
}
.fc .fc-timeGridDay-view .fc-timegrid-event,
.fc .fc-timeGridWeek-view .fc-timegrid-event {
  padding: 0.5rem;
  background-color: transparent;
  border: 0;
  border-radius: 0.5rem;
}
.fc .fc-timeGridDay-view .fc-timegrid-event:hover, .fc .fc-timeGridDay-view .fc-timegrid-event:focus,
.fc .fc-timeGridWeek-view .fc-timegrid-event:hover,
.fc .fc-timeGridWeek-view .fc-timegrid-event:focus {
  background-color: var(--phoenix-gray-200);
}
.fc .fc-timeGridDay-view .fc-timegrid-slot,
.fc .fc-timeGridWeek-view .fc-timegrid-slot {
  height: 2rem;
}
.fc .fc-timeGridDay-view .fc-timegrid-slot-lane,
.fc .fc-timeGridWeek-view .fc-timegrid-slot-lane {
  cursor: pointer;
}
.fc .fc-timeGridDay-view .fc-timegrid-slot-lane:active,
.fc .fc-timeGridWeek-view .fc-timegrid-slot-lane:active {
  background-color: var(--phoenix-gray-100);
}
.fc .fc-timeGridDay-view .fc-timegrid-col,
.fc .fc-timeGridWeek-view .fc-timegrid-col {
  padding: 0.375rem !important;
}
.fc .fc-list .fc-list-day-cushion {
  padding: 0.5rem 1.25rem;
  background-color: var(--fc-button-list-day-cushion);
}
.fc .fc-list .fc-list-day:not(:first-child) .fc-list-day-cushion {
  margin-top: 1.5rem;
}
.fc .fc-list .fc-list-event-time {
  padding-left: 1.25rem;
}
.fc .fc-list .fc-list-event-title {
  padding-right: 1.25rem;
}
.fc .fc-list-empty {
  background-color: var(--phoenix-gray-100);
}
.fc .fc-list-event-dot {
  border-color: var(--phoenix-gray-300);
}
.fc .fc-timegrid .text-primary .fc-event-main:after {
  background-color: #3874ff !important;
}
.fc .text-primary .fc-event-main:after {
  background-color: #3874ff !important;
}
.fc .text-primary.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-primary.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-primary) !important;
}
.fc .text-primary.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-primary);
}
.fc .text-primary.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-primary) !important;
}
.fc .fc-timegrid .text-secondary .fc-event-main:after {
  background-color: #31374a !important;
}
.fc .text-secondary .fc-event-main:after {
  background-color: #31374a !important;
}
.fc .text-secondary.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-secondary.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-secondary) !important;
}
.fc .text-secondary.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-secondary);
}
.fc .text-secondary.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-secondary) !important;
}
.fc .fc-timegrid .text-success .fc-event-main:after {
  background-color: #25b003 !important;
}
.fc .text-success .fc-event-main:after {
  background-color: #25b003 !important;
}
.fc .text-success.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-success.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-success) !important;
}
.fc .text-success.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-success);
}
.fc .text-success.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-success) !important;
}
.fc .fc-timegrid .text-info .fc-event-main:after {
  background-color: #0097eb !important;
}
.fc .text-info .fc-event-main:after {
  background-color: #0097eb !important;
}
.fc .text-info.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-info.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-info) !important;
}
.fc .text-info.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-info);
}
.fc .text-info.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-info) !important;
}
.fc .fc-timegrid .text-warning .fc-event-main:after {
  background-color: #e5780b !important;
}
.fc .text-warning .fc-event-main:after {
  background-color: #e5780b !important;
}
.fc .text-warning.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-warning.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-warning) !important;
}
.fc .text-warning.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-warning);
}
.fc .text-warning.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-warning) !important;
}
.fc .fc-timegrid .text-danger .fc-event-main:after {
  background-color: #ed2000 !important;
}
.fc .text-danger .fc-event-main:after {
  background-color: #ed2000 !important;
}
.fc .text-danger.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-danger.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-danger) !important;
}
.fc .text-danger.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-danger);
}
.fc .text-danger.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-danger) !important;
}
.fc .fc-timegrid .text-light .fc-event-main:after {
  background-color: #eff2f6 !important;
}
.fc .text-light .fc-event-main:after {
  background-color: #eff2f6 !important;
}
.fc .text-light.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-light.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-light) !important;
}
.fc .text-light.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-light);
}
.fc .text-light.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-light) !important;
}
.fc .fc-timegrid .text-dark .fc-event-main:after {
  background-color: #141824 !important;
}
.fc .text-dark .fc-event-main:after {
  background-color: #141824 !important;
}
.fc .text-dark.fc-v-event {
  border: 1px solid var(--phoenix-gray-300) !important;
  background-color: var(--phoenix-gray-100) !important;
}
.fc .text-dark.fc-daygrid-event .fc-event-main {
  color: var(--phoenix-dark) !important;
}
.fc .text-dark.fc-daygrid-event .fc-event-main::after {
  border: 4px solid var(--phoenix-dark);
}
.fc .text-dark.fc-daygrid-event .fc-daygrid-event-dot {
  border-color: var(--phoenix-dark) !important;
}

.timeline li {
  position: relative;
  padding-left: 1.5rem;
}
.timeline li:after {
  position: absolute;
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  border-radius: 50%;
  background: var(--phoenix-gray-200);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.timeline li:not(:last-child):before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--phoenix-gray-200);
  top: 50%;
  left: 0.3125rem;
}

.windows.chrome .fc-scroller {
  overflow: hidden auto !important;
  overflow: auto;
}
.windows.chrome .fc-scroller::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.windows.chrome .fc-scroller::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: var(--phoenix-scrollbar-bg);
}
.windows.chrome .fc-scroller:hover::-webkit-scrollbar, .windows.chrome .fc-scroller:hover::-webkit-scrollbar-thumb, .windows.chrome .fc-scroller:focus::-webkit-scrollbar, .windows.chrome .fc-scroller:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}
.windows.firefox .fc-scroller {
  overflow: hidden auto !important;
  overflow: auto !important;
  scrollbar-color: var(--phoenix-scrollbar-bg) transparent;
  scrollbar-width: thin;
}

.firefox .fc table {
  border-collapse: unset;
}

@media (min-width: 768px) {
  .fc .fc-daygrid-day-frame {
    padding: 0.375rem !important;
  }
  .fc .fc-daygrid-more-link {
    margin-left: 0.625rem;
    text-align: left;
    font-size: 0.8rem;
  }
  .fc .fc-daygrid-event {
    font-size: 0.8rem;
  }
}
.ie .fc-daygrid-event {
  overflow: hidden;
}

.safari .fc-dayGridMonth-view .fc-daygrid-day {
  position: relative;
}
.safari .fc-dayGridMonth-view .fc-daygrid-day .fc-daygrid-day-frame {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.fc .fc-timegrid-axis-frame,
.fc-list-event-time {
  text-transform: capitalize;
}

/* -------------------------------------------------------------------------- */
/*                                    Text                                    */
/* -------------------------------------------------------------------------- */
.text-smallcaps {
  font-variant: small-caps;
}

.text-superscript {
  vertical-align: super;
}

.text-word-break {
  word-break: break-word;
}

/*-----------------------------------------------
|   Font family
-----------------------------------------------*/
.font-sans-serif {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.font-base {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*-----------------------------------------------
|   Error Page
-----------------------------------------------*/
.fs-error {
  font-size: 7rem;
}
@media (min-width: 576px) {
  .fs-error {
    font-size: 10rem;
  }
}

/*-----------------------------------------------
|   Text alignment
-----------------------------------------------*/
.text-justify {
  text-align: justify !important;
}

/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}
.bg-holder.bg-right {
  left: auto;
  right: 0;
}
.bg-holder.overlay:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-holder.overlay-0:before {
  background: rgba(0, 0, 0, 0.7);
}
.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.55);
}
.bg-holder.overlay-2:before {
  background: rgba(0, 0, 0, 0.4);
}
.bg-holder .bg-video {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}
.bg-holder .bg-youtube {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-glass {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-card {
  background-size: contain;
  background-position: right;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
[dir=rtl] .bg-card {
  background-position: left;
  transform: scaleX(-1);
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

/*-----------------------------------------------
|   Background gradient
-----------------------------------------------*/
.bg-line-chart-gradient {
  background-image: var(--phoenix-line-chart-gradient);
  background-position: center;
}

.bg-card-gradient {
  background-image: var(--phoenix-card-gradient);
  background-position: center;
}

.bg-progress-gradient {
  background-image: var(--phoenix-progress-gradient);
  background-position: center;
}

.bg-auth-circle-shape,
.bg-auth-circle-shape-2 {
  display: none;
}
@media (min-width: 992px) {
  .bg-auth-circle-shape,
.bg-auth-circle-shape-2 {
    display: block;
  }
}

.bg-auth-circle-shape {
  position: absolute;
  right: -8.75rem;
  top: -5.125rem;
}

.bg-auth-circle-shape-2 {
  position: absolute;
  left: -6.25rem;
  bottom: -2.4375rem;
}

.bg-auth-card-shape {
  background-position: 0 100%;
}
@media (min-width: 768px) {
  .bg-auth-card-shape {
    background-position: 0 133%;
  }
}

.bg-shape {
  position: relative;
  overflow: hidden;
  background-color: var(--phoenix-bg-shape-bg);
}
.bg-shape:after, .bg-shape:before {
  position: absolute;
  content: "";
  border-radius: 50%;
}
.bg-shape:after {
  background-image: var(--phoenix-bg-shape-bg-ltd);
}
.bg-shape:before {
  background-image: var(--phoenix-bg-shape-bg-dtl);
}

.bg-circle-shape:after {
  height: 15.625rem;
  width: 115%;
  left: 32%;
  top: -188%;
}
.bg-circle-shape:before {
  height: 332%;
  width: 45%;
  left: -9%;
  top: 0.5625rem;
}

.modal-shape-header:before {
  height: 28.9375rem;
  width: 155%;
  right: 23%;
  top: -357%;
}
.modal-shape-header:after {
  height: 289%;
  width: 45%;
  right: -10%;
  top: 2.5rem;
}

.showcase-page-gradient {
  background: linear-gradient(357.7deg, #024787 -88.88%, rgba(0, 79, 197, 0) 27.93%);
}

.bg-transparent-50 {
  background-color: var(--phoenix-transparent-50);
}

.bg-product-hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */
.overflow-hidden[class*=rounded] {
  mask-image: radial-gradient(#fff, #000000);
}

.border-dashed {
  --phoenix-border-style: dashed;
}

/* -------------------------------------------------------------------------- */
/*                                  Position                                  */
/* -------------------------------------------------------------------------- */
.container,
.container-fluid,
.container-small-xl,
.container-small-lg,
.container-small-md,
.container-small-sm,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  position: relative;
}

.all-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* -------------------------------------------------------------------------- */
/*                                    Flex                                    */
/* -------------------------------------------------------------------------- */
.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-between-center {
  justify-content: space-between;
  align-items: center;
}

.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}

.flex-between-end {
  align-items: flex-end;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.link-primary {
  color: var(--phoenix-primary);
}
.link-primary:hover, .link-primary:focus {
  color: var(--phoenix-colored-link-primary-hover-color);
}

.link-secondary {
  color: var(--phoenix-secondary);
}
.link-secondary:hover, .link-secondary:focus {
  color: var(--phoenix-colored-link-secondary-hover-color);
}

.link-success {
  color: var(--phoenix-success);
}
.link-success:hover, .link-success:focus {
  color: var(--phoenix-colored-link-success-hover-color);
}

.link-info {
  color: var(--phoenix-info);
}
.link-info:hover, .link-info:focus {
  color: var(--phoenix-colored-link-info-hover-color);
}

.link-warning {
  color: var(--phoenix-warning);
}
.link-warning:hover, .link-warning:focus {
  color: var(--phoenix-colored-link-warning-hover-color);
}

.link-danger {
  color: var(--phoenix-danger);
}
.link-danger:hover, .link-danger:focus {
  color: var(--phoenix-colored-link-danger-hover-color);
}

.link-light {
  color: var(--phoenix-light);
}
.link-light:hover, .link-light:focus {
  color: var(--phoenix-colored-link-light-hover-color);
}

.link-dark {
  color: var(--phoenix-dark);
}
.link-dark:hover, .link-dark:focus {
  color: var(--phoenix-colored-link-dark-hover-color);
}

.link-soft {
  color: var(--phoenix-soft) !important;
}
.link-soft:hover, .link-soft:focus {
  color: var(--phoenix-colored-link-soft-hover-color) !important;
}

.link-100 {
  color: var(--phoenix-100) !important;
}
.link-100:hover, .link-100:focus {
  color: var(--phoenix-colored-link-100-hover-color) !important;
}

.link-200 {
  color: var(--phoenix-200) !important;
}
.link-200:hover, .link-200:focus {
  color: var(--phoenix-colored-link-200-hover-color) !important;
}

.link-300 {
  color: var(--phoenix-300) !important;
}
.link-300:hover, .link-300:focus {
  color: var(--phoenix-colored-link-300-hover-color) !important;
}

.link-400 {
  color: var(--phoenix-400) !important;
}
.link-400:hover, .link-400:focus {
  color: var(--phoenix-colored-link-400-hover-color) !important;
}

.link-500 {
  color: var(--phoenix-500) !important;
}
.link-500:hover, .link-500:focus {
  color: var(--phoenix-colored-link-500-hover-color) !important;
}

.link-600 {
  color: var(--phoenix-600) !important;
}
.link-600:hover, .link-600:focus {
  color: var(--phoenix-colored-link-600-hover-color) !important;
}

.link-700 {
  color: var(--phoenix-700) !important;
}
.link-700:hover, .link-700:focus {
  color: var(--phoenix-colored-link-700-hover-color) !important;
}

.link-800 {
  color: var(--phoenix-800) !important;
}
.link-800:hover, .link-800:focus {
  color: var(--phoenix-colored-link-800-hover-color) !important;
}

.link-900 {
  color: var(--phoenix-900) !important;
}
.link-900:hover, .link-900:focus {
  color: var(--phoenix-colored-link-900-hover-color) !important;
}

.link-1000 {
  color: var(--phoenix-1000) !important;
}
.link-1000:hover, .link-1000:focus {
  color: var(--phoenix-colored-link-1000-hover-color) !important;
}

.link-1100 {
  color: var(--phoenix-1100) !important;
}
.link-1100:hover, .link-1100:focus {
  color: var(--phoenix-colored-link-1100-hover-color) !important;
}

h1,
.h1 {
  font-weight: 800;
}

h2,
.h2 {
  font-weight: 800;
}

h3,
.h3 {
  font-weight: 800;
}

.lead {
  line-height: 1.4;
}

/*-----------------------------------------------
|   List
-----------------------------------------------*/
.bullet-inside {
  list-style-position: inside;
}

/*-----------------------------------------------
|   Line Clamp
-----------------------------------------------*/
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.alert {
  --phoenix-alert-bg: transparent;
  --phoenix-alert-padding-x: 1.5rem;
  --phoenix-alert-padding-y: 1.5rem;
  --phoenix-alert-margin-bottom: 1rem;
  --phoenix-alert-color: inherit;
  --phoenix-alert-border-color: transparent;
  --phoenix-alert-border: 1px solid var(--phoenix-alert-border-color);
  --phoenix-alert-border-radius: 0.5rem;
  position: relative;
  padding: var(--phoenix-alert-padding-y) var(--phoenix-alert-padding-x);
  margin-bottom: var(--phoenix-alert-margin-bottom);
  color: var(--phoenix-alert-color);
  background-color: var(--phoenix-alert-bg);
  border: var(--phoenix-alert-border);
  border-radius: var(--phoenix-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4.5rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.875rem 1.5rem;
}

.alert-soft-primary {
  --phoenix-alert-color: #003cc7;
  --phoenix-alert-bg: #f5f8ff;
  --phoenix-alert-border-color: #f5f8ff;
}
.alert-soft-primary .alert-link {
  color: #00309f;
}

.alert-soft-secondary {
  --phoenix-alert-color: #141824;
  --phoenix-alert-bg: #f5f7fa;
  --phoenix-alert-border-color: #f5f7fa;
}
.alert-soft-secondary .alert-link {
  color: #10131d;
}

.alert-soft-success {
  --phoenix-alert-color: #1c6c09;
  --phoenix-alert-bg: #f0fdec;
  --phoenix-alert-border-color: #f0fdec;
}
.alert-soft-success .alert-link {
  color: #165607;
}

.alert-soft-info {
  --phoenix-alert-color: #005585;
  --phoenix-alert-bg: #f0faff;
  --phoenix-alert-border-color: #f0faff;
}
.alert-soft-info .alert-link {
  color: #00446a;
}

.alert-soft-warning {
  --phoenix-alert-color: #bc3803;
  --phoenix-alert-bg: #fff6e0;
  --phoenix-alert-border-color: #fff6e0;
}
.alert-soft-warning .alert-link {
  color: #962d02;
}

.alert-soft-danger {
  --phoenix-alert-color: #b81800;
  --phoenix-alert-bg: #ffedeb;
  --phoenix-alert-border-color: #ffedeb;
}
.alert-soft-danger .alert-link {
  color: #931300;
}

.alert-outline-primary {
  --phoenix-alert-color: #3874ff;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #3874ff;
}
.alert-outline-primary .alert-link {
  color: #2d5dcc;
}

.alert-outline-secondary {
  --phoenix-alert-color: #31374a;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #31374a;
}
.alert-outline-secondary .alert-link {
  color: #272c3b;
}

.alert-outline-success {
  --phoenix-alert-color: #25b003;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #25b003;
}
.alert-outline-success .alert-link {
  color: #1e8d02;
}

.alert-outline-info {
  --phoenix-alert-color: #0097eb;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #0097eb;
}
.alert-outline-info .alert-link {
  color: #0079bc;
}

.alert-outline-warning {
  --phoenix-alert-color: #e5780b;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #e5780b;
}
.alert-outline-warning .alert-link {
  color: #b76009;
}

.alert-outline-danger {
  --phoenix-alert-color: #ed2000;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #ed2000;
}
.alert-outline-danger .alert-link {
  color: #be1a00;
}

.alert-outline-light {
  --phoenix-alert-color: #eff2f6;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #eff2f6;
}
.alert-outline-light .alert-link {
  color: #bfc2c5;
}

.alert-outline-dark {
  --phoenix-alert-color: #141824;
  --phoenix-alert-bg: transparent;
  --phoenix-alert-border-color: #141824;
}
.alert-outline-dark .alert-link {
  color: #10131d;
}

.alert-phoenix-primary {
  --phoenix-alert-color: #3874ff;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-primary .alert-link {
  color: #2d5dcc;
}

.alert-phoenix-secondary {
  --phoenix-alert-color: #31374a;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-secondary .alert-link {
  color: #272c3b;
}

.alert-phoenix-success {
  --phoenix-alert-color: #25b003;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-success .alert-link {
  color: #1e8d02;
}

.alert-phoenix-info {
  --phoenix-alert-color: #0097eb;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-info .alert-link {
  color: #0079bc;
}

.alert-phoenix-warning {
  --phoenix-alert-color: #e5780b;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-warning .alert-link {
  color: #b76009;
}

.alert-phoenix-danger {
  --phoenix-alert-color: #ed2000;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-danger .alert-link {
  color: #be1a00;
}

.alert-phoenix-light {
  --phoenix-alert-color: #eff2f6;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-light .alert-link {
  color: #bfc2c5;
}

.alert-phoenix-dark {
  --phoenix-alert-color: #141824;
  --phoenix-alert-bg: var(--phoenix-white);
  --phoenix-alert-border-color: var(--phoenix-gray-300);
}
.alert-phoenix-dark .alert-link {
  color: #10131d;
}

.alert-primary {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #3874ff;
  --phoenix-alert-border-color: #3874ff;
}
.alert-primary .alert-link {
  color: #a2a6b1;
}

.alert-secondary {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #31374a;
  --phoenix-alert-border-color: #31374a;
}
.alert-secondary .alert-link {
  color: #a2a6b1;
}

.alert-success {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #25b003;
  --phoenix-alert-border-color: #25b003;
}
.alert-success .alert-link {
  color: #a2a6b1;
}

.alert-info {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #0097eb;
  --phoenix-alert-border-color: #0097eb;
}
.alert-info .alert-link {
  color: #a2a6b1;
}

.alert-warning {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #e5780b;
  --phoenix-alert-border-color: #e5780b;
}
.alert-warning .alert-link {
  color: #a2a6b1;
}

.alert-danger {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #ed2000;
  --phoenix-alert-border-color: #ed2000;
}
.alert-danger .alert-link {
  color: #a2a6b1;
}

.alert-light {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #eff2f6;
  --phoenix-alert-border-color: #eff2f6;
}
.alert-light .alert-link {
  color: #a2a6b1;
}

.alert-dark {
  --phoenix-alert-color: #cbd0dd;
  --phoenix-alert-bg: #141824;
  --phoenix-alert-border-color: #141824;
}
.alert-dark .alert-link {
  color: #a2a6b1;
}

.nav-tabs .nav-link,
.nav-underline .nav-link {
  padding: 0.5rem;
  margin-bottom: -2px;
  font-weight: 700;
  font-size: 0.8rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.nav-tabs .nav-link.disabled,
.nav-underline .nav-link.disabled {
  color: var(--phoenix-black);
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs {
  border-bottom: 0px solid transparent;
}
.nav-tabs .nav-link {
  border: none;
  padding: 0.5rem 0.5rem;
  color: var(--phoenix-primary);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--phoenix-gray-1100);
  background-color: transparent;
}

.echart-tab-example .nav-link {
  color: var(--phoenix-gray-1100);
}
.echart-tab-example .nav-link.active {
  color: var(--phoenix-primary);
}

.nav-phoenix-pills {
  background: var(--phoenix-gray-100);
  border: 1px solid var(--phoenix-gray-300);
  border-radius: 0.5rem;
}
.nav-phoenix-pills .nav-item {
  flex: 1;
}
.nav-phoenix-pills .nav-item .nav-link {
  color: var(--phoenix-gray-600);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.nav-phoenix-pills .nav-item .nav-link.active {
  background: var(--phoenix-white);
  color: var(--phoenix-primary);
  border-left: 1px solid var(--phoenix-gray-300);
  border-right: 1px solid var(--phoenix-gray-300);
  border-radius: 0.5rem;
}
.nav-phoenix-pills .nav-item:last-child .nav-link.active {
  border-right: none;
}
.nav-phoenix-pills .nav-item:first-child .nav-link.active {
  border-left: none;
}

.nav-underline .nav-item:first-child .nav-link {
  padding-left: 0;
}
.nav-underline .nav-item:last-child .nav-link {
  padding-right: 0;
}
.nav-underline .nav-link {
  border: var(--phoenix-border-color);
  color: var(--phoenix-gray-900);
}
.nav-underline .nav-link.active,
.nav-underline .nav-item.show .nav-link {
  color: var(--phoenix-primary);
  background-color: transparent;
  border-bottom: 2px solid var(--phoenix-primary);
}

.nav-links .nav-link {
  font-weight: 700;
  font-size: 0.8rem;
}
.nav-links .nav-link.active {
  color: var(--phoenix-gray-1100);
}

.page-item {
  margin: 0.125rem;
}

.page-link {
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 700;
  border-radius: 0.375rem;
  line-height: 1.2;
}
.page-link.disabled {
  color: var(--phoenix-gray-400);
}

.progress-stack .progress-bar {
  margin-left: 1px;
  margin-right: 1px;
}
.progress-stack .progress-bar:first-child {
  margin-left: 0;
}
.progress-stack .progress-bar:last-child {
  margin-right: 0;
}

.circle-progress-svg {
  transform: rotate(170deg);
}
[dir=rtl] .circle-progress-svg {
  transform: rotate(-170deg);
}
.circle-progress-svg .progress-bar-rail {
  stroke-dasharray: 340px;
  stroke-dashoffset: 150;
  stroke: var(--phoenix-gray-200);
}
.circle-progress-svg .progress-bar-top {
  stroke-dasharray: 340px;
  stroke-dashoffset: 340;
  animation: strokeAnimation 1s ease-in-out forwards;
}

@keyframes strokeAnimation {
  to {
    stroke-dashoffset: calc(340px - 188px * var(--phoenix-circle-progress-bar) / 100);
  }
}
.toast {
  padding: 0.5rem 1.5rem;
}
.toast .toast-header .btn-close {
  margin-right: 0;
}
.toast .toast-body {
  padding: 1rem 0;
}

.tooltip-light .tooltip-inner {
  background-color: var(--phoenix-white) !important;
}

.form-label {
  padding-left: 1rem;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 0;
}
.form-check .form-label {
  padding-left: 0 !important;
}

.form-control.form-control-underlined {
  border: none;
  border-bottom: 1px solid;
  border-color: var(--phoenix-input-border-color);
  border-radius: 0;
  padding: 0.5rem 0 !important;
  box-shadow: none;
}
.form-control.form-control-underlined:focus {
  border-color: var(--phoenix-primary) !important;
}
.form-control::file-selector-button {
  background-color: var(--phoenix-input-group-addon-bg) !important;
}
.form-control:disabled::file-selector-button {
  background-color: var(--phoenix-input-disabled-group-addon-bg) !important;
  color: var(--phoenix-input-disabled-group-addon-color);
}

.form-floating > label {
  color: var(--phoenix-gray-800);
  font-weight: 700;
  font-size: 0.64rem;
  text-transform: uppercase;
}
.form-floating .form-control {
  box-shadow: none;
}
.form-floating .form-control-underlined + label {
  padding: 0;
}

.form-check-label {
  font-weight: 600;
  font-size: 0.8rem;
}

.form-check-light .form-check-input:checked {
  background-color: #fff;
  border-color: #9fa6bc;
}
.form-check-light .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%239fa6bc' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-lg {
  padding-left: 2rem;
}
.form-check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -2rem;
}

.form-switch .form-check-input {
  border: none;
  background-color: var(--phoenix-gray-400);
}
.form-switch .form-check-input:checked {
  background-color: #3874ff;
}

.form-switch-lg {
  padding-left: 3.5rem;
}
.form-switch-lg .form-check-input {
  width: 3rem;
  height: 1.5rem;
  margin-left: -3.5rem;
}

.form-icon-container {
  position: relative;
}
.form-icon-container .form-icon-input,
.form-icon-container .form-icon-label {
  padding-left: 2.5rem;
}
.form-icon-container .form-icon {
  position: absolute;
  top: 12px;
  left: 1rem;
}
.form-icon-container .form-floating .form-icon-input {
  padding-left: 35px;
}
.form-icon-container .form-floating + .form-icon {
  top: 1.0625rem;
}

.form-check-input,
.form-select {
  appearance: none;
  -webkit-appearance: none;
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-spin-none[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

.form-check-line-through + label {
  transition: all 0.2s ease-in-out;
}
.form-check-line-through:checked + label {
  color: var(--phoenix-gray-500);
  text-decoration: line-through;
}

/*-----------------------------------------------
|   Remove Arrows from Input[type="number"]
-----------------------------------------------*/
.number-arrows-none::-webkit-outer-spin-button, .number-arrows-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*-----------------------------------------------
|   Remove calendar icon from Input[type="date"]
-----------------------------------------------*/
.inventory-attributes input[type=date]::-webkit-calendar-picker-indicator {
  background-image: none;
}

.input-group .input-group-text {
  background-color: var(--phoenix-input-group-addon-bg) !important;
}

.form-check-input-todolist:checked + div label {
  color: var(--phoenix-gray-500);
  text-decoration: line-through;
}

.dark .navbar-dark.light .form-control {
  background-color: #141824;
  border-color: #373e53;
}

/* -------------------------------------------------------------------------- */
/*                               Navbar Vertical                              */
/* -------------------------------------------------------------------------- */
html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content {
  height: calc(100vh - var(--phoenix-navbar-top-height));
}
@media (min-width: 1540px) {
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xxl {
    height: calc(100vh - var(--phoenix-navbar-top-height));
  }
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xxl .navbar-vertical-content {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
  }
}
@media (min-width: 1200px) {
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xl {
    height: calc(100vh - var(--phoenix-navbar-top-height));
  }
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
  }
}
@media (min-width: 992px) {
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-lg {
    height: calc(100vh - var(--phoenix-navbar-top-height));
  }
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-lg .navbar-vertical-content {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
  }
}
@media (min-width: 768px) {
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-md {
    height: calc(100vh - var(--phoenix-navbar-top-height));
  }
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-md .navbar-vertical-content {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
  }
}
@media (min-width: 576px) {
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-sm {
    height: calc(100vh - var(--phoenix-navbar-top-height));
  }
  html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-sm .navbar-vertical-content {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
  }
}
html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xs {
  height: calc(100vh - var(--phoenix-navbar-top-height));
}
html:not(.navbar-vertical-collapsed) .navbar-vertical.navbar-expand-xs .navbar-vertical-content {
  height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
}

.navbar-vertical {
  position: fixed;
  display: inline-block;
  z-index: 1020;
  top: var(--phoenix-navbar-top-height);
  padding: 0;
  width: 100vw;
  background: var(--phoenix-navbar-vertical-bg-color);
}
.navbar-vertical .navbar-vertical-line {
  display: none;
}
.navbar-vertical + .navbar-top .navbar-logo {
  height: var(--phoenix-navbar-top-height)0.0625rem;
}
.navbar-vertical + .navbar-top .navbar-toggler {
  display: flex;
  margin-left: -0.5rem;
}
@media (min-width: 992px) {
  .navbar-vertical + .navbar-top .navbar-logo {
    width: 15.875rem;
  }
}
.navbar-vertical .navbar-collapse.collapsing .navbar-vertical-content, .navbar-vertical .navbar-collapse.show .navbar-vertical-content {
  max-height: calc(100vh - var(--phoenix-navbar-top-height));
}
.navbar-vertical + .navbar .navbar-vertical-toggle {
  display: none;
}
.navbar-vertical .navbar-vertical-content {
  padding: 1rem 0;
  overflow-y: auto;
}
.navbar-vertical .navbar-vertical-content .navbar-vertical-label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.64rem;
  color: var(--phoenix-navbar-vertical-label-color);
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  padding-left: 2rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav {
  flex-direction: column;
  font-size: 0.8rem;
  font-weight: 600;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link {
  word-break: break-word;
  line-height: 1.188rem;
  padding-left: 1.5rem;
  white-space: nowrap;
  color: var(--phoenix-navbar-vertical-link-color);
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
  padding-left: 0.75rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link:hover {
  color: var(--phoenix-navbar-vertical-link-hover-color);
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link.active {
  color: var(--phoenix-navbar-vertical-link-active-color);
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav-link.nav-link-disable {
  color: var(--phoenix-navbar-vertical-link-disable-color);
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav {
  flex-flow: column nowrap;
  font-size: 0.8125rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav .nav-link {
  padding: 0.25rem 0.5rem;
  padding-left: 3.125rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
  padding-left: 3.875rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 4.625rem;
}
.navbar-vertical .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
  padding-left: 5.25rem;
}
.navbar-vertical .navbar-vertical-content .nav-link-icon {
  margin-right: 0.625rem;
  min-width: 16px !important;
  text-align: center;
}
.navbar-vertical .navbar-vertical-content .nav-link-text {
  padding-top: 0.125rem;
}
.navbar-vertical .nav-item-wrapper:has(.active) .dropdown-indicator.label-1.collapsed .nav-link-text,
.navbar-vertical .nav-item-wrapper:has(.active) .dropdown-indicator.label-1.collapsed .nav-link-icon {
  color: var(--phoenix-navbar-vertical-link-active-color);
}
.navbar-vertical .nav-item-wrapper:has(.active) .nav-item:has(.active) .dropdown-indicator.collapsed .nav-link-text,
.navbar-vertical .nav-item-wrapper:has(.active) .nav-item:has(.active) .dropdown-indicator.collapsed .nav-link-icon {
  color: var(--phoenix-navbar-vertical-link-active-color);
}
.navbar-vertical .dropdown-indicator {
  position: relative;
}
.navbar-vertical .dropdown-indicator .dropdown-indicator-icon {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  color: var(--phoenix-navbar-vertical-dropdown-indicator-color);
  margin-left: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-vertical .dropdown-indicator .dropdown-indicator-icon .fa-caret-right {
  transform: scale(0.8);
  transition: transform 0.2s ease;
}
[dir=rtl] .navbar-vertical .dropdown-indicator .dropdown-indicator-icon .fa-caret-right {
  transform: scale(0.8) rotate(180deg);
}
.navbar-vertical .dropdown-indicator[aria-expanded=true] .dropdown-indicator-icon .fa-caret-right {
  transform: rotate(90deg) scale(0.8);
}
[dir=rtl] .navbar-vertical .dropdown-indicator[aria-expanded=true] .dropdown-indicator-icon .fa-caret-right {
  transform: rotate(-90deg) scale(0.8);
}
.navbar-vertical .navbar-vertical-footer {
  position: fixed;
  width: 15.875rem;
  height: 4rem;
  display: none;
  align-items: center;
  bottom: 0;
  border-top: 1px solid var(--phoenix-navbar-footer-border-color);
  transition: width 0.2s ease;
}
.navbar-vertical .navbar-vertical-footer .btn, .navbar-vertical .navbar-vertical-footer [data-list] .page, [data-list] .navbar-vertical .navbar-vertical-footer .page, .navbar-vertical .navbar-vertical-footer .table-list .page, .table-list .navbar-vertical .navbar-vertical-footer .page, .navbar-vertical .navbar-vertical-footer .tox .tox-button, .tox .navbar-vertical .navbar-vertical-footer .tox-button, .navbar-vertical .navbar-vertical-footer .tox .tox-button-secondary, .tox .navbar-vertical .navbar-vertical-footer .tox-button-secondary, .navbar-vertical .navbar-vertical-footer .tox .tox-dialog__footer .tox-button--secondary, .tox .tox-dialog__footer .navbar-vertical .navbar-vertical-footer .tox-button--secondary, .navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomIn, .google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomIn,
.navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomOut,
.google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomOut {
  color: var(--phoenix-navbar-vertical-link-color);
  text-decoration: none;
}
.navbar-vertical .navbar-vertical-footer .btn .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer [data-list] .page .navbar-vertical-footer-icon, [data-list] .navbar-vertical .navbar-vertical-footer .page .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer .table-list .page .navbar-vertical-footer-icon, .table-list .navbar-vertical .navbar-vertical-footer .page .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer .tox .tox-button .navbar-vertical-footer-icon, .tox .navbar-vertical .navbar-vertical-footer .tox-button .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer .tox .tox-button-secondary .navbar-vertical-footer-icon, .tox .navbar-vertical .navbar-vertical-footer .tox-button-secondary .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer .tox .tox-dialog__footer .tox-button--secondary .navbar-vertical-footer-icon, .tox .tox-dialog__footer .navbar-vertical .navbar-vertical-footer .tox-button--secondary .navbar-vertical-footer-icon, .navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomIn .navbar-vertical-footer-icon, .google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomIn .navbar-vertical-footer-icon,
.navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomOut .navbar-vertical-footer-icon,
.google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomOut .navbar-vertical-footer-icon {
  margin-right: 0.6125rem;
}
.navbar-vertical .navbar-vertical-footer .btn:hover, .navbar-vertical .navbar-vertical-footer [data-list] .page:hover, [data-list] .navbar-vertical .navbar-vertical-footer .page:hover, .navbar-vertical .navbar-vertical-footer .table-list .page:hover, .table-list .navbar-vertical .navbar-vertical-footer .page:hover, .navbar-vertical .navbar-vertical-footer .tox .tox-button:hover, .tox .navbar-vertical .navbar-vertical-footer .tox-button:hover, .navbar-vertical .navbar-vertical-footer .tox .tox-button-secondary:hover, .tox .navbar-vertical .navbar-vertical-footer .tox-button-secondary:hover, .navbar-vertical .navbar-vertical-footer .tox .tox-dialog__footer .tox-button--secondary:hover, .tox .tox-dialog__footer .navbar-vertical .navbar-vertical-footer .tox-button--secondary:hover, .navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomIn:hover, .google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomIn:hover,
.navbar-vertical .navbar-vertical-footer .google-map-control-btn .zoomOut:hover,
.google-map-control-btn .navbar-vertical .navbar-vertical-footer .zoomOut:hover {
  color: var(--phoenix-navbar-vertical-link-hover-color);
}
@media (min-width: 1540px) {
  .navbar-vertical.navbar-expand-xxl {
    width: 15.875rem;
    border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
    margin: 0;
    transition: width 0.2s ease;
  }
  .navbar-vertical.navbar-expand-xxl + .navbar .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer .uil-arrow-from-right {
    display: none;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer .uil-left-arrow-to-left {
    display: inline;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    width: 100%;
    flex-direction: column;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content {
    width: 100%;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 1rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav-link:hover {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 3.3125rem;
    scroll-margin-top: 200px;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
    padding-left: 15px;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
    padding-left: 3px;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
    padding-left: 4.375rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 5.125rem;
  }
  .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
    padding-left: 5.875rem;
  }
  .navbar-vertical.navbar-expand-xxl ~ .navbar-top {
    padding-right: 2.5rem;
  }
  .navbar-vertical.navbar-expand-xxl ~ .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-vertical.navbar-expand-xxl ~ .navbar-top .navbar-logo {
    position: relative;
    background: transparent;
  }
  .navbar-vertical.navbar-expand-xxl ~ .navbar-top ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 15.875rem;
  }
  .navbar-vertical.navbar-expand-xxl ~ .content .footer {
    padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    width: 15.875rem;
    border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
    margin: 0;
    transition: width 0.2s ease;
  }
  .navbar-vertical.navbar-expand-xl + .navbar .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-footer {
    display: flex;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-footer .uil-arrow-from-right {
    display: none;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-footer .uil-left-arrow-to-left {
    display: inline;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    width: 100%;
    flex-direction: column;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    width: 100%;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 1rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav-link:hover {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 3.3125rem;
    scroll-margin-top: 200px;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
    padding-left: 15px;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
    padding-left: 3px;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
    padding-left: 4.375rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 5.125rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
    padding-left: 5.875rem;
  }
  .navbar-vertical.navbar-expand-xl ~ .navbar-top {
    padding-right: 2.5rem;
  }
  .navbar-vertical.navbar-expand-xl ~ .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-vertical.navbar-expand-xl ~ .navbar-top .navbar-logo {
    position: relative;
    background: transparent;
  }
  .navbar-vertical.navbar-expand-xl ~ .navbar-top ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 15.875rem;
  }
  .navbar-vertical.navbar-expand-xl ~ .content .footer {
    padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    width: 15.875rem;
    border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
    margin: 0;
    transition: width 0.2s ease;
  }
  .navbar-vertical.navbar-expand-lg + .navbar .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-footer {
    display: flex;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-footer .uil-arrow-from-right {
    display: none;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-footer .uil-left-arrow-to-left {
    display: inline;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    width: 100%;
    flex-direction: column;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content {
    width: 100%;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 1rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link:hover {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 3.3125rem;
    scroll-margin-top: 200px;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
    padding-left: 15px;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
    padding-left: 3px;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
    padding-left: 4.375rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 5.125rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
    padding-left: 5.875rem;
  }
  .navbar-vertical.navbar-expand-lg ~ .navbar-top {
    padding-right: 2.5rem;
  }
  .navbar-vertical.navbar-expand-lg ~ .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-vertical.navbar-expand-lg ~ .navbar-top .navbar-logo {
    position: relative;
    background: transparent;
  }
  .navbar-vertical.navbar-expand-lg ~ .navbar-top ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 15.875rem;
  }
  .navbar-vertical.navbar-expand-lg ~ .content .footer {
    padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    width: 15.875rem;
    border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
    margin: 0;
    transition: width 0.2s ease;
  }
  .navbar-vertical.navbar-expand-md + .navbar .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-footer {
    display: flex;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-footer .uil-arrow-from-right {
    display: none;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-footer .uil-left-arrow-to-left {
    display: inline;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    width: 100%;
    flex-direction: column;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content {
    width: 100%;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 1rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav-link:hover {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 3.3125rem;
    scroll-margin-top: 200px;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
    padding-left: 15px;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
    padding-left: 3px;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
    padding-left: 4.375rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 5.125rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
    padding-left: 5.875rem;
  }
  .navbar-vertical.navbar-expand-md ~ .navbar-top {
    padding-right: 2.5rem;
  }
  .navbar-vertical.navbar-expand-md ~ .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-vertical.navbar-expand-md ~ .navbar-top .navbar-logo {
    position: relative;
    background: transparent;
  }
  .navbar-vertical.navbar-expand-md ~ .navbar-top ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 15.875rem;
  }
  .navbar-vertical.navbar-expand-md ~ .content .footer {
    padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    width: 15.875rem;
    border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
    margin: 0;
    transition: width 0.2s ease;
  }
  .navbar-vertical.navbar-expand-sm + .navbar .navbar-vertical-toggle {
    display: flex;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-footer {
    display: flex;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-footer .uil-arrow-from-right {
    display: none;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-footer .uil-left-arrow-to-left {
    display: inline;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    width: 100%;
    flex-direction: column;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content {
    width: 100%;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav-link {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    padding-left: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
    padding-left: 1rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav-link:hover {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav-link {
    padding-left: 3.3125rem;
    scroll-margin-top: 200px;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
    padding-left: 15px;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
    padding-left: 3px;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
    padding-left: 4.375rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 5.125rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
    padding-left: 5.875rem;
  }
  .navbar-vertical.navbar-expand-sm ~ .navbar-top {
    padding-right: 2.5rem;
  }
  .navbar-vertical.navbar-expand-sm ~ .navbar-top .navbar-toggler {
    display: none;
  }
  .navbar-vertical.navbar-expand-sm ~ .navbar-top .navbar-logo {
    position: relative;
    background: transparent;
  }
  .navbar-vertical.navbar-expand-sm ~ .navbar-top ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-left: 15.875rem;
  }
  .navbar-vertical.navbar-expand-sm ~ .content .footer {
    padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
  }
}
.navbar-vertical.navbar-expand-xs {
  width: 15.875rem;
  border-right: 1px solid var(--phoenix-navbar-vertical-border-color);
  margin: 0;
  transition: width 0.2s ease;
}
.navbar-vertical.navbar-expand-xs + .navbar .navbar-vertical-toggle {
  display: flex;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-footer {
  display: flex;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-footer .uil-arrow-from-right {
  display: none;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-footer .uil-left-arrow-to-left {
  display: inline;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
  width: 100%;
  flex-direction: column;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content {
  width: 100%;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav-link {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 1.75rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  border-radius: 0.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav-link.dropdown-indicator {
  padding-left: 1rem;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav-link:hover {
  background-color: var(--phoenix-navbar-vertical-link-hover-bg);
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav-link {
  padding-left: 3.3125rem;
  scroll-margin-top: 200px;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav-link .nav-link-text {
  padding-left: 15px;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav-link .dropdown-indicator-icon + .nav-link-text {
  padding-left: 3px;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav .nav-link {
  padding-left: 4.375rem;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 5.125rem;
}
.navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav .nav .nav .nav .nav-link {
  padding-left: 5.875rem;
}
.navbar-vertical.navbar-expand-xs ~ .navbar-top {
  padding-right: 2.5rem;
}
.navbar-vertical.navbar-expand-xs ~ .navbar-top .navbar-toggler {
  display: none;
}
.navbar-vertical.navbar-expand-xs ~ .navbar-top .navbar-logo {
  position: relative;
  background: transparent;
}
.navbar-vertical.navbar-expand-xs ~ .navbar-top ~ .content {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-left: 15.875rem;
}
.navbar-vertical.navbar-expand-xs ~ .content .footer {
  padding-left: calc(var(--phoenix-navbar-vertical-width) + 2.5rem);
}
.navbar-vertical .new-page-indicator {
  margin-top: -10px;
}

@media (min-width: 1540px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl {
    position: absolute;
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: var(--phoenix-navbar-top-height);
    padding-bottom: 4rem;
    width: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-line {
    display: block;
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--phoenix-navbar-vertical-hr-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer {
    bottom: 0;
    width: calc(4rem - 1px);
    background-color: var(--phoenix-navbar-vertical-bg-color);
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    transition: width 0.2s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer .uil-arrow-from-right {
    display: inline;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer .uil-left-arrow-to-left {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-active-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-label {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer-text {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-footer {
    padding-left: 1.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .label-1.nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl + .navbar.navbar-top ~ .content {
    margin-left: 4rem !important;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-content {
    overflow: visible;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav-link {
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .navbar-vertical-content .navbar-nav .nav-link.label-1 {
    padding-left: 0.75rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item {
    position: relative;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper {
    position: relative;
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .parent-wrapper.label-1 {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .parent-wrapper.label-1 > .parent:first-child {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .dropdown-indicator.label-1 {
    pointer-events: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .dropdown-indicator.label-1 {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
    border-radius: 0.5rem;
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .collapsed-nav-item-title {
    display: block !important;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
    color: var(--phoenix-navbar-vertical-link-hover-color);
    font-size: 12.8px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
    display: flex;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link:not(.label-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
    border-radius: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav .nav .nav-link {
    padding-left: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav .nav .nav .nav-link {
    padding-left: 3rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link-text-wrapper {
    position: absolute;
    display: block;
    left: 100%;
    background: transparent;
    top: -4px;
    padding-bottom: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    width: 220px;
    background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
    margin-left: 16px;
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 8px;
    border: 1px solid var(--phoenix-navbar-vertical-border-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xxl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: 0.85rem;
    left: -9px;
    transform: rotate(45deg);
    border-bottom-left-radius: 0.125rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--phoenix-navbar-vertical-border-color);
  }
}
@media (min-width: 1200px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl {
    position: absolute;
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: var(--phoenix-navbar-top-height);
    padding-bottom: 4rem;
    width: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-line {
    display: block;
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--phoenix-navbar-vertical-hr-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-footer {
    bottom: 0;
    width: calc(4rem - 1px);
    background-color: var(--phoenix-navbar-vertical-bg-color);
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    transition: width 0.2s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-footer .uil-arrow-from-right {
    display: inline;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-footer .uil-left-arrow-to-left {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-active-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-label {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-footer-text {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-footer {
    padding-left: 1.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .label-1.nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl + .navbar.navbar-top ~ .content {
    margin-left: 4rem !important;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-content {
    overflow: visible;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav-link {
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .navbar-vertical-content .navbar-nav .nav-link.label-1 {
    padding-left: 0.75rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item {
    position: relative;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper {
    position: relative;
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .parent-wrapper.label-1 {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .parent-wrapper.label-1 > .parent:first-child {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .dropdown-indicator.label-1 {
    pointer-events: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .dropdown-indicator.label-1 {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
    border-radius: 0.5rem;
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .collapsed-nav-item-title {
    display: block !important;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
    color: var(--phoenix-navbar-vertical-link-hover-color);
    font-size: 12.8px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
    display: flex;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link:not(.label-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
    border-radius: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav .nav .nav-link {
    padding-left: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav .nav .nav .nav-link {
    padding-left: 3rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link-text-wrapper {
    position: absolute;
    display: block;
    left: 100%;
    background: transparent;
    top: -4px;
    padding-bottom: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    width: 220px;
    background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
    margin-left: 16px;
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 8px;
    border: 1px solid var(--phoenix-navbar-vertical-border-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: 0.85rem;
    left: -9px;
    transform: rotate(45deg);
    border-bottom-left-radius: 0.125rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--phoenix-navbar-vertical-border-color);
  }
}
@media (min-width: 992px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg {
    position: absolute;
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: var(--phoenix-navbar-top-height);
    padding-bottom: 4rem;
    width: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-line {
    display: block;
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--phoenix-navbar-vertical-hr-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-footer {
    bottom: 0;
    width: calc(4rem - 1px);
    background-color: var(--phoenix-navbar-vertical-bg-color);
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    transition: width 0.2s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-footer .uil-arrow-from-right {
    display: inline;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-footer .uil-left-arrow-to-left {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-active-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-label {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-footer-text {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-footer {
    padding-left: 1.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .label-1.nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg + .navbar.navbar-top ~ .content {
    margin-left: 4rem !important;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-content {
    overflow: visible;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link {
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .navbar-vertical-content .navbar-nav .nav-link.label-1 {
    padding-left: 0.75rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item {
    position: relative;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper {
    position: relative;
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .parent-wrapper.label-1 {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .parent-wrapper.label-1 > .parent:first-child {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .dropdown-indicator.label-1 {
    pointer-events: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .dropdown-indicator.label-1 {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
    border-radius: 0.5rem;
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .collapsed-nav-item-title {
    display: block !important;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
    color: var(--phoenix-navbar-vertical-link-hover-color);
    font-size: 12.8px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
    display: flex;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link:not(.label-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
    border-radius: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav .nav .nav-link {
    padding-left: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav .nav .nav .nav-link {
    padding-left: 3rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link-text-wrapper {
    position: absolute;
    display: block;
    left: 100%;
    background: transparent;
    top: -4px;
    padding-bottom: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    width: 220px;
    background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
    margin-left: 16px;
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 8px;
    border: 1px solid var(--phoenix-navbar-vertical-border-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-lg .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: 0.85rem;
    left: -9px;
    transform: rotate(45deg);
    border-bottom-left-radius: 0.125rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--phoenix-navbar-vertical-border-color);
  }
}
@media (min-width: 768px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md {
    position: absolute;
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: var(--phoenix-navbar-top-height);
    padding-bottom: 4rem;
    width: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-line {
    display: block;
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--phoenix-navbar-vertical-hr-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-footer {
    bottom: 0;
    width: calc(4rem - 1px);
    background-color: var(--phoenix-navbar-vertical-bg-color);
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    transition: width 0.2s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-footer .uil-arrow-from-right {
    display: inline;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-footer .uil-left-arrow-to-left {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-active-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-label {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-footer-text {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-footer {
    padding-left: 1.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .label-1.nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md + .navbar.navbar-top ~ .content {
    margin-left: 4rem !important;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-content {
    overflow: visible;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav-link {
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .navbar-vertical-content .navbar-nav .nav-link.label-1 {
    padding-left: 0.75rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item {
    position: relative;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper {
    position: relative;
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .parent-wrapper.label-1 {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .parent-wrapper.label-1 > .parent:first-child {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .dropdown-indicator.label-1 {
    pointer-events: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .dropdown-indicator.label-1 {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
    border-radius: 0.5rem;
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .collapsed-nav-item-title {
    display: block !important;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
    color: var(--phoenix-navbar-vertical-link-hover-color);
    font-size: 12.8px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
    display: flex;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link:not(.label-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
    border-radius: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav .nav .nav-link {
    padding-left: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav .nav .nav .nav-link {
    padding-left: 3rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link-text-wrapper {
    position: absolute;
    display: block;
    left: 100%;
    background: transparent;
    top: -4px;
    padding-bottom: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    width: 220px;
    background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
    margin-left: 16px;
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 8px;
    border: 1px solid var(--phoenix-navbar-vertical-border-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-md .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: 0.85rem;
    left: -9px;
    transform: rotate(45deg);
    border-bottom-left-radius: 0.125rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--phoenix-navbar-vertical-border-color);
  }
}
@media (min-width: 576px) {
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm {
    position: absolute;
    top: 0;
    height: auto;
    min-height: 100%;
    padding-top: var(--phoenix-navbar-top-height);
    padding-bottom: 4rem;
    width: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-line {
    display: block;
    margin-right: 1rem;
    margin-left: 1rem;
    color: var(--phoenix-navbar-vertical-hr-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-footer {
    bottom: 0;
    width: calc(4rem - 1px);
    background-color: var(--phoenix-navbar-vertical-bg-color);
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    transition: width 0.2s ease;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-footer .uil-arrow-from-right {
    display: inline;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-footer .uil-left-arrow-to-left {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-active-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-label {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-footer-text {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-footer {
    padding-left: 1.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .label-1.nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.2;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm + .navbar.navbar-top ~ .content {
    margin-left: 4rem !important;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-content {
    overflow: visible;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav-link {
    padding-left: 1rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .navbar-vertical-content .navbar-nav .nav-link.label-1 {
    padding-left: 0.75rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item {
    position: relative;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper {
    position: relative;
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .parent-wrapper.label-1 {
    display: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .parent-wrapper.label-1 > .parent:first-child {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .dropdown-indicator.label-1 {
    pointer-events: none;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .dropdown-indicator.label-1 {
    background-color: var(--phoenix-navbar-vertical-link-hover-bg);
    border-radius: 0.5rem;
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
    color: var(--phoenix-navbar-vertical-link-hover-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .collapsed-nav-item-title {
    display: block !important;
    padding-left: 16px;
    padding-top: 15px;
    padding-bottom: 14px;
    border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
    color: var(--phoenix-navbar-vertical-link-hover-color);
    font-size: 12.8px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    display: block;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
    display: flex;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link:not(.label-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
    border-radius: 0;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav .nav .nav-link {
    padding-left: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav .nav .nav .nav-link {
    padding-left: 3rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
    padding-left: 4rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link-text-wrapper {
    position: absolute;
    display: block;
    left: 100%;
    background: transparent;
    top: -4px;
    padding-bottom: 2rem;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    width: 220px;
    background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
    margin-left: 16px;
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 8px;
    border: 1px solid var(--phoenix-navbar-vertical-border-color);
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
  }
  .navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-sm .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    background: inherit;
    top: 0.85rem;
    left: -9px;
    transform: rotate(45deg);
    border-bottom-left-radius: 0.125rem;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: var(--phoenix-navbar-vertical-border-color);
  }
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs {
  position: absolute;
  top: 0;
  height: auto;
  min-height: 100%;
  padding-top: var(--phoenix-navbar-top-height);
  padding-bottom: 4rem;
  width: 4rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-line {
  display: block;
  margin-right: 1rem;
  margin-left: 1rem;
  color: var(--phoenix-navbar-vertical-hr-color);
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-footer {
  bottom: 0;
  width: calc(4rem - 1px);
  background-color: var(--phoenix-navbar-vertical-bg-color);
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  transition: width 0.2s ease;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-footer .uil-arrow-from-right {
  display: inline;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-footer .uil-left-arrow-to-left {
  display: none;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:has(.active) .label-1 .nav-link-icon {
  color: var(--phoenix-navbar-vertical-link-active-color);
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-label {
  display: none;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .dropdown-indicator-icon,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-footer-text {
  display: none;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-footer {
  padding-left: 1.5rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .label-1.nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.2;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs + .navbar.navbar-top ~ .content {
  margin-left: 4rem !important;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-content {
  overflow: visible;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav-link {
  padding-left: 1rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .navbar-vertical-content .navbar-nav .nav-link.label-1 {
  padding-left: 0.75rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item {
  position: relative;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper {
  position: relative;
  display: block;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .parent-wrapper.label-1 {
  display: none;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .parent-wrapper.label-1 > .parent:first-child {
  display: block;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .dropdown-indicator.label-1 {
  pointer-events: none;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .dropdown-indicator.label-1 {
  background-color: var(--phoenix-navbar-vertical-link-hover-bg);
  border-radius: 0.5rem;
  color: var(--phoenix-navbar-vertical-link-hover-color);
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .dropdown-indicator.label-1 .nav-link-icon {
  color: var(--phoenix-navbar-vertical-link-hover-color);
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .collapsed-nav-item-title {
  display: block !important;
  padding-left: 16px;
  padding-top: 15px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
  color: var(--phoenix-navbar-vertical-link-hover-color);
  font-size: 12.8px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link:not(.label-1) .nav-link-text,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
  display: block;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link:not(.label-1) .dropdown-indicator-icon {
  display: flex;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link:not(.label-1) {
  margin-left: 0;
  margin-right: 0;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link:not(.label-1):hover {
  border-radius: 0;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav .nav .nav-link {
  padding-left: 2rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav .nav .nav .nav-link {
  padding-left: 3rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav .nav .nav .nav .nav-link {
  padding-left: 4rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .parent-wrapper.label-1,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link-text-wrapper {
  position: absolute;
  display: block;
  left: 100%;
  background: transparent;
  top: -4px;
  padding-bottom: 2rem;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
  width: 220px;
  background: var(--phoenix-navbar-vertical-collapsed-menu-bg);
  margin-left: 16px;
  border-radius: 0.5rem;
  position: relative;
  padding-bottom: 8px;
  border: 1px solid var(--phoenix-navbar-vertical-border-color);
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 16px;
}
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .parent-wrapper.label-1 > .parent:after,
.navbar-vertical-collapsed .navbar-vertical.navbar-expand-xs .nav-item-wrapper:hover .nav-link-text-wrapper .nav-link-text:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  background: inherit;
  top: 0.85rem;
  left: -9px;
  transform: rotate(45deg);
  border-bottom-left-radius: 0.125rem;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: var(--phoenix-navbar-vertical-border-color);
}
.navbar-vertical-collapsed .nav-link-badge {
  display: none;
}
.navbar-vertical-collapsed .new-page-indicator {
  margin-left: -8px !important;
  margin-top: -15px;
  display: block !important;
}

/* -------------------------------------------------------------------------- */
/*                                 Navbar Top                                 */
/* -------------------------------------------------------------------------- */
.navbar-horizontal .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-nav-top .dropdown .dropdown-toggle::after {
  margin-left: 6px;
  top: 1rem;
  border-top: 0.25rem solid;
  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid transparent;
  border-radius: 15px;
}
.navbar-horizontal .navbar-nav-top .dropdown .dropdown .dropdown-toggle:after,
.dual-nav .navbar-nav-top .dropdown .dropdown .dropdown-toggle:after,
.navbar-combo .navbar-nav-top .dropdown .dropdown .dropdown-toggle:after {
  display: none;
}
.navbar-horizontal .navbar-nav-top .nav-link,
.dual-nav .navbar-nav-top .nav-link,
.navbar-combo .navbar-nav-top .nav-link {
  color: var(--phoenix-navbar-top-link-color);
}

.navbar-top {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0;
  background: var(--phoenix-navbar-top-bg-color);
  min-height: var(--phoenix-navbar-top-height);
  color: var(--phoenix-nav-link-color);
  padding: 0 1.5rem 0 1.5rem;
  border-bottom: 1px solid var(--phoenix-navbar-vertical-border-color);
}
.navbar-top .dropdown-menu {
  margin-top: 0.5rem;
}
.navbar-top .navbar-toggler {
  margin-left: -0.5rem;
}
.navbar-top .navbar-nav-icons .nav-item {
  display: flex;
  align-items: center;
}
.navbar-top .navbar-nav-icons .nav-link {
  padding: 0.75rem 0.5rem;
}
.navbar-top .navbar-nav-icons .dropdown-menu {
  position: absolute;
}
.navbar-top .dropdown-notification {
  position: static;
}
@media (min-width: 576px) {
  .navbar-top .dropdown-notification {
    position: relative;
  }
}
.navbar-top .dropdown-notification .dropdown-menu {
  right: 0.25rem !important;
}
@media (min-width: 576px) {
  .navbar-top .dropdown-notification .dropdown-menu {
    right: -0.5625rem !important;
  }
}
.navbar-top .dropdown-notification .dropdown-menu.navbar-dropdown-caret:after {
  right: 9.125rem !important;
  left: auto;
}
@media (min-width: 576px) {
  .navbar-top .dropdown-notification .dropdown-menu.navbar-dropdown-caret:after {
    right: 0.9rem !important;
  }
}

.dropdown-profile {
  min-width: 18.3125rem;
}
.dropdown-profile .nav .nav-link {
  --phoenix-nav-link-color: var(--phoenix-gray-1000);
  --phoenix-nav-link-hover-color: var(--phoenix-black);
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  font-weight: 600;
}
.dropdown-profile .nav .nav-link:hover {
  background: var(--phoenix-gray-100);
}

.notification-dropdown-menu {
  min-width: 260px;
}
@media (min-width: 576px) {
  .notification-dropdown-menu {
    min-width: 360px;
  }
}

.dropdown-nide-dots {
  min-width: 15.375rem;
}
.dropdown-nide-dots.dropdown-menu-end:after {
  right: 0.75rem !important;
}

.dropdown-menu .nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1;
}
.dropdown-menu .nav-link:hover {
  background-color: var(--phoenix-primary-soft);
}

.dropdown-arrow-indicator {
  position: relative;
}
.dropdown-arrow-indicator:after {
  content: "";
  display: block;
  position: absolute;
  right: 1rem;
  height: 0.4rem;
  width: 0.4rem;
  border-right: 1px solid var(--phoenix-gray-700);
  border-bottom: 1px solid var(--phoenix-gray-700);
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: all 0.2s ease-in-out;
  transform-origin: center;
  transition-property: transform, border-color;
}
.dropdown-arrow-indicator[aria-expanded=true]:after {
  transform: translateY(-50%) rotate(45deg);
}

.navbar-vertical + .navbar-top .navbar-brand {
  margin-left: 0.4rem;
}

.theme-control-toggle .theme-control-toggle-label {
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--phoenix-theme-control-toggle-bg);
  color: var(--phoenix-theme-control-toggle-color);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.theme-control-toggle .theme-control-toggle-label:hover {
  background-color: var(--phoenix-theme-control-toggle-hover-bg);
  color: var(--phoenix-theme-control-toggle-hover-color);
}
.theme-control-toggle.theme-control-toggle-slim .theme-control-toggle-label {
  padding: 0 0.5rem;
  height: 1rem;
  width: auto;
  border-radius: 50rem;
}
.theme-control-toggle.theme-control-toggle-slim .theme-control-toggle-label .icon {
  height: 10.2px;
  width: 10.2px;
}
.theme-control-toggle .theme-control-toggle-input {
  display: none;
}
.theme-control-toggle .theme-control-toggle-input:checked ~ .theme-control-toggle-dark {
  display: none;
}
.theme-control-toggle .theme-control-toggle-input:not(:checked) ~ .theme-control-toggle-light {
  display: none;
}

/* -------------------------------------------------------------------------- */
/*                       Navbar horizontal || combo                           */
/* -------------------------------------------------------------------------- */
.navbar-horizontal .dropdown-item-group,
.dual-nav .dropdown-item-group,
.navbar-combo .dropdown-item-group {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: var(--phoenix-navbar-top-link-color);
}
.navbar-horizontal .dropdown-item-group .dropdown-item-title,
.dual-nav .dropdown-item-group .dropdown-item-title,
.navbar-combo .dropdown-item-group .dropdown-item-title {
  color: var(--phoenix-navbar-top-link-color);
  margin-bottom: 0;
  white-space: nowrap;
}
.navbar-horizontal .navbar-nav-top .nav-link,
.dual-nav .navbar-nav-top .nav-link,
.navbar-combo .navbar-nav-top .nav-link {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
}
.navbar-horizontal .navbar-nav-top .navbar-dropdown-caret::after,
.dual-nav .navbar-nav-top .navbar-dropdown-caret::after,
.navbar-combo .navbar-nav-top .navbar-dropdown-caret::after {
  display: none;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu,
.dual-nav .navbar-nav-top .dropdown-menu,
.navbar-combo .navbar-nav-top .dropdown-menu {
  border: 1px solid var(--phoenix-navbar-top-border-color);
  background-color: var(--phoenix-navbar-top-menu-bg-color);
}
.navbar-horizontal .navbar-nav-top .dropdown-menu-content,
.dual-nav .navbar-nav-top .dropdown-menu-content,
.navbar-combo .navbar-nav-top .dropdown-menu-content {
  border-radius: 0.5rem;
  background-color: var(--phoenix-navbar-top-menu-bg-color);
}
.navbar-horizontal .navbar-nav-top .dropdown-menu-content .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-menu-content .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-menu-content .dropdown-item {
  color: var(--phoenix-navbar-top-link-color);
}
.navbar-horizontal .navbar-nav-top .navbar-top-card .nav-link,
.dual-nav .navbar-nav-top .navbar-top-card .nav-link,
.navbar-combo .navbar-nav-top .navbar-top-card .nav-link {
  color: var(--phoenix-navbar-top-link-color);
}
.navbar-horizontal .navbar-nav-top .navbar-top-card .nav-link:hover,
.dual-nav .navbar-nav-top .navbar-top-card .nav-link:hover,
.navbar-combo .navbar-nav-top .navbar-top-card .nav-link:hover {
  color: var(--phoenix-navbar-top-link-hover-color);
}
.navbar-horizontal .navbar-top .max-h-dropdown,
.dual-nav .navbar-top .max-h-dropdown,
.navbar-combo .navbar-top .max-h-dropdown {
  max-height: 50vh;
  overscroll-behavior: contain;
}
.navbar-horizontal .navbar-top .navbar-top-collapse.show,
.dual-nav .navbar-top .navbar-top-collapse.show,
.navbar-combo .navbar-top .navbar-top-collapse.show {
  padding-bottom: 1rem;
}
@media (min-width: 1540px) {
  .navbar-horizontal .navbar-top.navbar-expand-xxl,
.dual-nav .navbar-top.navbar-expand-xxl,
.navbar-combo .navbar-top.navbar-expand-xxl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-xxl:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
    background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .nav-link {
    padding-left: 1.125rem;
    padding-right: 1.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-top-collapse.show {
    padding-bottom: 0rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-collapse .navbar-dropdown-caret::after {
    display: block;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl ~ .content,
.dual-nav .navbar-top.navbar-expand-xxl ~ .content,
.navbar-combo .navbar-top.navbar-expand-xxl ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .navbar-horizontal .navbar-top.navbar-expand-xl,
.dual-nav .navbar-top.navbar-expand-xl,
.navbar-combo .navbar-top.navbar-expand-xl {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-xl:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
    background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .nav-link {
    padding-left: 1.125rem;
    padding-right: 1.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-xl .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-top-collapse.show {
    padding-bottom: 0rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xl .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-collapse .navbar-dropdown-caret::after {
    display: block;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl ~ .content,
.dual-nav .navbar-top.navbar-expand-xl ~ .content,
.navbar-combo .navbar-top.navbar-expand-xl ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 992px) {
  .navbar-horizontal .navbar-top.navbar-expand-lg,
.dual-nav .navbar-top.navbar-expand-lg,
.navbar-combo .navbar-top.navbar-expand-lg {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-lg:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
    background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .nav-link {
    padding-left: 1.125rem;
    padding-right: 1.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-lg .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-top-collapse.show {
    padding-bottom: 0rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-lg .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-collapse .navbar-dropdown-caret::after {
    display: block;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg ~ .content,
.dual-nav .navbar-top.navbar-expand-lg ~ .content,
.navbar-combo .navbar-top.navbar-expand-lg ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 768px) {
  .navbar-horizontal .navbar-top.navbar-expand-md,
.dual-nav .navbar-top.navbar-expand-md,
.navbar-combo .navbar-top.navbar-expand-md {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-md:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
    background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .nav-link {
    padding-left: 1.125rem;
    padding-right: 1.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-md .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-md .navbar-top-collapse.show {
    padding-bottom: 0rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-md .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-md .navbar-collapse .navbar-dropdown-caret::after {
    display: block;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md ~ .content,
.dual-nav .navbar-top.navbar-expand-md ~ .content,
.navbar-combo .navbar-top.navbar-expand-md ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media (min-width: 576px) {
  .navbar-horizontal .navbar-top.navbar-expand-sm,
.dual-nav .navbar-top.navbar-expand-sm,
.navbar-combo .navbar-top.navbar-expand-sm {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-sm:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
    background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .nav-link {
    padding-left: 1.125rem;
    padding-right: 1.5rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-sm .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-top-collapse.show {
    padding-bottom: 0rem;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-sm .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-collapse .navbar-dropdown-caret::after {
    display: block;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm ~ .content,
.dual-nav .navbar-top.navbar-expand-sm ~ .content,
.navbar-combo .navbar-top.navbar-expand-sm ~ .content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.navbar-horizontal .navbar-top.navbar-expand-xs,
.dual-nav .navbar-top.navbar-expand-xs,
.navbar-combo .navbar-top.navbar-expand-xs {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.navbar-horizontal .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link.show, .navbar-horizontal .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.dual-nav .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.dual-nav .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link:hover,
.navbar-combo .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link.show,
.navbar-combo .navbar-top.navbar-expand-xs:not(.navbar-slim) .navbar-nav-top .nav-link:hover {
  background-color: var(--phoenix-navbar-top-dropdown-hover-bg);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .nav-link,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .nav-link,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .nav-link {
  padding-left: 1.125rem;
  padding-right: 1.5rem;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-top-collapse.show,
.dual-nav .navbar-top.navbar-expand-xs .navbar-top-collapse.show,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-top-collapse.show {
  padding-bottom: 0rem;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-collapse .navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xs .navbar-collapse .navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-collapse .navbar-dropdown-caret::after {
  display: block;
}
.navbar-horizontal .navbar-top.navbar-expand-xs ~ .content,
.dual-nav .navbar-top.navbar-expand-xs ~ .content,
.navbar-combo .navbar-top.navbar-expand-xs ~ .content {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

/* -------------------------------------------------------------------------- */
/*                            Navbar Combo                                    */
/* -------------------------------------------------------------------------- */
.navbar-combo .move-container .navbar-nav-top {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.navbar-combo .move-container .nav-link {
  padding-left: 0rem !important;
}
.navbar-combo .navbar-darker .move-container .navbar-vertical-hr {
  color: var(--phoenix-navbar-vertical-hr-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-menu {
  background-color: var(--phoenix-navbar-vertical-bg-color);
  border-color: var(--phoenix-navbar-vertical-border-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-item {
  color: var(--phoenix-navbar-vertical-link-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-item.active {
  color: var(--phoenix-navbar-vertical-link-active-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-item:hover {
  background-color: var(--phoenix-navbar-vertical-link-hover-bg);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-menu-card .dropdown-link {
  color: var(--phoenix-navbar-vertical-link-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-menu-card .dropdown-link:hover {
  background-color: var(--phoenix-navbar-vertical-link-hover-bg);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-item-group {
  color: var(--phoenix-navbar-vertical-link-color);
}
.navbar-combo .navbar-darker .move-container .navbar-nav-top .dropdown-item-group .dropdown-item-title {
  color: var(--phoenix-navbar-vertical-link-color);
}

.navbar-horizontal .navbar-nav-top,
.dual-nav .navbar-nav-top,
.navbar-combo .navbar-nav-top {
  max-height: calc(100vh - var(--phoenix-navbar-top-height));
  overflow: auto;
}
.navbar-horizontal .navbar-nav-top .nav-link,
.dual-nav .navbar-nav-top .nav-link,
.navbar-combo .navbar-nav-top .nav-link {
  color: var(--phoenix-navbar-top-link-color);
}
.navbar-horizontal .navbar-nav-top .nav-link.dropdown-toggle,
.dual-nav .navbar-nav-top .nav-link.dropdown-toggle,
.navbar-combo .navbar-nav-top .nav-link.dropdown-toggle {
  color: var(--phoenix-navbar-top-dropdown-color);
}
.navbar-horizontal .navbar-nav-top .dropdown-menu.navbar-dropdown-caret::after,
.dual-nav .navbar-nav-top .dropdown-menu.navbar-dropdown-caret::after,
.navbar-combo .navbar-nav-top .dropdown-menu.navbar-dropdown-caret::after {
  box-shadow: none;
  top: -10px;
  height: 1.2rem;
  width: 1.2rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu,
.dual-nav .navbar-nav-top .dropdown-menu,
.navbar-combo .navbar-nav-top .dropdown-menu {
  margin-top: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: none;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right {
  transform: rotate(90deg);
}
[dir=rtl] .navbar-horizontal .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right,
[dir=rtl] .dual-nav .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right,
[dir=rtl] .navbar-combo .navbar-nav-top .dropdown-menu [aria-expanded=true] .dropdown-indicator-icon.uil-angle-right {
  transform: rotate(-90deg);
}
.navbar-horizontal .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu,
.dual-nav .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu,
.navbar-combo .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu {
  border: none;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: 0px;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item {
  padding-left: 3rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item {
  padding-left: 4rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item {
  padding-left: 5rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-menu .dropdown .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item ~ .dropdown-menu .dropdown-item {
  padding-left: 6rem;
}
.navbar-horizontal .navbar-nav-top.navbar-nav > .nav-item:not(:first-child),
.dual-nav .navbar-nav-top.navbar-nav > .nav-item:not(:first-child),
.navbar-combo .navbar-nav-top.navbar-nav > .nav-item:not(:first-child) {
  margin-top: 0.3rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-item-wrapper,
.dual-nav .navbar-nav-top .dropdown-item-wrapper,
.navbar-combo .navbar-nav-top .dropdown-item-wrapper {
  display: flex;
  margin-left: -0.25rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-item {
  padding-left: 1.5rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-item:not(.dropdown-toggle) .dropdown-item-wrapper,
.dual-nav .navbar-nav-top .dropdown-item:not(.dropdown-toggle) .dropdown-item-wrapper,
.navbar-combo .navbar-nav-top .dropdown-item:not(.dropdown-toggle) .dropdown-item-wrapper {
  padding-left: 1rem;
}
.navbar-horizontal .navbar-nav-top .dropdown-item,
.dual-nav .navbar-nav-top .dropdown-item,
.navbar-combo .navbar-nav-top .dropdown-item {
  color: var(--phoenix-navbar-top-link-color);
  font-weight: 600;
  line-height: 1.2;
}
.navbar-horizontal .navbar-nav-top .dropdown-item:focus,
.dual-nav .navbar-nav-top .dropdown-item:focus,
.navbar-combo .navbar-nav-top .dropdown-item:focus {
  background-color: transparent;
  color: var(--phoenix-navbar-top-link-color);
}
.navbar-horizontal .navbar-nav-top .dropdown-item:hover,
.dual-nav .navbar-nav-top .dropdown-item:hover,
.navbar-combo .navbar-nav-top .dropdown-item:hover {
  background-color: var(--phoenix-navbar-top-link-hover-bg);
  color: var(--phoenix-navbar-top-link-hover-color);
}
.navbar-horizontal .navbar-nav-top .dropdown-item.active,
.dual-nav .navbar-nav-top .dropdown-item.active,
.navbar-combo .navbar-nav-top .dropdown-item.active {
  background-color: transparent;
  color: var(--phoenix-navbar-top-link-active-color);
}
@media (min-width: 1540px) {
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top {
    overflow: visible;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top.navbar-nav > .nav-item {
    margin-top: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item-wrapper {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-indicator-icon {
    margin-left: auto;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
    margin-top: var(--phoenix-navbar-top-menu-margin);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu {
    min-width: 13rem;
    box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    border: 1px solid var(--phoenix-navbar-top-border-color);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
    padding-left: 1rem !important;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
    transform: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
    min-width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown-item.show {
    background-color: var(--phoenix-navbar-top-link-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    top: 0;
    left: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(-90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
    position: static;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0;
    min-width: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xxl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 1200px) {
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top {
    overflow: visible;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top.navbar-nav > .nav-item {
    margin-top: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item-wrapper {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-indicator-icon {
    margin-left: auto;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
    margin-top: var(--phoenix-navbar-top-menu-margin);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu {
    min-width: 13rem;
    box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    border: 1px solid var(--phoenix-navbar-top-border-color);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
    padding-left: 1rem !important;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
    transform: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
    min-width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown-item.show {
    background-color: var(--phoenix-navbar-top-link-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    top: 0;
    left: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(-90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
    position: static;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0;
    min-width: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xl .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 992px) {
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top {
    overflow: visible;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top.navbar-nav > .nav-item {
    margin-top: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item-wrapper {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-indicator-icon {
    margin-left: auto;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
    margin-top: var(--phoenix-navbar-top-menu-margin);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu {
    min-width: 13rem;
    box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    border: 1px solid var(--phoenix-navbar-top-border-color);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
    padding-left: 1rem !important;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
    transform: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
    min-width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown-item.show {
    background-color: var(--phoenix-navbar-top-link-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    top: 0;
    left: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(-90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
    position: static;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0;
    min-width: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-lg .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 768px) {
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top {
    overflow: visible;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top.navbar-nav > .nav-item {
    margin-top: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item-wrapper {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-indicator-icon {
    margin-left: auto;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
    margin-top: var(--phoenix-navbar-top-menu-margin);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu {
    min-width: 13rem;
    box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    border: 1px solid var(--phoenix-navbar-top-border-color);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
    padding-left: 1rem !important;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
    transform: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
    min-width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown-item.show {
    background-color: var(--phoenix-navbar-top-link-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    top: 0;
    left: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(-90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
    position: static;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0;
    min-width: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-md .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
    padding-left: 2.5rem !important;
  }
}
@media (min-width: 576px) {
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top {
    overflow: visible;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top.navbar-nav > .nav-item {
    margin-top: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle::after {
    display: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item-wrapper {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-indicator-icon {
    margin-left: auto;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
    margin-top: var(--phoenix-navbar-top-menu-margin);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu {
    min-width: 13rem;
    box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
    left: 0;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    border: 1px solid var(--phoenix-navbar-top-border-color);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
    padding-left: 1rem !important;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
    transform: none;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
    min-width: 550px;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown-item.show {
    background-color: var(--phoenix-navbar-top-link-hover-bg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown .dropdown .dropdown-menu {
    top: 0;
    left: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
    transform: rotate(-90deg);
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
    position: static;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-top: 0rem;
    padding-bottom: 0;
    min-width: 100%;
  }
  .navbar-horizontal .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-sm .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
    padding-left: 2.5rem !important;
  }
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top {
  overflow: visible;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top.navbar-nav > .nav-item,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top.navbar-nav > .nav-item,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top.navbar-nav > .nav-item {
  margin-top: 0;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle::after,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle::after,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle::after {
  display: none;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-toggle > .dropdown-item-wrapper {
  display: flex;
  flex-direction: row-reverse;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item-wrapper,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item-wrapper,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item-wrapper {
  width: 100%;
  padding-left: 0;
  margin-left: 0;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-indicator-icon,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-indicator-icon,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-indicator-icon {
  margin-left: auto;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.nav-item > .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.nav-item > .dropdown-menu {
  margin-top: var(--phoenix-navbar-top-menu-margin);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu {
  min-width: 13rem;
  box-shadow: -8px 0px 16px -4px rgba(0, 0, 0, 0.1);
  left: 0;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  border: 1px solid var(--phoenix-navbar-top-border-color);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-item {
  padding-left: 1rem !important;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu .dropdown-indicator-icon.uil-angle-right {
  transform: none;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card {
  min-width: 550px;
  left: 50%;
  transform: translateX(-50%);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-menu.dropdown-menu-card.navbar-dropdown-caret::after {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item:hover, .navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item.show,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item:hover,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item.show,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item:hover,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown-item.show {
  background-color: var(--phoenix-navbar-top-link-hover-bg);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown .dropdown-menu,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown .dropdown .dropdown-menu {
  top: 0;
  left: 100%;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item .dropdown-indicator-icon.uil-angle-right {
  transform: rotate(90deg);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-item.show .dropdown-indicator-icon.uil-angle-right {
  transform: rotate(-90deg);
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show {
  position: static;
  border: none;
  margin: 0;
  box-shadow: none;
  padding-top: 0rem;
  padding-bottom: 0;
  min-width: 100%;
}
.navbar-horizontal .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.dual-nav .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item,
.navbar-combo .navbar-top.navbar-expand-xs .navbar-nav-top .dropdown.dropdown-inside .dropdown-menu.show .dropdown-item {
  padding-left: 2.5rem !important;
}

[data-dropdown-on-hover] .dropdown-menu::before {
  content: "";
  position: absolute;
  height: 1.5rem;
  background: transparent;
  top: -1.5rem;
  width: calc(100% - 5px);
  z-index: -1;
}

.dropdown-menu-card .dropdown-link {
  border-radius: 0.375rem;
  text-decoration: none;
  padding: var(--phoenix-dropdown-item-padding-y) 1.5rem;
  display: block;
  color: var(--phoenix-navbar-top-link-color);
}
.dropdown-menu-card .dropdown-link.active {
  color: var(--phoenix-primary);
}
.dropdown-menu-card .dropdown-link:hover {
  background: var(--phoenix-navbar-top-link-hover-bg);
  color: var(--phoenix-navbar-top-link-hover-color);
}

.img-dropdown {
  background-image: url("../img/icons/logo-bg.png");
  background-position: right -14px bottom -20px;
  background-repeat: no-repeat;
}

/* -------------------------------------------------------------------------- */
/*                       Navbar double-top                                    */
/* -------------------------------------------------------------------------- */
.dual-nav .navbar-top {
  border-bottom: 1px solid var(--phoenix-dual-nav-border-color);
}
@media (min-width: 1540px) {
  .dual-nav .navbar-top.navbar-expand-xxl {
    padding-left: 0;
    padding-right: 0;
  }
  .dual-nav .navbar-top.navbar-expand-xxl ~ .content {
    --phoenix-navbar-top-height: 7.3rem;
  }
  .dual-nav .navbar-top.navbar-expand-xxl .navbar-top-collapse {
    border-top: 1px solid var(--phoenix-dual-nav-border-color);
    padding: 0.5rem 2rem;
  }
  .dual-nav .navbar-top.navbar-expand-xxl .navbar-top-collapse.show {
    padding-bottom: 0.5rem;
  }
  .dual-nav .navbar-top.navbar-expand-xxl .dual-nav-first-layer {
    background-color: var(--phoenix-dual-nav-first-layer-bg);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 1200px) {
  .dual-nav .navbar-top.navbar-expand-xl {
    padding-left: 0;
    padding-right: 0;
  }
  .dual-nav .navbar-top.navbar-expand-xl ~ .content {
    --phoenix-navbar-top-height: 7.3rem;
  }
  .dual-nav .navbar-top.navbar-expand-xl .navbar-top-collapse {
    border-top: 1px solid var(--phoenix-dual-nav-border-color);
    padding: 0.5rem 2rem;
  }
  .dual-nav .navbar-top.navbar-expand-xl .navbar-top-collapse.show {
    padding-bottom: 0.5rem;
  }
  .dual-nav .navbar-top.navbar-expand-xl .dual-nav-first-layer {
    background-color: var(--phoenix-dual-nav-first-layer-bg);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 992px) {
  .dual-nav .navbar-top.navbar-expand-lg {
    padding-left: 0;
    padding-right: 0;
  }
  .dual-nav .navbar-top.navbar-expand-lg ~ .content {
    --phoenix-navbar-top-height: 7.3rem;
  }
  .dual-nav .navbar-top.navbar-expand-lg .navbar-top-collapse {
    border-top: 1px solid var(--phoenix-dual-nav-border-color);
    padding: 0.5rem 2rem;
  }
  .dual-nav .navbar-top.navbar-expand-lg .navbar-top-collapse.show {
    padding-bottom: 0.5rem;
  }
  .dual-nav .navbar-top.navbar-expand-lg .dual-nav-first-layer {
    background-color: var(--phoenix-dual-nav-first-layer-bg);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 768px) {
  .dual-nav .navbar-top.navbar-expand-md {
    padding-left: 0;
    padding-right: 0;
  }
  .dual-nav .navbar-top.navbar-expand-md ~ .content {
    --phoenix-navbar-top-height: 7.3rem;
  }
  .dual-nav .navbar-top.navbar-expand-md .navbar-top-collapse {
    border-top: 1px solid var(--phoenix-dual-nav-border-color);
    padding: 0.5rem 2rem;
  }
  .dual-nav .navbar-top.navbar-expand-md .navbar-top-collapse.show {
    padding-bottom: 0.5rem;
  }
  .dual-nav .navbar-top.navbar-expand-md .dual-nav-first-layer {
    background-color: var(--phoenix-dual-nav-first-layer-bg);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (min-width: 576px) {
  .dual-nav .navbar-top.navbar-expand-sm {
    padding-left: 0;
    padding-right: 0;
  }
  .dual-nav .navbar-top.navbar-expand-sm ~ .content {
    --phoenix-navbar-top-height: 7.3rem;
  }
  .dual-nav .navbar-top.navbar-expand-sm .navbar-top-collapse {
    border-top: 1px solid var(--phoenix-dual-nav-border-color);
    padding: 0.5rem 2rem;
  }
  .dual-nav .navbar-top.navbar-expand-sm .navbar-top-collapse.show {
    padding-bottom: 0.5rem;
  }
  .dual-nav .navbar-top.navbar-expand-sm .dual-nav-first-layer {
    background-color: var(--phoenix-dual-nav-first-layer-bg);
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.dual-nav .navbar-top.navbar-expand-xs {
  padding-left: 0;
  padding-right: 0;
}
.dual-nav .navbar-top.navbar-expand-xs ~ .content {
  --phoenix-navbar-top-height: 7.3rem;
}
.dual-nav .navbar-top.navbar-expand-xs .navbar-top-collapse {
  border-top: 1px solid var(--phoenix-dual-nav-border-color);
  padding: 0.5rem 2rem;
}
.dual-nav .navbar-top.navbar-expand-xs .navbar-top-collapse.show {
  padding-bottom: 0.5rem;
}
.dual-nav .navbar-top.navbar-expand-xs .dual-nav-first-layer {
  background-color: var(--phoenix-dual-nav-first-layer-bg);
  padding-left: 2rem;
  padding-right: 2rem;
}

.navbar-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--phoenix-white);
  filter: drop-shadow(0px -4px 40px rgba(var(--phoenix-gray-700), 0.07));
  border-top: 1px solid var(--phoenix-gray-300);
  padding: 1rem 1.5rem;
  z-index: 1020;
  display: flex;
  justify-content: center;
}
.navbar-bottom .nav {
  justify-content: center;
  width: 100%;
  max-width: 420px;
}
@media (min-width: 576px) {
  .navbar-bottom .nav {
    max-width: 100%;
  }
}
.navbar-bottom .nav .nav-link {
  line-height: 1;
  color: var(--phoenix-gray-700);
  font-size: 1.25rem;
  padding: 0;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .navbar-bottom .nav .nav-link:not(:last-child) {
    margin-right: 1rem;
  }
}
.navbar-bottom .nav .nav-link .nav-label {
  font-weight: 700;
  display: none;
}
.navbar-bottom .nav .nav-link.active {
  flex: 1;
  margin-right: 0.5rem;
  text-align: center;
  border-radius: 50rem;
  background: var(--phoenix-gray-100);
  color: var(--phoenix-primary);
}
.navbar-bottom .nav .nav-link.active .nav-icon {
  margin-right: 0.5rem;
}
.navbar-bottom .nav .nav-link.active .nav-label {
  display: inline-block;
}
@media (min-width: 576px) {
  .navbar-bottom .nav .nav-link.active {
    width: auto;
    padding: 0 1.5rem;
  }
  .navbar-bottom .nav .nav-link.active:not(:last-child) {
    margin-right: 2rem;
  }
}
@media (min-width: 576px) {
  .navbar-bottom .nav .nav-link.active {
    flex: 0;
    padding: 0 4rem;
  }
}

.navbar-top.navbar-slim {
  min-height: 1.7rem;
}
.navbar-top.navbar-slim .navbar-toggler-humburger-icon {
  height: 1rem;
}
.navbar-top.navbar-slim .navbar-toggler-humburger-icon:hover, .navbar-top.navbar-slim .navbar-toggler-humburger-icon:focus {
  background-color: transparent;
}
.navbar-top.navbar-slim .navbar-logo {
  height: 1.7rem;
}
.navbar-top.navbar-slim .navbar-brand {
  margin-bottom: 0;
  color: #e5780b;
  font-size: larger;
}
.navbar-top.navbar-slim .navbar-nav-icons > .nav-item > .nav-link {
  padding: 0.25rem 0.5rem;
}
@media (min-width: 1540px) {
  .navbar-top.navbar-expand-xxl.navbar-slim .nav-link.dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
  }
}
@media (min-width: 1200px) {
  .navbar-top.navbar-expand-xl.navbar-slim .nav-link.dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
  }
}
@media (min-width: 992px) {
  .navbar-top.navbar-expand-lg.navbar-slim .nav-link.dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
  }
}
@media (min-width: 768px) {
  .navbar-top.navbar-expand-md.navbar-slim .nav-link.dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
  }
}
@media (min-width: 576px) {
  .navbar-top.navbar-expand-sm.navbar-slim .nav-link.dropdown-toggle {
    padding-top: 0.5rem;
    padding-bottom: 0.4rem;
  }
}
.navbar-top.navbar-expand-xs.navbar-slim .nav-link.dropdown-toggle {
  padding-top: 0.5rem;
  padding-bottom: 0.4rem;
}

/* -------------------------------------------------------------------------- */
/*                                   Navbar                                   */
/* -------------------------------------------------------------------------- */
.navbar .container-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.navbar .navbar-logo {
  height: 4rem;
  display: flex;
  align-items: center;
}
.navbar .nav-link {
  outline: none;
}
.navbar .dropdown-menu {
  overflow: visible;
  left: -0.5625rem;
}
.navbar .dropdown-menu.navbar-dropdown-caret:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--phoenix-black);
  top: -10px;
  left: 1.5rem;
  transform: rotate(45deg);
  background: var(--phoenix-white);
  background: inherit;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: var(--phoenix-gray-300);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.navbar .dropdown-menu.dropdown-menu-end.navbar-dropdown-caret {
  left: auto;
  right: -0.5625rem;
}
.navbar .dropdown-menu.dropdown-menu-end.navbar-dropdown-caret:after {
  right: 0.9rem;
  left: auto;
}
.navbar.navbar-slim .dropdown-menu.navbar-dropdown-caret:after {
  width: 1rem;
  height: 1rem;
  top: -9px;
}
.navbar .dropdown-item {
  font-weight: 500;
}
.navbar .dropdown-item:hover, .navbar .dropdown-item:focus {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-gray-1000);
}

.navbar-toggler-humburger-icon {
  height: 2.25rem;
  width: 2.25rem;
  padding: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0;
  margin-right: 0.6125rem;
}
.navbar-toggler-humburger-icon:hover, .navbar-toggler-humburger-icon:focus {
  background-color: var(--phoenix-gray-300);
}

.navbar-toggle-icon {
  position: relative;
  height: 0.125rem;
  width: 1.125rem;
  transition: all 0.2s ease-in-out;
  display: block;
  top: -0.3125rem;
  padding-right: 0;
}
.navbar-toggle-icon .toggle-line {
  display: block;
  height: 100%;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
  background-color: var(--phoenix-hamburger-menu-color);
}
.navbar-toggle-icon:after, .navbar-toggle-icon:before {
  content: "";
  position: absolute;
  height: 0.125rem;
  background-color: var(--phoenix-hamburger-menu-color);
  left: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 0.375rem;
  width: 100%;
}
.navbar-toggle-icon:before {
  top: 0.3125rem;
}
.navbar-toggle-icon:after {
  top: 0.625rem;
}

.nav-vertical .nav .nav-link {
  padding-left: 1.5rem;
}
.nav-vertical .nav-link {
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: var(--phoenix-gray-800);
}
.nav-vertical .nav-link:visited, .nav-vertical .nav-link:active, .nav-vertical .nav-link:focus, .nav-vertical .nav-link:hover {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-dark);
}
.nav-vertical.doc-nav .nav-link.active {
  background-color: var(--phoenix-gray-200);
}
@media (max-width: 1199.98px) {
  .nav-vertical.nav-vertical .nav-link {
    padding-left: 1.5rem;
  }
}

.ecommerce-topbar .navbar-nav .dropdown-menu {
  position: absolute;
}

.ecommerce-navbar .nav-item {
  white-space: nowrap;
}
.ecommerce-navbar .nav-item .nav-link {
  color: var(--phoenix-gray-700);
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 15px;
}
.ecommerce-navbar .nav-item .nav-link.active, .ecommerce-navbar .nav-item .nav-link:hover {
  color: var(--phoenix-black);
}

/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box {
  position: relative;
  font-size: 0.8rem;
  width: 20rem;
}
.search-box .search-box-icon {
  position: absolute;
  color: var(--phoenix-gray-500);
  top: 50%;
  left: 1rem;
  transform: translateY(-48%);
}
.search-box .search-input {
  padding-left: 2.5rem;
  padding-right: 2rem;
  border-radius: 0.375rem;
  box-shadow: none;
}
.search-box .search-input::-webkit-search-cancel-button {
  display: none;
}
.search-box [data-bs-toggle=search]:not(.show) + [data-bs-dismiss=search] {
  display: none;
}
.search-box .file-thumbnail {
  width: 1.75rem;
  height: 1.75rem;
}
.search-box.ecommerce-search-box {
  width: 31.25rem;
}
.search-box.ecommerce-search-box .search-input {
  border-radius: 3rem;
}
.search-box.navbar-top-search-box .search-input:focus {
  border-color: var(--phoenix-gray-300);
}
.search-box.navbar-top-search-box .show .search-input {
  border-radius: 0.375rem 0.375rem 0 0 !important;
}
.search-box.navbar-top-search-box .dropdown-menu {
  margin-top: 0;
}
.search-box.navbar-top-search-box .dropdown-menu.show {
  border-top: 0 !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.search-box.navbar-top-search-box .dropdown-menu .dropdown-item:hover {
  background: var(--phoenix-gray-100);
}

/* -------------------------------------------------------------------------- */
/*                                   Avatar                                   */
/* -------------------------------------------------------------------------- */
.avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.avatar .avatar-placeholder {
  background: var(--phoenix-gray-200);
}
.avatar .avatar-name {
  position: absolute;
  text-align: center;
  color: var(--phoenix-avatar-name-color);
  background-color: var(--phoenix-primary-100);
  font-weight: bold;
  text-transform: uppercase;
  display: block;
}
.avatar .avatar-name > span {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-weight: 900 !important;
}
.avatar .avatar-emoji {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar .avatar-emoji > span {
  transform: translateY(2%);
  display: inline-block;
}
.avatar img {
  display: block;
}
.avatar img,
.avatar .avatar-name {
  width: 100%;
  height: 100%;
}
.avatar img {
  object-fit: cover;
}
.avatar.avatar-bordered.avatar-s img,
.avatar.avatar-bordered.avatar-s .avatar-name {
  border: 1px solid;
}
.avatar.avatar-bordered.avatar-m img,
.avatar.avatar-bordered.avatar-m .avatar-name {
  border: 1px solid;
}
.avatar.avatar-bordered.avatar-l img,
.avatar.avatar-bordered.avatar-l .avatar-name {
  border: 2px solid var(--phoenix-avatar-status-border-color);
}
.avatar.avatar-bordered.avatar-xl img,
.avatar.avatar-bordered.avatar-xl .avatar-name {
  border: 3px solid var(--phoenix-avatar-status-border-color);
}
.avatar[class*=status-]:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: 1;
}
.avatar.status-online:before {
  background-color: var(--phoenix-success);
}
.avatar.status-offline:before {
  background-color: var(--phoenix-secondary);
}
.avatar.status-away:before {
  background-color: var(--phoenix-warning);
}
.avatar.status-do-not-disturb:before {
  background-color: var(--phoenix-danger);
}
.avatar .avatar-button {
  background-color: var(--phoenix-avatar-button-bg) !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--phoenix-avatar-button-bg);
  font-size: 12px;
}
.avatar .avatar-button:hover, .avatar .avatar-button:focus {
  background-color: var(--phoenix-avatar-button-hover-bg) !important;
}

.avatar:hover .overlay-icon {
  opacity: 1;
}

.avatar-s {
  height: 1.5rem;
  width: 1.5rem;
}
.avatar-s .avatar-name {
  font-size: 0.7894736842rem;
  line-height: 1.2;
}
.avatar-s .avatar-emoji {
  font-size: 1.5rem;
}
.avatar-s[class*=status-]:before {
  border: 1px solid var(--phoenix-avatar-status-border-color);
  height: 6px;
  width: 6px;
  right: 1px;
  bottom: 1px;
}
.avatar-s .rounded-soft {
  border-radius: 0.25rem !important;
}
.avatar-s .rounded-none {
  border-radius: 0 !important;
}

.avatar-m {
  height: 2rem;
  width: 2rem;
}
.avatar-m .avatar-name {
  font-size: 1.0526315789rem;
  line-height: 1.2;
}
.avatar-m .avatar-emoji {
  font-size: 2rem;
}
.avatar-m[class*=status-]:before {
  border: 1px solid var(--phoenix-avatar-status-border-color);
  height: 8px;
  width: 8px;
  right: 1px;
  bottom: 1px;
}
.avatar-m .rounded-soft {
  border-radius: 0.3333333333rem !important;
}
.avatar-m .rounded-none {
  border-radius: 0 !important;
}

.avatar-l {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-l .avatar-name {
  font-size: 1.3157894737rem;
  line-height: 1.2;
}
.avatar-l .avatar-emoji {
  font-size: 2.5rem;
}
.avatar-l[class*=status-]:before {
  border: 1px solid var(--phoenix-avatar-status-border-color);
  height: 9px;
  width: 9px;
  right: 2px;
  bottom: 2px;
}
.avatar-l .rounded-soft {
  border-radius: 0.4166666667rem !important;
}
.avatar-l .rounded-none {
  border-radius: 0 !important;
}

.avatar-xl {
  height: 3rem;
  width: 3rem;
}
.avatar-xl .avatar-name {
  font-size: 1.5789473684rem;
  line-height: 1.2;
}
.avatar-xl .avatar-emoji {
  font-size: 3rem;
}
.avatar-xl[class*=status-]:before {
  border: 2px solid var(--phoenix-avatar-status-border-color);
  height: 12px;
  width: 12px;
  right: 1px;
  bottom: 1px;
}
.avatar-xl .rounded-soft {
  border-radius: 0.5rem !important;
}
.avatar-xl .rounded-none {
  border-radius: 0 !important;
}

.avatar-2xl {
  height: 3.5rem;
  width: 3.5rem;
}
.avatar-2xl .avatar-name {
  font-size: 1.8421052632rem;
  line-height: 1.2;
}
.avatar-2xl .avatar-emoji {
  font-size: 3.5rem;
}
.avatar-2xl[class*=status-]:before {
  border: 2px solid var(--phoenix-avatar-status-border-color);
  height: 16px;
  width: 16px;
  right: -3px;
  bottom: -3px;
}
.avatar-2xl .rounded-soft {
  border-radius: 0.5833333333rem !important;
}
.avatar-2xl .rounded-none {
  border-radius: 0 !important;
}

.avatar-3xl {
  height: 4.5rem;
  width: 4.5rem;
}
.avatar-3xl .avatar-name {
  font-size: 2.3684210526rem;
  line-height: 1.2;
}
.avatar-3xl .avatar-emoji {
  font-size: 4.5rem;
}
.avatar-3xl[class*=status-]:before {
  border: 3px solid var(--phoenix-avatar-status-border-color);
  height: 18px;
  width: 18px;
  right: 0;
  bottom: 0;
}
.avatar-3xl .rounded-soft {
  border-radius: 0.75rem !important;
}
.avatar-3xl .rounded-none {
  border-radius: 0 !important;
}

.avatar-4xl {
  height: 6rem;
  width: 6rem;
}
.avatar-4xl .avatar-name {
  font-size: 3.1578947368rem;
  line-height: 1.2;
}
.avatar-4xl .avatar-emoji {
  font-size: 6rem;
}
.avatar-4xl[class*=status-]:before {
  border: 3px solid var(--phoenix-avatar-status-border-color);
  height: 24px;
  width: 24px;
  right: 3px;
  bottom: 3px;
}
.avatar-4xl .rounded-soft {
  border-radius: 1rem !important;
}
.avatar-4xl .rounded-none {
  border-radius: 0 !important;
}

.avatar-5xl {
  height: 9.375rem;
  width: 9.375rem;
}
.avatar-5xl .avatar-name {
  font-size: 4.9342105263rem;
  line-height: 1.2;
}
.avatar-5xl .avatar-emoji {
  font-size: 9.375rem;
}
.avatar-5xl[class*=status-]:before {
  border: 4px solid var(--phoenix-avatar-status-border-color);
  height: 32px;
  width: 32px;
  right: 8px;
  bottom: 8px;
}
.avatar-5xl .rounded-soft {
  border-radius: 1.5625rem !important;
}
.avatar-5xl .rounded-none {
  border-radius: 0 !important;
}

.avatar-group {
  display: flex;
  flex-wrap: nowrap;
}
.avatar-group .dropdown-toggle:not(:first-child) .avatar-s,
.avatar-group .dropdown-toggle:not(:first-child) .avatar-m,
.avatar-group .dropdown-toggle:not(:first-child) .avatar-l,
.avatar-group .dropdown-toggle:not(:first-child) .avatar-xl,
.avatar-group .dropdown-toggle:not(:first-child) .avatar-2xl {
  margin-left: -0.5rem;
}
.avatar-group .avatar-s:not(:first-child),
.avatar-group .avatar-m:not(:first-child),
.avatar-group .avatar-l:not(:first-child),
.avatar-group .avatar-xl:not(:first-child),
.avatar-group .avatar-2xl:not(:first-child) {
  margin-left: -0.5rem;
}
.avatar-group .avatar-3xl:not(:first-child) {
  margin-left: -1rem;
}
.avatar-group .avatar-4xl:not(:first-child) {
  margin-left: -1.5rem;
}
.avatar-group .avatar-5xl:not(:first-child) {
  margin-left: -2rem;
}
.avatar-group-dense .avatar-s:not(:first-child) {
  margin-left: -0.6rem;
}
.avatar-group-dense .avatar-m:not(:first-child) {
  margin-left: -0.8rem;
}
.avatar-group-dense .avatar-l:not(:first-child),
.avatar-group-dense .avatar-xl:not(:first-child),
.avatar-group-dense .avatar-2xl:not(:first-child) {
  margin-left: -1rem;
}
.avatar-group-dense .avatar-3xl:not(:first-child) {
  margin-left: -1.5rem;
}
.avatar-group-dense .avatar-4xl:not(:first-child) {
  margin-left: -2.2rem;
}
.avatar-group-dense .avatar-5xl:not(:first-child) {
  margin-left: -3.6rem;
}

.avatar-dropdown-menu .nav .nav-link:hover {
  background: var(--phoenix-gray-100);
}

.modal-title {
  font-size: 1.25rem;
  color: var(--phoenix-gray-800);
}

@media (min-width: 768px) {
  .modal-md {
    --phoenix-modal-width: 768px;
  }
}
.accordion {
  --phoenix-accordion-color: #222834;
}

.accordion-button {
  font-weight: 700;
}
.accordion-button.collapsed {
  color: var(--phoenix-primary);
}

.accordion-item {
  border-bottom: 1px solid var(--phoenix-gray-300);
  padding: 0.5rem;
}

.standard-accordion .accordion-button {
  padding: 0.5rem 0;
  color: #141824;
}
.standard-accordion .accordion-button::before {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  margin-right: 0.5rem;
}
.standard-accordion .accordion-button::after {
  display: none;
}
.standard-accordion .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  transform: rotate(-180deg);
}
.standard-accordion .accordion-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  margin-left: 0.25rem;
}
.standard-accordion .accordion-item {
  border-bottom: none;
}

.iconic-accordion .accordion-button {
  color: #141824;
  padding: 0.5rem 0;
  margin: 0.25rem 0;
}
.iconic-accordion .accordion-button::before {
  content: "";
  width: 3rem;
  height: 3rem;
  padding: 4px;
  border-radius: 50%;
  background: var(--phoenix-primary);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='16'%3E%3C/line%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  margin-right: 0.5rem;
}
.iconic-accordion .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233874FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  transform: rotate(-180deg);
}
.iconic-accordion .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874FF' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
}
.iconic-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='-200 -200 900 900'%3E%3Cpath fill='%233874FF' d='M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z'%3E%3C/path%3E%3C/svg%3E");
}
.iconic-accordion .accordion-body {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  margin-left: 0.5rem;
}
.iconic-accordion .accordion-item {
  border-bottom: none;
}

.dot-accordion .accordion-button {
  padding: 0.5rem 0;
}
.dot-accordion .accordion-button::before {
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--phoenix-gray-400);
  margin-right: 0.5rem;
}
.dot-accordion .accordion-button::after {
  display: none;
}
.dot-accordion .accordion-item {
  border-bottom: none;
}

.breadcrumb {
  font-weight: 700;
}

@media (min-width: 1200px) {
  .breadcrumb-sticky-top {
    position: sticky;
    top: calc(var(--phoenix-navbar-top-height) + 2rem);
  }
}

/*-----------------------------------------------
|   Documentation link and Prism
-----------------------------------------------*/
.code-block {
  padding: 0;
  overflow: auto;
}
.code-block pre[class*=language-] {
  padding: 1.6rem;
  border: 0;
  margin: 0;
  border-radius: 0;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background-color: var(--phoenix-docs-language-bg);
}

code.language-html:first-child {
  display: block;
}

code[class*=language-],
pre[class*=language-] {
  font-family: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.8rem;
  border-radius: 0;
}

pre[class*=language-] {
  margin: 0;
  padding: 1.5rem;
}

.contains-anchor a {
  opacity: 0;
  transition-property: opacity;
}
.contains-anchor:hover a, .contains-anchor:focus a {
  opacity: 1;
  text-decoration: none;
}

.components-nav {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.85rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.component-example [class^=border] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  margin: 0.75rem;
  background-color: var(--phoenix-gray-100);
}

/*-----------------------------------------------
|   Utilities
-----------------------------------------------*/
.border-component [class^=border],
.border-component [class^=rounded-] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
}
.border-component [class^=border] {
  background-color: var(--phoenix-gray-100);
}
.border-component [class^=rounded-] {
  background-color: var(--phoenix-gray-200);
}

#loaders [class^=spinner] {
  margin-right: 0.5rem;
}

.doc-tab-nav a.btn, .doc-tab-nav [data-list] a.page, [data-list] .doc-tab-nav a.page, .doc-tab-nav .table-list a.page, .table-list .doc-tab-nav a.page, .doc-tab-nav .tox a.tox-button, .tox .doc-tab-nav a.tox-button, .doc-tab-nav .tox a.tox-button-secondary, .tox .doc-tab-nav a.tox-button-secondary, .doc-tab-nav .tox .tox-dialog__footer a.tox-button--secondary, .tox .tox-dialog__footer .doc-tab-nav a.tox-button--secondary, .doc-tab-nav .tox .tox-dialog__footer a.tox-button:last-child, .tox .tox-dialog__footer .doc-tab-nav a.tox-button:last-child, .doc-tab-nav .google-map-control-btn a.zoomIn, .google-map-control-btn .doc-tab-nav a.zoomIn,
.doc-tab-nav .google-map-control-btn a.zoomOut,
.google-map-control-btn .doc-tab-nav a.zoomOut {
  width: 8.4375rem;
  text-align: center;
}
.doc-tab-nav .active {
  display: none;
}

.code-btn:not(.collapsed) {
  display: none;
}

.code-btn.collapsed + .preview-btn {
  display: none;
}

.opacity-docs [class^=opacity-] {
  height: 100px;
  width: 100px;
  background-color: var(--phoenix-primary);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.overflow-docs [class^=overflow-] {
  max-height: 70px;
  max-width: 22%;
  background-color: var(--phoenix-gray-100);
  display: inline-block;
  padding: 1rem;
  margin-right: 0.5rem;
}

/*-----------------------------------------------
|   Dropdown
-----------------------------------------------*/
.dropdown-menu {
  font-size: 0.8rem;
}

.dropdown-caret-none:after, .dropdown-caret-none:before {
  display: none !important;
}

.dropdown-md {
  min-width: 16.625rem;
}

/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */
.hover-actions {
  display: none;
  position: absolute;
}
.hover-actions.show {
  display: inline-block;
}
.hover-actions.show + .hover-hide {
  opacity: 1;
  transition: opacity 0.3s ease;
}

a[href].bg-200:hover, a[href].bg-200:focus {
  background-color: var(--phoenix-gray-300) !important;
}

.hover-primary:hover, .hover-primary:focus {
  color: #3874ff !important;
}

.hover-danger:hover, .hover-danger:focus {
  color: #ed2000 !important;
}

.hover-shadow:hover, .hover-shadow:focus {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
}

.hover-actions-trigger {
  position: relative;
}
.hover-actions-trigger .inbox-link {
  color: inherit;
  text-decoration: none;
}
.hover-actions-trigger:hover .hover-actions, .hover-actions-trigger:focus .hover-actions {
  z-index: 1;
  display: flex;
  animation: fade 0.15s ease-in-out;
}
.hover-actions-trigger:hover .hover-actions.btn, .hover-actions-trigger:hover [data-list] .hover-actions.page, [data-list] .hover-actions-trigger:hover .hover-actions.page, .hover-actions-trigger:hover .table-list .hover-actions.page, .table-list .hover-actions-trigger:hover .hover-actions.page, .hover-actions-trigger:hover .tox .hover-actions.tox-button, .tox .hover-actions-trigger:hover .hover-actions.tox-button, .hover-actions-trigger:hover .tox .hover-actions.tox-button-secondary, .tox .hover-actions-trigger:hover .hover-actions.tox-button-secondary, .hover-actions-trigger:hover .tox .tox-dialog__footer .hover-actions.tox-button--secondary, .tox .tox-dialog__footer .hover-actions-trigger:hover .hover-actions.tox-button--secondary, .hover-actions-trigger:hover .google-map-control-btn .hover-actions.zoomIn, .google-map-control-btn .hover-actions-trigger:hover .hover-actions.zoomIn,
.hover-actions-trigger:hover .google-map-control-btn .hover-actions.zoomOut,
.google-map-control-btn .hover-actions-trigger:hover .hover-actions.zoomOut, .hover-actions-trigger:focus .hover-actions.btn, .hover-actions-trigger:focus [data-list] .hover-actions.page, [data-list] .hover-actions-trigger:focus .hover-actions.page, .hover-actions-trigger:focus .table-list .hover-actions.page, .table-list .hover-actions-trigger:focus .hover-actions.page, .hover-actions-trigger:focus .tox .hover-actions.tox-button, .tox .hover-actions-trigger:focus .hover-actions.tox-button, .hover-actions-trigger:focus .tox .hover-actions.tox-button-secondary, .tox .hover-actions-trigger:focus .hover-actions.tox-button-secondary, .hover-actions-trigger:focus .tox .tox-dialog__footer .hover-actions.tox-button--secondary, .tox .tox-dialog__footer .hover-actions-trigger:focus .hover-actions.tox-button--secondary, .hover-actions-trigger:focus .google-map-control-btn .hover-actions.zoomIn, .google-map-control-btn .hover-actions-trigger:focus .hover-actions.zoomIn,
.hover-actions-trigger:focus .google-map-control-btn .hover-actions.zoomOut,
.google-map-control-btn .hover-actions-trigger:focus .hover-actions.zoomOut {
  display: inline-block;
}
.hover-actions-trigger:hover .hover-hide, .hover-actions-trigger:focus .hover-hide {
  opacity: 0;
}
.hover-actions-trigger:hover .hover-xs-hide, .hover-actions-trigger:focus .hover-xs-hide {
  opacity: 0;
}
.hover-actions-trigger:hover .hover-xs-show, .hover-actions-trigger:focus .hover-xs-show {
  opacity: 1;
}
@media (min-width: 576px) {
  .hover-actions-trigger:hover .hover-sm-hide, .hover-actions-trigger:focus .hover-sm-hide {
    opacity: 0;
  }
}
@media (min-width: 576px) {
  .hover-actions-trigger:hover .hover-sm-show, .hover-actions-trigger:focus .hover-sm-show {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .hover-actions-trigger:hover .hover-md-hide, .hover-actions-trigger:focus .hover-md-hide {
    opacity: 0;
  }
}
@media (min-width: 768px) {
  .hover-actions-trigger:hover .hover-md-show, .hover-actions-trigger:focus .hover-md-show {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .hover-actions-trigger:hover .hover-lg-hide, .hover-actions-trigger:focus .hover-lg-hide {
    opacity: 0;
  }
}
@media (min-width: 992px) {
  .hover-actions-trigger:hover .hover-lg-show, .hover-actions-trigger:focus .hover-lg-show {
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .hover-actions-trigger:hover .hover-xl-hide, .hover-actions-trigger:focus .hover-xl-hide {
    opacity: 0;
  }
}
@media (min-width: 1200px) {
  .hover-actions-trigger:hover .hover-xl-show, .hover-actions-trigger:focus .hover-xl-show {
    opacity: 1;
  }
}
@media (min-width: 1540px) {
  .hover-actions-trigger:hover .hover-xxl-hide, .hover-actions-trigger:focus .hover-xxl-hide {
    opacity: 0;
  }
}
@media (min-width: 1540px) {
  .hover-actions-trigger:hover .hover-xxl-show, .hover-actions-trigger:focus .hover-xxl-show {
    opacity: 1;
  }
}

.hover-bg-soft:hover {
  background-color: var(--phoenix-gray-soft) !important;
}
.hover-bg-100:hover {
  background-color: var(--phoenix-gray-100) !important;
}
.hover-bg-200:hover {
  background-color: var(--phoenix-gray-200) !important;
}
.hover-bg-300:hover {
  background-color: var(--phoenix-gray-300) !important;
}
.hover-bg-400:hover {
  background-color: var(--phoenix-gray-400) !important;
}
.hover-bg-500:hover {
  background-color: var(--phoenix-gray-500) !important;
}
.hover-bg-600:hover {
  background-color: var(--phoenix-gray-600) !important;
}
.hover-bg-700:hover {
  background-color: var(--phoenix-gray-700) !important;
}
.hover-bg-800:hover {
  background-color: var(--phoenix-gray-800) !important;
}
.hover-bg-900:hover {
  background-color: var(--phoenix-gray-900) !important;
}
.hover-bg-1000:hover {
  background-color: var(--phoenix-gray-1000) !important;
}
.hover-bg-1100:hover {
  background-color: var(--phoenix-gray-1100) !important;
}

.hover-text-soft:hover {
  color: var(--phoenix-gray-soft) !important;
}
.hover-text-100:hover {
  color: var(--phoenix-gray-100) !important;
}
.hover-text-200:hover {
  color: var(--phoenix-gray-200) !important;
}
.hover-text-300:hover {
  color: var(--phoenix-gray-300) !important;
}
.hover-text-400:hover {
  color: var(--phoenix-gray-400) !important;
}
.hover-text-500:hover {
  color: var(--phoenix-gray-500) !important;
}
.hover-text-600:hover {
  color: var(--phoenix-gray-600) !important;
}
.hover-text-700:hover {
  color: var(--phoenix-gray-700) !important;
}
.hover-text-800:hover {
  color: var(--phoenix-gray-800) !important;
}
.hover-text-900:hover {
  color: var(--phoenix-gray-900) !important;
}
.hover-text-1000:hover {
  color: var(--phoenix-gray-1000) !important;
}
.hover-text-1100:hover {
  color: var(--phoenix-gray-1100) !important;
}
.hover-text-primary:hover {
  color: var(--phoenix--primary) !important;
}
.hover-text-secondary:hover {
  color: var(--phoenix--secondary) !important;
}
.hover-text-success:hover {
  color: var(--phoenix--success) !important;
}
.hover-text-info:hover {
  color: var(--phoenix--info) !important;
}
.hover-text-warning:hover {
  color: var(--phoenix--warning) !important;
}
.hover-text-danger:hover {
  color: var(--phoenix--danger) !important;
}
.hover-text-light:hover {
  color: var(--phoenix--light) !important;
}
.hover-text-dark:hover {
  color: var(--phoenix--dark) !important;
}

.d-toggle-container .d-none-hover {
  display: block !important;
}
.d-toggle-container .d-block-hover {
  display: none !important;
}
.d-toggle-container:hover .d-none-hover, .d-toggle-container:focus .d-none-hover {
  display: none !important;
}
.d-toggle-container:hover .d-block-hover, .d-toggle-container:focus .d-block-hover {
  display: block !important;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  width: 2.5rem;
}
.icon-item.icon-item-xs {
  height: 0.75rem;
  width: 0.75rem;
}
.icon-item.icon-item-sm {
  height: 1.5rem;
  width: 1.5rem;
}
.icon-item.icon-item-md {
  height: 2.5rem;
  width: 2.5rem;
}
.icon-item.icon-item-lg {
  height: 2.75rem;
  width: 2.75rem;
}

.icon-nav-item {
  text-align: center;
  color: var(--phoenix-gray-900);
  font-weight: 600;
  font-size: 0.8rem;
}
.icon-nav-item:not(:first-child) {
  margin-left: 15px;
}
.icon-nav-item:not(:last-child) {
  margin-right: 15px;
}
.icon-nav-item .icon-container {
  background: var(--phoenix-primary-100);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 8px;
}
.icon-nav-item:hover .icon-container {
  color: var(--phoenix-white);
  background: var(--phoenix-primary);
}
.icon-nav-item:hover .nav-label {
  color: var(--phoenix-gray-800);
}

.fa-icon-wait {
  opacity: 0;
}
.fontawesome-i2svg-active .fa-icon-wait {
  opacity: 1;
}

.hover-actions-trigger.btn-reveal-trigger .hover-actions {
  right: 3rem;
}
[dir=rtl] .hover-actions-trigger.btn-reveal-trigger .hover-actions {
  right: 3rem;
}

@media (min-width: 1200px) {
  .todo-list .to-do-list-body {
    height: 270px;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.table > thead > tr > th {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  line-height: 1;
}
.table > thead > tr > th:last-child {
  padding-right: 0;
}
.table > tbody > tr > td:first-child {
  padding-left: 0;
}
.table > tbody > tr > td:last-child {
  padding-right: 0;
}
.table .table-primary, .table.table-primary {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-secondary, .table.table-secondary {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-success, .table.table-success {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-info, .table.table-info {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-warning, .table.table-warning {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-danger, .table.table-danger {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-light, .table.table-light {
  color: #31374a;
  border-color: #cbd0dd;
}
.table .table-dark, .table.table-dark {
  --phoenix-table-hover-bg: rgba(
    var(--phoenix-primary-600-rgb),
    0.07
  );
  color: #9fa6bc;
}

/*-----------------------------------------------
|   Scrollbar style for windows
-----------------------------------------------*/
.scrollbar, .picmo__picker.picmo__picker .picmo__emojiArea, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content,
.scrollbar-overlay {
  overflow: auto;
}
.scrollbar::-webkit-scrollbar, .picmo__picker.picmo__picker .picmo__emojiArea::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content::-webkit-scrollbar,
.scrollbar-overlay::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .picmo__emojiArea::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content::-webkit-scrollbar-thumb,
.scrollbar-overlay::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: var(--phoenix-scrollbar-bg);
}
.scrollbar:hover::-webkit-scrollbar, .picmo__picker.picmo__picker .picmo__emojiArea:hover::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:hover::-webkit-scrollbar, .scrollbar:hover::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .picmo__emojiArea:hover::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:hover::-webkit-scrollbar-thumb, .scrollbar:focus::-webkit-scrollbar, .picmo__picker.picmo__picker .picmo__emojiArea:focus::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:focus::-webkit-scrollbar, .scrollbar:focus::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .picmo__emojiArea:focus::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:focus::-webkit-scrollbar-thumb,
.scrollbar-overlay:hover::-webkit-scrollbar,
.scrollbar-overlay:hover::-webkit-scrollbar-thumb,
.scrollbar-overlay:focus::-webkit-scrollbar,
.scrollbar-overlay:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}

.navbar-vibrant .scrollbar, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content {
  overflow: auto;
}
.navbar-vibrant .scrollbar::-webkit-scrollbar, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea::-webkit-scrollbar, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea::-webkit-scrollbar, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.navbar-vibrant .scrollbar::-webkit-scrollbar-thumb, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea::-webkit-scrollbar-thumb, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.4);
}
.navbar-vibrant .scrollbar:hover::-webkit-scrollbar, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea:hover::-webkit-scrollbar, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea:hover::-webkit-scrollbar, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:hover::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content:hover::-webkit-scrollbar, .navbar-vibrant .scrollbar:hover::-webkit-scrollbar-thumb, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea:hover::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea:hover::-webkit-scrollbar-thumb, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:hover::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content:hover::-webkit-scrollbar-thumb, .navbar-vibrant .scrollbar:focus::-webkit-scrollbar, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea:focus::-webkit-scrollbar, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea:focus::-webkit-scrollbar, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:focus::-webkit-scrollbar, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content:focus::-webkit-scrollbar, .navbar-vibrant .scrollbar:focus::-webkit-scrollbar-thumb, .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea:focus::-webkit-scrollbar-thumb, .picmo__picker.picmo__picker .navbar-vibrant .picmo__emojiArea:focus::-webkit-scrollbar-thumb, .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content:focus::-webkit-scrollbar-thumb, html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vibrant .navbar-vertical-content:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}

.firefox .scrollbar, .firefox .picmo__picker.picmo__picker .picmo__emojiArea, .picmo__picker.picmo__picker .firefox .picmo__emojiArea, .firefox html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content, html:not(.navbar-vertical-collapsed) .navbar-vertical .firefox .navbar-vertical-content {
  overflow: auto !important;
  scrollbar-color: var(--phoenix-scrollbar-bg) transparent;
  scrollbar-width: thin;
}
.firefox .navbar-vibrant .scrollbar, .firefox .navbar-vibrant .picmo__picker.picmo__picker .picmo__emojiArea, .picmo__picker.picmo__picker .firefox .navbar-vibrant .picmo__emojiArea, .firefox .navbar-vibrant html:not(.navbar-vertical-collapsed) .navbar-vertical .navbar-vertical-content, html:not(.navbar-vertical-collapsed) .navbar-vertical .firefox .navbar-vibrant .navbar-vertical-content {
  overflow: auto !important;
  scrollbar-color: rgba(255, 255, 255, 0.4) transparent;
  scrollbar-width: thin;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: var(--phoenix-scrollbar-bg);
}
.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-dark > .os-scrollbar:focus > .os-scrollbar-track > .os-scrollbar-handle {
  background: var(--phoenix-scrollbar-bg);
}

/*-----------------------------------------------
|   Simplebar
-----------------------------------------------*/
.simplebar-track.simplebar-vertical {
  width: 10px;
}

.simplebar-scrollbar::before {
  background: var(--phoenix-scrollbar-bg);
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}

/* -------------------------------------------------------------------------- */
/*                             Bootstrap Carousel                             */
/* -------------------------------------------------------------------------- */
.theme-slider:hover .carousel-control-next,
.theme-slider:hover .carousel-control-prev, .theme-slider:focus .carousel-control-next,
.theme-slider:focus .carousel-control-prev {
  color: var(--phoenix-gray-700);
  opacity: 0.5 !important;
}
.theme-slider .carousel-control-prev,
.theme-slider .carousel-control-next {
  width: 3rem;
  height: 3rem;
  background: var(--phoenix-white);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: opacity 0.4s ease-in-out;
  color: var(--phoenix-gray-700);
  opacity: 0;
}
.theme-slider .carousel-control-prev:hover, .theme-slider .carousel-control-prev:focus,
.theme-slider .carousel-control-next:hover,
.theme-slider .carousel-control-next:focus {
  opacity: 1 !important;
}
.theme-slider .carousel-control-prev {
  left: 0.5rem;
}
.theme-slider .carousel-control-next {
  right: 0.5rem;
}

.testimonial-carousel {
  box-shadow: 0px 4px 80px 84px rgba(83, 83, 83, 0.02);
  border-radius: 1.5rem;
}
.testimonial-carousel .carousel-indicators {
  bottom: -3.75rem;
}
.testimonial-carousel .carousel-indicators button {
  width: 12px;
  height: 3px;
  border-radius: 0.5rem;
  border: 0;
  transition: width 0.5s;
}
.testimonial-carousel .carousel-indicators button.active {
  width: 1.5rem;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem;
}

.list-group-item-action {
  width: 100%;
  color: #525b75;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--phoenix-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--phoenix-list-group-hover-bg);
}
.list-group-item-action:active {
  color: #31374a;
  background-color: var(--phoenix-200);
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: var(--phoenix-800);
  background-color: var(--phoenix-list-group-bg);
  border: 1px solid var(--phoenix-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6e7891;
  pointer-events: none;
  background-color: var(--phoenix-list-group-bg);
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3874ff;
  border-color: #3874ff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1540px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: var(--phoenix-list-group-item-primary-color);
  background-color: var(--phoenix-list-group-item-primary-background);
  --phoenix-list-group-item-primary-hover-focus-background: #c2cce6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-primary-color);
  background-color: var(--phoenix-list-group-item-primary-hover-focus-background);
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-primary-color);
  border-color: var(--phoenix-list-group-item-primary-color);
}

.list-group-item-secondary {
  color: var(--phoenix-list-group-item-secondary-color);
  background-color: var(--phoenix-list-group-item-secondary-background);
  --phoenix-list-group-item-secondary-hover-focus-background: #c1c2c5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-secondary-color);
  background-color: var(--phoenix-list-group-item-secondary-hover-focus-background);
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-secondary-color);
  border-color: var(--phoenix-list-group-item-secondary-color);
}

.list-group-item-success {
  color: var(--phoenix-list-group-item-success-color);
  background-color: var(--phoenix-list-group-item-success-background);
  --phoenix-list-group-item-success-hover-focus-background: #bed7b9;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-success-color);
  background-color: var(--phoenix-list-group-item-success-hover-focus-background);
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-success-color);
  border-color: var(--phoenix-list-group-item-success-color);
}

.list-group-item-info {
  color: var(--phoenix-list-group-item-info-color);
  background-color: var(--phoenix-list-group-item-info-background);
  --phoenix-list-group-item-info-hover-focus-background: #b8d3e2;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-info-color);
  background-color: var(--phoenix-list-group-item-info-hover-focus-background);
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-info-color);
  border-color: var(--phoenix-list-group-item-info-color);
}

.list-group-item-warning {
  color: var(--phoenix-list-group-item-warning-color);
  background-color: var(--phoenix-list-group-item-warning-background);
  --phoenix-list-group-item-warning-hover-focus-background: #e1cdb9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-warning-color);
  background-color: var(--phoenix-list-group-item-warning-hover-focus-background);
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-warning-color);
  border-color: var(--phoenix-list-group-item-warning-color);
}

.list-group-item-danger {
  color: var(--phoenix-list-group-item-danger-color);
  background-color: var(--phoenix-list-group-item-danger-background);
  --phoenix-list-group-item-danger-hover-focus-background: #e2bdb8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-danger-color);
  background-color: var(--phoenix-list-group-item-danger-hover-focus-background);
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-danger-color);
  border-color: var(--phoenix-list-group-item-danger-color);
}

.list-group-item-light {
  color: var(--phoenix-list-group-item-light-color);
  background-color: var(--phoenix-list-group-item-light-background);
  --phoenix-list-group-item-light-hover-focus-background: #e3e3e4;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-light-color);
  background-color: var(--phoenix-list-group-item-light-hover-focus-background);
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-light-color);
  border-color: var(--phoenix-list-group-item-light-color);
}

.list-group-item-dark {
  color: var(--phoenix-list-group-item-dark-color);
  background-color: var(--phoenix-list-group-item-dark-background);
  --phoenix-list-group-item-dark-hover-focus-background: #bbbcbe;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: var(--phoenix-list-group-item-dark-color);
  background-color: var(--phoenix-list-group-item-dark-hover-focus-background);
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: var(--phoenix-list-group-item-dark-color);
  border-color: var(--phoenix-list-group-item-dark-color);
}

/* -------------------------------------------------------------------------- */
/*                                    Badge                                   */
/* -------------------------------------------------------------------------- */
.badge-light-primary {
  color: #003cc7;
  background-color: #e5edff;
}
.badge-light-primary[href]:hover, .badge-light-primary[href]:focus {
  text-decoration: none;
  color: #003cc7;
}

.badge-light-secondary {
  color: #141824;
  background-color: #e3e6ed;
}
.badge-light-secondary[href]:hover, .badge-light-secondary[href]:focus {
  text-decoration: none;
  color: #141824;
}

.badge-light-success {
  color: #1c6c09;
  background-color: #d9fbd0;
}
.badge-light-success[href]:hover, .badge-light-success[href]:focus {
  text-decoration: none;
  color: #1c6c09;
}

.badge-light-info {
  color: #005585;
  background-color: #c7ebff;
}
.badge-light-info[href]:hover, .badge-light-info[href]:focus {
  text-decoration: none;
  color: #005585;
}

.badge-light-warning {
  color: #bc3803;
  background-color: #ffefca;
}
.badge-light-warning[href]:hover, .badge-light-warning[href]:focus {
  text-decoration: none;
  color: #bc3803;
}

.badge-light-danger {
  color: #b81800;
  background-color: #ffe0db;
}
.badge-light-danger[href]:hover, .badge-light-danger[href]:focus {
  text-decoration: none;
  color: #b81800;
}

.badge-phoenix {
  text-transform: uppercase;
  padding: 2px 8px;
  background-color: var(--phoenix-badge-bg);
  color: var(--phoenix-badge-color);
  border: 1px solid var(--phoenix-badge-border-color);
}
.badge-phoenix .badge-label {
  display: inline-block;
  transform: translateY(0.0875rem);
}
.badge-phoenix[href]:hover, .badge-phoenix[href]:focus {
  text-decoration: none;
}

.badge-phoenix-primary[href]:hover, .badge-phoenix-primary[href]:focus {
  color: #003cc7;
}

.badge-phoenix-secondary[href]:hover, .badge-phoenix-secondary[href]:focus {
  color: #141824;
}

.badge-phoenix-success[href]:hover, .badge-phoenix-success[href]:focus {
  color: #1c6c09;
}

.badge-phoenix-info[href]:hover, .badge-phoenix-info[href]:focus {
  color: #005585;
}

.badge-phoenix-warning[href]:hover, .badge-phoenix-warning[href]:focus {
  color: #bc3803;
}

.badge-phoenix-danger[href]:hover, .badge-phoenix-danger[href]:focus {
  color: #b81800;
}

.badge-phoenix-warning img {
  height: 10px;
}

.badge-tag {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-gray-1000);
  font-weight: 700;
  font-size: 0.64rem;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
}
.badge-tag:hover, .badge-tag:focus {
  background-color: var(--phoenix-gray-300);
}

/*-----------------------------------------------
|   Hover Box
-----------------------------------------------*/
.hoverbox {
  position: relative;
  overflow: hidden;
}
.hoverbox .hoverbox-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin: 0 !important;
  display: flex;
  background: rgba(0, 0, 0, 0.6);
}
.hoverbox .hoverbox-primary-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}
.hoverbox:hover .hover-box-content-initial, .hoverbox:focus .hover-box-content-initial {
  opacity: 0;
}
.hoverbox:hover .hoverbox-content, .hoverbox:focus .hoverbox-content {
  opacity: 1;
}
.hoverbox:hover .as-hoverbox-content, .hoverbox:focus .as-hoverbox-content {
  z-index: 1;
}

.hoverbox-content-gradient {
  background: linear-gradient(transparent, var(--phoenix-black));
}

.container-small,
.container-small-fluid {
  --phoenix-gutter-x: 2rem;
  --phoenix-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--phoenix-gutter-x) * 0.5);
  padding-left: calc(var(--phoenix-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-small-sm, .container-small {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-small-md, .container-small-sm, .container-small {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-small-lg, .container-small-md, .container-small-sm, .container-small {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-small-xl, .container-small-lg, .container-small-md, .container-small-sm, .container-small {
    max-width: 1216px;
  }
}
.timeline-vertical .timeline-item:not(:last-child) .timeline-item-bar .timeline-bar {
  position: absolute;
  height: 100px;
  left: 11px;
  top: 24px;
}
.timeline-vertical.timeline-with-details .timeline-item:not(:last-child) .timeline-item-bar .timeline-bar {
  height: calc(100% - 1rem) !important;
}

.timeline-basic .timeline-item:hover .timeline-item-bar .icon-item {
  background: var(--phoenix-gray-100) !important;
  border-color: var(--phoenix-gray-300) !important;
}
.timeline-basic .timeline-item:hover .timeline-item-title {
  color: var(--phoenix-gray-1100) !important;
}
.timeline-basic .timeline-item:hover .timeline-time {
  color: var(--phoenix-gray-1100) !important;
}
.timeline-basic .timeline-item:not(:last-child) .timeline-item-bar {
  height: 100%;
}
.timeline-basic .timeline-item:not(:last-child) .timeline-item-bar .timeline-bar {
  position: absolute;
  height: calc(100% - 2.5rem);
  left: 20px;
  top: 40px;
}

/* --------------------------- ecommerce-homepage --------------------------- */
.whooping-banner {
  position: relative;
  background: linear-gradient(350.65deg, #35479f -9.32%, #9528ea 113.71%);
}
.whooping-banner .product-bg {
  background-size: 50%;
}
[dir=rtl] .whooping-banner .product-bg {
  transform: scaleX(-1);
}
@media (min-width: 1200px) {
  .whooping-banner .product-bg {
    background-size: contain;
  }
}
.whooping-banner .shape-bg {
  background-size: 50%;
}
[dir=rtl] .whooping-banner .shape-bg {
  transform: scaleX(-1);
}
@media (min-width: 1200px) {
  .whooping-banner .shape-bg {
    background-size: contain;
  }
}
.whooping-banner .banner-text {
  padding: 25px 0 15px 25px;
}
@media (min-width: 576px) {
  .whooping-banner .banner-text {
    padding: 50px 0 20px 50px;
  }
}
@media (min-width: 992px) {
  .whooping-banner .banner-text {
    padding: 70px 0 40px 100px;
  }
}
@media (min-width: 1540px) {
  .whooping-banner .banner-text {
    padding: 130px 0 48px 140px;
  }
}
.whooping-banner .banner-text .gradient-text {
  background: linear-gradient(181.55deg, rgba(255, 158, 158, 0.7) 3.21%, rgba(255, 80, 237, 0.7) 68.35%, rgba(210, 30, 255, 0.7) 98.68%, rgba(255, 80, 237, 0.7) 98.69%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.whooping-banner .banner-text .gradient-text::selection {
  -webkit-text-fill-color: var(--phoenix-white);
  color: var(--phoenix-white);
  background: var(--phoenix- #3874ff);
}
.whooping-banner .banner-button {
  padding: auto;
  margin-left: 25px;
  margin-bottom: 70px;
}
@media (min-width: 576px) {
  .whooping-banner .banner-button {
    margin-left: 50px;
    margin-bottom: 70px;
  }
}
@media (min-width: 576px) {
  .whooping-banner .banner-button {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .whooping-banner .banner-button {
    margin-left: 100px;
  }
  .whooping-banner .banner-button [dir=rtl] {
    margin-right: 100px;
  }
}
@media (min-width: 1540px) {
  .whooping-banner .banner-button {
    margin-left: 140px;
    margin-bottom: 155px;
  }
}

.gift-items-banner {
  position: relative;
}
.gift-items-banner .banner-text {
  padding: 26px 0 40px 25px;
}
@media (min-width: 576px) {
  .gift-items-banner .banner-text {
    padding: 50px 0 50px 50px;
  }
}
@media (min-width: 768px) {
  .gift-items-banner .banner-text {
    padding: 70px 0 180px 0;
  }
}
.gift-items-banner .banner-text .gradient-text {
  background: linear-gradient(197.02deg, #28e4ff 11.41%, rgba(97, 198, 255, 0) 107.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.gift-items-banner .banner-text .gradient-text::selection {
  -webkit-text-fill-color: var(--phoenix-white);
  color: var(--phoenix-white);
  background: var(--phoenix-primary);
}
.gift-items-banner .banner-text .banner-button {
  margin-top: 24px;
}
@media (min-width: 992px) {
  .gift-items-banner .banner-text .banner-button {
    margin-top: 36px;
  }
}

.best-in-market-banner {
  position: relative;
}
.best-in-market-banner .banner-text .gradient-text {
  background: linear-gradient(197.02deg, #28e4ff 11.41%, #61c6ff 107.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.best-in-market-banner .banner-text .banner-button {
  margin-top: 24px;
}
@media (min-width: 992px) {
  .best-in-market-banner .banner-text .banner-button {
    margin-top: 36px;
  }
}

/* ------------------------------------------------------------------------- */
.product-thumb-container {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--phoenix-border-color);
  border-radius: 0.375rem;
  cursor: pointer;
}
@media (min-width: 1200px) {
  .product-thumb-container {
    height: 82px;
  }
}
.swiper-slide-thumb-active .product-thumb-container {
  border-color: var(--phoenix-primary);
}
.product-thumb-container img {
  width: 100%;
}

.product-color-variants .active {
  border-color: var(--phoenix-primary) !important;
}

@media (min-width: 768px) {
  .swiper-products-thumb {
    height: 484px;
  }
}
@media (min-width: 992px) {
  .swiper-products-thumb {
    height: auto;
  }
}
@media (min-width: 1200px) {
  .swiper-products-thumb {
    height: 484px;
  }
}

.vertical-tab .nav-link {
  position: relative;
  color: var(--phoenix-gray-800);
  padding: 0.875rem 0.5rem;
  flex: 1;
}
.vertical-tab .nav-link .nav-icons {
  stroke-width: 1.7px;
  color: var(--phoenix-gray-700);
}
.vertical-tab .nav-link.active {
  font-weight: 700;
  font-size: 12.8px;
  color: var(--phoenix-gray-1100);
}
.vertical-tab .nav-link.active::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 18px;
  right: -6px;
  transform: rotate(-45deg);
  background: var(--phoenix-body-bg);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: var(--phoenix-gray-300);
}
@media (max-width: 575.98px) {
  .vertical-tab .nav-link.active::after {
    top: 41px;
    right: 42%;
    transform: rotate(45deg);
  }
}
.vertical-tab .nav-link.active .nav-icons {
  stroke-width: 2.5px;
  color: var(--phoenix-gray-1000);
}

.product-variant-select-menu .choices__inner {
  --phoenix-choices-inner-min-height: 70px;
}

.category-dropdown-menu {
  min-width: 250px;
}
@media (min-width: 576px) {
  .category-dropdown-menu {
    min-width: 520px;
  }
}
@media (min-width: 768px) {
  .category-dropdown-menu {
    min-width: 750px;
  }
}
.category-dropdown-menu::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--phoenix-black);
  top: -10px;
  left: 1.5rem;
  transform: rotate(45deg);
  background: var(--phoenix-white);
  border-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: var(--phoenix-gray-200);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.product-card .btn-wish {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 2rem;
  width: 2rem;
}
.product-card .product-verified-badge {
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;
}
.product-card:hover .product-name {
  color: var(--phoenix-primary);
}

.cart .cart-table-row:hover .btn-reveal, .cart .cart-table-row:focus .btn-reveal {
  color: var(--phoenix-danger) !important;
}

.products-slider .swiper-nav [class*=swiper-] {
  top: 25%;
}
.products-slider .swiper-nav .swiper-button-prev {
  left: -1rem;
}
[dir=rtl] .products-slider .swiper-nav .swiper-button-prev {
  right: -1rem;
  left: auto;
}
.products-slider .swiper-nav .swiper-button-next {
  right: -1rem;
}
[dir=rtl] .products-slider .swiper-nav .swiper-button-next {
  left: -1rem;
  right: auto;
}

.collapse-indicator {
  position: relative;
}
.collapse-indicator .toggle-icon {
  height: 1rem;
  width: 1rem;
  transition: transform 0.2s ease;
  color: var(--phoenix-navbar-vertical-caret-color);
  margin-left: -4px;
}
.collapse-indicator[aria-expanded=true] .toggle-icon {
  transform: rotate(180deg);
}

.product-filter-offcanvas {
  max-width: 300px;
  top: 92px !important;
  height: calc(100vh - 5rem);
  padding: 2rem;
}
@media (min-width: 992px) {
  .product-filter-offcanvas {
    position: sticky !important;
    top: 1rem !important;
    padding: 0 !important;
    transform: none !important;
    height: calc(100vh - 2rem);
    padding-left: 4px;
  }
}

.product-card-container {
  display: flex;
  justify-content: center;
}
.product-card-container .product-card {
  max-width: 350px !important;
}
@media (min-width: 992px) {
  .product-card-container .product-card {
    max-width: auto;
  }
}

.icon-indicator {
  position: relative;
}
.icon-indicator::before {
  position: absolute;
  content: "";
  right: -3px;
  top: 1px;
  height: 19px;
  width: 19px;
  border-radius: 50%;
}
.icon-indicator-sm::before {
  height: 0.5rem;
  width: 0.5rem;
  right: 9px;
  top: 8px;
}
.icon-indicator-number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -1.5px;
  top: 3.5px;
  height: 1rem;
  width: 1rem;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 700;
}
.icon-indicator-primary::before {
  background-color: #3874ff;
}
.icon-indicator-secondary::before {
  background-color: #31374a;
}
.icon-indicator-success::before {
  background-color: #25b003;
}
.icon-indicator-info::before {
  background-color: #0097eb;
}
.icon-indicator-warning::before {
  background-color: #e5780b;
}
.icon-indicator-danger::before {
  background-color: #ed2000;
}
.icon-indicator-light::before {
  background-color: #eff2f6;
}
.icon-indicator-dark::before {
  background-color: #141824;
}
.icon-indicator-fill::before {
  border: 0;
}

/*-----------------------------------------------
|   Zero Roadmap chart
-----------------------------------------------*/
.gantt-zero-roadmap .gantt-zero-roadmap-chart {
  width: 100%;
  height: 222px;
}
.gantt-zero-roadmap .btn-group .btn-check:checked + .btn, .gantt-zero-roadmap .btn-group [data-list] .btn-check:checked + .page, [data-list] .gantt-zero-roadmap .btn-group .btn-check:checked + .page, .gantt-zero-roadmap .btn-group .table-list .btn-check:checked + .page, .table-list .gantt-zero-roadmap .btn-group .btn-check:checked + .page, .gantt-zero-roadmap .btn-group .tox .btn-check:checked + .tox-button, .tox .gantt-zero-roadmap .btn-group .btn-check:checked + .tox-button, .gantt-zero-roadmap .btn-group .tox .btn-check:checked + .tox-button-secondary, .tox .gantt-zero-roadmap .btn-group .btn-check:checked + .tox-button-secondary, .gantt-zero-roadmap .btn-group .tox .tox-dialog__footer .btn-check:checked + .tox-button--secondary, .tox .tox-dialog__footer .gantt-zero-roadmap .btn-group .btn-check:checked + .tox-button--secondary, .gantt-zero-roadmap .btn-group .tox .tox-dialog__footer .btn-check:checked + .tox-button:last-child, .gantt-zero-roadmap .btn-group .google-map-control-btn .btn-check:checked + .zoomIn, .google-map-control-btn .gantt-zero-roadmap .btn-group .btn-check:checked + .zoomIn,
.gantt-zero-roadmap .btn-group .google-map-control-btn .btn-check:checked + .zoomOut,
.google-map-control-btn .gantt-zero-roadmap .btn-group .btn-check:checked + .zoomOut {
  border-color: var(--phoenix-gray-soft) transparent;
}
.gantt-zero-roadmap .gantt_container {
  position: relative;
  border: 0;
  background: var(--phoenix-gray-soft);
}
.gantt-zero-roadmap .gantt_container .gantt_layout_cell,
.gantt-zero-roadmap .gantt_container .gantt_grid_scale,
.gantt-zero-roadmap .gantt_container .gantt_task_scale,
.gantt-zero-roadmap .gantt_container .gantt_row_task,
.gantt-zero-roadmap .gantt_container .gantt_task_row {
  border-bottom: 0;
  background: var(--phoenix-gray-soft);
}
.gantt-zero-roadmap .gantt_container .gantt_tree_content {
  color: var(--phoenix-gray-900);
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.gantt-zero-roadmap .gantt_container .gantt_task_line {
  border: none;
  background: var(--phoenix-info-100);
  border-radius: 2px;
  border-left: 2px solid var(--phoenix-gray-soft);
  border-right: 2px solid var(--phoenix-gray-soft);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line .gantt_task_progress {
  border-radius: 2px;
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.research .gantt_task_progress {
  background: var(--phoenix-info-200);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.planning .gantt_task_progress {
  background: var(--phoenix-info-200);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.design .gantt_task_progress {
  background: var(--phoenix-info);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.review .gantt_task_progress {
  background: var(--phoenix-info-300);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.develop .gantt_task_progress {
  background: var(--phoenix-info);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.review-2 .gantt_task_progress {
  background: var(--phoenix-info-300);
}
.gantt-zero-roadmap .gantt_container .gantt_task_line.gantt_selected {
  box-shadow: none;
}
.gantt-zero-roadmap .gantt_container .gantt_scale_line {
  border-width: 0;
}
.gantt-zero-roadmap .gantt_container .gantt_task_link .gantt_line_wrapper [class*=gantt_link_line_] {
  background-color: var(--phoenix-gray-300);
}
.gantt-zero-roadmap .gantt_container .gantt_grid_data .gantt_row.gantt_selected {
  background: transparent;
}
.gantt-zero-roadmap .gantt_container .gantt_grid_data .gantt_row:hover {
  background-color: transparent;
}
.gantt-zero-roadmap .gantt_link_arrow_right {
  border-left-color: var(--phoenix-gray-300);
}
.gantt-zero-roadmap .gantt_link_arrow_left {
  border-right-color: var(--phoenix-gray-300);
}
.gantt-zero-roadmap .gantt_row,
.gantt-zero-roadmap .gantt_task_row,
.gantt-zero-roadmap .grid_cell,
.gantt-zero-roadmap .gantt_task_scale,
.gantt-zero-roadmap .gantt_task_cell,
.gantt-zero-roadmap .gantt_grid_scale {
  border-color: var(--phoenix-gray-200) !important;
}
.gantt-zero-roadmap .gantt_task_link:hover .gantt_line_wrapper div {
  box-shadow: none;
}
.gantt-zero-roadmap .gantt_task_link:hover [class*=gantt_link_line_] {
  background-color: var(--phoenix-gray-400) !important;
}
.gantt-zero-roadmap .gantt_task_link:hover .gantt_link_arrow_right {
  border-left-color: var(--phoenix-gray-400);
}
.gantt-zero-roadmap .gantt_task_link:hover .gantt_link_arrow_left {
  border-right-color: var(--phoenix-gray-400);
}
.gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll {
  overflow: auto;
}
.gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll::-webkit-scrollbar, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll::-webkit-scrollbar {
  visibility: hidden;
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
  background-color: transparent;
}
.gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll::-webkit-scrollbar-thumb, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll::-webkit-scrollbar-thumb {
  visibility: hidden;
  border-radius: 3px;
  background-color: var(--phoenix-scrollbar-bg);
}
.gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll:hover::-webkit-scrollbar, .gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll:hover::-webkit-scrollbar-thumb, .gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll:focus::-webkit-scrollbar, .gantt-zero-roadmap .gantt_layout_cell.gantt_ver_scroll:focus::-webkit-scrollbar-thumb, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll:hover::-webkit-scrollbar, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll:hover::-webkit-scrollbar-thumb, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll:focus::-webkit-scrollbar, .gantt-zero-roadmap .gantt_layout_cell.gantt_hor_scroll:focus::-webkit-scrollbar-thumb {
  visibility: visible;
}

.navbar-shadow {
  box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.08);
}
.dark .navbar-shadow, .dark .picmo__popupContainer.picmo__light .navbar-shadow {
  box-shadow: 0px 8px 24px -4px rgba(0, 0, 0, 0.6);
}

.gradient-text-primary {
  background: linear-gradient(144.36deg, #598bff 4.5%, #094be7 94.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.gradient-text-primary::selection {
  -webkit-text-fill-color: var(--phoenix-white);
  color: var(--phoenix-white);
  background: var(--phoenix- #3874ff);
}

.text-gradient-info {
  background: linear-gradient(180deg, #28b2ff 0%, #2990ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.text-gradient-info::selection {
  -webkit-text-fill-color: var(--phoenix-white);
  color: var(--phoenix-white);
  background: var(--phoenix- #3874ff);
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}
.icon-wrapper::before {
  position: absolute;
  content: "";
  height: 1.625rem;
  width: 1.625rem;
  border-radius: 0.363rem;
  transform: rotate(7.45deg);
  top: 1.7rem;
  left: calc(50% - 1.4rem);
}
@media (min-width: 768px) {
  .icon-wrapper::before {
    position: absolute;
    content: "";
    height: 1.625rem;
    width: 1.625rem;
    border-radius: 0.363rem;
    transform: rotate(7.45deg);
    top: 1.7rem;
    left: 0.1rem;
  }
}

.shadow-info:before {
  background-color: #0097eb;
  opacity: 0.4;
}

@media (min-width: 576px) {
  .avatar-bg {
    position: relative;
  }
  .avatar-bg::before {
    position: absolute;
    content: "";
    top: 18px;
    left: 1rem;
    height: 12.26rem;
    width: 100%;
    border-radius: 0.5rem;
    background: #e5edff;
    transform: rotate(4.35deg);
  }
}
.team-avatar-container::before {
  position: absolute;
  content: "";
  left: 6%;
  top: 80%;
  height: 1.151875rem;
  width: 1.151875rem;
  opacity: 0.6;
  border-radius: 0.25rem;
  border: 1px solid #e5edff;
  transform: rotate(-5.33deg);
  z-index: 2;
}

.bg-dark-blue {
  background-color: #112860;
}

.cta-card {
  margin-bottom: -300px;
}
@media (min-width: 1540px) {
  .cta-card {
    margin-bottom: -500px;
  }
}

.badge-pricing {
  margin: 0 -2px;
  top: -34px;
}

.testimonial-avatar-container {
  padding: 30px;
}

.team-avatar-container {
  padding: 1px 15px 1px 0px;
}

@media (min-width: 1200px) {
  .hero-header-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1540px) {
  .hero-header-container {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
}
.hero-header-container .hero-image-container {
  left: 53%;
}
.hero-header-container .hero-image-container .hero-image-container-bg {
  left: -23%;
}
[dir=rtl] .hero-header-container .hero-image-container .hero-image-container-bg {
  transform: scaleX(-1);
}

.alternate-landing {
  background-color: #fff;
}
.dark .alternate-landing, .dark .picmo__popupContainer.picmo__light .alternate-landing {
  background-color: #0f111a;
}
.dark .alternate-landing .landing-navbar {
  background-color: #0f111a !important;
}

@media (min-width: 1200px) {
  .hero-header-container-alternate {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media (min-width: 1540px) {
  .hero-header-container-alternate {
    padding-top: 9rem;
    padding-bottom: 15rem;
  }
}
.hero-header-container-alternate .hero-image-container {
  left: 52%;
  top: 6% !important;
}
.hero-header-container-alternate .hero-image-container-overlay {
  transform: skewY(-7.5deg);
  transform-origin: right;
  bottom: -44.3%;
  background-color: var(--phoenix-white);
  width: 150%;
  height: 90%;
  left: -25%;
}
@media (min-width: 992px) {
  .hero-header-container-alternate .hero-image-container-overlay {
    bottom: -39.5%;
    width: 120% !important;
    height: 80%;
    left: unset;
  }
}
.dark .hero-header-container-alternate .hero-image-container-overlay {
  background-color: #0f111a;
}
.hero-header-container-alternate .hero-image-container-bg {
  width: 130%;
  left: -15%;
}
@media (min-width: 992px) {
  .hero-header-container-alternate .hero-image-container-bg {
    width: 120%;
    bottom: 0;
    left: unset;
  }
}
[dir=rtl] .hero-header-container-alternate .hero-image-container-bg {
  transform: scaleX(-1);
  padding-left: 0;
  padding-right: 1.5rem;
}
.hero-header-container-alternate .hero-image-shadow {
  box-shadow: 0px 6.99483px 126.542px -3.49742px rgba(36, 40, 46, 0.22);
}

.alternate-landing-team {
  padding-top: 8rem;
}
@media (min-width: 768px) {
  .alternate-landing-team {
    padding-top: 10rem;
  }
}
@media (min-width: 1200px) {
  .alternate-landing-team {
    padding-top: 20rem;
  }
}

.dark .world-map-bg, .dark .picmo__popupContainer.picmo__light .world-map-bg {
  opacity: 0.6;
}

[dir=rtl] .pricing-list {
  margin: 0 !important;
}

.feature-image {
  max-width: 28.125rem;
  width: 100%;
}
@media (min-width: 1200px) {
  .feature-image {
    max-width: 100%;
  }
}

.pricing-card {
  max-width: 23.43rem;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .pricing-card {
    max-width: 100%;
  }
}

.offer-card {
  box-shadow: 0px 4px 84px rgba(0, 0, 0, 0.03);
}

.blog-card {
  max-width: 17.25rem;
  margin: 0 auto;
}
@media (min-width: 1200px) {
  .blog-card {
    max-width: 100%;
  }
}

@media (min-width: 1540px) {
  .rotating-earth-container {
    padding: 13.43rem 0 10.625rem !important;
  }
}
.rotating-earth-container .rotating-earth {
  left: -20%;
}
@media (min-width: 992px) {
  .rotating-earth-container .rotating-earth {
    top: 8%;
    right: -18%;
    left: unset;
  }
}
@media (min-width: 1200px) {
  .rotating-earth-container .rotating-earth {
    top: -16%;
    right: -16%;
  }
}
@media (min-width: 1540px) {
  .rotating-earth-container .rotating-earth {
    top: -16%;
    right: -14%;
  }
}
.rotating-earth-container .rotating-earth .lottie {
  width: 140%;
  height: 100%;
}
@media (min-width: 992px) {
  .rotating-earth-container .rotating-earth .lottie {
    width: 47rem;
    height: 47rem;
  }
}
@media (min-width: 1200px) {
  .rotating-earth-container .rotating-earth .lottie {
    width: 60rem;
    height: 60rem;
  }
}
@media (min-width: 1540px) {
  .rotating-earth-container .rotating-earth .lottie {
    width: 78.125rem;
    height: 78.125rem;
  }
}
[dir=rtl] .rotating-earth-container .rotating-earth .lottie {
  transform: scaleX(-1);
}
.rotating-earth-container .rotating-earth img {
  left: 20%;
  top: 50%;
  width: 50%;
}
@media (min-width: 992px) {
  .rotating-earth-container .rotating-earth img {
    left: 14%;
    top: 50%;
    width: 40%;
  }
}
[dir=rtl] .rotating-earth-container .rotating-earth img {
  transform: scaleX(-1);
}

.counter-container {
  padding: 4.5rem 0;
}
@media (min-width: 576px) {
  .counter-container {
    padding: 7.5rem 0;
  }
}
@media (min-width: 768px) {
  .counter-container {
    padding: 13rem 0;
    margin-top: -3.125rem;
  }
}
@media (min-width: 992px) {
  .counter-container {
    padding: 21.5rem 0;
    margin-top: 0px;
  }
}
@media (min-width: 1540px) {
  .counter-container {
    margin-top: -3.125rem;
  }
}
.counter-container .counter-overlay {
  background-color: var(--phoenix-primary);
  opacity: 4%;
  min-height: 100%;
  top: 0;
}
@media (min-width: 768px) {
  .counter-container .counter-overlay {
    min-height: 67%;
    top: 5rem;
  }
}
@media (min-width: 992px) {
  .counter-container .counter-overlay {
    top: 7.5rem;
  }
}

.gallery .gallery-overlay {
  background-color: var(--phoenix-primary);
  opacity: 8%;
  height: 54%;
  transform: skewY(-8deg);
  top: 27%;
}

.content-offcanvas {
  position: fixed;
  width: 35rem !important;
  top: calc(var(--phoenix-navbar-top-height) + 1px) !important;
  height: calc(100vh - (var(--phoenix-navbar-top-height) + 4rem)) !important;
}
@media (min-width: 576px) {
  .content-offcanvas {
    background-position: 100% !important;
  }
}

.offcanvas-backdrop-transparent ~ .offcanvas-backdrop {
  opacity: 0;
}

.phoenix-offcanvas-container {
  position: relative;
  overflow: hidden;
}

.phoenix-offcanvas {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 10;
}
.phoenix-offcanvas.phoenix-offcanvas-fixed {
  position: fixed;
  left: 0;
  transform: translateX(-100%);
}
.phoenix-offcanvas.phoenix-offcanvas-start {
  left: 0;
  transform: translateX(-100%);
}
.phoenix-offcanvas.phoenix-offcanvas-end {
  transform: translateX(100%);
  right: 0;
}
.phoenix-offcanvas.phoenix-offcanvas-top {
  transform: translateY(-100%);
}
.phoenix-offcanvas.phoenix-offcanvas-bottom {
  transform: translateY(100%);
}
.phoenix-offcanvas.show {
  transform: none;
}
.phoenix-offcanvas.show ~ .phoenix-offcanvas-backdrop {
  position: fixed;
  top: 92px;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.theme-wizard .nav-item {
  position: relative;
}
.theme-wizard .nav-item:not(:last-child) {
  flex: 1;
}
.theme-wizard .nav-item:first-child .nav-item-circle-parent:before, .theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
  width: 50%;
}
.theme-wizard .nav-item:first-child .nav-item-circle-parent:before {
  transform: translate3d(0, -50%, 0);
}
.theme-wizard .nav-item:last-child .nav-item-circle-parent:before {
  left: 0;
  transform: translate3d(0, -50%, 0);
}
.theme-wizard .nav-item .nav-link {
  display: flex;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  color: var(--phoenix-gray-600);
  display: block;
}
.theme-wizard .nav-item .nav-link .nav-item-circle {
  color: var(--phoenix-gray-600);
  background-color: var(--phoenix-theme-wizard-nav-item-circle-bg);
  border: 2px solid var(--phoenix-gray-300);
  height: 2.375rem;
  width: 2.375rem;
  border-radius: 50%;
  line-height: 2.25rem;
  padding: 0;
  text-align: center;
  align-self: center;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}
.theme-wizard .nav-item .nav-link.active {
  color: var(--phoenix-theme-wizard-active-color);
}
.theme-wizard .nav-item .nav-link.active .nav-item-circle {
  color: var(--phoenix-theme-wizard-active-color);
  border-color: var(--phoenix-theme-wizard-active-color);
}
.theme-wizard .nav-item .nav-link.done {
  color: var(--phoenix-theme-wizard-complete-color);
}
.theme-wizard .nav-item .nav-link.done .nav-item-circle {
  background-color: var(--phoenix-theme-wizard-complete-color) !important;
  color: #fff;
  border-color: var(--phoenix-theme-wizard-complete-color);
}
.theme-wizard .nav-item .nav-link.done .nav-item-circle .check-icon {
  display: inline-block;
}
.theme-wizard .nav-item .nav-link.done .nav-item-circle-parent:before {
  background-color: var(--phoenix-theme-wizard-complete-color);
}
.theme-wizard .nav-item .nav-link .nav-item-circle .check-icon {
  display: none;
}
.theme-wizard .nav-item .nav-link .nav-item-circle-parent {
  display: block;
}
.theme-wizard .nav-item:not(.theme-wizard .nav-item:last-child) .nav-link:before {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  left: 3%;
  top: 48%;
  background: var(--phoenix-gray-300);
}
@media (min-width: 768px) {
  .theme-wizard .nav-item:not(.theme-wizard .nav-item:last-child) .nav-link:before {
    top: 35%;
  }
}
.theme-wizard .nav-item:not(.theme-wizard .nav-item:last-child) .nav-link.done:before {
  background: var(--phoenix-theme-wizard-active-color) !important;
}
.theme-wizard .nav-item:not(.theme-wizard .nav-item:last-child) .nav-link.complete:before {
  background: var(--phoenix-theme-wizard-complete-color) !important;
}
.theme-wizard .nav-item .nav-item:not(:first-child) .active .nav-item-circle-parent:after {
  content: "";
  width: 50%;
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  background-color: var(--phoenix-theme-wizard-complete-color);
}
.theme-wizard .nav-item .nav-item-circle-parent {
  position: relative;
}
.theme-wizard .nav-item .nav-item-circle {
  margin: 0 auto;
  z-index: 1;
  position: relative;
}
.theme-wizard .nav-item .pager {
  display: flex;
  justify-content: space-between;
}
.theme-wizard .nav-item .wizard-lottie-wrapper {
  height: 1px;
  position: relative;
  padding-bottom: 11.875rem;
  overflow: hidden;
}
.theme-wizard .nav-item .wizard-lottie-wrapper .wizard-lottie {
  width: 21.25rem;
  height: auto;
  transform: translateY(-73%);
}

.email-sidebar {
  top: var(--phoenix-navbar-top-height);
  padding: 2rem;
}
.email-sidebar .nav-link {
  color: var(--phoenix-gray-900);
  font-weight: 600;
}
.email-sidebar .nav-link:hover {
  color: var(--phoenix-gray-1100);
}
.email-sidebar .nav-link .nav-item-count {
  margin-bottom: 0;
  margin-top: 0.25rem;
  font-size: 0.512rem;
  font-weight: 700;
  color: var(--phoenix-gray-700);
}
.email-sidebar .nav-link.active {
  color: var(--phoenix-primary);
}
.email-sidebar .nav-link.active .nav-item-count {
  color: var(--phoenix-primary);
}
@media (min-width: 992px) {
  .email-sidebar {
    position: sticky !important;
    top: calc(var(--phoenix-navbar-top-height) + 5.5rem);
    padding: 0 !important;
    transform: none !important;
    height: auto !important;
  }
}

.email-sidebar-width {
  width: 300px;
}
@media (min-width: 992px) {
  .email-sidebar-width {
    width: 220px;
  }
}

.email-content {
  height: calc(100vh - 278px);
  overflow-x: hidden;
}
.email-content .email-detail-content {
  height: calc(100% - 74px);
}

.email-sidebar .email-content {
  height: 100%;
}
@media (min-width: 992px) {
  .email-sidebar .email-content {
    height: calc(100vh - 278px);
  }
}

.email-textarea + .tox {
  min-height: 250px;
}
.email-textarea + .tox .tox-editor-header {
  margin: 0 !important;
  padding: 0 !important;
}

.email-container {
  padding-top: calc(var(--phoenix-navbar-top-height) + 1px);
}
.email-container .email-header {
  top: calc(var(--phoenix-navbar-top-height) + 1px);
}
.email-container .email-toolbar {
  top: 0;
  margin-bottom: 0;
}
.email-container .email-toolbar.inbox-toolbar {
  top: calc(var(--phoenix-navbar-top-height) + 5.375rem);
}

.location-map {
  height: 220px;
  border-radius: 0.5rem;
}

.feed-avatar-profile {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.feed-profile {
  transform: translateY(50%);
}

.feed-profile-cover {
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url("generic/59.png");
}

/*-----------------------------------------------
|   Cover and profile image
-----------------------------------------------*/
.cover-image-file-input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;
  margin-top: 1rem;
  border-radius: 0.375rem;
  font-size: 0.8rem;
  color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.cover-image {
  overflow: hidden;
  z-index: 0;
}
.cover-image:hover .cover-image-file-input {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

/* -------------------------------------------------------------------------- */
/*                                  Override                                  */
/* -------------------------------------------------------------------------- */
.dark .dark__bg-soft, .dark .picmo__popupContainer.picmo__light .dark__bg-soft {
  background-color: #f5f7fa !important;
}
.dark .dark__text-soft, .dark .picmo__popupContainer.picmo__light .dark__text-soft {
  color: #f5f7fa !important;
}
.dark .dark__bg-100, .dark .picmo__popupContainer.picmo__light .dark__bg-100 {
  background-color: #eff2f6 !important;
}
.dark .dark__text-100, .dark .picmo__popupContainer.picmo__light .dark__text-100 {
  color: #eff2f6 !important;
}
.dark .dark__bg-200, .dark .picmo__popupContainer.picmo__light .dark__bg-200 {
  background-color: #e3e6ed !important;
}
.dark .dark__text-200, .dark .picmo__popupContainer.picmo__light .dark__text-200 {
  color: #e3e6ed !important;
}
.dark .dark__bg-300, .dark .picmo__popupContainer.picmo__light .dark__bg-300 {
  background-color: #cbd0dd !important;
}
.dark .dark__text-300, .dark .picmo__popupContainer.picmo__light .dark__text-300 {
  color: #cbd0dd !important;
}
.dark .dark__bg-400, .dark .picmo__popupContainer.picmo__light .dark__bg-400 {
  background-color: #9fa6bc !important;
}
.dark .dark__text-400, .dark .picmo__popupContainer.picmo__light .dark__text-400 {
  color: #9fa6bc !important;
}
.dark .dark__bg-500, .dark .picmo__popupContainer.picmo__light .dark__bg-500 {
  background-color: #8a94ad !important;
}
.dark .dark__text-500, .dark .picmo__popupContainer.picmo__light .dark__text-500 {
  color: #8a94ad !important;
}
.dark .dark__bg-600, .dark .picmo__popupContainer.picmo__light .dark__bg-600 {
  background-color: #6e7891 !important;
}
.dark .dark__text-600, .dark .picmo__popupContainer.picmo__light .dark__text-600 {
  color: #6e7891 !important;
}
.dark .dark__bg-700, .dark .picmo__popupContainer.picmo__light .dark__bg-700 {
  background-color: #525b75 !important;
}
.dark .dark__text-700, .dark .picmo__popupContainer.picmo__light .dark__text-700 {
  color: #525b75 !important;
}
.dark .dark__bg-800, .dark .picmo__popupContainer.picmo__light .dark__bg-800 {
  background-color: #3e465b !important;
}
.dark .dark__text-800, .dark .picmo__popupContainer.picmo__light .dark__text-800 {
  color: #3e465b !important;
}
.dark .dark__bg-900, .dark .picmo__popupContainer.picmo__light .dark__bg-900 {
  background-color: #31374a !important;
}
.dark .dark__text-900, .dark .picmo__popupContainer.picmo__light .dark__text-900 {
  color: #31374a !important;
}
.dark .dark__bg-1000, .dark .picmo__popupContainer.picmo__light .dark__bg-1000 {
  background-color: #222834 !important;
}
.dark .dark__text-1000, .dark .picmo__popupContainer.picmo__light .dark__text-1000 {
  color: #222834 !important;
}
.dark .dark__bg-1100, .dark .picmo__popupContainer.picmo__light .dark__bg-1100 {
  background-color: #141824 !important;
}
.dark .dark__text-1100, .dark .picmo__popupContainer.picmo__light .dark__text-1100 {
  color: #141824 !important;
}
.dark .dark__bg-facebook, .dark .picmo__popupContainer.picmo__light .dark__bg-facebook {
  background-color: #3c5a99 !important;
}
.dark .dark__text-facebook, .dark .picmo__popupContainer.picmo__light .dark__text-facebook {
  color: #3c5a99 !important;
}
.dark .dark__bg-google-plus, .dark .picmo__popupContainer.picmo__light .dark__bg-google-plus {
  background-color: #dd4b39 !important;
}
.dark .dark__text-google-plus, .dark .picmo__popupContainer.picmo__light .dark__text-google-plus {
  color: #dd4b39 !important;
}
.dark .dark__bg-twitter, .dark .picmo__popupContainer.picmo__light .dark__bg-twitter {
  background-color: #1da1f2 !important;
}
.dark .dark__text-twitter, .dark .picmo__popupContainer.picmo__light .dark__text-twitter {
  color: #1da1f2 !important;
}
.dark .dark__bg-linkedin, .dark .picmo__popupContainer.picmo__light .dark__bg-linkedin {
  background-color: #0077b5 !important;
}
.dark .dark__text-linkedin, .dark .picmo__popupContainer.picmo__light .dark__text-linkedin {
  color: #0077b5 !important;
}
.dark .dark__bg-youtube, .dark .picmo__popupContainer.picmo__light .dark__bg-youtube {
  background-color: #ff0001 !important;
}
.dark .dark__text-youtube, .dark .picmo__popupContainer.picmo__light .dark__text-youtube {
  color: #ff0001 !important;
}
.dark .dark__bg-github, .dark .picmo__popupContainer.picmo__light .dark__bg-github {
  background-color: #333333 !important;
}
.dark .dark__text-github, .dark .picmo__popupContainer.picmo__light .dark__text-github {
  color: #333333 !important;
}
.dark .dark__bg-bootstrap, .dark .picmo__popupContainer.picmo__light .dark__bg-bootstrap {
  background-color: #6f45a9 !important;
}
.dark .dark__text-bootstrap, .dark .picmo__popupContainer.picmo__light .dark__text-bootstrap {
  color: #6f45a9 !important;
}
.dark .dark__bg-css3, .dark .picmo__popupContainer.picmo__light .dark__bg-css3 {
  background-color: #203ccf !important;
}
.dark .dark__text-css3, .dark .picmo__popupContainer.picmo__light .dark__text-css3 {
  color: #203ccf !important;
}
.dark .dark__bg-html5, .dark .picmo__popupContainer.picmo__light .dark__bg-html5 {
  background-color: #bf4b2c !important;
}
.dark .dark__text-html5, .dark .picmo__popupContainer.picmo__light .dark__text-html5 {
  color: #bf4b2c !important;
}
.dark .dark__bg-sass, .dark .picmo__popupContainer.picmo__light .dark__bg-sass {
  background-color: #c55589 !important;
}
.dark .dark__text-sass, .dark .picmo__popupContainer.picmo__light .dark__text-sass {
  color: #c55589 !important;
}
.dark .dark__bg-gulp, .dark .picmo__popupContainer.picmo__light .dark__bg-gulp {
  background-color: #d54049 !important;
}
.dark .dark__text-gulp, .dark .picmo__popupContainer.picmo__light .dark__text-gulp {
  color: #d54049 !important;
}
.dark .dark__bg-w3c, .dark .picmo__popupContainer.picmo__light .dark__bg-w3c {
  background-color: #255997 !important;
}
.dark .dark__text-w3c, .dark .picmo__popupContainer.picmo__light .dark__text-w3c {
  color: #255997 !important;
}
.dark .dark__bg-primary, .dark .picmo__popupContainer.picmo__light .dark__bg-primary {
  background-color: #3874ff !important;
}
.dark .dark__text-primary, .dark .picmo__popupContainer.picmo__light .dark__text-primary {
  color: #3874ff !important;
}
.dark .dark__bg-secondary, .dark .picmo__popupContainer.picmo__light .dark__bg-secondary {
  background-color: #31374a !important;
}
.dark .dark__text-secondary, .dark .picmo__popupContainer.picmo__light .dark__text-secondary {
  color: #31374a !important;
}
.dark .dark__bg-success, .dark .picmo__popupContainer.picmo__light .dark__bg-success {
  background-color: #25b003 !important;
}
.dark .dark__text-success, .dark .picmo__popupContainer.picmo__light .dark__text-success {
  color: #25b003 !important;
}
.dark .dark__bg-info, .dark .picmo__popupContainer.picmo__light .dark__bg-info {
  background-color: #0097eb !important;
}
.dark .dark__text-info, .dark .picmo__popupContainer.picmo__light .dark__text-info {
  color: #0097eb !important;
}
.dark .dark__bg-warning, .dark .picmo__popupContainer.picmo__light .dark__bg-warning {
  background-color: #e5780b !important;
}
.dark .dark__text-warning, .dark .picmo__popupContainer.picmo__light .dark__text-warning {
  color: #e5780b !important;
}
.dark .dark__bg-danger, .dark .picmo__popupContainer.picmo__light .dark__bg-danger {
  background-color: #ed2000 !important;
}
.dark .dark__text-danger, .dark .picmo__popupContainer.picmo__light .dark__text-danger {
  color: #ed2000 !important;
}
.dark .dark__bg-light, .dark .picmo__popupContainer.picmo__light .dark__bg-light {
  background-color: #eff2f6 !important;
}
.dark .dark__text-light, .dark .picmo__popupContainer.picmo__light .dark__text-light {
  color: #eff2f6 !important;
}
.dark .dark__bg-dark, .dark .picmo__popupContainer.picmo__light .dark__bg-dark {
  background-color: #141824 !important;
}
.dark .dark__text-dark, .dark .picmo__popupContainer.picmo__light .dark__text-dark {
  color: #141824 !important;
}
.dark .dark__bg-primary-soft, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-soft {
  background-color: #f5f8ff !important;
}
.dark .dark__text-primary-soft, .dark .picmo__popupContainer.picmo__light .dark__text-primary-soft {
  color: #f5f8ff !important;
}
.dark .dark__bg-primary-100, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-100 {
  background-color: #e5edff !important;
}
.dark .dark__text-primary-100, .dark .picmo__popupContainer.picmo__light .dark__text-primary-100 {
  color: #e5edff !important;
}
.dark .dark__bg-primary-200, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-200 {
  background-color: #adc5ff !important;
}
.dark .dark__text-primary-200, .dark .picmo__popupContainer.picmo__light .dark__text-primary-200 {
  color: #adc5ff !important;
}
.dark .dark__bg-primary-300, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-300 {
  background-color: #85a9ff !important;
}
.dark .dark__text-primary-300, .dark .picmo__popupContainer.picmo__light .dark__text-primary-300 {
  color: #85a9ff !important;
}
.dark .dark__bg-primary-500, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-500 {
  background-color: #004dff !important;
}
.dark .dark__text-primary-500, .dark .picmo__popupContainer.picmo__light .dark__text-primary-500 {
  color: #004dff !important;
}
.dark .dark__bg-primary-600, .dark .picmo__popupContainer.picmo__light .dark__bg-primary-600 {
  background-color: #003cc7 !important;
}
.dark .dark__text-primary-600, .dark .picmo__popupContainer.picmo__light .dark__text-primary-600 {
  color: #003cc7 !important;
}
.dark .dark__bg-warning-soft, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-soft {
  background-color: #fff6e0 !important;
}
.dark .dark__text-warning-soft, .dark .picmo__popupContainer.picmo__light .dark__text-warning-soft {
  color: #fff6e0 !important;
}
.dark .dark__bg-warning-100, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-100 {
  background-color: #ffefca !important;
}
.dark .dark__text-warning-100, .dark .picmo__popupContainer.picmo__light .dark__text-warning-100 {
  color: #ffefca !important;
}
.dark .dark__bg-warning-200, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-200 {
  background-color: #ffe6ad !important;
}
.dark .dark__text-warning-200, .dark .picmo__popupContainer.picmo__light .dark__text-warning-200 {
  color: #ffe6ad !important;
}
.dark .dark__bg-warning-300, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-300 {
  background-color: #ffcc85 !important;
}
.dark .dark__text-warning-300, .dark .picmo__popupContainer.picmo__light .dark__text-warning-300 {
  color: #ffcc85 !important;
}
.dark .dark__bg-warning-500, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-500 {
  background-color: #d6700a !important;
}
.dark .dark__text-warning-500, .dark .picmo__popupContainer.picmo__light .dark__text-warning-500 {
  color: #d6700a !important;
}
.dark .dark__bg-warning-600, .dark .picmo__popupContainer.picmo__light .dark__bg-warning-600 {
  background-color: #bc3803 !important;
}
.dark .dark__text-warning-600, .dark .picmo__popupContainer.picmo__light .dark__text-warning-600 {
  color: #bc3803 !important;
}
.dark .dark__bg-danger-300, .dark .picmo__popupContainer.picmo__light .dark__bg-danger-300 {
  background-color: #f48270 !important;
}
.dark .dark__text-danger-300, .dark .picmo__popupContainer.picmo__light .dark__text-danger-300 {
  color: #f48270 !important;
}
.dark .dark__bg-danger-100, .dark .picmo__popupContainer.picmo__light .dark__bg-danger-100 {
  background-color: #ffe0db !important;
}
.dark .dark__text-danger-100, .dark .picmo__popupContainer.picmo__light .dark__text-danger-100 {
  color: #ffe0db !important;
}
.dark .dark__bg-danger-200, .dark .picmo__popupContainer.picmo__light .dark__bg-danger-200 {
  background-color: #fabcb3 !important;
}
.dark .dark__text-danger-200, .dark .picmo__popupContainer.picmo__light .dark__text-danger-200 {
  color: #fabcb3 !important;
}
.dark .dark__bg-danger-500, .dark .picmo__popupContainer.picmo__light .dark__bg-danger-500 {
  background-color: #cc1b00 !important;
}
.dark .dark__text-danger-500, .dark .picmo__popupContainer.picmo__light .dark__text-danger-500 {
  color: #cc1b00 !important;
}
.dark .dark__bg-danger-600, .dark .picmo__popupContainer.picmo__light .dark__bg-danger-600 {
  background-color: #b81800 !important;
}
.dark .dark__text-danger-600, .dark .picmo__popupContainer.picmo__light .dark__text-danger-600 {
  color: #b81800 !important;
}
.dark .dark__bg-success-100, .dark .picmo__popupContainer.picmo__light .dark__bg-success-100 {
  background-color: #d9fbd0 !important;
}
.dark .dark__text-success-100, .dark .picmo__popupContainer.picmo__light .dark__text-success-100 {
  color: #d9fbd0 !important;
}
.dark .dark__bg-success-200, .dark .picmo__popupContainer.picmo__light .dark__bg-success-200 {
  background-color: #bee8b4 !important;
}
.dark .dark__text-success-200, .dark .picmo__popupContainer.picmo__light .dark__text-success-200 {
  color: #bee8b4 !important;
}
.dark .dark__bg-success-300, .dark .picmo__popupContainer.picmo__light .dark__bg-success-300 {
  background-color: #90d67f !important;
}
.dark .dark__text-success-300, .dark .picmo__popupContainer.picmo__light .dark__text-success-300 {
  color: #90d67f !important;
}
.dark .dark__bg-success-500, .dark .picmo__popupContainer.picmo__light .dark__bg-success-500 {
  background-color: #23890b !important;
}
.dark .dark__text-success-500, .dark .picmo__popupContainer.picmo__light .dark__text-success-500 {
  color: #23890b !important;
}
.dark .dark__bg-success-600, .dark .picmo__popupContainer.picmo__light .dark__bg-success-600 {
  background-color: #1c6c09 !important;
}
.dark .dark__text-success-600, .dark .picmo__popupContainer.picmo__light .dark__text-success-600 {
  color: #1c6c09 !important;
}
.dark .dark__bg-info-100, .dark .picmo__popupContainer.picmo__light .dark__bg-info-100 {
  background-color: #c7ebff !important;
}
.dark .dark__text-info-100, .dark .picmo__popupContainer.picmo__light .dark__text-info-100 {
  color: #c7ebff !important;
}
.dark .dark__bg-info-200, .dark .picmo__popupContainer.picmo__light .dark__bg-info-200 {
  background-color: #96d9ff !important;
}
.dark .dark__text-info-200, .dark .picmo__popupContainer.picmo__light .dark__text-info-200 {
  color: #96d9ff !important;
}
.dark .dark__bg-info-300, .dark .picmo__popupContainer.picmo__light .dark__bg-info-300 {
  background-color: #60c6ff !important;
}
.dark .dark__text-info-300, .dark .picmo__popupContainer.picmo__light .dark__text-info-300 {
  color: #60c6ff !important;
}
.dark .dark__bg-info-500, .dark .picmo__popupContainer.picmo__light .dark__bg-info-500 {
  background-color: #0080c7 !important;
}
.dark .dark__text-info-500, .dark .picmo__popupContainer.picmo__light .dark__text-info-500 {
  color: #0080c7 !important;
}
.dark .dark__bg-info-600, .dark .picmo__popupContainer.picmo__light .dark__bg-info-600 {
  background-color: #005585 !important;
}
.dark .dark__text-info-600, .dark .picmo__popupContainer.picmo__light .dark__text-info-600 {
  color: #005585 !important;
}
.dark .dark__bg-white, .dark .picmo__popupContainer.picmo__light .dark__bg-white {
  background-color: #fff !important;
}
.dark .dark__text-white, .dark .picmo__popupContainer.picmo__light .dark__text-white {
  color: #fff !important;
}
.dark .dark__bg-black, .dark .picmo__popupContainer.picmo__light .dark__bg-black {
  background-color: #000000 !important;
}
.dark .dark__text-black, .dark .picmo__popupContainer.picmo__light .dark__text-black {
  color: #000000 !important;
}
.dark .dark__bg-1200, .dark .picmo__popupContainer.picmo__light .dark__bg-1200 {
  background-color: #0f111a !important;
}
.dark ::selection, .dark .picmo__popupContainer.picmo__light ::selection {
  background-color: #e5780b;
}

/* -------------------------------------------------------------------------- */
/*                               Settings Panel                               */
/* -------------------------------------------------------------------------- */
.settings-panel .img-prototype.dark, .settings-panel .dark .img-prototype.picmo__popupContainer.picmo__light, .dark .settings-panel .img-prototype.picmo__popupContainer.picmo__light {
  display: none;
}

.dark .settings-panel .img-prototype.light {
  display: none;
}
.dark .settings-panel .img-prototype.dark, .dark .settings-panel .img-prototype.picmo__popupContainer.picmo__light {
  display: block;
}

.settings-panel {
  max-width: 28.62rem;
  width: 100% !important;
}

.setting-panel-item {
  border-radius: 0.5rem;
  background-color: var(--phoenix-gray-soft);
  margin-top: 1rem;
  border: 1px solid var(--phoenix-border-color);
  padding: 1rem 1rem 0 1rem;
}
@media (min-width: 768px) {
  .setting-panel-item {
    padding: 1.5rem 1.5rem 0.25rem 1.5rem;
  }
}

.setting-panel-item-title {
  line-height: 1.2;
  color: var(--phoenix-gray-900);
  margin-bottom: 0.5rem;
}

.settings-panel-header {
  align-items: flex-start;
  padding: 0.5rem 1.25rem;
}
.settings-panel-header:before, .settings-panel-header:after {
  border-radius: 50%;
  height: 12.5rem;
  width: 12.5rem;
}
.settings-panel-header:before {
  left: 5.125rem;
  top: 1.188rem;
}
.settings-panel-header:after {
  right: -6.25rem;
  top: -7.938rem;
}

.setting-panel-item .btn-check + .btn-navbar-style {
  box-shadow: none;
  padding: 0;
  margin-bottom: 1rem;
  text-align: left;
}
.setting-panel-item .btn-check + .btn-navbar-style .img-prototype {
  border: 2px solid var(--phoenix-gray-200);
  border-radius: 0.5rem;
  transition: border 0.2s ease;
  margin-bottom: 0.5rem;
  background-color: var(--phoenix-gray-100);
}
.setting-panel-item .btn-check + .btn-navbar-style .label-text {
  position: relative;
  padding-left: 1.5rem;
}
.setting-panel-item .btn-check + .btn-navbar-style .label-text:after {
  position: absolute;
  content: "";
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--phoenix-gray-500);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  transition: border 0.2s ease;
}
.setting-panel-item .btn-check:checked:not([disabled]) + .btn-navbar-style .img-prototype {
  border-color: var(--phoenix-primary);
}
.setting-panel-item .btn-check:checked:not([disabled]) + .btn-navbar-style .label-text::after {
  border: 5px solid var(--phoenix-primary);
}
.setting-panel-item .btn-check:disabled + .btn-navbar-style {
  opacity: 0.5;
}
.setting-panel-item .hover-overlay {
  position: relative;
  overflow: hidden;
}
.setting-panel-item .hover-overlay:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}
.setting-panel-item .hover-overlay:hover:after, .setting-panel-item .hover-overlay:focus:after {
  background: rgba(0, 0, 0, 0.2);
}

.setting-toggle {
  position: fixed;
  top: 50%;
  right: -3px;
  text-decoration: none;
  z-index: 1016;
  transform: rotate(-90deg) translate3d(-25px, 39px, 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: -83px;
  box-shadow: var(--phoenix-setting-toggle-shadow);
}
@media (max-width: 575.98px) {
  .setting-toggle {
    transform: rotate(-90deg);
    outline: none;
  }
  .setting-toggle .bg-soft-primary {
    background-color: transparent !important;
  }
  .setting-toggle small, .setting-toggle .small {
    display: none;
  }
}
.setting-toggle:hover {
  text-decoration: none !important;
}

.variant-container .img-container {
  position: relative;
  z-index: 0;
  max-width: 549px;
  box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.08);
}
.variant-container .img-container .side-panel-thumb {
  position: absolute;
  transition: transform 200ms ease-in-out;
  z-index: 2;
  left: 0;
  filter: drop-shadow(0 0 13px transparent);
}
.variant-container:hover {
  background: #e5edff;
  transition: 0.2s;
}
.variant-container:hover .img-container + h5, .variant-container:hover .img-container + .h5 {
  color: #3874ff !important;
}
.variant-container:hover .side-panel-thumb {
  transform: scale(1.05);
  filter: drop-shadow(0 0 13px rgba(0, 0, 0, 0.12));
}
.variant-container:hover .layout-thumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(229, 237, 255, 0.6);
}

.showcase-gallery img {
  position: relative;
  filter: drop-shadow(0px 0px 75.44px rgba(0, 0, 0, 0.12));
  border-radius: 0.5rem;
}
.showcase-gallery .layer-1 {
  transform: translateY(150px);
}
.showcase-gallery .layer-2 {
  transform: translateY(180px);
}
.showcase-gallery .layer-3 {
  transform: translateY(210px);
}
.showcase-gallery .layer-4 {
  transform: translateY(240px);
}
.showcase-gallery .layer-5 {
  transform: translateY(270px);
}
.showcase-gallery .layer-6 {
  transform: translateY(300px);
}

.page-container .img-container {
  position: relative;
  max-width: 549px;
  box-shadow: 0px 8px 24px -4px rgba(36, 40, 46, 0.08);
}
.page-container .img-container .page-thumb {
  transition: transform 200ms ease-in-out;
  z-index: 2;
  left: 0;
}
.page-container .img-container:hover .page-thumb {
  transform: scale(1.05);
  background: rgba(229, 237, 255, 0.6);
  filter: drop-shadow(0 0 13px transparent);
}
.page-container:hover {
  background: #e5edff;
  transition: 0.2s;
}
.page-container:hover .page-thumb {
  filter: drop-shadow(0 0 13px rgba(0, 0, 0, 0.12));
  transform: scale(1.05);
}
.page-container:hover .img-container + h5, .page-container:hover .img-container + .h5 {
  color: #3874ff !important;
}

.feature-figma-img {
  position: absolute;
  max-width: 250px;
  left: 20%;
  top: 15%;
  mix-blend-mode: overlay;
  opacity: 0.5;
}
@media (min-width: 1200px) {
  .feature-figma-img {
    opacity: 0.75;
  }
}

.bg-gradient-figma {
  position: absolute;
  top: 0;
  height: 1200%;
  background: linear-gradient(162.48deg, #f24e1e -2.09%, #ff7262 10.32%, #ab69ff 35.4%, #1a9efe 56.39%, #1abdf9 70.28%, #0acf83 89.37%);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.calendar-title {
  min-width: 220px;
  text-align: center;
}

.chat {
  height: calc(100vh - (var(--phoenix-navbar-top-height) + 10rem));
}
.chat .chat-sidebar {
  width: 100%;
}
@media (min-width: 576px) {
  .chat .chat-sidebar {
    width: auto;
    background: transparent;
    border: 0;
    position: static;
    transform: none;
  }
}
@media (min-width: 1200px) {
  .chat .chat-sidebar {
    width: 360px;
    min-width: 360px;
    position: static;
    transform: none;
  }
}
.chat .chat-thread-tab .nav-item .nav-link.active {
  background: var(--phoenix-200);
  border-radius: 6px;
}
.chat .chat-thread-tab .nav-item .nav-link.unread .name {
  color: var(--phoenix-1100) !important;
  font-weight: 700 !important;
}
.chat .chat-thread-tab .nav-item .nav-link.unread .message {
  color: var(--phoenix-800) !important;
  font-weight: 600 !important;
}
.chat .chat-message .actions {
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.12), 0px 8px 24px -4px rgba(36, 40, 46, 0.12);
}
.chat .chat-message .chat-message-content {
  max-width: calc(100% - 4rem);
}
.chat .chat-message .chat-message-content.received {
  max-width: calc(100% - 6rem);
}
@media (min-width: 576px) {
  .chat .chat-message .chat-message-content {
    max-width: calc(100% - 10rem);
  }
  .chat .chat-message .chat-message-content.received {
    max-width: calc(100% - 11rem);
  }
}
.chat .chat-message .received-message-content {
  position: relative;
}
.chat .chat-message .received-message-content:not(.chat .chat-message .received-message-content.gallery):after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -10px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 10px solid;
  border-color: var(--phoenix-white) transparent transparent transparent;
}
.chat .chat-message .received-message-content:not(.chat .chat-message .received-message-content.gallery):before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -12px;
  right: auto;
  top: -1px;
  bottom: auto;
  border: 11px solid;
  border-color: var(--phoenix-200) transparent transparent transparent;
}
.chat .chat-message .sent-message-content {
  position: relative;
}
.chat .chat-message .sent-message-content:not(.chat .chat-message .sent-message-content.gallery):after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -12px;
  top: auto;
  bottom: 0;
  border: 12px solid;
  border-color: transparent transparent var(--phoenix-primary) transparent;
}
.chat .chat-textarea {
  max-height: 6.25rem;
}
.chat .chat-textarea[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: var(--phoenix-gray-500);
  font-weight: 600;
}

.chat-search-box {
  box-shadow: 0px 16px 80px -4px rgba(36, 40, 46, 0.3);
}
.chat-search-box .form-icon {
  top: 1.25rem !important;
}

#searchBoxModal .modal-content {
  background-color: transparent;
}
#searchBoxModal .modal-backdrop {
  background-color: transparent;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  50% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px -10px 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px -10px 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  }
}
.typing {
  position: relative;
}
.typing span {
  display: inline-block;
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: #3b5998;
  border-radius: 50%;
}
.typing span:nth-child(2) {
  animation-delay: 0.2s;
  margin-left: 15px;
}
.typing span:nth-child(3) {
  animation-delay: 0.4s;
  margin-left: 30px;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
.lead-details-container .nav-underline-scrollspy {
  z-index: 1014;
  top: calc(var(--phoenix-navbar-top-height) + 1px);
}

.deal-details .nav-link {
  color: var(--phoenix-gray-1100);
  font-weight: 600;
}
.deal-details .nav-link .tab-icon-color {
  color: var(--phoenix-gray-700);
}
.deal-details .nav-link.active {
  color: var(--phoenix-primary);
}
.deal-details .nav-link.active .tab-icon-color {
  color: var(--phoenix-primary);
}

.sticky-leads-sidebar {
  position: sticky;
  z-index: 1015;
  top: calc(var(--phoenix-navbar-top-height) + 21px);
}

.leads-table th {
  position: relative;
}
.leads-table th::after {
  position: absolute;
  top: 35%;
}

.lead-details-offcanvas {
  max-width: 350px;
  top: var(--phoenix-navbar-top-height) !important;
  height: calc(100vh - var(--phoenix-navbar-top-height));
  padding: 1.5rem;
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .lead-details-offcanvas {
    position: static !important;
    top: 1rem !important;
    padding: 0 !important;
    transform: none !important;
    max-width: unset;
    height: auto;
  }
}

.icon-wrapper-sm {
  position: relative;
  display: inline-block;
}
.icon-wrapper-sm::before {
  position: absolute;
  content: "";
  height: 1.299rem;
  width: 1.299rem;
  border-radius: 0.243rem;
  top: 0.5rem;
  left: 0.2rem;
}

.shadow-primary-100:before {
  background-color: rgba(var(--phoenix-primary-rgb), 0.3);
}

.shadow-info-100:before {
  background-color: rgba(var(--phoenix-info-rgb), 0.3);
}

.list-group-item.list-group-crm {
  padding: 0.3rem 0;
}

.echart-contacts-width {
  min-width: 19rem !important;
  min-height: 320px;
  overflow: hidden;
}
@media (min-width: 576px) {
  .echart-contacts-width {
    min-width: unset !important;
  }
}
@media (min-width: 768px) {
  .echart-contacts-width {
    min-height: 435px !important;
  }
}
@media (min-width: 992px) {
  .echart-contacts-width {
    min-height: 320px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1539.98px) {
  .echart-contacts-width {
    min-height: 430px !important;
  }
}

@media (min-width: 576px) {
  .table-stats tr th:nth-child(1) {
    width: 45%;
  }
  .table-stats tr th:nth-child(1) {
    width: 5%;
  }
  .table-stats tr th:nth-child(1) {
    width: 50%;
  }
}
.table-stats tr td {
  display: table-row;
}
@media (min-width: 576px) {
  .table-stats tr td {
    display: table-cell;
  }
}

.reports-details-chart-table tbody tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0 !important;
}

.deals {
  width: 146.06rem;
  white-space: nowrap;
  height: calc(100vh - var(--phoenix-navbar-top-height) - 15.625rem - 4rem);
}
@media (min-width: 1200px) {
  .deals {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 12.375rem - 4rem);
  }
}
.deals .deals-col {
  width: 22.875rem;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  white-space: nowrap;
}
.deals .deals-col .deals-items-container {
  height: calc(100% - 5.18rem);
}
.deals .deals-col .deals-items-container .sortable-ghost {
  position: relative;
}
.deals .deals-col .deals-items-container .sortable-ghost::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--phoenix-ghost-bg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  border-radius: 0.375rem;
}
.dark .deals .deals-col .deals-items-container .sortable-ghost::before {
  background-color: #141824;
}
.deals .deals-col .deals-items-container .sortable-drag .card {
  border: 0 !important;
  transform: rotate(-5deg) !important;
  background-color: var(--phoenix-drag-bg);
  top: 5px;
}
.deals .deals-col .deals-items-container .dropdown-indicator-icon {
  right: 1.5rem;
  top: 1.25rem;
}
.deals .deals-col .deals-items-container .dropdown-indicator-icon .fa-angle-down {
  transition: 0.5s ease transform;
}
.deals .deals-col .deals-items-container [aria-expanded=true].dropdown-indicator-icon .fa-angle-down {
  transform: rotate(180deg);
}
.deals .deals-col .deals-items-container [aria-expanded=true] ~ .deals-company-agent {
  display: none !important;
}
.deals .deals-col .deals-items-container [aria-expanded=true] ~ .deals-items-head .deals-revenue {
  display: none !important;
}
.deals .deals-col .deals-items-container [aria-expanded=true] ~ .deals-items-head .deals-category {
  display: flex !important;
}

.kanban-deals-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
}

.crm-deals-breadcrumb {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.bg-auth-card-overlay {
  mix-blend-mode: overlay;
}

.auth-card {
  background-color: #fff;
}
.dark .auth-card, .dark .picmo__popupContainer.picmo__light .auth-card {
  background-color: #0f111a;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .auth-card {
    width: 27rem;
    margin: 0 auto;
  }
}
.auth-card .auth-title-box {
  width: 100%;
}
@media (min-width: 768px) {
  .auth-card .auth-title-box {
    width: 16.875rem;
  }
}
@media (min-width: 992px) {
  .auth-card .auth-title-box {
    width: 23.4375rem;
  }
}
.auth-card .auth-title-box .auth-title-box-img {
  width: 9.5rem;
  height: 7.56rem;
}
@media (min-width: 768px) {
  .auth-card .auth-title-box .auth-title-box-img {
    width: 12.5rem;
    height: 9.875rem;
  }
}
@media (min-width: 992px) {
  .auth-card .auth-title-box .auth-title-box-img {
    width: 17.93rem;
    height: 14.18rem;
  }
}
@media (min-width: 768px) {
  .auth-card .auth-title-box .card-sign-up {
    padding-bottom: 10rem !important;
  }
}
@media (min-width: 1200px) {
  .auth-card .auth-title-box .card-sign-up {
    padding-bottom: 3rem !important;
  }
}
.auth-card .auth-form-box {
  margin: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .auth-card .auth-form-box {
    max-width: 16.625rem;
  }
}
@media (min-width: 992px) {
  .auth-card .auth-form-box {
    max-width: 18.75rem;
  }
}
@media (min-width: 1540px) {
  .auth-card .auth-form-box {
    max-width: 25rem;
  }
}

.support-chat-container {
  display: none;
}
.support-chat-container.show {
  display: block;
}

.support-chat {
  position: fixed;
  bottom: 3rem;
  right: 0;
  max-width: 27.87rem;
  width: 100%;
  transform: scale(0);
  opacity: 0;
  transform-origin: bottom right;
  z-index: 1045;
  transition: 0.3s ease-out;
  padding-bottom: 3rem;
}
.support-chat-start .support-chat {
  right: auto;
  left: 0;
  transform-origin: bottom left;
}
.support-chat-bottom-lg .support-chat {
  bottom: 5rem;
}
@media (min-width: 576px) {
  .support-chat {
    right: 1rem;
  }
  .support-chat-start .support-chat {
    left: 1rem;
  }
}
@media (min-width: 992px) {
  .support-chat {
    right: 2rem;
  }
  .support-chat-start .support-chat {
    left: 2rem;
  }
}
.support-chat .card {
  box-shadow: 0px 0px 32px 0px rgba(36, 40, 46, 0.12);
}
.dark .support-chat .card {
  box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.41);
}
.support-chat .card-body {
  height: 27rem;
}
.support-chat.show-chat {
  transform: scale(1);
  opacity: 1;
}
.support-chat .send-btn {
  width: 37.06px;
  height: 37.06px;
  border-radius: 50%;
  color: var(--phoenix-primary);
}
.support-chat .send-btn:hover {
  background-color: var(--phoenix-gray-100);
  color: var(--phoenix-primary-500);
}
.support-chat .send-btn:active {
  background-color: var(--phoenix-gray-200);
  color: var(--phoenix-primary-500);
}

.support-chat + .btn-support-chat {
  position: fixed;
  bottom: 2.5rem;
  right: 1rem;
  width: 9rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: var(--phoenix-white) !important;
  z-index: 1045;
  border-radius: var(--phoenix-border-radius-pill);
  box-shadow: 0px 0px 32px 0px rgba(36, 40, 46, 0.12);
  transition: 0.3s ease;
}
.dark .support-chat + .btn-support-chat, .dark .picmo__popupContainer.picmo__light .support-chat + .btn-support-chat {
  box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.41);
}
.support-chat-start .support-chat + .btn-support-chat {
  right: auto;
  left: 1rem;
}
.support-chat-bottom-lg .support-chat + .btn-support-chat {
  bottom: 4.5rem;
}
@media (min-width: 576px) {
  .support-chat + .btn-support-chat {
    right: 2rem;
  }
  .support-chat-start .support-chat + .btn-support-chat {
    left: 2rem;
  }
}
@media (min-width: 992px) {
  .support-chat + .btn-support-chat {
    right: 3rem;
  }
  .support-chat-start .support-chat + .btn-support-chat {
    left: 3rem;
  }
}
.support-chat + .btn-support-chat:hover {
  background-color: var(--phoenix-gray-100) !important;
}
.support-chat + .btn-support-chat .fa-chevron-down {
  display: none;
}
.support-chat + .btn-support-chat.btn-chat-close {
  border-radius: 50%;
  width: 3rem;
}
.support-chat + .btn-support-chat.btn-chat-close .btn-text,
.support-chat + .btn-support-chat.btn-chat-close .fa-circle {
  display: none;
}
.support-chat + .btn-support-chat.btn-chat-close .fa-chevron-down {
  display: block;
}

.kanban-content {
  padding-top: var(--phoenix-navbar-top-height);
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
}
.kanban-content .phoenix-offcanvas {
  width: 100%;
  top: var(--phoenix-navbar-top-height);
  padding: 1rem 2rem;
  height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
}
.kanban-content .phoenix-offcanvas.show ~ .phoenix-offcanvas-backdrop {
  top: var(--phoenix-navbar-top-height);
  height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem);
}

.kanban-container {
  white-space: nowrap;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: var(--phoenix-kanban-bg);
  height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem - 106px);
}
@media (min-width: 768px) {
  .kanban-container {
    height: calc(100vh - var(--phoenix-navbar-top-height) - 4rem - 63px);
  }
}
.kanban-container .sortable-drag {
  border: 0 !important;
}
.kanban-container .sortable-drag .kanban-item-card {
  transform: rotate(-5deg) !important;
  background-color: var(--phoenix-drag-bg);
  top: 5px;
}
.kanban-container .kanban-item {
  cursor: pointer;
}
.kanban-container .kanban-item:active {
  cursor: grabbing;
}
.kanban-container .kanban-item.sortable-ghost .kanban-item-card {
  position: relative;
}
.kanban-container .kanban-item.sortable-ghost .kanban-item-card::before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--phoenix-ghost-bg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  border-radius: 0.375rem;
}
.kanban-container .kanban-item-card {
  background-color: var(--phoenix-kanban-column-bg);
}
.kanban-container .kanban-item-card:hover {
  background-color: var(--phoenix-kanban-column-hover-bg);
}
.kanban-container .kanban-items-container {
  opacity: 1;
  transition: opacity 0.4s ease-out;
  transition-delay: 0.2s;
  min-height: 1rem;
}
.kanban-container .kanban-column {
  display: inline-block !important;
  width: 22rem;
  white-space: normal;
  vertical-align: top;
  margin-right: 1rem;
  background-color: var(--phoenix-kanban-column-bg);
  transition: width 0.2s ease;
  height: 100%;
  user-select: none;
}
.kanban-container .kanban-column-title {
  font-weight: 700;
  color: var(--phoenix-black);
}
.kanban-container .kanban-title-badge {
  border-radius: var(--phoenix-border-radius-pill);
  background-color: var(--phoenix-gray-200);
  font-size: 0.8rem;
  font-weight: 600;
  padding: 4px 9px;
  margin-left: 0.5rem;
  color: var(--phoenix-black);
  line-height: 1;
  display: inline;
}
.kanban-container .kanban-item-dropdown-btn {
  padding: 0 0.5rem;
  position: absolute !important;
  right: 0;
  top: 0;
  z-index: 2 !important;
}
.kanban-container .kanban-header-dropdown-btn {
  position: absolute !important;
  top: -15px;
  z-index: 2 !important;
}
.kanban-container .kanban-collapse-icon {
  cursor: pointer;
}
.kanban-container .kanban-collapse-icon.uil-arrow-from-right {
  display: none;
}
.kanban-container .kanban-column.collapsed {
  width: 3.5rem;
  transition: width 0.2s ease;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.kanban-container .kanban-column.collapsed .kanban-header-dropdown-btn {
  display: none !important;
}
.kanban-container .kanban-column.collapsed .kanban-header {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.kanban-container .kanban-column.collapsed .kanban-column-title {
  margin: 0;
  position: absolute;
  top: 10rem;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  white-space: nowrap;
  min-width: 10rem;
  text-align: end;
}
.kanban-container .kanban-column.collapsed .kanban-collapse-icon {
  margin: 0 auto;
}
.kanban-container .kanban-column.collapsed .kanban-collapse-icon.uil-left-arrow-to-left {
  display: none;
}
.kanban-container .kanban-column.collapsed .kanban-collapse-icon.uil-arrow-from-right {
  display: inline;
}
.kanban-container .kanban-column.collapsed .kanban-add-task {
  display: none;
}
.kanban-container .kanban-column.collapsed .kanban-items-container {
  opacity: 0;
  transition: none;
}
.kanban-container .kanban-column.collapsed .kanban-items-container .kanban-item {
  display: none;
}

.kanban-add-task {
  display: flex;
}
.kanban-add-task button {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
}
.kanban-add-task button:hover {
  background-color: var(--phoenix-gray-400) !important;
}

.kanban-action-item {
  background-color: var(--phoenix-gray-soft);
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
}

.sortable-dragging * {
  cursor: grabbing !important;
}

.kanban-create-board .nav-underline .nav-item {
  flex: 1;
}
.kanban-create-board .nav-underline .nav-item .nav-link {
  text-align: center;
}
.kanban-create-board .nav-underline .nav-item .nav-link::before {
  display: none;
}

.kanban-column-input-container {
  position: relative;
}
.kanban-column-input-container .kanban-column-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-8px);
}
.kanban-column-input-container .form-floating input {
  padding-left: 2.25rem;
}
.kanban-column-input-container.flatpickr-input-sm .flatpickr-icon {
  transform: translateY(-13px);
}

.kanban-color-picker {
  min-width: 128px;
  padding: 14px 48px 14px 16px;
  background: var(--phoenix-white);
  height: 100%;
  width: 100%;
  border: 1px solid var(--phoenix-input-border-color);
  border-radius: var(--phoenix-border-radius);
  position: relative;
  background-image: var(--phoenix-form-select-indicator);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 11px 12px;
}
.kanban-color-picker::-webkit-color-swatch {
  border: none;
}

.kanban-column-item {
  display: flex;
  margin-bottom: 2rem;
}

.kanban-swatch-label {
  width: 50px;
  height: 40px;
  border: 1px solid var(--phoenix-border-color);
  border-radius: var(--phoenix-border-radius-pill);
}

.kanban-radio-bg-image {
  border-radius: var(--phoenix-border-radius-lg);
}

.kanban-swatch-radio:checked + .kanban-swatch-btn-shadow {
  border-color: var(--phoenix-primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--phoenix-primary-rgb), 0.5);
  outline: 0;
}
.kanban-swatch-radio:checked + .kanban-radio-bg-image {
  box-shadow: 0 0 0 0.2rem rgba(var(--phoenix-primary-rgb), 0.5);
}

.kanban-tag .kanban-tag-badge {
  width: 100%;
  transition: width 0.2s ease;
}
.kanban-tag .kanban-tag-action-icons {
  padding: 0.66rem 0.66rem;
  border-color: transparent;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.kanban-tag .kanban-tag-action-icons:hover {
  background-color: var(--phoenix-100) !important;
}
.kanban-tag:hover .kanban-tag-badge {
  width: calc(100% - 80px);
  transition: width 0.2s ease;
}
.kanban-tag:hover .kanban-tag-action-icons {
  opacity: 1;
}

.theme-wizard.theme-wizard-progress .nav-wizard .nav-item {
  pointer-events: none;
  flex: 1;
}
.theme-wizard.theme-wizard-progress .nav-wizard .nav-item:first-child .nav-link::before {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.theme-wizard.theme-wizard-progress .nav-wizard .nav-item:last-child .nav-link::before {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.theme-wizard.theme-wizard-progress .nav-wizard .nav-item .nav-link:before {
  content: "" !important;
  height: 6px !important;
  left: 0 !important;
  top: 48% !important;
  width: 100% !important;
  position: absolute !important;
  background-color: var(--phoenix-300) !important;
}
.theme-wizard.theme-wizard-progress .nav-wizard.nav-wizard-progress .nav-item .nav-link.active::before {
  background-color: var(--phoenix-primary) !important;
}
.theme-wizard.theme-wizard-progress .nav-wizard.nav-wizard-progress .nav-item .nav-link.complete::before, .theme-wizard.theme-wizard-progress .nav-wizard.nav-wizard-progress .nav-item .nav-link.done::before {
  background-color: var(--phoenix-primary) !important;
}

.kanban-column-underline-primary {
  position: relative;
}
.kanban-column-underline-primary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-primary);
}

.kanban-column-underline-secondary {
  position: relative;
}
.kanban-column-underline-secondary::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-secondary);
}

.kanban-column-underline-success {
  position: relative;
}
.kanban-column-underline-success::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-success);
}

.kanban-column-underline-info {
  position: relative;
}
.kanban-column-underline-info::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-info);
}

.kanban-column-underline-warning {
  position: relative;
}
.kanban-column-underline-warning::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-warning);
}

.kanban-column-underline-danger {
  position: relative;
}
.kanban-column-underline-danger::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-danger);
}

.kanban-column-underline-light {
  position: relative;
}
.kanban-column-underline-light::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-light);
}

.kanban-column-underline-dark {
  position: relative;
}
.kanban-column-underline-dark::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--phoenix-dark);
}

.kanban-radio-collapse input[aria-expanded=true] {
  pointer-events: none;
}
.kanban-radio-collapse input[aria-expanded=true] + label {
  pointer-events: none;
}

.kanban-board-bg img {
  max-width: 100%;
  width: 450px;
}
@media (min-width: 1200px) {
  .kanban-board-bg img {
    width: 600px;
  }
}

.kanban-boards-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}